import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken, fetchConTokenFile,handleResponse } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { descargarFactura } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';
import Table from 'components/table/Table'
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import Swal from 'sweetalert2'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    
    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "editar"},
        {
            field: 'id', headerName: 'Id',  maxWidth: 20 , renderCell: (params) => (
                <a href={`${path + '/edit/' +  params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'nombre', headerName: 'Nombre', renderCell: (params) => (
                <a href={`${path + '/edit/' +  params.row.id}`}>{params.value}</a>)
        }
        
    ];
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo,
          titulo: 'Tipos Mensajes' ,
          subtitulo: 'Lista de los tipos de mensajes configurados' 
        });
      };
      
      React.useEffect(() => {actualizarTitulo();},[]);

    return (
        <>
            <div className='container-fluid'>
                <div className='col-md-12'>
                    <Link to={`${path}/add`} className='btn btn-outline-success'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            fill='currentColor'
                            className='bi bi-plus-square'
                            viewBox='0 0 16 16'
                        >
                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                        </svg>
                        &nbsp; Nuevo
                    </Link>
                    &nbsp; &nbsp;
                </div>
                &nbsp;
                <div className=' col-12 col-sm-12 col-md-12'>
               
                    <ListTable
                        columns = {columns}
                        path = {path}
                        url={`MensajesEcommerce/TipoMensajeGetList?`}
                    />
                        {/*<Table
                            columns={[
                                '#',
                                'Nombre',
                                'Acciones'
                            ]}
                            rows={[
                                ['text', 'id'],
                                ['text', 'nombre'],
                                ['opciones','id']
                            ]}
                            opciones={"edit"}
                            path={path}
                            url={`/MensajesEcommerce/TiposMensajesLista?sucursal_Id=${getDataSession('sucursal')}`}
                        ></Table>*/}
                    </div>
              
                
            </div>

        </>
    );
};

export { List };
