import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken, fetchConTokenFile,handleResponse } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { descargarFactura } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';
import ListTable from 'components/table/ListTable'
import Modal from "components/modal/Modal";
import { useModal } from "hooks/useModal"
import Swal from 'sweetalert2'
import moment from 'moment';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { useGlobalState } from 'resources/GlobalStateContext';


import RenderButtons from 'components/table/RenderButtons';
const Mensajes = ({ match, saludo, subtitulo }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const {t} = useTranslation("common");
    const { path } = match;

    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path, opciones: "editar"},
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'usuario_Id', headerName: 'Usuario', flex: 1},
        { field: 'mensaje', headerName: 'Mensaje', flex: 1 },
        { field: 'estatusMensaje', headerName: 'Estatus', flex: 1},
        { field: 'tipoMensaje', headerName: 'Tipo Mensaje' , flex: 1},
        
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: t('app.Mensajes.title'),
          subtitulo: t('app.Mensajes.subtitle')
        });
      };
      React.useEffect(() => {actualizarTitulo();},[]);

    return (
        <>
            <div className='container-fluid'>
                
             
                    <div className='col-md-12'>
                    <ListTable 
                    columns = {columns}
                    path = {path}
                    url={`MensajesEcommerce/MensajesGetList?sucursal_Id=${getDataSession('sucursal')}`}
                  />


                        
                    
                </div>
                
            </div>

        </>
    );
};

export { Mensajes };
