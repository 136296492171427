import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';

import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';

const SellosFis = ({ history, match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [data, setData] = useState(['']);
    let [nombreCer, setNombreCer] = useState(['']);
    let [nombreKey, setNombreKey] = useState(['']);
    let [nombreCerFiel, setNombreCerFiel] = useState(['']);
    let [nombreKeyFiel, setNombreKeyFiel] = useState(['']);

    let [configuraciones, setConfiguraciones] = useState(['']);

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Empresa',
          subtitulo: ''
        });
      };
    React.useEffect(() => {
        async function getData() {
            const response = await fetchConToken('Empresas?id=' + getDataSession('empresa'));
            setData(response);
            setNombreCer(split(response.ruta_Cert));
            setNombreKey(split(response.ruta_Key));
            setNombreCerFiel(split(response.ruta_Cert_Fiel));
            setNombreKeyFiel(split(response.ruta_Key_Fiel));
        }

        getData();
        actualizarTitulo();
    }, []);

    const {
        indexOf,
        ruta_Cert,
        ruta_Key,
        clave,
        rfc,
        nombre,
        regimen_Fiscal,
        estatus,
        url_Personalizada,
        fecha_Registro,
        ruta_Cert_Fiel,
        ruta_Key_Fiel,
        clave_Fiel
    } = data;

    const handleInputChangeFile = ({ target }) => {
        const formData = new FormData();

        formData.append('empresa_id', getDataSession('empresa'));
        formData.append('sucursal_id', getDataSession('sucursal'));
        formData.append('tipo', 'certificados');

        formData.append('FileToUpload', target.files[0], target.files[0].name);

        fetchConToken('Archivos', formData, 'POST', true)
            .then((response) => {
                /*alertService.success('Se ha guardado el archivo.', {
                    keepAfterRouteChange: true,
                    autoClose: true
                });*/
                setData({
                    ...data,
                    [target.name]: response.url
                });
                if (target.name === 'ruta_Cert') {
                    setNombreCer(split(response.url));
                }
                if (target.name === 'ruta_Key') {
                    setNombreKey(split(response.url));
                }
                if (target.name === 'ruta_Cert_Fiel') {
                    setNombreCerFiel(split(response.url));
                }
                if (target.name === 'ruta_Key_Fiel') {
                    setNombreKeyFiel(split(response.url));
                }
            })
            .catch(alertService.error);
    };

    const saveInfoempresa = () => {
        data.estatus="A";
        fetchConToken('empresas', data, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    Object.entries(response.errors).map(([key, value]) => {

                        Swal.fire('Error','Termine la configuracion general para poder continuar: \r\n' + value[0], 'error');
                        return;
                    });

                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error','Termine la configuracion general para poder continuar: \r\n' +  response.mensajes[0], 'error');
                    return false;
                }

                Swal.fire('Exito', 'Los cambios se han guardado correctamente.', 'success');
            })
            .catch(alertService.error);
    };

    const handleInputChange = ({ target }) => {
        setData({
            ...data,
            [target.name]: target.value
        });
    };
    /*
    const handleInputChangeConfig = ({ target }) => {
        let configAux = configuraciones.filter((config) => config.nombre === target.name);
        configAux[0].valor = target.value;
        setConfiguraciones(
            configuraciones.map((configuracion) =>
                configuracion.nombre === target.name ? configAux[0] : configuracion
            )
        );
    };*/

    /*const getConfig = () => {
        fetchConToken('ConfiguracionesEmpresas/GetList?pagina=1&totalXpagina=100', null, 'GET')
            .then((response) => {
                setConfiguraciones(response);
            })
            .catch(alertService.error);
    };*/

    /*const agregaConfiguracionEmpresa = () => {
        fetchConToken('ConfiguracionesEmpresas/AgregaLista', configuraciones, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    alertService.error(response.mensaeje);
                    return;
                }
            })
            .catch(alertService.error);
    };*/

    var split = function (str) {
        console.log(str.split('\\').pop().split('/').pop());
        return str.split('\\').pop().split('/').pop();
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='mb-3'>
                    <div className='col-md-12'>

                        <div className='col-md-12 text-right'>
                            <button
                                className='btn btn-outline-success'
                                type='button'
                                onClick={saveInfoempresa}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-check-lg'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                </svg>
                                &nbsp; Guardar
                            </button>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className='card mb-3 '>
                            <div className='card-body text-start'>
                                <div className='card-title'>
                                    <h5>Certificados</h5>
                                </div>
                                <div className='row'>
                                    <div className='mb-3 '>
                                        <label htmlFor='ruta_Cert' className='form-label'>
                                            Certificado (.cer)
                                        </label>
                                        <label htmlFor='ruta_Cert' className='form-label'>
                                            ({nombreCer})
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control'
                                            name='ruta_Cert'
                                            onChange={handleInputChangeFile}
                                        />

                                        <label htmlFor='ruta_Key' className='form-label'>
                                            Llave (.key)
                                        </label>
                                        <label htmlFor='ruta_Cert' className='form-label'>
                                            ({nombreKey})
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control'
                                            name='ruta_Key'
                                            onChange={handleInputChangeFile}
                                        />

                                        <label htmlFor='clave' className='form-label'>
                                            Clave
                                        </label>
                                        <input
                                            type='password'
                                            className='form-control'
                                            name='clave'
                                            value={clave || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className='card-title'>
                                    <h5>Fiel</h5>
                                </div>
                                <div className='row'>
                                    <div className='mb-3 '>
                                        <label htmlFor='ruta_Cert' className='form-label'>
                                            Certificado Fiel (.cer)
                                        </label>
                                        <label htmlFor='ruta_Cert' className='form-label'>
                                            ({nombreCerFiel})
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control'
                                            name='ruta_Cert_Fiel'
                                            onChange={handleInputChangeFile}
                                        />

                                        <label htmlFor='ruta_Key' className='form-label'>
                                            Llave Fiel(.key)
                                        </label>
                                        <label htmlFor='ruta_Cert' className='form-label'>
                                            ({nombreKeyFiel})
                                        </label>
                                        <input
                                            type='file'
                                            className='form-control'
                                            name='ruta_Key_Fiel'
                                            onChange={handleInputChangeFile}
                                        />

                                        <label htmlFor='clave' className='form-label'>
                                            Clave Fiel
                                        </label>
                                        <input
                                            type='password'
                                            className='form-control'
                                            name='clave_Fiel'
                                            value={clave_Fiel || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

SellosFis.propTypes = {
    saludo: PropTypes.string
};

SellosFis.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { SellosFis };
