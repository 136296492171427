import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { Mensajes } from "./Mensajes";
import { AddEdit } from "./AddEdit";


import SLUGS from 'resources/slugs';

function Procesos({ match }) {
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={Mensajes} />
            <Route path={`${path}/edit/:id`} component={AddEdit} />
            <Redirect from="*" to={{
                                pathname: Mensajes
                            }}/>
        </Switch>
    ); 
}


export { Procesos };
