import React, { useState } from 'react';
import Chart from 'react-apexcharts'
import {COLORS} from './Constants'
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { moneyFormat } from 'helpers/formats';
import './styles.css';
import { useGlobalState } from 'resources/GlobalStateContext';
const TopProveedores = () => {
    let [proveedores, setProveedores] = useState([]);
    let [seriesTotales, setSeriesTotales] = useState([]);
    let [optionsTotales, setOptionsTotales] = useState({});
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    React.useEffect(() => {
        getTopProveedores()
        actualizarTitulo()
    }, []);
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Top proveedores',
          subtitulo: ''
        });
      };
    const getTopProveedores = async () => {
        const date = new Date();
        const sucursal = getDataSession('sucursal')
        const mesIndex = date.getMonth();
        const mes = mesIndex +1;
        await fetchConToken(
            'Dashboard/topProveedores?mes='+mes+'&anio='+date.getFullYear()+'&sucursal_id='+sucursal
        ).then((resp) => {
            setProveedores(resp)
            let array = []
            let labels = []
            resp.map((item,i)=>{
                array.push(item.totalcfdi)
                labels.push(item.nombre)
            })
            getTotales(array,labels)
          });
    }

    const getTotales = async (totales,labels) =>
    {
        setSeriesTotales(totales)
    
        setOptionsTotales({
            options: {
                colors: COLORS,
                labels: labels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        })
    }

    return (
        <div className='row contentEst' >
            <div className='col-md-8'>
                <div className='cardGraph'>
                    <p>Gráfico de proveedores que han facturado frecuentemente</p>
                    {
                        proveedores.length > 0
                        ?
                        <ul className='m-0 mt-5'>
                            {
                            proveedores.map((item,i)=>(
                                <li key={i} className='row-flex'>
                                    <p className='space-50 text-left'>{ item.nombre}</p>
                                    <p className='space-25'>{ item.totalcfdi } facturas</p>
                                    <p className='space-25 text-right font-weight-500'>{ item.total !== undefined ? moneyFormat(item.total) : '0' } MXN</p>
                                </li>
                            ))
                            }
                        </ul>
                        : ''
                    }  
                </div>
            </div>
            <div className='col-md-4'>
                <div className='cardGraph'>
                    <h1 className='colorDarkBlue'>Totales</h1>
                    <p>Comparativa de facturación</p>
                    {
                        seriesTotales.length > 0
                        ?
                        <Chart
                            options={optionsTotales}
                            series={seriesTotales}
                            height= {350}
                            type="pie"
                        />
                        : ''
                    }
                </div>
            </div>
        </div>
    )
}
export {TopProveedores};