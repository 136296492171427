import React from 'react';
import Stack from '@mui/material/Stack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const MenuDescargas = ({ data, onClickButton }) => {
    return (
        <Stack key='MenuDescarga' direction="row" justifyContent="center" alignItems="center" spacing={1} className='container-fluid list_overflow'>
            {data && data.map((item) => { return item && (<MenuDescarga key={'Menu_' + item.Titulo} item={item} onClickButton={onClickButton} />); })}
        </Stack>
    );
}

const MenuDescarga = ({ item, onClickButton }) => {
    const getIcono = (itemButton) => {
        switch (itemButton.Slug) {
            case 'PDF': return <PictureAsPdfIcon />;
            case 'EXCEL': 
            return <svg xmlns='http://www.w3.org/2000/svg' width='20' eight='20' fill='currentColor' className='bi bi-file-excel' viewBox='0 0 16 16'>
                <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
            </svg>
            default: return <CloudDownloadIcon />;
        }
    }

    const getButton = (itemButton) => {
        return (
            <button className='btn btn-outline-primary m-1' style={{ width: 'auto' }} onClick={() => onClickButton(itemButton.Slug)}>
                {getIcono(itemButton)}&nbsp; {itemButton.Titulo}
            </button>
        );
    }

    return (
        <div key={'card_' + item.Titulo} className='card'>
            {(item.Titulo && item.Titulo !== '') &&
            (<div key={'card_header_' + item.Titulo} className='card-header container-center'>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><label className='form-label'>{item.Titulo}</label></Stack>
            </div>)}
            <div key={'card_body_' + item.Titulo} className='card-body'>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <div style={{ width: '5px' }} />
                    {item.Data && item.Data.map((itemButton) => { return itemButton && getButton(itemButton); })}
                    <div style={{ width: '5px' }} />
                </Stack>
            </div>
        </div>
    );
}

export { MenuDescargas };