import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { setDataSession, setUserSession } from 'views/Utils/Common';
import { fetchSinToken } from 'helpers/fetch';
import { useForm } from 'react-hook-form';
import SLUGS from 'resources/slugs';
import {  rfcValido } from 'resources/utilities';
import videomz from 'images/login/microzync.m4v'

//////imagenes////
//////////////////
import fondo from 'images/login/registro/fondo.png';
import fondoMovil from 'images/login/registro/fondoMovil.png';
import logo from 'images/login/logo.png';

import amazon from 'images/login/recuperacion_contrasena/amazon.png'
import shopify from 'images/login/recuperacion_contrasena/shopify.png'
import mercado_libre from 'images/login/recuperacion_contrasena/mercado_libre.png'
import woocomerce from 'images/login/recuperacion_contrasena/woocomerce.png'
 /////////////////

import iconUser from 'images/registro/UserIcon.svg';
import iconTelefono from 'images/registro/TelIcon.svg';
import iconCorreo from 'images/registro/MailIcon.svg';
import iconContrasenia from 'images/registro/PwdIcon.svg';
import iconRfc from 'images/registro/RFCIcon.svg';


import './Login.css';
import "./Register.css";

import Swal from 'sweetalert2'

const Register = ({ history, saludo, subtitulo }) => {
    const { register, handleSubmit, reset } = useForm();
    const [loading, setLoading] = useState(false);
    let [usuario, setUsuario] = useState(['']);
    const { nombre = '', correo = '', pwd = '',confirm_pwd = '',telefono='' } = usuario;
    const isMobile = window.innerWidth <= 767;

    let [rfc, setRfc] = useState("");

    let [terminos, setTerminos] = useState(false);

    let [error, setError] = useState(['']);
    let [errores, setErrores] = useState({});
    let [success, setSuccess] = useState(['']);

    // handle button click of login form
    const handleLogin = () => {
        setError(null);
        setLoading(true);

        console.log(usuario)
        if(!terminos){
            Swal.fire('Atención','Acepte los terminos y condiciones para poder continuar.','info')
            setLoading(false);
            return;
        }
        const regex =/^[0-9]{10}$/i;
        if(!regex.test(usuario?.telefono))
        {
             Swal.fire('Error', "Escriba un número de teléfono válido", 'error');
             setLoading(false);
             return false;
        }
        if(usuario?.nombre === undefined)
        {
            Swal.fire('Atención','Capture su información para poder continuar.','info')
            setLoading(false);
            return;
        }
        if(usuario.nombre === undefined || usuario.nombre?.length === 0)
        {
            Swal.fire('Atención','Escriba su nombre de usuario.','info')
            setLoading(false);
            return;
        }
        if(usuario.correo === undefined ||  usuario.correo?.length === 0)
        {
            Swal.fire('Atención','Escriba su dirección de correo.','info')
            setLoading(false);
            return;
        }
        if(usuario.pwd === undefined || usuario.pwd?.length === 0)
        {
            Swal.fire('Atención','Capture una contraseña.','info')
            setLoading(false);
            return;
        }
        if(usuario.telefono === undefined ||  usuario.telefono?.length === 0)
        {
            Swal.fire('Atención','Escriba su teléfono.','info')
            setLoading(false);
            return;
        }
        if(rfc === undefined ||  rfc?.length === 0)
        {
            Swal.fire('Atención','Escriba su RFC.','info')
            setLoading(false);
            return;
        }
        var rfcCorrecto = rfcValido(rfc, false);
        if (!rfcCorrecto) {
            Swal.fire('Error', 'RFC no válido', 'error');
            setLoading(false);
            return;
        }
        
        usuario.rol = {
            id: 2,
            nombre: "Empresa"
          }
          usuario.empresa = {
            rfc: rfc,
            estatus: 'A'
          }
          if( usuario.confirm_pwd !== usuario.pwd)
          {
              Swal.fire('Error','Las contraseñas no coinciden.','error')
              setLoading(false);
              return;
          }
        fetchSinToken('Usuarios', usuario, 'POST')
            .then((response) => {
                setLoading(false);

                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0],'error')
                        setLoading(false);
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0],'error')
                    setLoading(false);
                    return false;
                }
                Swal.fire('Exito','Se ha registrado correctamente.','success')
                history.push( SLUGS.login);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const handleInputRFC = ({ target }) => {
        setRfc(target.value)
    };

    const handleInputChange = ({ target }) => {
        
        setUsuario({
            ...usuario,
            [target.name]: target.value
        });

        if( (target.name === 'confirm_pwd' && target.value !== usuario.pwd) || (target.name === 'pwd' && target.value !== usuario.confirm_pwd))
        {
            //Swal.fire('Error','Las contraseñas no coinciden.','error')
            setError('Las contraseñas no coinciden.');
        }
        else
        {
            setError('');
        }
    };

    function check(){
        console.log(terminos);
        setTerminos(!terminos);
        //setOpcion(target.value);
    };

    return (
        <>
      <div className='col-md-12 row' style={{height: '100vh'}}>
        <div className='col-md-7 colorfg text-center'  style={{"background-color":'#FAFAFA'}}>
            <div className='container text-center' style={{'marginTop':  isMobile ? '40%' : '5%'}}>
            <video src={videomz} loop muted width="600" id="background-video" height="300" type="video/mp4" autoplay="true" />

                {isMobile ? '' :
                <>
                    <h2 className='colorfg'><b>¡Conectamos sistemas!</b></h2>
                    <br></br>
                    <p  className='colorfg' >Administra tu negocio con nuestra plataforma, sincroniza stock, precios, ventas y mucho mas!.</p>
                  </>
                }
             
                        <br/>
                       
            </div>

        {isMobile ? '' : 
        <>
            <div className='col-6 col-sm-6 col-md-6 row text-center' style={{paddingTop: "2%",position: 'absolute',bottom: '7vh' }}>
                            <div className='col-2 col-sm-3 col-md-3 text-center' style={{ backgroundColor:'gray', borderTopLeftRadius: '10px'}}>
                                <img src={ woocomerce }  alt='' style={{width:'15vh'}} />
                            </div>
                            <div className='col-2 col-sm-3 col-md-3 text-center' style={{color:'gray', backgroundColor:'gray'}}>
                                <img src={ amazon }  alt=''  style={{width:'15vh'}}/>
                            </div>
                            <div className='col-2 col-sm-3 col-md-3 text-center' style={{color:'gray', backgroundColor:'gray'}}>
                                <img src={ mercado_libre }  alt='' style={{width:'15vh'}}/>
                            </div>
                            <div className='col-2 col-sm-3 col-md-3 text-center' style={{color:'gray', backgroundColor:'gray', borderTopRightRadius:'10px'}}>
                                <img src={ shopify }  alt='' style={{width:'15vh'}}/>
                            </div>
            </div>
            
            </>
        }
        </div> 
        <div className='col-md-5' style={{ marginTop: "0%"}}>
        <div
                    className='col-12'
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        backgroundImage: window.innerWidth < 768 ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${fondo})` : 'none',
                        backgroundSize: 'cover',
             
                    }}
                >
                    <form>
                        <div className='card'>
                            <div>
                                <div className='col-md-12 text-center'>
                                    <Link to={SLUGS.home}>
                                        <img className='img-fyt' src={ window.innerWidth < 768 ? logo : logo }  />
                                    </Link>
                                    <h2
                                        style={{
                                            color: '#01295F'
                                        }}
                                    >
                                        <b>¡Creación de cuentas!</b>
                                    </h2>
                                    <div className='col-md-12 text-center'>
                                    <p
                                        style={{
                                            color: window.innerWidth < 768 ? '#2D3277' : '#2D3277',
                                            padding: "0px 10% 0px 10%"
                                        }}
                                        
                                    >
                                       Administra tu comercio electrónico.
                                    </p>
                                    </div>
                                </div>
                                
                                <div className='col-md-11 text-center' style={{paddingBottom: '0px' , padding: isMobile ? '0px 30px 0px 30px' : '0px'}}>
                                <p style={{ color: 'red' }}>{error}</p>
                                {Object.entries(errores).map(([key, value]) => {
                                    return (
                                        <>
                                            <p style={{ color: 'red' }}>{value}</p>
                                        </>
                                    );
                                })}

                                    <div className='col-md-12  col-lg-12 col-xl-12 row'>
                                    <div className='col-md-1 col-lg-1 col-xl-1'></div>
                                        <div className='col-md-5 col-lg-5 col-xl-5'>
                                        <div className="text-left">
                                         <div className="input-registro">
                                        <label className='form-label'>
                                               Nombre completo
                                                </label>
                                                <div className="input-icon-form">
                                                <img
                                                    className="icon-registro"
                                                    src={iconUser}
                                                    alt="Icono Usuario"
                                                    style={{ width: '4vh',padding: '1vh 5px'}}
                                                />
                                                <input
                                                    type='text'
                                                    value={nombre}
                                                    name='nombre'
                                                    className='form-control'
                                                    onChange={handleInputChange}
                                                    placeholder='Ingrese su nombre y apellidos'
                                                />
                                                </div>
                                            </div>
                                            </div>
                                            <div className="text-left">
                                         <div className="input-registro">
                                        <label className='form-label'>
                                                Teléfono
                                                </label>
                                                <div className="input-icon-form">
                                                <img
                                                    className="icon-registro"
                                                    src={iconTelefono}
                                                    alt="Icono telefono"
                                                    style={{ width: '4vh',padding: '1vh 5px'}}
                                                />
                                                <input
                                                    type='text'
                                                    value={telefono}
                                                    name='telefono'
                                                    className='form-control'
                                                    onChange={handleInputChange}
                                                    placeholder='Ingrese su teléfono'
                                                />
                                            </div>
                                            </div>
                                            </div>

                                            <div className="text-left">
                                         <div className="input-registro">
                                            <label className='form-label'>
                                                    Correo
                                                </label>
                                                <div className="input-icon-form">
                                                <img
                                                    className="icon-registro"
                                                    src={iconCorreo}
                                                    alt="Icono correo"
                                                    style={{ width: '4vh',padding: '1vh 5px'}}
                                                />
                                                <input
                                                    type='text'
                                                    value={correo}
                                                    name='correo'
                                                    className='form-control'
                                                    onChange={handleInputChange}
                                                />
                                                </div>
                                            </div>
                                            </div>
                                            {isMobile ? '' :
                                            <div className='col-md-12 row text-left' style={{fontSize: "13px",paddingTop: "1%"}} >
                                                <label>Ya tengo una cuenta, <Link to={SLUGS.login} >Iniciar Sesión</Link></label>
                                            </div>}
                                        </div>
                                        <div className='col-md-6 col-lg-6 col-xl-6'>
                                        
                                        <div className="text-left">
                                         <div className="input-registro">
                                        <label className='form-label'>
                                                Contraseña
                                            </label>
                                            <div className="input-icon-form">
                                                <img
                                                    className="icon-registro"
                                                    src={iconContrasenia}
                                                    alt="Icono correo"
                                                    style={{ width: '4vh',padding: '1vh 5px'}}
                                                />
                                            <input
                                                type='password'
                                                value={pwd}
                                                name='pwd'
                                                className='form-control'
                                                placeholder='Contraseña'
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        </div>
                                        </div>

                                        <div className="text-left">
                                         <div className="input-registro">
                                        <label className='form-label'>
                                                Confirme contraseña
                                            </label>
                                            <div className="input-icon-form">
                                                <img
                                                    className="icon-registro"
                                                    src={iconContrasenia}
                                                    alt="Icono correo"
                                                    style={{ width: '4vh',padding: '1vh 5px'}}
                                                />
                                            <input
                                                type='password'
                                                value={confirm_pwd}
                                                name='confirm_pwd'
                                                className='form-control'
                                                placeholder='Contraseña'
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        </div>
                                        </div>

                                        <div className="text-left">
                                         <div className="input-registro">
                                        <label className='form-label'>
                                               RFC
                                                </label>
                                                <div className="input-icon-form">
                                                <img
                                                    className="icon-registro"
                                                    src={iconRfc}
                                                    alt="Icono Rfc"
                                                    style={{ width: '4vh',padding: '1vh 5px'}}
                                                />
                                                <input
                                                    type='text'
                                                    value={rfc}
                                                    name='nombre'
                                                    className='form-control'
                                                    onChange={handleInputRFC}
                                                    placeholder='Ingrese RFC'
                                                    required={true}
                                                />
                                                </div>
                                            </div>
                                            </div>

                                        <div className='text-left'>
                                        <label >
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='matriz'
                                            role='switch'
                                            onChange={check}
                                        />&nbsp;
                                          Acepto <a href={SLUGS.terminoscondiciones} >Términos y condiciones</a>
                                          </label>
                                          </div>

                                        <div className='text-left' style={{paddingTop: "7px"}}>
                                            <input
                                                type='button'
                                                className='btn btn-info button-login'
                                                value={loading ? 'Cargando...' : 'Crear cuenta'}
                                                onClick={handleLogin}
                                                disabled={loading}
                                                style={{width: "100%"}}
                                            />
                                        </div>
                                       
                                        {isMobile ? <>
                                            <div className='col-md-12 row text-left' style={{fontSize: "13px",paddingTop: "1%"}} >
                                                <label>Ya tengo una cuenta, <Link to={SLUGS.login} >Iniciar Sesión</Link></label>
                                            </div> 
                                            
                                        </>
                                          : ''}
                                            
                                    </div>
                                    </div>
                            </div>
                            
                                        <div style={{background: 'rgba(245, 245, 245, 1)'}}>
                                            <div className=' col-12 col-sm-12 col-md-12 row text-center' >
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                    <a href="https://atti.com.mx" target="_blank" rel="noopener noreferrer">AttiSolutions</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                        </div>
                        </div>
                    </form>
                </div>
              
        </div>

      </div>

        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

Register.propTypes = {
    saludo: PropTypes.string
};

Register.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { Register };
