import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { AddEdit } from "./AddEdit";
import { DiferenciasArt } from "./DiferenciasArt";
import { List } from "./List";
import { Asignacion } from "./Asignacion";
import { ListPublicador } from "./Publicador/ListPublicador";
import { ListEdicionPublicador } from "./Publicador/ListEdicionPublicador";
import { ListReferencias } from "./ListReferencias";

function Articulos({ match }) {
    const { path } = match;

    //console.log(`path- ${match.url}/add`);
    console.log(path+"- articulos list");
    return (
        <Switch>
            <Route exact path={path} component={List} />
            <Route path={`${path}/add`} component={AddEdit} />
            <Route path={`${path}/edit/:id`} component={AddEdit} />
            <Route path={`${path}/asignacion/:id`} component={Asignacion} />
            <Route path={`${path}/publicador/:tienda`} component={ListPublicador} />
            <Route path={`${path}/publicador_edicion/:tienda`} component={ListEdicionPublicador} />
            <Route path={`${path}/diferencias`} component={DiferenciasArt} />
            <Route path={`${path}/referencias/:id`} component={ListReferencias} />
            <Redirect from="*" to={{pathname: List}}/>
        </Switch>
    ); 
}


export { Articulos };

//export default Sucurs;