import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { MenuDescargas } from '../MenuDescargas';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import ListTable from 'components/table/ListTable';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';

const List = ({ history, match }) => {
    const { path } = match;
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [tipoDocumento, setTipoDocumento] = useState('Nota');
    const [cliente, setCliente] = useState([]);
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [dataMenu] = useState([{
        Titulo: 'Listado de Documentos',
        Data: [
            { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
            { Titulo: 'Descargar PDF', Slug: 'PDF' }
        ]
    }, {
        Titulo: 'Documentos',
        Data: [
            { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
            { Titulo: 'Descargar PDF', Slug: 'PDF' }
        ]
    }]);
    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "" },
        { field: 'folio', headerName: 'Folio', minWidth: 50 },
        { field: 'uuid', headerName: 'UUID', minWidth: 250 },
        { field: 'fecha_Timbrado', headerName: 'Fecha Timbrado', minWidth: 100 },
        { field: 'fecha_Registro', headerName: 'Fecha Registro', minWidth: 100 },
        { field: 'nombre_Receptor', headerName: 'Cliente', minWidth: 275 },
        { field: 'rfc_Receptor', headerName: 'RFC', minWidth: 100 },
        { field: 'subtotal', headerName: 'SubTotal', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'total_Impuestos', headerName: 'Impuestos', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'total', headerName: 'Total', type: 'number', renderCell: ({ value }) => currency(value) }
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Facturas',
            subtitulo: 'Listado de Facturas'
        });
    };

    const onClickButtonMenu = (slug) => {

    }

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    const handleChangeTipoDocumento = ({ target }) => {
        setTipoDocumento(target.value);
    }

    const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        var resp = await fetchConToken(`Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);
        return resp.data;
    };

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(`Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`);
        clienteAux.regimen_Fiscal_Receptor = clienteAux.regimen_Fiscal_Receptor.replace(' ') === '' ? '601' : clienteAux.regimen_Fiscal_Receptor;
        clienteAux.uso_Cfdi = clienteAux.uso_Cfdi.replace(' ') === '' ? 'G01' : clienteAux.uso_Cfdi;
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }

    useEffect(() => actualizarTitulo(), []);

    return (
        <>
            <MenuDescargas data={dataMenu} onClickButton={onClickButtonMenu} />
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-5' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                    <div className='col-sm-4'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <AsyncSelect
                                placeholder='&nbsp; Cliente'
                                aria-label='Cliente'
                                isClearable
                                getOptionLabel={(option) => 'Cliente:' + option.nombre + ' - ' + option.rfc }
                                getOptionValue={(option) => option.id}
                                loadOptions={loadClientes}
                                onChange={(selectedItem) => { getCliente(selectedItem === null ? '' : selectedItem?.id); }}
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-3'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="documento-label">Tipo Documento</InputLabel>
                            <Select label="Tipo Fecha" value={tipoDocumento} onChange={handleChangeTipoDocumento}>
                                <MenuItem key='Nota' value='Nota'><ListItemText primary='Nota de Venta' /></MenuItem>
                                <MenuItem key='Remision' value='Remision'><ListItemText primary='Remision' /></MenuItem>
                                <MenuItem key='Factura' value='Factura'><ListItemText primary='Factura' /></MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        reload={actualizarTabla} 
                        url={`Facturacion/Facturas/CfdisGetList?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`} />
                </div>
            </div>
        </>
    );
}

export { List };