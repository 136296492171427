import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SLUGS from '../../resources/slugs';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession, setDataSession } from 'views/Utils/Common';
import { useTranslation } from "react-i18next";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import StoreIcon from '@mui/icons-material/Store';
import SyncIcon from '@mui/icons-material/Sync';
import Settings from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import Swal from 'sweetalert2';
import { useGlobalState } from 'resources/GlobalStateContext';

const AddEditSucursal = ({ history, match }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const _AUTH_MERCADOLIBRE = process.env.REACT_APP_AUTH_MERCADOLIBRE;
    const path = match.url;
    const { id, empresa } = match.params;
    const isAddMode = !id;
    const { t } = useTranslation("common");
    let [data, setData] = useState(['']);
    const { code } = data;
    const { register, handleSubmit, reset } = useForm();
    let [error, setError] = useState(['']);
    let [erroresCliente, setErroresCliente] = useState({});
    const [valueTab, setValueTab] = useState(0);
    let [folios_sucursales, setFoliosSucursales] = useState();
    let [folios_cotizacion, setFoliosCotizacion] = useState();
    let [folios_factura, setFoliosFactura] = useState();
    let [folios_venta, setFoliosVentas] = useState();
    let [folios_remision, setFoliosRemision] = useState();
    let [serie_ordencompra, setFoliosOrdenCompra] = useState();
    let [serie_recepcioncompra, setFoliosRecepcionCompra] = useState();
    let [serie_compra, setFoliosCompra] = useState();
    let [serie_devolucion, setFoliosDevolucion] = useState();
    let [serie_ordenReparacion, setFoliosOrdenReparacion] = useState();
    let [tienda, setTienda] = useState(['']);
    let [confiTienda, setConfiTienda] = useState(['']);
    let { url_Tienda = '', api_Key = '', api_Secret = '' } = confiTienda;
    let [sucursal, setSucursal] = useState('');
    let [almacenes, setAlmacenes] = useState([]);
    const {
        empresa_Id = getDataSession('empresa'),
        nombre = '',
        cp = '',
        calle = '',
        no_Exterior = '',
        no_Interior = '',
        colonia = '',
        poblacion = '',
        municipio = '',
        ciudad = '',
        estado = '',
        pais = '',
        matriz = '',
        estatus = 'A',
        desglosar_Impuesto = false,
        configuracion_Sucursal = [],
        registrar_Almacen_Vinculado = false,
        descontar_x_Prioridad = false,
        descontar_x_Mayor_Existencias = false,
        guardar_Venta_Almacen = false,
        almancen_Id = null,
        principal = false,
    } = sucursal;
    let [configSucursalSincro, setConfigSucursalSincro] = useState('');
    const {
        sinc_existencias_to_api = false,
        sinc_stop_stock_min_to_api = false,
        sinc_precios_to_api = false,
        sinc_precios_mas_comisiones = false,
        save_vta_pago_neto = false,
        sinc_vta_full = false,
        sinc_vta_pack_mode = false,
        sinc_vta_envio = false,
        sinc_vta = false,
        sinc_vta_cancel = false,
        stock_min_to_api = '5',
        sinc_tienda_to_api = false,
        tienda_api_id = ''
    } = configSucursalSincro;
    const [almacenesMultiple, setAlmacenesMultiple] = useState([]);
    const [sucursalMatriz, setSucursalMatriz] = useState({});
    const [listaPrecios, setListaPrecios] = useState([]);
    const [tiendasML, setTiendasML] = useState([]);
    const [tiendaML, setTiendaML] = useState();
    const [listaPrecioPrincipal, setListaPrecioPrincipal] = useState({});
    const [almacenPrincipal, setAlmacenPrincipal] = useState({});
    const [precioEmpresa, setPrecioEmpresa] = useState('0');
    const ITEM_HEIGHT = 50;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleChange = ({ target }) => {
        const { value } = target;
        if (target.name === 'sinc_precio_empresa_id') {
            setPrecioEmpresa(value);
            setListaPrecioPrincipal({ ...listaPrecioPrincipal, lista_Padre_Id: value });
        }
        else setAlmacenesMultiple(typeof value === 'string' ? value.split(',') : value,);
    };

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    }

    const handleChangeTienda = ({ target }) => {
        var confg = sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === 'tienda_api_id');
        if (confg && confg[0]) confg[0].valor = target.value;
        else sucursal.configuracion_Sucursal.push({
            "sucursal_Id": id,
            "nombre": 'tienda_api_id',
            "valor": target.value,
            "es_Visible": true,
            "descripcion": 'tienda_api_id'
        });

        setConfigSucursalSincro({
            ...configSucursalSincro,
            ['tienda_api_id']: target.value
        });
        setSucursal({ ...sucursal });
    }

    const handelChangeSelect = ({ target }) => {
        const xCheck = target.checked;
        if (xCheck) GetTiendasML();

        var confg = sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === 'sinc_tienda_to_api');
        if (confg && confg[0]) confg[0].valor = xCheck ? 'S' : 'N';
        else sucursal.configuracion_Sucursal.push({
            "sucursal_Id": id,
            "nombre": 'sinc_tienda_to_api',
            "valor": xCheck ? 'S' : 'N',
            "es_Visible": true,
            "descripcion": 'sinc_tienda_to_api'
        });

        setConfigSucursalSincro({
            ...configSucursalSincro,
            ['sinc_tienda_to_api']: xCheck
        });
        setSucursal({ ...sucursal });
    }

    function onSubmit(data) {
        if (!ValidarSincExistanciasPrecios()) return;

        if (!ValidarSincTiendaApi()) return;

        return isAddMode ? create(data) : update(id, data);
    }

    function create(data) {
        data.empresa_Id = empresa;
        console.log(data);
        setErroresCliente('');
        setError('');
        return fetchConToken('Sucursales', data, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                UpdateSincExistanciasPrecios();
                Swal.fire('Exito', 'Conexión agregada', 'success');
                history.push(SLUGS.sucursales);
            })
            .catch(alertService.error);
    }

    async function update(id, data) {
        if (tienda === 'MERCADOLIBRE') {
            //await saveCode(data);
        }
        else await saveECommerce(tienda, data);

        if (data.guardar_Venta_Almacen && (data.almancen_Id == 0 || !data.almancen_Id)) {
            Swal.fire('Info', "Seleccione un almacen en: Registrar ventas a la conexión principal", 'info');
            return;
        }

        console.log(tienda)
        console.log(data);
        return fetchConToken('Sucursales', data, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                UpdateSincExistanciasPrecios();
                Swal.fire('Exito', 'Conexión actualizada', 'success');
                history.push(SLUGS.sucursales);
            })
            .catch(alertService.error);
    }

    function UpdateSincExistanciasPrecios() {
        if (configSucursalSincro.sinc_existencias_to_api) {
            almacenPrincipal.consolidar = true;
            fetchConToken('Almacenes', almacenPrincipal, 'PUT')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }
                })
                .catch(alertService.error);
        }

        if (configSucursalSincro.sinc_precios_to_api) {
            fetchConToken('ListaPrecios', listaPrecioPrincipal, 'PUT')
                .then((response) => {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        var erroresC = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return false;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }
                })
                .catch(alertService.error);
        }
    }

    function ValidarSincExistanciasPrecios() {
        if (configSucursalSincro.sinc_existencias_to_api) {
            var almacenesOrg = GetAlmacenesRelacionados();
            if (almacenesOrg == null || almacenesOrg.length == 0) {
                Swal.fire('Error', 'Al activar la sincronizacion de existencias debe seleccionar uno o mas almacenes', 'error');
                return;
            }

            almacenPrincipal.almacenes_Origen_Ids = '|' + almacenesOrg.join('||') + '|';
        }

        if (configSucursalSincro.sinc_precios_to_api) {
            if (listaPrecioPrincipal.lista_Padre_Id == null || listaPrecioPrincipal.lista_Padre_Id == 0) {
                Swal.fire('Error', 'Al activar la sincronizacion de precios debe seleccionar una lista de precio', 'error');
                return;
            }
        }

        return true;
    }

    function ValidarSincTiendaApi() {
        if (tienda === 'MERCADOLIBRE') {
            if (configSucursalSincro.sinc_tienda_to_api) {
                var cfg = sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === 'tienda_api_id');
                if (cfg && cfg[0] && cfg[0].valor && cfg[0].valor !== '') return true;
                
                Swal.fire('Error', 'Al activar la configuracion de tienda oficial debe seleccionar una tienda', 'error');
                return;
            }
        }
        
        var confg = sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === 'sinc_tienda_to_api');
        if (confg && confg[0]) confg[0].valor = 'N';
        
        var confg2 = sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === 'tienda_api_id');
        if (confg2 && confg2[0]) confg2[0].valor = '';

        setSucursal({ ...sucursal });
        return true;
    }

    function ListFoliosSucursales() {
        return fetchConToken(`FoliosSucursales/FoliosSucursales?sucursal_id=${id}`)
            .then((response) => {

                setFoliosCotizacion(response?.filter(x => x.serie_Documento == 'serie_cotizacion'));
                setFoliosFactura(response?.filter(x => x.serie_Documento == 'serie_factura'));
                setFoliosVentas(response?.filter(x => x.serie_Documento == 'serie_pedido'));
                setFoliosRemision(response?.filter(x => x.serie_Documento == 'serie_remision'));

                setFoliosOrdenCompra(response?.filter(x => x.serie_Documento == 'serie_ordencompra'));
                setFoliosRecepcionCompra(response?.filter(x => x.serie_Documento == 'serie_recepcioncompra'));
                setFoliosCompra(response?.filter(x => x.serie_Documento == 'serie_compra'));
                setFoliosDevolucion(response?.filter(x => x.serie_Documento == 'serie_devolucion'));
                setFoliosOrdenReparacion(response?.filter(x => x.serie_Documento == 'serie_orden_reparacion'));
            })
            .catch(alertService.error);
    }

    function GetAlmacenes() {
        return fetchConToken('Almacenes/GetListByPrincipal')
            .then((response) => {
                setAlmacenes(response);
                GetAlmacenPrincipal(response);
            })
            .catch(alertService.error);
    }

    function GetMatriz() {
        return fetchConToken('Sucursales/Principal')
            .then((response) => {
                setSucursalMatriz(response);
                GetListaPrecios(response.id);
            })
            .catch(alertService.error);
    }

    function GetListaPrecios(sucursal_Id) {
        return fetchConToken(`ListaPrecios/GetList?paginas=${0}&totalXpagina=${50}&sucursal_Id=${sucursal_Id}`)
            .then((response) => { setListaPrecios(response.data); GetListaPrecioPrincipal(); })
            .catch(alertService.error);
    }

    function GetListaPrecioPrincipal() {
        return fetchConToken(`ListaPrecios/GetPrincipalBySucursal?sucursal_Id=${id}`)
            .then((response) => {
                if (response == null || response === "") {
                    Swal.fire('Info', 'Configure una lista de precios principal antes de configurar la sucursal', 'info');
                    history.push(SLUGS.sucursales);
                }

                setListaPrecioPrincipal(response);
                setPrecioEmpresa(response?.lista_Padre_Id ?? '0');
            })
            .catch(alertService.error);
    }

    function GetAlmacenPrincipal(almacenes) {
        return fetchConToken(`Almacenes/GetPrincipalBySucursal?sucursal_Id=${id}`)
            .then((response) => {
                //console.log(response);
                if (response == null || response === "") {
                    Swal.fire('Info', 'Configure un almacen principal antes de configurar la sucursal', 'info');
                    history.push(SLUGS.sucursales);
                }

                setAlmacenPrincipal(response);
                if (response && response.almacenes_Origen_Ids) {

                    var ids = response.almacenes_Origen_Ids?.split('||').filter(str => str !== '').map(str => str.replace(/\|/g, ''));
                    if (ids && ids.length) {
                        ids.forEach(element => {
                            var almacen = almacenes.find(x => x.id == element);
                            if (almacen && !(almacenesMultiple?.indexOf(almacen.nombre) > -1)) {
                                almacenesMultiple.push(almacen.nombre);
                            }
                        });
                    }
                }
            })
            .catch(alertService.error);
    }

    function GetAlmacenesRelacionados() {
        var ids = [];
        almacenesMultiple.forEach(element => {
            var temp = almacenes.find(x => x.nombre === element);
            if (temp) ids.push(temp.id);
        });
        //almacenPrincipal?.consolidar = true;
        return ids;
    }

    function GetTiendasML() {
        return fetchConToken(`ConfiguracionesSucursales/GetTiendasAPI?sucursal_id=${id}`)
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                setTiendasML(response);
            }).catch(alertService.error);
    }

    const handleInputChange = ({ target }) => {
        if (target.name !== 'stock_min_to_api') setSucursal({
            ...sucursal,
            [target.name]: target.value
        });
        else {
            var confg = sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === target.name?.toUpperCase());
            if (confg && confg[0]) confg[0].valor = target.value;
            else sucursal.configuracion_Sucursal.push({
                "sucursal_Id": id,
                "nombre": target.name.toLowerCase(),
                "valor": target.value,
                "es_Visible": true,
                "descripcion": target.name.toLowerCase()
            });

            setConfigSucursalSincro({
                ...configSucursalSincro,
                [target.name]: target.value
            });
            setSucursal({ ...sucursal });
        }

    };

    const handleInputChangeSerie = ({ target }) => {

        const existeSerie = sucursal.configuracion_Sucursal.find(item => item.nombre === target.name);

        if (existeSerie) {
            sucursal.configuracion_Sucursal.map(item => {
                if (item.nombre == target.name)
                    item.valor = target.value;
            })
        }
        else {

            sucursal.configuracion_Sucursal.push(
                {
                    "sucursal_Id": sucursal.id,
                    "nombre": target.name,
                    "valor": target.value,
                    "es_Visible": true,
                    "descripcion": target.name
                }
            );
        }

        setSucursal({ ...sucursal });
    };

    const handleInputChangeTienda = ({ target }) => {
        setConfiTienda({
            ...confiTienda,
            [target.name]: target.value
        });
    };

    const handleInputChangeCode = ({ target }) => {
        setData({
            ...data,
            [target.name]: target.value
        });
    };

    const onChangeCheckActivo = ({ target }) => {
        const xCheck = target.checked;

        if (!guardar_Venta_Almacen) {
            if (target.name === 'descontar_x_Prioridad') sucursal.descontar_x_Mayor_Existencias = !xCheck;

            if (target.name === 'descontar_x_Mayor_Existencias') sucursal.descontar_x_Prioridad = !xCheck;

        }

        if (target.name === 'principal' && target.checked) {
            sucursal.guardar_Venta_Almacen = false;
            sucursal.almancen_Id = null;

            Swal.fire({
                text: "¿Seguro de asignar esta sucursal como principal?. Una vez asignada no se podra cambiar",
                showCancelButton: true,
                confirmButtonText: 'Si, asignar',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (!result.isConfirmed) {
                    setSucursal({
                        ...sucursal,
                        [target.name]: false
                    });
                    return;
                }
            });
        }

        setSucursal({
            ...sucursal,
            [target.name]: xCheck
        });
    };

    const onChangeCheckActivoConfig = ({ target }) => {
        const xCheck = target.checked;
        var confg = sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === target.name?.toUpperCase());
        if (confg && confg[0]) confg[0].valor = xCheck ? 'S' : 'N';
        else sucursal.configuracion_Sucursal.push({
            "sucursal_Id": id,
            "nombre": target.name.toLowerCase(),
            "valor": xCheck ? 'S' : 'N',
            "es_Visible": true,
            "descripcion": target.name.toLowerCase()
        });

        setConfigSucursalSincro({
            ...configSucursalSincro,
            [target.name]: xCheck
        });
        setSucursal({ ...sucursal });

    };

    async function saveECommerce(tiendaValor) {
        let respuesta = fetchConToken(
            `ConfiguracionesSucursales/${tiendaValor}?url_tienda=${url_Tienda}&key=${api_Key}&secret=${api_Secret}&sucursal_id=${id}`,
            null,
            'POST'
        ).then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                setError('');
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return;
                }
            }
        });
    };

    function isActive(sucursal, nombre) {
        return sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === nombre?.toUpperCase())[0]?.valor == 'S';
    }

    /*async function saveCode(){
        let respuesta = await  fetchConToken(
            `ConfiguracionesSucursales/ObtenerToken?Code=${code}&sucursal_id=${id}`
        )
            .then((response) => {
                setError('');
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
            })
            .catch(alertService.error);
    };*/

    useEffect(() => {
        GetMatriz();
        GetAlmacenes();
        ListFoliosSucursales();
        if (!isAddMode) {
            fetchConToken(`ConfiguracionesSucursales?sucursal_id=${id}&nombre=TIENDA`).then(
                (response) => {
                    setTienda(response.valor);
                    if (response.valor === 'MERCADOLIBRE') fetchConToken('ConfiguracionesSucursales/GetToken?sucursal_id=' + id).then((response) => { setData(response); });
                    else if (response && response.valor) fetchConToken(`ConfiguracionesSucursales/${response.valor}?sucursal_id=${id}`).then((response) => { setConfiTienda(response); });

                    fetchConToken('Sucursales?id=' + id).then((sucursal) => {
                        setSucursal(sucursal);
                        if (sucursal.configuracion_Sucursal) {
                            setConfigSucursalSincro({
                                sinc_existencias_to_api: isActive(sucursal, 'sinc_existencias_to_api'),
                                sinc_stop_stock_min_to_api: isActive(sucursal, 'sinc_stop_stock_min_to_api'),
                                sinc_precios_to_api: isActive(sucursal, 'sinc_precios_to_api'),
                                sinc_precios_mas_comisiones: isActive(sucursal, 'sinc_precios_mas_comisiones'),
                                save_vta_pago_neto: isActive(sucursal, 'save_vta_pago_neto'),
                                sinc_vta_full: isActive(sucursal, 'sinc_vta_full'),
                                sinc_vta_pack_mode: isActive(sucursal, 'sinc_vta_pack_mode'),
                                sinc_vta_envio: isActive(sucursal, 'sinc_vta_envio'),
                                sinc_vta: isActive(sucursal, 'sinc_vta'),
                                sinc_vta_cancel: isActive(sucursal, 'sinc_vta_cancel'),
                                stock_min_to_api: sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === 'stock_min_to_api'.toUpperCase())[0]?.valor ?? '5',
                                sinc_tienda_to_api: response.valor === 'MERCADOLIBRE' ? isActive(sucursal, 'sinc_tienda_to_api') : false,
                                tienda_api_id: response.valor === 'MERCADOLIBRE' ? sucursal.configuracion_Sucursal?.filter(x => x.nombre?.toUpperCase() === 'tienda_api_id'.toUpperCase())[0]?.valor ?? '' : ''
                            });
                        }
                    });
                }
            );
        }
    }, []);

    useEffect(() => { reset(sucursal); }, [sucursal]);

    globalTitulo.titulo = isAddMode ? 'Agregar Canal' : 'Editar Canal';
    globalTitulo.subtitulo = '';

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-3'>
                        <div className='mb-3'>
                            <div className='col-md-12'>
                                <div className='col-md-12 text-right'>
                                    <button className='btn btn-outline-success' type='submit'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; Guardar
                                    </button>
                                    &nbsp;
                                    <Link
                                        to={(location) => ({
                                            pathname: `${SLUGS.sucursales}`
                                        })}
                                        className='btn btn-outline-secondary'
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-reply'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                        </svg>
                                        &nbsp; Regresar &nbsp;
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Tabs value={valueTab} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                            <Tab value={0} icon={<StoreIcon />} iconPosition="start" aria-label="phone" label='Tienda' />
                            <Tab icon={<SyncIcon />} value={1} iconPosition="start" aria-label="phone" label='Sincronizacion' />
                            <Tab value={2} icon={<Settings />} iconPosition="start" aria-label="phone" label={t('app.Articulos.form.tabs.tabGneral')} />
                            <Tab value={3} icon={<ListAltIcon />} iconPosition="start" aria-label="phone" label='Serie' />
                        </Tabs>
                        <div className='col-md-12'>
                            <p style={{ color: 'red' }}>{error}</p>
                            {Object.entries(erroresCliente).map(([key, value]) => {
                                return (<><p style={{ color: 'red' }}>{value}</p></>);
                            })}
                        </div>
                        <div className='espacio15px' />
                        {valueTab === 0 && (
                            <div className='col-md-6' style={{ display: 'grid' }}>
                                <div className='card mb-3'>
                                    <div className='card-body text-start'>
                                        <div className='espacio5px' />
                                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><div className='card-title'><h5>Configuracion de tu tienda {tienda}</h5></div></Stack>
                                        <div className='espacio10px' />
                                        {tienda === 'MERCADOLIBRE' ? (
                                            <div>
                                                &nbsp; <a href={_AUTH_MERCADOLIBRE} target='_blank' onClick={() => { setDataSession('sucursalMercadoLibre', id) }}>
                                                    Mercado libre code
                                                </a>
                                                <div className='espacio10px' />
                                                {code ? (
                                                    <>
                                                        <label>&nbsp; Mercado Libre configurado</label>
                                                        <div className='espacio5px' />
                                                    </>
                                                ) : (<label>&nbsp; Mercado Libre no configurado</label>)}
                                                {code && (
                                                    <div className='col-12 col-sm-12 col-md-12 row'>
                                                        <div className='col-sm-1' />
                                                        <div className='col-sm-4'><FormControlLabel control={<Checkbox name='tiendaOficial' checked={configSucursalSincro.sinc_tienda_to_api} onChange={handelChangeSelect} />} label="Es tienda Oficial" /></div>
                                                        <div className='col-sm-6'>
                                                            <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                                                                <InputLabel id="tienda-label">Tienda</InputLabel>
                                                                <Select label="Tienda" value={configSucursalSincro.tiendaML} onChange={handleChangeTienda}>
                                                                    {configSucursalSincro.sinc_tienda_to_api && tiendasML?.map((item) => {
                                                                        return <MenuItem key={item.clave_Tienda} value={item.clave_Tienda}><ListItemText primary={(item.nombre)} /></MenuItem>
                                                                    })}
                                                                </Select>
                                                            </FormControl>
                                                        </div>
                                                        <div className='col-sm-1' />
                                                    </div>
                                                )}
                                            </div>
                                        ) : ('')}
                                        {tienda === 'WOOCOMMERCE' ? (
                                            <div>
                                                <label htmlFor='nombre'>URL Tienda</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='url_Tienda'
                                                    value={url_Tienda}
                                                    onChange={handleInputChangeTienda}
                                                    required={true}
                                                />
                                                <label htmlFor='api_key'>API Key</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='api_Key'
                                                    value={api_Key}
                                                    onChange={handleInputChangeTienda}
                                                    required={true}
                                                />
                                                <label htmlFor='secret_key'>Secret Key</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='api_Secret'
                                                    value={api_Secret}
                                                    onChange={handleInputChangeTienda}
                                                    required={true}
                                                />
                                            </div>
                                        ) : ('')}
                                        {tienda === 'SHOPIFY' ? (
                                            <div>
                                                <label htmlFor='nombre'>URL Tienda</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='url_Tienda'
                                                    value={url_Tienda}
                                                    onChange={handleInputChangeTienda}
                                                    required={true}
                                                />
                                                <label htmlFor='api_key'>API Key</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='api_Key'
                                                    value={api_Key}
                                                    onChange={handleInputChangeTienda}
                                                    required={true}
                                                />
                                                <label htmlFor='secret_key'>Secret Key</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='api_Secret'
                                                    value={api_Secret}
                                                    onChange={handleInputChangeTienda}
                                                    required={true}
                                                />
                                            </div>
                                        ) : ('')}
                                        <div className='espacio5px' />
                                    </div>
                                </div>
                            </div>
                        )}
                        {valueTab === 1 && (
                            <div className='row'>
                                <div className='col-md-6' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='card-body text-start'>
                                            <div className='espacio15px' />
                                            <div className='card-title'><h5>Informacion de MicroZync a {tienda}</h5></div>
                                            <div className='espacio15px' />
                                            <div className='form-check form-switch margin_left_switch'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='sinc_existencias_to_api'
                                                    role='switch'
                                                    onChange={onChangeCheckActivoConfig}
                                                    checked={configSucursalSincro.sinc_existencias_to_api}
                                                />
                                                <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Existencias</label> &nbsp;&nbsp;
                                            </div>
                                            {configSucursalSincro.sinc_existencias_to_api ? <div className='espacio15px' /> : ''}
                                            {configSucursalSincro.sinc_existencias_to_api ?
                                                <FormControl fullWidth>
                                                    <InputLabel id="almacen-multiple-checkbox-label">Almacen</InputLabel>
                                                    <Select
                                                        labelId="almacen-multiple-checkbox-label"
                                                        id="almacen-multiple-checkbox"
                                                        multiple
                                                        value={almacenesMultiple}
                                                        onChange={handleChange}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => selected.join(', ')}
                                                        MenuProps={MenuProps}
                                                        label="Almacen"
                                                    >
                                                        {almacenes?.map((item) => (
                                                            <MenuItem key={item.id} value={item.nombre}>
                                                                <Checkbox checked={almacenesMultiple?.indexOf(item.nombre) > -1} />
                                                                <ListItemText primary={item.nombre} />
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl> : ''}
                                            <div className='espacio10px' />
                                            <div className='row'>
                                                <div className='col-md-1' />
                                                <div className='col-md-10'>
                                                    <div className='form-check form-switch margin_left_switch'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            name='sinc_stop_stock_min_to_api'
                                                            role='switch'
                                                            onChange={onChangeCheckActivoConfig}
                                                            checked={configSucursalSincro.sinc_stop_stock_min_to_api}
                                                        />
                                                        <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Pausar por stock menor igual a:</label> &nbsp;&nbsp;
                                                    </div>
                                                    {configSucursalSincro.sinc_stop_stock_min_to_api ? <div className='espacio10px' /> : ''}
                                                    {configSucursalSincro.sinc_stop_stock_min_to_api ?
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            name='stock_min_to_api'
                                                            value={configSucursalSincro.stock_min_to_api}
                                                            onChange={handleInputChange}
                                                        /> : ''}
                                                </div>
                                            </div>
                                            <div className='espacio30px' />
                                            <Divider variant="middle" color='indigo' />
                                            <div className='espacio30px' />
                                            <div className='form-check form-switch margin_left_switch'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='sinc_precios_to_api'
                                                    role='switch'
                                                    onChange={onChangeCheckActivoConfig}
                                                    checked={configSucursalSincro.sinc_precios_to_api}
                                                />
                                                <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Lista de precios</label> &nbsp;&nbsp;
                                            </div>
                                            {configSucursalSincro.sinc_precios_to_api ? <div className='espacio10px' /> : ''}
                                            {configSucursalSincro.sinc_precios_to_api ?
                                                <select className='form-select' onChange={handleChange} name='sinc_precio_empresa_id' value={precioEmpresa} style={{ width: '91%' }}>
                                                    <option key={0} value='0' disabled>Seleccione Lista de Precios</option>
                                                    {listaPrecios?.map((item) => { return (<option key={item.id} value={item.id}>{item.clave} - {item.nombre}</option>); })}
                                                </select> : ''}
                                            <div className='espacio10px' />
                                            {tienda === 'MERCADOLIBRE' ?
                                                <div className='row'>
                                                    <div className='col-md-1' />
                                                    <div className='col-md-10'>
                                                        <div className='form-check form-switch margin_left_switch'>
                                                            <input
                                                                type='checkbox'
                                                                className='form-check-input'
                                                                name='sinc_precios_mas_comisiones'
                                                                role='switch'
                                                                onChange={onChangeCheckActivoConfig}
                                                                checked={configSucursalSincro.sinc_precios_mas_comisiones}
                                                            />
                                                            <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Sumar costos y comisiones al precio</label> &nbsp;&nbsp;
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                            {tienda === 'MERCADOLIBRE' ? <div className='espacio10px' /> : ''}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='card-body text-start'>
                                            <div className='espacio15px' />
                                            <div className='card-title'><h5>Informacion de {tienda} a MicroZync</h5></div>
                                            <div className='espacio15px' />
                                            {tienda === 'MERCADOLIBRE' ?
                                                <div>
                                                    <div className='form-check form-switch margin_left_switch'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            name='save_vta_pago_neto'
                                                            role='switch'
                                                            onChange={onChangeCheckActivoConfig}
                                                            checked={configSucursalSincro.save_vta_pago_neto}
                                                        />
                                                        <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Guardar Venta con Pago Neto</label> &nbsp;&nbsp;
                                                    </div>
                                                    <div className='espacio15px' />
                                                    <div className='form-check form-switch margin_left_switch'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            name='sinc_vta_full'
                                                            role='switch'
                                                            onChange={onChangeCheckActivoConfig}
                                                            checked={configSucursalSincro.sinc_vta_full}
                                                        />
                                                        <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Registrar Ventas FULL</label> &nbsp;&nbsp;
                                                    </div>
                                                    <div className='espacio15px' />
                                                    <div className='form-check form-switch margin_left_switch'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            name='sinc_vta_pack_mode'
                                                            role='switch'
                                                            onChange={onChangeCheckActivoConfig}
                                                            checked={configSucursalSincro.sinc_vta_pack_mode}
                                                        />
                                                        <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Registrar Ordenes Paquetes como Vta. Conjunta</label> &nbsp;&nbsp;
                                                    </div>
                                                    <div className='espacio15px' />
                                                </div> : ''}
                                            <div className='form-check form-switch margin_left_switch'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='sinc_vta_envio'
                                                    role='switch'
                                                    onChange={onChangeCheckActivoConfig}
                                                    checked={configSucursalSincro.sinc_vta_envio}
                                                />
                                                <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Registrar Envio en Vta.</label> &nbsp;&nbsp;
                                            </div>
                                            <div className='espacio15px' />
                                            <div className='form-check form-switch margin_left_switch'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='sinc_vta'
                                                    role='switch'
                                                    onChange={onChangeCheckActivoConfig}
                                                    checked={configSucursalSincro.sinc_vta}
                                                />
                                                <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Registrar Ventas Aut.</label> &nbsp;&nbsp;
                                            </div>
                                            <div className='espacio15px' />
                                            <div className='form-check form-switch margin_left_switch'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='sinc_vta_cancel'
                                                    role='switch'
                                                    onChange={onChangeCheckActivoConfig}
                                                    checked={configSucursalSincro.sinc_vta_cancel}
                                                />
                                                <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Cancelar Ventas Aut.</label> &nbsp;&nbsp;
                                            </div>
                                        </div>
                                        <div className='espacio15px' />
                                        {principal ? '' :
                                            <div className='card card-body text-start'>
                                                <div className='espacio5px' />
                                                <h6>¿Como registrarás las ventas en la conexión principal?</h6>
                                                <div className='espacio10px' />

                                                <div>
                                                    {!principal ? <>
                                                        {/*<div className='form-check form-switch margin_left_switch'>
        <input
            type='checkbox'
            className='form-check-input'
            name='registrar_Almacen_Vinculado'
            role='switch'
            onChange={onChangeCheckActivo}
            checked={registrar_Almacen_Vinculado}
        />
        <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Al propio almacen vinculado</label> &nbsp;&nbsp;
        </div>*/}
                                                        <div className='espacio15px' />
                                                        <div className='form-check form-switch margin_left_switch'>
                                                            <input
                                                                type='checkbox'
                                                                className='form-check-input'
                                                                name='guardar_Venta_Almacen'
                                                                role='switch'
                                                                onChange={onChangeCheckActivo}
                                                                checked={guardar_Venta_Almacen}
                                                            />
                                                            <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Guardar ventas en el siguiente almacen</label> &nbsp;&nbsp;
                                                        </div>
                                                        {guardar_Venta_Almacen ? <>
                                                            <label htmlFor='Almacen' className='form-label'>Almacen</label>
                                                            <select
                                                                name='almancen_Id'
                                                                className='form-control'
                                                                onChange={handleInputChange}
                                                                value={almancen_Id}
                                                            >
                                                                <option value="0">Seleccione un almacen</option>
                                                                {almacenes?.map((item, index) => { return (<option value={item.id}>{item.nombre}</option>); })}
                                                            </select></> : ''}
                                                    </> : ''}
                                                    {!guardar_Venta_Almacen ? <>
                                                        <div className='espacio15px' />
                                                        <div className='form-check form-switch margin_left_switch'>
                                                            <input
                                                                type='checkbox'
                                                                className='form-check-input'
                                                                name='descontar_x_Prioridad'
                                                                role='switch'
                                                                onChange={onChangeCheckActivo}
                                                                checked={descontar_x_Prioridad}
                                                            />
                                                            <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Descontar por prioridad en los almacenes</label> &nbsp;&nbsp;
                                                        </div>
                                                        <div className='espacio5px' />
                                                        <div className='form-check form-switch margin_left_switch'>
                                                            <input
                                                                type='checkbox'
                                                                className='form-check-input'
                                                                name='descontar_x_Mayor_Existencias'
                                                                role='switch'
                                                                onChange={onChangeCheckActivo}
                                                                checked={descontar_x_Mayor_Existencias}
                                                            />
                                                            <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Descontar por mayor existencias</label> &nbsp;&nbsp;
                                                        </div>
                                                    </> : ''}
                                                    <div className='espacio5px' />
                                                </div>

                                            </div>}
                                    </div>
                                </div>
                            </div>
                        )}
                        {valueTab === 2 && (
                            <div className='row'>
                                <div className='col-md-6' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='card-title' />
                                        <div className='card-body text-start'>
                                            <label htmlFor='nombre' className='form-label'>Nombre</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='nombre'
                                                value={nombre || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='cp' className='form-label'>Codigo Postal</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='cp'
                                                value={cp || ''}
                                                onChange={handleInputChange}
                                                required={true}
                                            />

                                            <div className='espacio15px' />

                                            <div className='form-check form-switch margin_left_switch'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='principal'
                                                    role='switch'
                                                    onChange={onChangeCheckActivo}
                                                    checked={principal}
                                                    disabled={sucursalMatriz ? true : false}
                                                />
                                                <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Principal</label> &nbsp;&nbsp;
                                            </div>
                                            <div className='espacio5px' />
                                            <div className='form-check form-switch margin_left_switch'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='desglosar_Impuesto'
                                                    role='switch'
                                                    onChange={onChangeCheckActivo}
                                                    checked={desglosar_Impuesto === true}
                                                />
                                                <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>Desglosar impuestos al precio del articulo</label> &nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='card-title' />
                                        <div className='card-body text-start'>
                                            <label htmlFor='municipio' className='form-label'>Municipio</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='municipio'
                                                value={municipio || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='poblacion' className='form-label'>Poblacion</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='poblacion'
                                                value={poblacion || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='ciudad' className='form-label'>Ciudad</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='ciudad'
                                                value={ciudad || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='estado' className='form-label'>Estado</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='estado'
                                                value={estado || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='pais' className='form-label'>Pais</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='pais'
                                                value={pais || ''}
                                                onChange={handleInputChange}
                                            />

                                            <label htmlFor='calle' className='form-label'>Calle</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='calle'
                                                value={calle || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='no_exterior' className='form-label'>No. Exterior</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='no_Exterior'
                                                value={no_Exterior || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='no_interior' className='form-label'>No. Interior</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='no_Interior'
                                                value={no_Interior || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='colonia' className='form-label'>Colonia</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='colonia'
                                                value={colonia || ''}
                                                onChange={handleInputChange}
                                            />
                                            <input
                                                type='hidden'
                                                className='form-control'
                                                name='estatus'
                                                value={estatus !== 'I' ? 'A' : 'I'}
                                            />
                                            <div className='espacio15px' />
                                            {/*<div className='form-check form-switch'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='matriz'
                                                    role='switch'
                                                    onChange={onChangeCheckActivo}
                                                    checked={matriz === true}
                                                />
                                                <label htmlFor='estatus'>matriz</label> &nbsp;&nbsp;
                                            </div>}*/}

                                            <br />
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {valueTab === 3 && (
                            <div className='col-md-6' style={{ display: 'grid' }}>
                                <div className='card mb-3 '>
                                    <div className='card-body text-start'>
                                        <label htmlFor='poblacion' className='form-label'>Serie cotizacion</label>
                                        <select
                                            name={`serie_cotizacion`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            {console.log(folios_cotizacion)}
                                            {folios_cotizacion?.map((item, index) => {
                                                if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_cotizacion')[0]?.valor == item.serie)
                                                    return (<option value={item.serie} selected>{item.serie}</option>);
                                                else return (<option value={item.serie}>{item.serie}</option>);
                                            })}
                                        </select>
                                        <label htmlFor='poblacion' className='form-label'>Serie factura</label>
                                        <select
                                            name={`serie_factura`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            {folios_factura?.map((item, index) => {
                                                if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_factura')[0]?.valor == item.serie)
                                                    return (<option value={item.serie} selected>{item.serie}</option>);
                                                else return (<option value={item.serie}>{item.serie}</option>);
                                            })}
                                        </select>
                                        <label htmlFor='poblacion' className='form-label'>Serie Pedido</label>
                                        <select
                                            name={`serie_pedido`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            {
                                                folios_venta?.map((item, index) => {
                                                    if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_pedido')[0]?.valor == item.serie)
                                                        return (<option value={item.serie} selected>{item.serie}</option>);
                                                    else return (<option value={item.serie}>{item.serie}</option>);
                                                })
                                            }
                                        </select>
                                        <label htmlFor='poblacion' className='form-label'>Serie remision</label>
                                        <select
                                            name={`serie_remision`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            {
                                                folios_remision?.map((item, index) => {
                                                    if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_remision')[0]?.valor == item.serie)
                                                        return (<option value={item.serie} selected>{item.serie}</option>);
                                                    else return (<option value={item.serie}>{item.serie}</option>);
                                                })
                                            }
                                        </select>
                                        <label htmlFor='poblacion' className='form-label'>Serie orden compra</label>
                                        <select
                                            name={`serie_ordencompra`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            <option>Seleccione una serie</option>
                                            {
                                                serie_ordencompra?.map((item, index) => {
                                                    if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_ordencompra')[0]?.valor == item.serie)
                                                        return (<option value={item.serie} selected>{item.serie}</option>);
                                                    else return (<option value={item.serie}>{item.serie}</option>);
                                                })
                                            }
                                        </select>
                                        <label htmlFor='poblacion' className='form-label'>Serie recepcion compra</label>
                                        <select
                                            name={`serie_recepcioncompra`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            {
                                                serie_recepcioncompra?.map((item, index) => {
                                                    if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_recepcioncompra')[0]?.valor == item.serie)
                                                        return (<option value={item.serie} selected>{item.serie}</option>);
                                                    else return (<option value={item.serie}>{item.serie}</option>);
                                                })
                                            }
                                        </select>
                                        <label htmlFor='poblacion' className='form-label'>Serie compra</label>
                                        <select
                                            name={`serie_compra`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            {
                                                serie_compra?.map((item, index) => {
                                                    if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_compra')[0]?.valor == item.serie)
                                                        return (<option value={item.serie} selected>{item.serie}</option>);
                                                    else return (<option value={item.serie}>{item.serie}</option>);
                                                })
                                            }
                                        </select>

                                        <label htmlFor='poblacion' className='form-label'>Serie Devolución</label>
                                        <select
                                            name={`serie_devolucion`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            <option>Seleccione una serie</option>
                                            {
                                                serie_devolucion?.map((item, index) => {
                                                    if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_devolucion')[0]?.valor == item.serie)
                                                        return (<option value={item.serie} selected>{item.serie}</option>);
                                                    else return (<option value={item.serie}>{item.serie}</option>);
                                                })
                                            }
                                        </select>

                                        <label htmlFor='poblacion' className='form-label'>Serie Orden Reparación</label>
                                        <select
                                            name={`serie_orden_reparacion`}
                                            className='form-control'
                                            onChange={handleInputChangeSerie}
                                        >
                                            <option>Seleccione una serie</option>
                                            {
                                                serie_ordenReparacion?.map((item, index) => {
                                                    if (sucursal.configuracion_Sucursal?.filter(x => x.nombre == 'serie_orden_reparacion')[0]?.valor == item.serie)
                                                        return (<option value={item.serie} selected>{item.serie}</option>);
                                                    else return (<option value={item.serie}>{item.serie}</option>);
                                                })
                                            }
                                        </select>

                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};



export { AddEditSucursal };
