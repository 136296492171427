import React, { useState, useEffect, useRef } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import { descargarFactura } from 'actions/archivos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';
import { useForm } from 'react-hook-form';
import { currency } from 'resources/utilities';
import { useGlobalState } from 'resources/GlobalStateContext';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SLUGS from 'resources/slugs';
import Swal from 'sweetalert2';

const AddEdit = ({ history, match, saludo, subtitulo }) => {
    const { id } = match.params;
    const isAddMode = !id;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const { t } = useTranslation('common');
    const [TipoDocto] = useState('P');
    const { handleSubmit } = useForm();
    const button_submit = useRef(null);
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    const [cliente, setCliente] = useState([]);
    const [articulos, setArticulos] = useState([]);
    const [satFormaPago, setSatFormaPago] = useState(['']);
    const busqueda = useFormInput('');
    const [error, setError] = useState(['']);
    const [erroresV, setErroresV] = useState({});
    const [erroresArticulos, setErroresArticulos] = useState({});
    const [ventaDetalles, setVentaDetalles] = useState([]);
    const [venta, setVenta] = useState({
        id: 0,
        empresa_Id: getDataSession('empresa'),
        sucursal_Id: getDataSession('sucursal'),
        tipo_Documento: TipoDocto,
        cliente_Id: 0,
        direccion_Id: 0,
        vendedor: '',
        repartidor: '',
        no_Orden: '',
        folio: '',
        moneda: 'MXN',
        descuento_Importe: 0,
        estatus: 'P',
        importe_Total_Impuestos: 0,
        metodo_Pago: '',
        clave_Envio: '',
        forma_De_Pago: '',
        descripcion: ''
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickButtonSubmit = () => {
        if (validar()) button_submit.current.click();
    };

    function regresar() {
        history.push(`${SLUGS.vxpedidos}`);
    }

    function onSubmit(data) { 
        if (validar()) guardarVenta();
    }

    async function getSatFormaPago() {
        const response = await fetchConToken(`CatalogosSat/ListFormaPago?pagina=1&totalXpagina=300`);
        setSatFormaPago(response);
    }

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: isAddMode ? "Registrar Pedido" : "Editar Pedido",
            subtitulo: ''
        });
    };

    const handleInputChangeVenta = ({ target }) => {
        setVenta({
            ...venta,
            [target.name]: target.value
        });
    };

    const handleInputChangeCliente = ({ target }) => {
        setCliente({
            ...cliente,
            [target.name]: target.value
        });
    };

    const handleInputChangeArticulos = (event, key, campo) => {
        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);
        detalleV[0].articulo[campo] = event.target.value;
        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));
    };

    const handleInputChangeDetallesVenta = (event, key, campo) => {
        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);
        switch (campo) {
            case "cantidad": detalleV[0][campo] = parseFloat(event.target.value); break;
            case "precio_Unitario": detalleV[0]["precio_Unitario"] = parseFloat(event.target.value); break;
            default: detalleV[0][campo] = event.target.value; break;
        }

        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));
        if (campo !== "nombre_Articulo") ImpuestosArticulo(ventaDetalles.filter(x => x.articulo_Id === key), false);
    };

    const validar = () => {
        if (ventaDetalles.length === 0) {
            Swal.fire('Error', 'Seleccione por los menos un articulo', 'error');
            return false;
        }

        if (!venta.direccion_Id || venta.direccion_Id == 0) {
            if (!cliente || cliente.length == 0) Swal.fire('Error', 'Seleccione el cliente', 'error');
            else Swal.fire('Error', 'El cliente no cuenta con direccion asignada', 'error');

            return false;
        }

        if (!venta.vendedor) {
            Swal.fire('Error', 'Seleccione el vendedor', 'error');
            return false;
        }

        if (!venta.repartidor) {
            Swal.fire('Error', 'Seleccione el repartidor', 'error');
            return false;
        }

        return true;
    }

    const guardarVenta = () => {
        venta.sucursal_Id = getDataSession('sucursal');

        if (venta.forma_De_Pago.length === 0 || !venta.hasOwnProperty('forma_De_Pago')) venta.forma_De_Pago = '01';

        if (venta.metodo_Pago.length === 0 || !venta.hasOwnProperty('metodo_Pago')) venta.metodo_Pago = 'PUE';

        if (venta.length === 0 || !venta.hasOwnProperty('moneda')) venta.moneda = 'MXN';

        venta.venta_Detalles = ventaDetalles;
        fetchConToken(`Ventas?sucursal_Id=${getDataSession('sucursal')}`, venta, venta.hasOwnProperty('id') && venta.id > 0 ? 'PUT' : 'POST').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => { Swal.fire('Error', value[0], 'error'); return; });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            
            Swal.fire('Exito', 'Pedido guardado', 'success');
            venta.id > 0 ? history.push('..') : history.push('.');
            setError('');
            setVenta(response);
            setVentaDetalles([]);
            setVentaDetalles(response.venta_Detalles);
            return true;
        });

        return true;
    };

    async function eliminar(id) {
        if(isAddMode) setVentaDetalles(ventaDetalles.filter((item) => item.articulo_Id !== id));
        else
        {
            var det = ventaDetalles.filter((d) => d.articulo_Id == id);
            det.map(detV => {
                if(detV?.id > 0)
                {
                    detV.cantidad = 0;
                    setVentaDetalles(ventaDetalles.map((d) => (d.articulo_Id === id ? detV : d)));
                }
                else setVentaDetalles(ventaDetalles.filter((item) => item.articulo_Id !== id));
           })
        }
    }

    async function descripcionDetalle(id) {
        var detAux = ventaDetalles.filter((d) => d.articulo_Id == id);
        Swal.fire({
            title: 'Agregar descripción al detalle',
            input: 'textarea',
            inputValue: detAux[0]?.descripcion,
            inputAttributes: { autocapitalize: 'off' },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Guardar',
            showLoaderOnConfirm: true,
        }).then((result) => {
            if (result.isConfirmed) {
                detAux[0].descripcion = result.value;
                setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === id ? detAux[0] : det)));
            }
        })
    }

    async function getVenta() {
        const ventaAux = await fetchConToken(`Ventas?id=${id}&sucursal_Id=${getDataSession('sucursal')}`);
        ventaAux.forma_De_Pago = ventaAux.forma_De_Pago.replace(' ') === '' ? '01' : ventaAux.forma_De_Pago;
        ventaAux.metodo_Pago = ventaAux.metodo_Pago.replace(' ') === '' ? 'PUE' : ventaAux.metodo_Pago;
        setVenta(ventaAux);
        setVentaDetalles(ventaAux.venta_Detalles);
        return ventaAux.venta_Detalles.filter(e => e.requiere_Revision === true).length > 0;
    }

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(`Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`);
        clienteAux.regimen_Fiscal_Receptor = clienteAux.regimen_Fiscal_Receptor.replace(' ') === '' ? '601' : clienteAux.regimen_Fiscal_Receptor;
        clienteAux.uso_Cfdi = clienteAux.uso_Cfdi.replace(' ') === '' ? 'G01' : clienteAux.uso_Cfdi;
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
        venta.direccion_Id = clienteAux.clientes_Direcciones[0].id;
    }

    async function getArticulo(id) {
        if (!Number.isInteger(id)) return;

        var artAux = ventaDetalles.filter((d) => d.articulo_Id == id);
        if (artAux.length > 0) {
            artAux[0].cantidad = artAux[0].cantidad + 1;
            setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === id ? artAux[0] : det)));
            ImpuestosArticulo(ventaDetalles.filter(x => x.articulo_Id === id), false, artAux[0].articulo);
            return;
        }

        const articulosAux = await fetchConToken(`Articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}`);
        const detalleVenta = {
            id: 0,
            venta_Id: 0,
            articulo_Id: articulosAux.id,
            no_Detalle: 'local',
            posicion: 0,
            cantidad: 1,
            clave_Articulo: articulosAux.clave,
            clave_Unidad: articulosAux.clave_Unidad,
            precio_Unitario: articulosAux.costo_Unidad,
            descuento_Total: 0,
            precio_Total: articulosAux.costo_Unidad,
            impuesto_Total: 0,
            estatus: 'A',
            articulo: articulosAux,
            nombre_Articulo: articulosAux.nombre,
            impuesto: 0,
            precioSinImpuesto: articulosAux.costo_Unidad
        };
        ImpuestosArticulo(detalleVenta, true, articulosAux);
    }

    async function ImpuestosArticulo(ventaDet, nuevaLinea, articulo) {
        if (nuevaLinea) {
            await fetchConToken(`ventas/CalculaDetalle?sucursal_Id=` + getDataSession('sucursal'), ventaDet, 'POST').then((detVentaResp) => {
                detVentaResp.articulo = detVentaResp.articulo ? detVentaResp.articulo : articulo;
                setVentaDetalles([...ventaDetalles, detVentaResp]);
            });
        }
        else {
            await fetchConToken(`ventas/CalculaDetalle?sucursal_Id=` + getDataSession('sucursal'), ventaDet[0], 'POST').then((detVentaResp) => {
                detVentaResp.articulo = detVentaResp.articulo ? detVentaResp.articulo : articulo;
                setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === detVentaResp.articulo_Id ? detVentaResp : det)));
            });
        }
    }

    const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        var resp = await fetchConToken(`Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);
        return resp.data;
    };

    const loadArticulos = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        var resp = await fetchConToken(`Articulos/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);
        return resp?.data;
    };

    useEffect(() => {
        actualizarTitulo();
        getSatFormaPago();
        if (!isAddMode) getVenta();
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-3'>
                        <div className='col-12 col-sm-12 col-md-12 row'>
                            <div className='col-sm-6  col-md-6'><div className='espacio5px' /></div>
                            <div className='col-sm-2' />
                            <div className='col-sm-4 text-right'>
                                <button className='input_file_hidden' type='submit' ref={button_submit}>{t('app.botones.guardar')}</button>
                                <button type='button' className='btn btn-outline-primary m-1' style={{ width: 'auto' }} onClick={handleClick}>
                                    <TuneIcon />&nbsp; Acciones <ArrowDropDownIcon />
                                </button>
                                <Menu id='basic-menu' anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                                    <MenuItem onClick={() => { handleClickButtonSubmit(); handleClose(); }}>
                                        <ListItemIcon>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='20'
                                                height='20'
                                                fill='currentColor'
                                                className='bi bi-check-lg'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                            </svg>
                                        </ListItemIcon>
                                        {t('app.botones.guardar')}
                                    </MenuItem>
                                    <MenuItem onClick={() => { regresar(); handleClose(); }}>
                                        <ListItemIcon>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='20'
                                                height='20'
                                                fill='currentColor'
                                                className='bi bi-reply'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                            </svg>
                                        </ListItemIcon>
                                        {t('app.botones.regresar')}
                                    </MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <div className='espacio10px' />
                        <div className='card container-fluid'>
                            <div className='card-header'><label className='form-label title2'>Datos Pedido</label></div>
                            <div className='row'>
                                <div className='col-md-6' style={{ display: 'grid' }}>
                                    <div className='card-body'>
                                        <input
                                            type='hidden'
                                            className='form-control'
                                            name='no_cliente'
                                            value={cliente.no_Cliente}
                                            onChange={handleInputChangeCliente}
                                            disabled={false}
                                            required
                                        />
                                        <label htmlFor='no_cliente'>Cliente:</label>
                                        <AsyncSelect
                                            className='react-select-placeholder'
                                            isClearable
                                            getOptionLabel={(option) => option.rfc + '-' + option.nombre}
                                            getOptionValue={(option) => option.id}
                                            loadOptions={loadClientes}
                                            onChange={(selectedItem) => { getCliente(selectedItem === null ? '' : selectedItem?.id) }}
                                        />
                                        <label htmlFor='vendedor'>Vendedor*</label>
                                        <select
                                            className='form-select'
                                            name='vendedor'
                                            value={venta.vendedor}
                                            onChange={handleInputChangeVenta}
                                            required
                                        >
                                            <option key='' value=''>Seleccione</option>
                                            <option value='PUE'>(PUE) Pago en una sola exhibición</option>
                                            <option value='PPD'>(PPD) Pago en Parcialidades o Diferido</option>
                                        </select>
                                        <label htmlFor='repartidor'>Repartidor*</label>
                                        <select
                                            className='form-select'
                                            name='repartidor'
                                            value={venta.repartidor}
                                            onChange={handleInputChangeVenta}
                                            required
                                        >
                                            <option key='' value=''>Seleccione</option>
                                            <option value='PUE'>(PUE) Pago en una sola exhibición</option>
                                            <option value='PPD'>(PPD) Pago en Parcialidades o Diferido</option>
                                        </select>
                                        <div className='espacio10px' />
                                    </div>
                                </div>
                                <div className='col-md-6' style={{ display: 'grid' }}>
                                    <div className='card-body'>
                                        <label htmlFor='forma_De_Pago'>Forma de Pago*</label>
                                        <select
                                            className='form-select'
                                            name='forma_De_Pago'
                                            value={venta.forma_De_Pago}
                                            onChange={handleInputChangeVenta}
                                            required
                                        >
                                            {satFormaPago.map((formapago) => {
                                                return (
                                                    <option key={formapago.id} value={formapago.clave}>
                                                        {formapago.clave + ' - ' + formapago.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <label htmlFor='metodo_Pago'>Metodo de Pago*</label>
                                        <select
                                            className='form-select'
                                            name='metodo_Pago'
                                            value={venta.metodo_Pago}
                                            onChange={handleInputChangeVenta}
                                            required
                                        >
                                            <option value='PUE'>(PUE) Pago en una sola exhibición</option>
                                            <option value='PPD'>(PPD) Pago en Parcialidades o Diferido</option>
                                        </select>
                                        <label htmlFor='descripcion' className='form-label'>Descripción del pedido</label>
                                        <textarea rows="4"
                                            className='form-control'
                                            name='descripcion'
                                            value={venta?.descripcion || ''}
                                            onChange={handleInputChangeVenta}
                                        />
                                        <div className='espacio10px' />
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>
                                <label htmlFor='id'>Buscar Articulo</label>
                                <AsyncSelect
                                    className='react-select-placeholder'
                                    isClearable
                                    getOptionLabel={(option) => option.clave + '-' + option.nombre + '- $' + option.costo_Unidad}
                                    value=''
                                    getOptionValue={(option) => option.id}
                                    loadOptions={loadArticulos}
                                    onChange={(selectedItem) => { getArticulo(selectedItem === null ? '' : selectedItem?.id); }}
                                />
                                <div className='espacio10px' />
                                <div className='col-md-12' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='text-start'>
                                            <div className='card col-12 col-sm-12 col-md-12'>
                                                {Object.entries(erroresArticulos).map(([key, value]) => { return (<p style={{ color: 'red' }}>{value}</p>); })}
                                                <div style={{ overflowX: 'auto' }}>
                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Articulo</th>
                                                                <th>Clave</th>
                                                                <th>NoIdentificacion</th>
                                                                <th>Cantidad</th>
                                                                <th>Precio Unitario <br></br> (sin impuestos)</th>
                                                                <th>IVA</th>
                                                                <th>Precio Total</th>
                                                                <th style={{ width: "50px" }}></th>
                                                                <th style={{ width: "50px" }}></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {ventaDetalles.filter(i => i.cantidad > 0)?.map((item) => {
                                                                return (
                                                                    <tr key={item.id}>
                                                                        <td className='text-center'>{item.articulo.id}</td>
                                                                        <td>
                                                                            <textarea
                                                                                type='text'
                                                                                className='form-control'
                                                                                name='item.nombre_Articulo'
                                                                                value={item.nombre_Articulo}
                                                                                onChange={(event) =>
                                                                                    handleInputChangeDetallesVenta(
                                                                                        event,
                                                                                        item.articulo.id,
                                                                                        'nombre_Articulo'
                                                                                    )
                                                                                }
                                                                                cols="170"
                                                                                rows="3"
                                                                            />
                                                                        </td>
                                                                        <td className='text-center'>{item.articulo.clave}</td>
                                                                        <td className='text-center'>
                                                                            <input
                                                                                type='text'
                                                                                className='form-control'
                                                                                name='item.articulo.no_Identificacion'
                                                                                value={item.articulo.no_Identificacion}
                                                                                onChange={(event) =>
                                                                                    handleInputChangeArticulos(
                                                                                        event,
                                                                                        item.articulo.id,
                                                                                        'no_Identificacion'
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className='text-right'>
                                                                            <input
                                                                                type='number'
                                                                                className='form-control'
                                                                                name='item.cantidad'
                                                                                value={item.cantidad}
                                                                                onChange={(event) =>
                                                                                    handleInputChangeDetallesVenta(
                                                                                        event,
                                                                                        item.articulo.id,
                                                                                        'cantidad'
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className='text-right'>
                                                                            <input
                                                                                type='number'
                                                                                className='form-control'
                                                                                name='item.precio_Unitario'
                                                                                value={item.precio_Unitario}
                                                                                onChange={(event) =>
                                                                                    handleInputChangeDetallesVenta(
                                                                                        event,
                                                                                        item.articulo.id,
                                                                                        'precio_Unitario'
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                        <td className='text-right'>{currency(item.impuesto_Total)}</td>
                                                                        <td className='text-right'>{currency(item.precio_Total + item.impuesto_Total)}</td>
                                                                        <td style={{ width: "50px" }}>
                                                                            <button
                                                                                type='button'
                                                                                className='btn  mr-1'
                                                                                onClick={() => eliminar(item.articulo?.id)}
                                                                            >
                                                                                <svg
                                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                                    width='25'
                                                                                    height='25'
                                                                                    fill='currentColor'
                                                                                    className='bi bi-x-circle-fill'
                                                                                    viewBox='0 0 16 16'
                                                                                >
                                                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                                </svg>
                                                                            </button>
                                                                        </td>
                                                                        <td style={{ width: "50px" }}>
                                                                            <button
                                                                                type='button'
                                                                                className='btn  mr-1'
                                                                                onClick={() => descripcionDetalle(item.articulo.id)}
                                                                            >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                                                                                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                                                    <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                                                                                </svg>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            <tr>
                                                                <td colSpan={6} className='text-right'>
                                                                    Subtotal: {currency(ventaDetalles.reduce((a, v) => (a = a + v.precio_Total), 0))}
                                                                </td>
                                                                <td colSpan={2} className='text-right'>
                                                                    IVA:{' '} {currency(ventaDetalles.reduce((a, v) => (a = a + v.impuesto_Total), 0))}
                                                                </td>
                                                                <td colSpan={2} className='text-right'>
                                                                    Total:{' '} {currency(ventaDetalles.reduce((a, v) => (a = a + v.precio_Total + v.impuesto_Total), 0))}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e) => { setValue(e.target.value); };
    return { value, onChange: handleChange };
};

export { AddEdit };