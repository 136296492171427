import React, { useState } from 'react';
import Chart from 'react-apexcharts'
import {COLORS,COLOR_1} from './Constants'
import { fetchConToken } from 'helpers/fetch';
import { moneyFormat,numberFormat } from 'helpers/formats';
import { getDataSession } from 'views/Utils/Common';
import moment from 'moment';
import './styles.css';
import { useGlobalState } from 'resources/GlobalStateContext';
const IngresosDiarios = () => {

    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [series, setSeries] = useState([]);
    let [options, setOptions] = useState({});
    let [seriesTotales, setSeriesTotales] = useState([]);
    let [optionsTotales, setOptionsTotales] = useState({});
    let [ingresos, setIngresos] = useState({});
    let [estaSemana, setEstaSemana] = useState([]);
    let [anteriorSemana, setAnteriorSemana] = useState([]);
    let [response, setResponse] = useState({});
    let [responseBefore, setResponseBefore] = useState({});

    React.useEffect(() => {
        getDataIngresos()
        getDataTotales()
        actualizarTitulo()
    }, []);

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Ingresos Diarios',
          subtitulo: ''
        });
      };
    const getDataIngresos = async () => {
        const date = new Date();
        const sucursal = getDataSession('sucursal')
        const mesIndex = date.getMonth();
        const mes = mesIndex +1;
        const dias = [
            {
                nombre: 'Monday',
                dia:1,
                value:0
            },
            {
                nombre: 'Tuesday',
                dia:2,
                value:0
            },
            {
                nombre: 'Wednesday',
                dia:3,
                value:0
            },
            {
                nombre: 'Thursday',
                dia:4,
                value:0
            },
            {
                nombre: 'Friday',
                dia:5,
                value:0
            },
            {
                nombre: 'Saturday',
                dia:6,
                value:0
            },
            {
                nombre: 'Sunday',
                dia:7,
                value:0
            },
        ]
        const response = await fetchConToken(
            'Dashboard/estadisticaMes?tipo_factura=E&mes='+mes+'&anio='+date.getFullYear()+'&sucursal_id='+sucursal
        );

        const from_date = moment().startOf('week');
        const to_date = moment().endOf('week');
        const start = Number(moment(from_date).format('D'))
        const end = Number(moment(to_date).format('D'))

        let dataDias = []
        let hoy = Number(moment().format('D'))
        let ayerFecha = moment().subtract(1, 'days')
        let ayer = Number(moment(ayerFecha).format('D'))
        
        let totalHoy = 0, totalAyer = 0
        let array = []
        if(response.dias !== undefined) {
            response.dias.map((item,i)=>{
                let day = Number(moment(item.fecha).format('D'))
                if(day >= start && day <= end )
                {
                    let name = moment(item.fecha).format('dddd')
                    dataDias.push(
                    {
                        total:item.total,
                        nombre: name
                    })
                }
                if(day === hoy)
                {
                    totalHoy = item.total
                }
                if(day === ayer)
                {
                    totalAyer = item.total
                }
            })
            
            dias.map((item,i) =>{
                dataDias.map((data,y)=>{
                    if(item.nombre === data.nombre)
                    {
                        item.value = numberFormat(data.total)
                    }
                })
            })
            
            dias.map((item,i)=>{
                array.push(numberFormat(item.value))
            })
        }
        let ingresos = {
            total: response.total || 0,
            hoy: totalHoy,
            ayer: totalAyer
        }
        setIngresos(ingresos)

        setSeries([{
            name: 'Ingresos diarios',
            data: array
        }])
    
        setOptions({
            chart: {
				height: 350,
				type: 'line',
				zoom: {
					enabled: false
				},
			},
            colors: COLORS,
            stroke: {
                show: true,
                dashArray: 5,
                width: 1,
            },
            xaxis: {
                categories: ['Lunes', 'Martes', 'Miércoles', 'Jueves','Viernes','Sábado','Domingo'],
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                },
                background: {
                    enabled: true,
                    foreColor: '#fff',
                    padding: 1,
                    borderRadius: 9,
                    borderWidth: 3,
                    borderColor: COLOR_1,
                    opacity: 0.9,
                  },    
            },
        })
    }

    const getDataTotales = async () => {
        const sucursal = getDataSession('sucursal')
        
        const from_date = moment().startOf('week');
        const to_date = moment().endOf('week');
        const start = moment(from_date).format('Y-MM-DD')
        const end = moment(to_date).format('Y-MM-DD')
        
        const beforeWeek = moment(from_date).subtract(7, 'days').format('Y-MM-DD');
        
        await fetchConToken(
            'Dashboard/estadisticaXrango?tipo_factura=E&fechainicio='+start+'&fechafin='+end+'&sucursal_id='+sucursal
        )
        .then((resp) => {
            response = resp
            setResponse(resp)
          })
        .finally(()=>{
            if(response.dias !== undefined)
            {
                let sum = 0
                response.dias.map(item=>{
                    sum += item.total
                })
                estaSemana = [numberFormat(sum)]
                setEstaSemana([sum])
            }
        });;
        
        await fetchConToken(
            'Dashboard/estadisticaXrango?tipo_factura=E&fechainicio='+beforeWeek+'&fechafin='+start+'&sucursal_id='+sucursal
        )
        .then((resp) => {
            responseBefore = resp
            setResponseBefore(resp)
          })
        .finally(()=>{
            if(responseBefore.dias !== undefined)
            {
                let sum = 0
                responseBefore.dias.map(item=>{
                    sum += numberFormat(item.total)
                })
                anteriorSemana = [sum]
                setAnteriorSemana([sum])
            }
        });

        if(estaSemana.length > 0 || anteriorSemana.length > 0)
        {
            setSeriesTotales([{
                name: 'Esta semana',
                data: estaSemana
            },
            {
                name: 'Semana anterior',
                data: anteriorSemana
            }])
        }
    
        setOptionsTotales({
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        position: 'bottom',
                    }
                }
            },
            colors: COLORS,
            dataLabels: {
                enabled: true
            },
            xaxis: {
                categories: [''],
            },
            legend: {
                position: 'bottom',
                horizontalAlign: 'center',
                offsetX: 40
            }
        })
    }

    return (
        <div className='row contentEst' >
            <div className='col-md-8'>
                <div className='cardGraph'>
            
                    <p>Gráfico de ingresos diarios</p>
                    <span className='divider'></span>
                    {
                        ingresos !== {} 
                        ?
                        <div className='blockNumbers'>
                            <div className='itemNumber'>
                                <p>Totales</p>
                                <p className='positionRelative'>{ moneyFormat(ingresos.total) } <span className='spanBlue'>MXN</span></p>
                            </div>
                            <div className='itemNumber'>
                                <p>Hoy</p>
                                <p className='positionRelative'>{ moneyFormat(ingresos.hoy) } <span className='spanBlue'>MXN</span></p>
                            </div>
                            <div className='itemNumber'>
                                <p>Ayer</p>
                                <p className='positionRelative'>{ moneyFormat(ingresos.ayer) } <span className='spanBlue'>MXN</span></p>
                            </div>
                        </div>
                        : ''
                    }
                    {
                        series.length > 0 && options !== {}
                        ?
                        <Chart
                            options={options}
                            series={series}
                            height= {350}
                        />
                        : ''
                    }
                </div>
            </div>
            <div className='col-md-4'>
                <div className='cardGraph'>
                    <h1 className='colorDarkBlue'>Totales</h1>
                    <p>Resumen semanal de ingresos</p>
                    {
                        response !== {} 
                        ?
                        <Chart
                            options={optionsTotales}
                            series={seriesTotales}
                            height= {350}
                            type="bar"
                        />
                        : ''
                    }
                </div>
            </div>
        </div>
    )
}
export {IngresosDiarios};