import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Column, Row } from 'simple-flexbox';
import { SidebarComponent, SidebarContext } from 'components/sidebar';
import SidebarOptionsComponent from 'components/sidebar/SidebarOptionsComponent';

import HeaderComponent from 'components/header/HeaderComponent';
import { GlobalStateProvider } from 'resources/GlobalStateContext';
import PrivateRoutes from './PrivateRoutes';
import { Alert } from 'components/alert/Alert';

const useStyles = createUseStyles({
    contentBlock: {
        marginTop: 20,
        marginLeft: 5
    },
    contentOptions: {
        marginLeft: '2px',
        zIndex: 100
    }
});

function PrivateSection() {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <SidebarContext>
            <GlobalStateProvider>
            <div className="dom-sections">
                <SidebarComponent key={"SidebarComponent"}/>
                <div>
                    <HeaderComponent />
                    <Alert />
                    <div className={classes.contentBlock}>
                        <PrivateRoutes />
                    </div>
                </div>
            </div>
            </GlobalStateProvider>
        </SidebarContext>
    );
}

export default PrivateSection;
