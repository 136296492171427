import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import { fetchSinToken } from 'helpers/fetch';
import { Link } from 'react-router-dom';
import SLUGS from 'resources/slugs';

import { alertService } from '_services';

import fondo from 'images/login/recuperacion_contrasena/fondo.png';
import fondo_movil from 'images/login/recuperacion_contrasena/fondo_movil.png';
import logo from 'images/login/logo.png';

import amazon from 'images/login/recuperacion_contrasena/amazon2.png'
import shopify from 'images/login/recuperacion_contrasena/shopify2.png'
import mercado_libre from 'images/login/recuperacion_contrasena/mercado_libre2.png'
import woocomerce from 'images/login/recuperacion_contrasena/woocomerce2.png'
import videomz from 'images/login/microzync.m4v'


const ForgotPassword = ({ history, saludo, subtitulo}) => {
  var today = new Date();
  var year = today.getFullYear();

    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    // const password = useFormInput('');

    const [textoInfo, settextoInfo] = useState('');
    const [textoError, settextoError] = useState('');
    let [error, setError] = useState(['']);
    let [errores, setErrores] = useState({});
    let [success, setSuccess] = useState(['']);

    const handleLogin = () => {
      setLoading(true);
      setErrores('');
      setError('');
      setSuccess('');
      fetchSinToken("Usuarios/CorreoRecuperacion?correo="+username.value,null,"POST")
        .then(response => {
          setLoading(false);
          if (response.hasOwnProperty('status') && response.status === 400) {
            var erroresC = {};
            Object.entries(response.errors).map(([key, value]) => {
                erroresC[key] = value[0];
                setErrores(erroresC);
            });
            setError('Revisar errores');
            return false;
        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
            setError(response.mensajes[0]);
            return false;
        }
        setLoading(false);
        setSuccess('Se envio correo de recuperacion');
      }).catch(error => {
        console.log(error);
          setLoading(false);
          if (error.response.status === 401) 
             settextoError(error.response.data.message);
          else 
            settextoError("Error al recuperar su cuenta.");
      });
    }
    
    

    return (
      <>
      <div className='col-md-12' >

        {window.innerWidth < 768 ?
        <div className='text-center'>
             <img src={ window.innerWidth < 768 ? logo : logo } className="img-fyt" alt='' />
             <h2
                style={{
                    color: '#01295F'
                 }}
            >
                <b>Recuperación de cuentas</b>
            </h2>
            <br></br> 
            <div className='col-md-12 text-center'>
                <p
                    style={{
                        color: '#2D3277',
                        padding: "0px 10% 0px 10%"
                    }}
                >
                    Una vez enviado el formulario, revisa tu correo electrónico para la recuperación de tu cuenta
                </p>
            </div>
            <form>
            
                            <div>
                                       
                                <div className='col-md-11 container text-center' style={{paddingBottom: '15px'}}>
                                    <div className='card-body text-start'>
                                    <label className='form-label'>Correo</label>
                                     <input 
                                          type="text" {...username} 
                                          className="form-control" 
                                          autoComplete="new-password" 
                                          placeholder="Ingresa tu correo" />
                                 
                                    <br />
       
                                    <input style={{color: "white", borderRadius: "10px", background: 'linear-gradient(86.42deg, #232ED1 11.64%, #0F5EF6 80.17%)', width:"100%"}} type="button" className="btn btn-info col-md-8" value={loading ? 'Cargando...' : 'Recuperar mi cuenta'} onClick={handleLogin} disabled={loading} /><br />
                                    <div className='col-md-12 text-center'>
                                      <br></br>
                                      <Link to={SLUGS.login}  style={{ color: '#0F5EF6'}}>Volver a inicio de sesión</Link>
                                      <br></br>
                                      <br></br>
                                      <p>¿Aún no tienes una cuenta?<Link to={SLUGS.register}>Registrate aquí</Link></p>
                                   </div>
                                    <br></br>
                                    
                                </div>
                            </div>
                        </div>
                       
                    </form>
        </div> : "" 
        }
      </div>
      <div className= { window.innerWidth > 768 ? 'col-md-12 row' : 'col-md-12'}>
        <div className='col-md-7' style={{ padding: window.innerWidth > 768 ? '0% 0% 0% 5%' : '0px 25px 0px 25px' }}>
            <div style={{ paddingLeft: window.innerWidth > 768 ? '15%' : '0%', padding: window.innerWidth > 768 ? '0px' : '10px', background: window.innerWidth > 768 ? '#FFFFFF' : 'rgba(244, 247, 255, 1)' }}>
                
            <div
                className='col-12 col-sm-12 col-md-12 row login'
                style={{ minHeight: '100vh', marginLeft: '0', textAlign:'center' }}
            >
                <div 
                    style={{
                        
                        backgroundRepeat: 'no-repeat',
                        display: window.innerWidth < 768 ? 'none' : 'block',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'relative',
                        backgroundColor:'#FAFAFA'
                    }}
                >
      <video src={videomz} loop muted width="100%" id="background-video" height="380" type="video/mp4" autoplay="true" />
    
                <p style={{color: "#01295F"}}>Administra tu negocio desde una sola plataforma, sincroniza stock, precios, ventas y mucho mas!.</p>
                <p style={{color: "#01295F"}}>Descarga ventas, guias de envío, clientes.</p>
                <p style={{color: "#01295F"}}>Crea nuevos artículos y duplícalos.</p>
                <div className='col-md-12 row' style={{paddingTop: "5%"}}>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ woocomerce }  alt=''  />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ amazon }  alt=''  />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ mercado_libre }  alt='' />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3'>
                                <img src={ shopify }  alt='' />
                            </div>
                        </div>
                        <br></br><br></br>
                       
                </div>
                <div className='col-md-12 text-center row'>
                    <div className='col-4 col-sm-4 col-md-4'>
                        <a href="https://atti.com.mx" target="_blank" rel="noopener noreferrer">AttiSolutions</a>
                    </div>
                    <div className='col-4 col-sm-4 col-md-4'>
                    <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                    </div>
                    <div className='col-4 col-sm-4 col-md-4'>
                    <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                    </div>
                </div>
            </div>
            </div></div>
            
        {window.innerWidth > 768 ?
        <div className='col-md-5' style={{ marginTop: "2%"}}>
        <div
                    className='col-12'
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        backgroundImage: window.innerWidth < 768 ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${fondo})` : 'none',
                        backgroundSize: 'cover',
             
                    }}
                >
                    <form>
                        <div className='card' style={{
                                       boxShadow: 'rgb(30 56 136 / 20%) 8px 8px 48px',
                                       borderRadius: '8px'
                                }}>
                            <div>
                                <div className='col-md-12 text-center'>
                                    <Link to={SLUGS.home}>
                                        <img src={ window.innerWidth < 768 ? logo : logo } className="img-fyt" alt='' />
                                    </Link>
                                    <h2
                                        style={{
                                            color: window.innerWidth < 768 ? '#ffff' : '#01295F'
                                        }}
                                    >
                                        <b>¡Recuperacion!</b>
                                    </h2>
                                    
                                    <div className='col-md-12 text-center'>
                                    <p
                                        style={{
                                            color: window.innerWidth < 768 ? '#2D3277' : '#2D3277',
                                            padding: "0px 10% 0px 10%"
                                        }}
                                        
                                    >
                                       Una vez enviado el formulario, revisa tu correo electrónico para la recuperación de tu cuenta
                                    </p>
                                    </div>
                                </div>
                                
                                <div className='col-md-11 container text-center' style={{paddingBottom: '15px'}}>
                                    <div className='card-body text-start'>
                                    <label className='form-label'>Correo</label>
                                    <input 
                                          type="text" {...username} 
                                          className="form-control" 
                                          autoComplete="new-password" 
                                          placeholder="Ingresa tu correo" />
                                 
                                    <br />
                                    <br />
                                    <div className='col-md-12 text-center'>
                                        <p style={{ color: 'red' }}>{error}</p>
                                        <p style={{ color: 'blue' }}>{success}</p>
                                        {Object.entries(errores).map(([key, value]) => {
                                            return (
                                                <>
                                                    <p style={{ color: 'red' }}>{value}</p>
                                                </>
                                            );
                                        })}
                                    </div>  
                                    <input style={{color: "white", borderRadius: "15px", background:"#189ad6", width:"100%"}} type="button" className="btn btn-info col-md-8" value={loading ? 'Cargando...' : 'Recuperar mi cuenta'} onClick={handleLogin} disabled={loading} /><br />
                                    <div className='col-md-12 text-center'>
                                      <br></br>
                                      <b> <Link to={SLUGS.login}  style={{ color: window.innerWidth < 768 ? "##0F5EF6" : "##0F5EF6"}}>Volver a inicio de sesión</Link></b>
                                      <br></br>
                                      <p>¿Aún no tienes una cuenta?<Link to={SLUGS.register}>Registrate aquí</Link></p>
                                   </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </form>
                </div>
        </div> : '' }
      </div>

     
      </>
    );
}



const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
   
  }
  return {
    value,
    onChange: handleChange
  }
}


export { ForgotPassword };

/*
<div className={window.innerWidth > 768 ? 'col-md-12' : 'col-md-12 text-center'}>
                    <h2 style={{color: "#0F5EF6"}}><b>¡Factura tus compras!</b></h2>
                    </div>
*/