import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { MenuMoviminetosContador } from '../MenuMoviminetosContador';
import { useGlobalState } from 'resources/GlobalStateContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListTable from 'components/table/ListTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import { currency } from 'resources/utilities';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';

const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const [TipoDocto] = useState('P');
    const [statusFiltro, setStatusFiltro] = useState("");
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [cliente, setCliente] = useState([]);
    const [repartidor, setRepartidor] = useState([]);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [totales, setTotales] = useState([]);
    const handleClose = () => setAnchorEl(null);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const [dataMenu, setDataMenu] = useState(GenDataMenu('Todos'));
    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "vista,verEntregas,asignarRepartidor" },
        {
            field: 'id', headerName: 'Id', maxWidth: 20, renderCell: (params) => (
                <a href={`${path.replace(':documento', TipoDocto) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'no_Orden', headerName: 'Orden', minWidth: 200, renderCell: (params) => (
                <a href={`${path.replace(':documento', TipoDocto) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'folio', headerName: 'Folio', minWidth: 100, renderCell: (params) => (
                <a href={`${path.replace(':documento', TipoDocto) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'documento_FoliosOrigen', headerName: 'Folios ERP', minWidth: 130, renderCell: (params) => (
                <p>{params.row.documento_FoliosOrigen.map(objeto => objeto.erp_Folio)?.join(', ')}</p>)
        },
        { field: 'estatus', headerName: 'Estatus', minWidth: 130 },
        { field: 'fecha_Orden', headerName: 'Fecha', minWidth: 160 },
        { field: 'fecha_Registro', headerName: 'Fecha Registro', minWidth: 160 },
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 300},
        { field: 'rfc_Cliente', headerName: 'RFC', minWidth: 150},
        { field: 'importe_Total', headerName: 'SubTotal', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'impuestos', headerName: 'Impuestos', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'importe_Total_Impuestos', headerName: 'Total', type: 'number', renderCell: ({ value }) => currency(value) }
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Pedidos',
            subtitulo: 'Listado de Pedidos realizadas'
        });
    };

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    const handleInputChange = (event) => {
        setDataMenu(GenDataMenu(event.target.name));
        var selected = dataMenu.find(x => x.Titulo === event.target.name);
        setStatusFiltro(selected?.ValueStatus);
    }

    const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        var resp = await fetchConToken(`Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);
        return resp.data;
    };

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(`Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`);
        clienteAux.regimen_Fiscal_Receptor = clienteAux.regimen_Fiscal_Receptor.replace(' ') === '' ? '601' : clienteAux.regimen_Fiscal_Receptor;
        clienteAux.uso_Cfdi = clienteAux.uso_Cfdi.replace(' ') === '' ? 'G01' : clienteAux.uso_Cfdi;
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
    }

    const loadRepartidor = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        var resp = await fetchConToken(`Clientes/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`);
        return resp.data;
    };

    async function getRepartidor(repartidor_id) {
        const repartidorAux = await fetchConToken(`Clientes?id=${repartidor_id}&sucursal_Id=${getDataSession('sucursal')}`);
        repartidorAux.regimen_Fiscal_Receptor = repartidorAux.regimen_Fiscal_Receptor.replace(' ') === '' ? '601' : repartidorAux.regimen_Fiscal_Receptor;
        repartidorAux.uso_Cfdi = repartidorAux.uso_Cfdi.replace(' ') === '' ? 'G01' : repartidorAux.uso_Cfdi;
        repartidorAux.estatus = repartidorAux.estatus.replace(' ') === '' ? 'A' : repartidorAux.estatus;
        repartidorAux.rfc = repartidorAux.estatus.replace(' ') === '' ? '' : repartidorAux.rfc;
        setRepartidor(repartidorAux);
    }

    function GenDataMenu(name) {
        return [{
            Titulo: 'Todos',
            ValueStatus: '',
            Value: totales?.todos ?? 0,
            SelectedValue: name === 'Todos'
        }, {
            Titulo: 'Pendiente',
            ValueStatus: 'P',
            Value: totales?.pendientes ?? 0,
            SelectedValue: name === 'Pendiente'
        }, {
            Titulo: 'Terminado',
            ValueStatus: 'T',
            Value: totales?.terminados ?? 0,
            SelectedValue: name === 'Terminado'
        }, {
            Titulo: 'Enviado',
            ValueStatus: 'E',
            Value: totales?.enviado ?? 0,
            SelectedValue: name === 'Enviado'
        }, {
            Titulo: 'Surtido',
            ValueStatus: 'S',
            Value: totales?.surtido ?? 0,
            SelectedValue: name === 'Surtido'
        }, {
            Titulo: 'Cancelado',
            ValueStatus: 'C',
            Value: totales?.cancelado ?? 0,
            SelectedValue: name === 'Cancelado'
        }]
    }

    function getTotales() {
        fetchConToken(`Ventas/CantidadXEstatus?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${TipoDocto}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`).then(function (response) { setTotales(response); });
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth() , new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }

    useEffect(() => {console.log("entro");actualizarTitulo(); }, []);

    useEffect(() => {console.log("entro");getTotales();}, [fechaInicio, fechaFin]);

    useEffect(() => {console.log("entro");setDataMenu(GenDataMenu('Todos'));}, [totales]);


    return (
        <>
            <MenuMoviminetosContador data={dataMenu} HandleChange={handleInputChange} />
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-3'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                        <label style={{ width: '2%' }} />
                        <button className='btn btn-outline-primary m-1' style={{ width: 'auto' }} onClick={handleClick}><CloudDownloadIcon />&nbsp; Descargar <ArrowDropDownIcon /></button>
                        <Menu id="descargar-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                            <MenuItem><ListItemIcon><svg xmlns='http://www.w3.org/2000/svg' width='20' eight='20' fill='currentColor' className='bi bi-file-excel' viewBox='0 0 16 16'>
                                <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                                <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                            </svg></ListItemIcon>Descargar Excel</MenuItem>
                            <MenuItem><ListItemIcon><PictureAsPdfIcon /></ListItemIcon>Descargar PDF</MenuItem>
                        </Menu>
                    </div>
                    <div className='col-sm-3'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <AsyncSelect
                                placeholder='&nbsp; Cliente'
                                aria-label='Cliente'
                                isClearable
                                getOptionLabel={(option) => 'Cliente:' + option.nombre + ' - ' + option.rfc }
                                getOptionValue={(option) => option.id}
                                loadOptions={loadClientes}
                                onChange={(selectedItem) => { getCliente(selectedItem === null ? '' : selectedItem?.id); }}
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-3' >
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <AsyncSelect
                                placeholder='&nbsp; Repartidor'
                                aria-label='Repartidor'
                                label="Repartidor"
                                isClearable
                                getOptionLabel={(option) => 'Repartidor:' + option.nombre + ' - ' + option.rfc }
                                getOptionValue={(option) => option.id}
                                loadOptions={loadRepartidor}
                                onChange={(selectedItem) => { getRepartidor(selectedItem === null ? '' : selectedItem?.id); }}
                            />
                        </FormControl>
                    </div>
                    <div className='col-sm-3' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        reload={actualizarTabla} 
                        url={`Ventas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${TipoDocto}&status=${statusFiltro}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`} />
                </div>
            </div>
        </>
    );
};

export { List };