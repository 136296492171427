import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import CircularProgress from '@mui/material/CircularProgress';

const FormFichhaTecnica = ({ data, HandleChangePrincipal }) => {
    const [atributos, setAtributos] = useState(null);
    const loadDataAtributos = async (query) => await fetchConToken(`Categorias/AtributosXCategoria?pagina=${1}&totalXpagina=${100}&claveCategoria=${query}`).then((resp) => {
        setAtributos(resp);
    });

    useEffect(() => { loadDataAtributos(data.clave_Categoria) }, []);

    return (
        <>
            <FichaTecnica data={data} Atributos={atributos} HandleChangePrincipal={HandleChangePrincipal} />
        </>
    );
}

const FichaTecnica = ({ data, Atributos, HandleChangePrincipal }) => {
    const GetAtributos = () => {
        var atributosReturn = [];
        if (Atributos) {
            Atributos = Atributos.filter((item) => !item.readonly && item.clave_Atributo !== 'PRODUCT_DATA_SOURCE' && item.clave_Atributo !== 'SELLER_SKU');
            var AtributosAux = Atributos.filter((item) => item.value_Type !== 'boolean');
            AtributosAux = AtributosAux.filter((item) => item.clave_Atributo !== 'WARRANTY_TYPE');
            AtributosAux = AtributosAux.filter((item) => item.clave_Atributo !== 'WARRANTY_TIME');
            for (let i = 0; i < AtributosAux.length; i += 3) atributosReturn.push([AtributosAux[i], AtributosAux[i + 1], AtributosAux[i + 2]]);
        }

        return atributosReturn;
    }

    const GetAtributosGarantia = () => {
        var atributosReturn = [];
        if (Atributos) {
            Atributos = Atributos.filter((item) => !item.readonly && item.clave_Atributo !== 'PRODUCT_DATA_SOURCE' && item.clave_Atributo !== 'SELLER_SKU');
            atributosReturn = Atributos.filter((item) => item.clave_Atributo === 'WARRANTY_TIME' || item.clave_Atributo === 'WARRANTY_TYPE');
        }

        return atributosReturn;
    }

    const GetAtributosBool = () => {
        var atributosReturn = [];
        if (Atributos) {
            Atributos = Atributos.filter((item) => !item.readonly && item.clave_Atributo !== 'PRODUCT_DATA_SOURCE' && item.clave_Atributo !== 'SELLER_SKU');
            var AtributosBool = Atributos.filter((item) => item.value_Type === 'boolean');
            for (let i = 0; i < AtributosBool.length; i += 3) atributosReturn.push([AtributosBool[i], AtributosBool[i + 1], AtributosBool[i + 2]]);
        }

        return atributosReturn;
    }

    const RowFichaTecnica = (Atributos, key) => {
        return (
            <div key={key} className='row'>
                {Atributos && Atributos.map((item) => { return item && (<CampoFichaTecnica key={item.clave_Atributo} data={data} Atributo={item} HandleChangePrincipal={HandleChangePrincipal} />); })}
            </div>
        );
    }

    const RowGarantiaFichaTecnica = (Atributos) => {
        if (Atributos && Atributos.length > 0)
            return (
                <div key='ATRB_WARRANTY' className='row'>
                    {Atributos && (<CampoGarantiaFichaTecnica data={data} Atributos={Atributos} HandleChangePrincipal={HandleChangePrincipal} />)}
                </div>
            );
    }

    return (
        <>
            <div className='espacio15px' />
            <div className='container-fluid'>
                {(!Atributos || Atributos.length === 0) &&
                    <div style={{ height: '10vw' }}>
                        <div style={{ height: '4vw'}} />
                        <div className='col-md-12 row' style={{ width: '100%' }}>
                            <div className='col-sm-5' />
                            <div className='col-sm-1' style={{ display: 'grid' }}><div className='card-body text-start'><CircularProgress /></div></div>
                            <div className='col-sm-5' />
                        </div>
                    </div>
                }
                {Atributos && GetAtributos().map((item) => { return RowFichaTecnica(item, `ATRB_${item[0].clave_Atributo}`); })}
                {Atributos && RowGarantiaFichaTecnica(GetAtributosGarantia())}
                {Atributos && GetAtributosBool().map((item) => { return RowFichaTecnica(item, `ATRB_BOOL_${item[0].clave_Atributo}`); })}
            </div>
        </>
    );
}

const CampoFichaTecnica = ({ data, Atributo, HandleChangePrincipal }) => {
    const [initialObjectUnidad, setInitialObjectUnidad] = useState('');
    const valueInitial = () => {
        switch (Atributo.value_Type) {
            case 'boolean': return data.atributos?.find(x => x.clave_Atributo === Atributo.clave_Atributo)?.valor ?? '' === "Si";
            case 'number_unit': return 0;
            default: return data.atributos?.find(x => x.clave_Atributo === Atributo.clave_Atributo)?.valor ?? '';
        }
    };
    const [initialObject, setInitialObject] = useState(valueInitial());
    const getCampo = () => {
        if (Atributo.value_Type === 'boolean')
            return (
                <>
                    <div className='espacio15px' />
                    <div className='form-check form-switch margin_left_switch'>
                        <input
                            type='checkbox'
                            role='switch'
                            className='form-check-input'
                            name={Atributo.clave_Atributo}
                            checked={initialObject}
                            onChange={handleInputChange}
                        />
                        <label htmlFor={Atributo.clave_Atributo} className='margin_left_switch margin_top_switch_label'>{Atributo.nombre}</label> &nbsp;&nbsp;
                    </div>
                </>
            );

        if (Atributo.tiene_Values && Atributo.values) {
            if (Atributo.value_Type === 'number_unit') {
                return (
                    <>
                        <label htmlFor={Atributo.clave_Atributo} className='form-label'>{Atributo.nombre}{Atributo.required ? '*' : ''}</label>
                        <div className='row'>
                            <div className='col-sm-6' style={{ display: 'grid' }}>
                                <input
                                    type='number'
                                    className='form-control'
                                    maxLength={100}
                                    min={0}
                                    name={Atributo.clave_Atributo}
                                    required={Atributo.required}
                                    value={initialObject}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='col-sm-6' style={{ display: 'grid' }}>
                                <select className='form-select' name='SELECT_UNIDAD' required={Atributo.required} value={initialObjectUnidad} onChange={handleInputChange}>
                                    <option key='DEFAULT' value=''>Seleccione</option>
                                    {Atributo.values.map((item) => { return (<option key={item.nombre} value={item.nombre}>{item.nombre}</option>); })}
                                </select>
                            </div>
                        </div>
                    </>
                );
            }

            return (
                <>
                    <label htmlFor={Atributo.clave_Atributo} className='form-label'>{Atributo.nombre}{Atributo.required ? '*' : ''}</label>
                    <div className="form-control select-editable">
                        <select className='form-select' name={Atributo.clave_Atributo} onChange={handleInputChange}>
                            <option key='DEFAULT' value=''>Seleccione</option>
                            {Atributo.values.map((item) => { return (<option key={item.nombre} value={item.nombre}>{item.nombre}</option>); })}
                        </select>
                        <input type="text" className='form-control' name={Atributo.clave_Atributo} required={Atributo.required} value={initialObject} onChange={handleInputChange} />
                    </div>
                </>
            );
        }

        return (
            <>
                <label htmlFor={Atributo.clave_Atributo} className='form-label'>{Atributo.nombre}{Atributo.required ? '*' : ''}</label>
                <input
                    type={Atributo.value_Type === 'number_unit' ? 'number' : 'text'}
                    className='form-control'
                    maxLength={100}
                    name={Atributo.clave_Atributo}
                    required={Atributo.required}
                    value={initialObject}
                    onChange={handleInputChange}
                />
            </>
        );
    }

    useEffect(() => GetValueInputNumberValues(), []);

    const GetValueInputNumberValues = () => {
        if (Atributo.value_Type === 'number_unit') {
            var valor = data.atributos?.find(x => x.clave_Atributo === Atributo.clave_Atributo)?.valor ?? '';
            if (Atributo.tiene_Values) {
                var cantidad = valor.split(' ');
                var val = '';
                var unidad = '';
                if (cantidad.length > 1) {//si el valor viene 1 km se pinta en pantalla solo el 1
                    val = cantidad[0];
                    unidad = cantidad[1];
                }
                else {
                    //si el valor viene 1V se presenta en pantalla solo el 1
                    if (valor !== "" && valor.substring(valor.length - 1, 1).toUpperCase() === "V") {
                        val = valor.substring(0, valor.length - 1);
                        unidad = valor.substring(valor.length - 1, 1);
                    }
                    else unidad = cantidad[0];
                }

                setInitialObjectUnidad(unidad);
                valor = val;
            }

            setInitialObject(valor);
        }
    }

    function handleInputChange(event) {console.log(event);
        const target = event.target;
        if (target.type === "checkbox") {
            setInitialObject(target.checked);
            HandleChangePrincipal(Atributo.clave_Atributo, target.checked ? 'Si' : 'No');
            return;
        }

        if (target.name === 'SELECT_UNIDAD') {
            setInitialObjectUnidad(target.value);
            HandleChangePrincipal(Atributo.clave_Atributo, `${initialObject} ${target.value}`);
            return;
        }

        if (target.type === "number") {
            setInitialObject(target.value);
            var valor = (initialObjectUnidad && initialObjectUnidad !== '') ? `${target.value} ${initialObjectUnidad}` : target.value;
            HandleChangePrincipal(Atributo.clave_Atributo, valor);
            return;
        }

        setInitialObject(target.value);
        HandleChangePrincipal(Atributo.clave_Atributo, target.value);
    }

    return (<div className='col-md-4' style={{ display: 'grid' }}><div className='card-body text-start'>{getCampo()}</div></div>);
}

const CampoGarantiaFichaTecnica = ({ data, Atributos, HandleChangePrincipal }) => {
    const getAtributo = (clave) => { return Atributos.find((item) => item.clave_Atributo === clave); }
    const [atributoTipoGarantia,] = useState(getAtributo('WARRANTY_TYPE'));
    const [atributoTiempoGarantia,] = useState(getAtributo('WARRANTY_TIME'));
    const [tipoGarantia, setTipoGarantia] = useState('');
    const [tiempoGarantia, setTiempoGarantia] = useState('0');
    const [unidadTiempoGarantia, setUnidadTiempoGarantia] = useState('');
    const [garantia, setGarantia] = useState(true);
    const isSoloSingarantia = () => { if (atributoTipoGarantia && atributoTipoGarantia.values) return atributoTipoGarantia.values.filter((item) => item.valor !== 'Sin garantía').length > 0; }
    function handleInputChange(event) {
        const target = event.target;
        if (target.name === 'WARRANTY_TYPE') {
            setGarantia(target.value !== 'Sin garantía');
            setTipoGarantia(target.value);
            HandleChangePrincipal('WARRANTY_TYPE', target.value === 'Sin garantía' ? target.value : `${target.value}: ${tiempoGarantia} ${unidadTiempoGarantia}`);
        }
        else if (target.name === 'W_TIME') {
            setTiempoGarantia(target.value);
            HandleChangePrincipal('WARRANTY_TYPE', target.value === 'Sin garantía' ? target.value : `${tipoGarantia}: ${target.value} ${unidadTiempoGarantia}`);
        }
        else {
            setUnidadTiempoGarantia(target.value);
            HandleChangePrincipal('WARRANTY_TYPE', target.value === 'Sin garantía' ? target.value : `${tipoGarantia}: ${tiempoGarantia} ${target.value}`);
        }

    }

    useEffect(() => {
        var valor = data.garantia ?? '';
        setGarantia(valor !== 'Sin garantía');
        if (valor !== 'Sin garantía') {
            var nombreGarantia = valor.split(':');
            if (nombreGarantia.length > 1) { //contempla la situacion en la que la garantia se encuentre de la siguiente manera: Garantía de fábrica: 1 años
                setTipoGarantia(nombreGarantia[0]);
                var tiempoGarantia = nombreGarantia[1].trim().split(' ');
                if (tiempoGarantia.length > 1) setUnidadTiempoGarantia(tiempoGarantia[1]);

                setTiempoGarantia(tiempoGarantia[0]);
                return;
            }
        }

        setTipoGarantia(valor);
    }, []);

    return (
        <>
            {atributoTipoGarantia && (
                <div className='col-md-4' style={{ display: 'grid' }}>
                    <div className='card-body text-start'>
                        <label htmlFor={atributoTipoGarantia.clave_Atributo} className='form-label'>{atributoTipoGarantia.nombre}</label>
                        <select className='form-select' name={atributoTipoGarantia.clave_Atributo} required={atributoTipoGarantia.required} value={tipoGarantia} onChange={handleInputChange}>
                            {atributoTipoGarantia.values.map((item) => { return (<option key={item.nombre} value={item.nombre}>{item.valor}</option>); })}
                        </select>
                    </div>
                </div>
            )}
            {isSoloSingarantia() && atributoTiempoGarantia && (
                <div className='col-md-4' style={{ display: 'grid' }}>
                    <div className='card-body text-start'>
                        <label htmlFor={atributoTiempoGarantia.clave_Atributo} className='form-label'>{atributoTiempoGarantia.nombre}</label>
                        <div className='row'>
                            <div className='col-sm-6' style={{ display: 'grid' }}>
                                <input
                                    type='number'
                                    className={garantia ? 'form-control' : 'form-control form-control-disabled'}
                                    min={0}
                                    maxLength={100}
                                    name='W_TIME'
                                    value={tiempoGarantia}
                                    onChange={handleInputChange}
                                    disabled={!garantia}
                                />
                            </div>
                            <div className='col-sm-6' style={{ display: 'grid' }}>
                                <select
                                    className={garantia ? 'form-select' : 'form-select form-control-disabled'}
                                    name={atributoTiempoGarantia.clave_Atributo}
                                    required={atributoTiempoGarantia.required}
                                    value={unidadTiempoGarantia}
                                    onChange={handleInputChange}
                                    disabled={!garantia}>
                                    {atributoTiempoGarantia.values.map((item) => { return (<option key={item.nombre} value={item.nombre}>{item.nombre}</option>); })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export { FormFichhaTecnica, FichaTecnica };