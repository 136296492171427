import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbar, GridCellParams } from '@mui/x-data-grid';
import { fetchConToken } from 'helpers/fetch';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Swal from 'sweetalert2';
import { useGlobalState } from 'resources/GlobalStateContext';
import { getDataSession, setDataSession } from 'views/Utils/Common';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ListTable = (props) => {
  const theme = createTheme({ palette: { primary: { main: '#1976d2' } } });
  const [buscar, setBuscar] = useState('');
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [modelPaginate, setModelPaginate] = useState({ page: 0, pageSize: 5 });
  const [selectionModel, setSelectionModel] = useState([]);
  const { globalVariable, setGlobalVariable } = useGlobalState();
  const [sucursalId, setSucursalId] = useState(getDataSession('sucursal'));
  const [selectedCellData, setSelectedCellData] = useState(null);
  const [selectedTitleCellData, setSelectedTitleCellData] = useState(null);

  const handlePageSizeChange = (newPageSize) => {
    setLoading(true);
    setModelPaginate(newPageSize);
    fetchData(newPageSize.page, newPageSize.pageSize);
  };

  const handleSelectionChange = (newSelection) => {
    setSelectionModel(newSelection);
    if (props.onChangeSelectdIds) props.onChangeSelectdIds(newSelection);
  };

  function handleInputChange(event) {
    const { name, value } = event.target;
    setBuscar(value);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbar></GridToolbar>
      </GridToolbarContainer>
    );
  };

  const DialogContentData = (TitleCellData, CellData) => {
    if (TitleCellData?.toUpperCase() == 'QR') return (<img src={"data:image/jpg;base64," + CellData} style={{ maxWidth: "95%" }} />);

    ///if ((TitleCellData ?? "").toUpperCase().includes("FECHA"))
    ///  return (<TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small" type="date" label={TitleCellData} value={CellData} InputLabelProps={{ shrink: true, }} />);

    return CellData;
  }

  const fetchData = (page, pageSize) => {
    try {
      //console.log("efect 5");
      fetchConToken(props.url + `&paginas=${page}&totalXpagina=${pageSize}${(!props.hideSearch ? `&busqueda=${buscar}` : '')}`)
        .then(response => {
          if (response.hasOwnProperty('status')) {
            Object.entries(response?.errors).map(([key, value]) => {
              Swal.fire('Error', value[0], 'error');
              setLoading(false);
              return;
            });
            return false;
          } else if (response.hasOwnProperty('codigo')) {
            Swal.fire('Error', response.mensajes[0], 'error');
            setLoading(false);
            return;
          }
          setRows(response.data);
          setTotalRows(response.recordsFiltered);
          setLoading(false);
        });
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    //console.log("efect 3");
    setLoading(true);
    fetchData(0, modelPaginate.pageSize);
    if (props.setBusqueda) props.setBusqueda(buscar);
  }, [buscar, props.url, props.path]);

  useEffect(() => {
    //console.log("efect 1");
    if (props.reload == true) {
      //console.log("efect 2");
      setLoading(true);
      fetchData(0, modelPaginate.pageSize);
    }
  }, [props.reload]);

  function handleChange(e) {
    fetchConToken(`ConfiguracionesSucursales?nombre=TIENDA&sucursal_id=${e.target.value}`).then(response => {
      setDataSession('tienda', response.valor);
      window.location.href = window.location.href;
    });
    setSucursalId(e.target.value);
    setDataSession('sucursal', e.target.value);
  }

  const convertirMayuscula = (cadena) => {
    return cadena.split(' ').map((palabra) => {
      return palabra.charAt(0).toUpperCase() + palabra.slice(1);
    }).join(' ');
  };

  const handleCellClick = (params) => {
    setSelectedTitleCellData(convertirMayuscula(params.field.replace("_", " ").replace(".", " ")));
    var columns = params.field.split(".");
    if (columns.length == 1) setSelectedCellData(params.row[params.field]);

    if (columns.length == 2) setSelectedCellData(params.row[columns[0]][columns[1]]);
  };

  const handleCloseDialog = () => {
    setSelectedTitleCellData(null);
    setSelectedCellData(null);
  };

  return (
    <Box sx={{ position: "relative" }}>
      {!props.hideSearch && (
        <div className='row' style={{ paddingBottom: "10px", paddingTop: "15px" }}>
          <div className='col-md-4'>
            {!props.hideConexion ?
              <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="sucursal-checkbox-label">Conexión</InputLabel>
                <Select onChange={handleChange} value={sucursalId} label="Conexión">
                  {globalVariable?.map((item) => {
                    return <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={item.nombre + (item.principal ? " (Principal)" : "")} />
                    </MenuItem>
                  })}
                </Select>
              </FormControl> : ''}
          </div>
          <div className='col-md-2'>{props.hideFiltroExtra ? props.filtroExtra : ''}</div>
          <div className='col-md-6'>
            <FormControl className='form-control'>
              <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small" label="Buscar" value={buscar} type="search" variant="filled" onChange={handleInputChange} />
            </FormControl>
          </div>
        </div>
      )}
      <ThemeProvider theme={theme}>
        <Box sx={{ width: "100%", overflowX: 'auto', position: "absolute" }} >
          <DataGrid
            columns={props.columns}
            //maxColumns={2}
            rows={rows}
            paginationMode="server"
            paginationModel={modelPaginate}
            rowCount={totalRows}
            loading={loading}
            autoHeight={true}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            checkboxSelection={props.checkboxSelection}
            components={{ Toolbar: CustomToolbar, }}
            disableColumnFilter
            onPaginationModelChange={handlePageSizeChange}
            onRowSelectionModelChange={handleSelectionChange}
            ///disableRowSelectionOnClick
            ///onEditCellChange={props.onEditCellChange}
            ///editMode="cell"
            ///isTreeData={props.isTreeData}
            onCellDoubleClick={handleCellClick}
          />
          <Dialog open={!!selectedCellData} onClose={handleCloseDialog}>
            <DialogTitle>{selectedTitleCellData}</DialogTitle>
            <DialogContent>{DialogContentData(selectedTitleCellData, selectedCellData)}</DialogContent>
          </Dialog>
        </Box>
      </ThemeProvider>
    </Box>
  )
}

export default ListTable;