import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
// import { IconDownload } from 'assets/icons';
import { getDataSession, getFirstDay, getLastDay } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import estadisticas from 'images/panel/dashboard/estadisticas.svg';
import * as FileSaver from 'file-saver';

import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';


const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;

    var curr = new Date();
    curr.setDate(curr.getDate() + 3);
    const [data, setData] = useState([]);
    const [bolsa, setBolsa] = useState([]);

    const [busqueda, setbusqueda] = useState('');
    const [totalxPagina, setTotalxPagina] = useState(10);

    let [pagina, setPagina] = useState('');

    React.useEffect(() => {
        getData(1);
        getTimbre();
        // eslint-disable-next-line
    }, []);

    async function getData(page) {
        setPagina(page);
        const response = await fetchConToken(
            `MovimientosPacs/GetList?paginas=${page}&totalXpagina=${totalxPagina}`
        );
        setData(response);
    }

    async function getTimbre() {
        const response = await fetchConToken(`TimbresPacs`);
        setBolsa(response);
    }

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };

    const handleChangeTotalXpagina = ({ target }) => {
        const pagination = target.value;
        setTotalxPagina(pagination);
    };

    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "editar"},
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'fecha', headerName: 'Fecha', flex: 1},
        { field: 'referencia_De_Pago', headerName: 'Referencia de pago', flex: 1 },
        { field: 'timbres_Comprados', headerName: 'Timbres comprados', flex: 1},
        { field: 'precio', headerName: 'Precio' , flex: 1}
        
    ];

    return (
        <>
            <div className='container-fluid'>
                <TituloPagina
                    titulo='Bolsa de timbres'
                    descripcion='Listado de compras realizadas a comercio digital.'
                />

                <div className='col-md-12'>
                    <Link to={`${path}/add`} className='btn btn-outline-success'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            fill='currentColor'
                            className='bi bi-plus-square'
                            viewBox='0 0 16 16'
                        >
                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                        </svg>
                        &nbsp; Nuevo
                    </Link>
                    &nbsp; &nbsp;
                </div>
                &nbsp;
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-md-3 status-ultimo-mes' style={{ marginBottom: '15px' }}>
                        <img src={estadisticas} />
                        <div>
                            <p className='bold'>Timbres Comprados</p>
                        </div>
                        <span>{bolsa.total_Timbres_Comprados}</span>
                    </div>

                    <div className='col-md-3 status-ultimo-mes' style={{ marginBottom: '15px' }}>
                        <img src={estadisticas} />
                        <div>
                            <p className='bold'>Timbres Usados</p>
                        </div>
                        <span>{bolsa.total_Usados}</span>
                    </div>

                    <div className='col-md-3 status-ultimo-mes' style={{ marginBottom: '15px' }}>
                        <img src={estadisticas} />
                        <div>
                            <p className='bold'>Timbres Disponible</p>
                        </div>
                        <span>{bolsa.total_Disponible}</span>
                    </div>

                    <div className='col-md-3 status-ultimo-mes' style={{ marginBottom: '15px' }}>
                        <img src={estadisticas} />
                        <div>
                            <p className='bold'>Timbres Disponibles Pac</p>
                        </div>
                        <span>{bolsa.timbres_Disponibles_Pac}</span>
                    </div>

                </div>
                <div className='card col-12 col-sm-12 col-md-12'>
                    <div style={{ overflowX: 'auto' }}>
                    <ListTable
                        columns = {columns}
                        path = {path}
                        url={`MovimientosPacs/MovimientosGetList?`}
                    />
                        {/*<Table
                            columns={['Fecha', 'Referencia de pago', 'Timbres comprados', 'Precio']}
                            rows={[
                                ['fecha', 'fecha'],
                                ['text', 'referencia_De_Pago'],
                                ['text', 'timbres_Comprados'],
                                ['moneda', 'precio']
                            ]}
                            path={path}
                            url={`/MovimientosPacs/List?sucursal_Id=${getDataSession('sucursal')}`}
                        ></Table>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

export { List };
