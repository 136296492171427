import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { MenuMoviminetosContador } from '../MenuMoviminetosContador';
import { useGlobalState } from 'resources/GlobalStateContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListTable from 'components/table/ListTable';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import { currency } from 'resources/utilities';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Swal from 'sweetalert2';

const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const [TipoDocto] = useState('R');
    const [statusFiltro, setStatusFiltro] = useState("");
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [tipoDocumento, setTipoDocumento] = useState('Todos');
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [totales, setTotales] = useState([]);
    const handleClose = () => setAnchorEl(null);
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const [dataMenu, setDataMenu] = useState(GenDataMenu('Todos'));
    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "vista,verEntregas,asignarRepartidor" },
        {
            field: 'id', headerName: 'Id', maxWidth: 20, renderCell: (params) => (
                <a href={`${path.replace(':documento', TipoDocto) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'no_Orden', headerName: 'Orden', minWidth: 200, renderCell: (params) => (
                <a href={`${path.replace(':documento', TipoDocto) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'folio', headerName: 'Folio', minWidth: 100, renderCell: (params) => (
                <a href={`${path.replace(':documento', TipoDocto) + '/view/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'documento_FoliosOrigen', headerName: 'Folios ERP', minWidth: 130, renderCell: (params) => (
                <p>{params.row.documento_FoliosOrigen.map(objeto => objeto.erp_Folio)?.join(', ')}</p>)
        },
        { field: 'estatus', headerName: 'Estatus', minWidth: 130 },
        { field: 'fecha_Orden', headerName: 'Fecha', minWidth: 160 },
        { field: 'fecha_Registro', headerName: 'Fecha Registro', minWidth: 160 },
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 300 },
        { field: 'rfc_Cliente', headerName: 'RFC', minWidth: 150},
        { field: 'importe_Total', headerName: 'SubTotal', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'impuestos', headerName: 'Impuestos', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'importe_Total_Impuestos', headerName: 'Total', type: 'number', renderCell: ({ value }) => currency(value) }
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Ventas',
            subtitulo: 'Listado de Ventas realizadas'
        });
    };

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    const handleInputChange = (event) => {
        setDataMenu(GenDataMenu(event.target.name));
        var selected = dataMenu.find(x => x.Titulo === event.target.name);
        setStatusFiltro(selected?.ValueStatus);
    }

    const handleChangeTipoDocumento = ({ target }) => {
        setTipoDocumento(target.value);
    }

    function GenDataMenu(name) {
        return [{
            Titulo: 'Todos',
            ValueStatus: '',
            Value: totales?.todos ?? 0,
            SelectedValue: name === 'Todos'
        }, {
            Titulo: 'Vigente',
            ValueStatus: 'V',
            Value: totales?.Vigente ?? 0,
            SelectedValue: name === 'Vigente'
        }, {
            Titulo: 'Cancelado',
            ValueStatus: 'C',
            Value: totales?.cancelado ?? 0,
            SelectedValue: name === 'Cancelado'
        }]
    }

    function getTotales() {
        fetchConToken(`Ventas/CantidadXEstatus?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${TipoDocto}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`).then(function (response) { setTotales(response); });
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }

    useEffect(() => actualizarTitulo(), []);

    useEffect(() => getTotales(), [fechaInicio, fechaFin]);

    useEffect(() => setDataMenu(GenDataMenu('Todos')), [totales]);


    return (
        <>
            <MenuMoviminetosContador data={dataMenu} HandleChange={handleInputChange} />
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-4'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                        <label style={{ width: '2%' }} />
                        <button className='btn btn-outline-primary m-1' style={{ width: 'auto' }} onClick={handleClick}><CloudDownloadIcon />&nbsp; Descargar <ArrowDropDownIcon /></button>
                        <Menu id="descargar-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                            <MenuItem><ListItemIcon><svg xmlns='http://www.w3.org/2000/svg' width='20' eight='20' fill='currentColor' className='bi bi-file-excel' viewBox='0 0 16 16'>
                                <path d='M5.18 4.616a.5.5 0 0 1 .704.064L8 7.219l2.116-2.54a.5.5 0 1 1 .768.641L8.651 8l2.233 2.68a.5.5 0 0 1-.768.64L8 8.781l-2.116 2.54a.5.5 0 0 1-.768-.641L7.349 8 5.116 5.32a.5.5 0 0 1 .064-.704z' />
                                <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                            </svg></ListItemIcon>Descargar Excel</MenuItem>
                            <MenuItem><ListItemIcon><PictureAsPdfIcon /></ListItemIcon>Descargar PDF</MenuItem>
                        </Menu>
                    </div>
                    <div className='col-sm-3' >
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="documento-label">Tipo Documento</InputLabel>
                            <Select label="Tipo Fecha" value={tipoDocumento} onChange={handleChangeTipoDocumento}>
                                <MenuItem key='Todos' value='Todos'><ListItemText primary='Todos' /></MenuItem>
                                <MenuItem key='Ticket' value='Ticket'><ListItemText primary='Ticket' /></MenuItem>
                                <MenuItem key='Nota' value='Nota'><ListItemText primary='Nota' /></MenuItem>
                                <MenuItem key='Remision' value='Remision'><ListItemText primary='Remision' /></MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-sm-5' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable
                        columns={columns}
                        path={path}
                        reload={actualizarTabla}
                        url={`Ventas/List?sucursal_Id=${getDataSession('sucursal')}&tipo_documento=${TipoDocto}&status=${statusFiltro}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`} />
                </div>
            </div>
        </>
    );
};

export { List };