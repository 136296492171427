import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useGlobalState } from 'resources/GlobalStateContext';

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';

import Swal from 'sweetalert2';

const AddEdit = ({ history, match }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const { id } = match.params;
    const path = match.url;
    const isAddMode = !id;
    const { register, handleSubmit, reset } = useForm();
    let [condicionesPago, setCondicionesPago] = useState([]);

    let [data, setData] = useState(
        {
            nombre: "",
            contacto1: "",
            contacto2: "",
            nombre_Calle: "",
            no_Exterior: "",
            no_Interior: "",
            colonia: "",
            poblacion: "",
            referencia: "",
            ciudad_Id: 0,
            estado_Id: 0,
            pais_Id: 0,
            codigo_Postal: "",
            telefono1: "",
            telefono2: "",
            email: "",
            rfc_Curp: "",
            estatus: "A",
            cond_Pago_Id: 0,
            sucursal_Id: getDataSession('sucursal')
        }
    );

    
    function onSubmit() {
        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        return isAddMode ? create(data) : update(id,data);
    }

    function GetProveedor() {
        fetchConToken(`VxProveedor?id=${id}`).then(
            response=>{
                setData(response);
            }
        );
    }

    async function eliminar() {
        Swal.fire({
            title: '¿Desea eliminar este cliente?',
		icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(`clientes?cliente_id=${id}`, null, 'DELETE')
                .then((response) => {
                    if (response.hasOwnProperty('status')) {
                        Object.entries(response?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }
                    Swal.fire('Exito', 'Cliente eliminado', 'success');
                    history.push('..');
                })
            }})
    }

    function create(data) {
        return fetchConToken('VxProveedor', data, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Proveedor agregado', 'success');
                history.push('.');
            })
            .catch(alertService.error);
    }

    function update(id,data) {
        data.id = id;
        return fetchConToken('VxProveedor', data, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error'); 
                    return false;
                }
                Swal.fire('Exito', 'Proveedor modificado', 'success');
                history.push('..');
            })
            .catch(alertService.error);
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 'A' : 'I') : target.value;
        const name = target.name;
        setData({
            ...data,
            [name]: value
        });
    }
    

    useEffect(() => {
        GetCondicionesPago();
        if (!isAddMode) {
            GetProveedor();
        } else {
            if (getDataSession('sucursal') === '0') {
                Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
                return;
            }
        }
        actualizarTitulo();
    }, []);
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: isAddMode ? 'Agregar Proveedor' : 'Editar Proveedor',
          subtitulo: ''
        });
      };

    function GetCondicionesPago(){
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        fetchConToken(`VxCondicionPagos/GetList?paginas=0&totalXpagina=500&sucursal_Id=${getDataSession('sucursal')}`).then(
            response=>{
                setCondicionesPago(response.data);

                setData({
                    ...data,
                    ['cond_Pago_Id']: response.data[0]?.id
                });
                
            }
        );
    };

    return (
        <>
            <div className='container-fluid '>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className='mb-3'>
                        <div className='col-md-12' style={{ textAlign: 'left' }}>
                    
             
                                <div className='col-md-12 text-right'>
                                    {!isAddMode ? (
                                        <button
                                            className='btn btn-outline-danger'
                                            type='button'
                                            style={{ float: 'left' }}
                                            onClick={() => eliminar()}
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='20'
                                                height='20'
                                                fill='currentColor'
                                                className='bi bi-x-lg'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                                            </svg>
                                            &nbsp; &nbsp; Eliminar
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                    &nbsp;
                                    <button className='btn btn-outline-success' type='submit'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; Guardar
                                    </button>
                                    &nbsp;
                                    <Link
                                        to={isAddMode ? '.' : '..'}
                                        className='btn btn-outline-secondary'
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-reply'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                        </svg>
                                        &nbsp; Regresar &nbsp;
                                    </Link>
                                
                            </div>
                        </div>
                    </div>

                    <div className=''>
                        <div className='col-md-12' style={{ display: 'grid', float: 'left' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start row'>
                                    <div className='col-md-6'>
                                        <label htmlFor='nombre'>Nombre / Razón Social *:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='nombre'
                                            value={data.nombre || ''}
                                            onChange={handleInputChange}
                                            required
                                        />
                                         <label htmlFor='rfc'>RFC:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='rfc_Curp'
                                            value={data.rfc_Curp || ''}
                                            onChange={handleInputChange}
                                        />
                                         <label htmlFor='rfc'>Contacto 1:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='contacto1'
                                            value={data.contacto1 || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='rfc'>Contacto 2:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='contacto2'
                                            value={data.contacto2 || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='rfc'>Telefono 1:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='telefono1'
                                            value={data.telefono1 || ''}
                                            onChange={handleInputChange}
                                        />
                                         <label htmlFor='rfc'>Telefono 2:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='telefono2'
                                            value={data.telefono2 || ''}
                                            onChange={handleInputChange}
                                        />
                                         <label htmlFor='rfc'>Correo:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='email'
                                            value={data.email || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='id'>Condicion de Pago:</label>
                                            <select className='form-control' name='cond_Pago_Id' value={data?.cond_Pago_Id} onChange={handleInputChange}>
                                                { condicionesPago?.map((item) => {
                                                    return (<option key={'suc'+item.id} value={item.id}>{item.nombre}</option>);
                                                }) } 
                                            </select>
                                            <div className='form-check form-switch'>
                                                <br></br>
                                                <label htmlFor='nombre' className='form-label'> 
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    name='estatus'
                                                    role='switch'
                                                    checked={data.estatus == 'A'}
                                                    onChange={handleInputChange}
                                                /> Estatus</label>
                                            </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='rfc'>Nombre Calle:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='nombre_Calle'
                                            value={data.nombre_Calle || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='rfc'>No. Exterior:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='no_Exterior'
                                            value={data.no_Exterior || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='rfc'>No. Interior:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='no_Interior'
                                            value={data.no_Interior || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='rfc'>Colonia:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='colonia'
                                            value={data.colonia || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='rfc'>Poblacion:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='poblacion'
                                            value={data.poblacion || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='rfc'>Codigo Postal:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='codigo_Postal'
                                            value={data.codigo_Postal || ''}
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor='rfc'>Referencia:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='referencia'
                                            value={data.referencia || ''}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export { AddEdit };
