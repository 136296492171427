import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import { Link } from 'react-router-dom';
import ListTable from 'components/table/ListTable';
import TextField from '@mui/material/TextField';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';


const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation("common");
    const { globalTitulo, setGlobalTitulo } = useGlobalState();

    let [proveedor, setProveedor] = useState({});
    let [saldoProveedor, setSaldoProveedor] = useState([]);

    const [isOpenModal, openModal, closeModal] = useModal(false);

    const verAdeudos = (proveedor_id) => {
        fetchConToken(`vxproveedor?id=${proveedor_id}&sucursal_id=${getDataSession('sucursal')}`).then(resp=>{
            setProveedor(resp);
        });
        fetchConToken(`vxproveedor/Saldos?proveedor_id=${proveedor_id}`).then(resp=>{
            setSaldoProveedor(resp);
        });        
        openModal();
    }

    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "editar,verAdeudos",verAdeudos: verAdeudos },
        { field: 'nombre', headerName: 'Cliente', minWidth: 250 },
        { field: 'rfc_Curp', headerName: 'RFC', minWidth: 150},
        { field: 'deuda', headerName: 'Deuda', minWidth: 150, type: 'number', renderCell: (params) => currency(params.row.saldo_Proveedor.deuda)   },
        { field: 'acreditado', headerName: 'Acreditado', minWidth: 150, type: 'number', renderCell: (params) => currency(params.row.saldo_Proveedor.acreditado)  },
        { field: 'diferencia', headerName: 'Diferencia', minWidth: 150, type: 'number', renderCell: (params) => currency(params.row.saldo_Proveedor.diferencia)  },      
        
    ];

    
 

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Creditos',
            subtitulo: 'Listado de Creditos'
        });
    };




    useEffect(() => actualizarTitulo(), []);


    return (
        <>
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-2'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary m-1' style={{ width: 'auto' }}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; {t('app.SwalFire.nuevo')}
                        </Link>
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable 
                        columns={columns} 
                        path={path} 
                        url={`VxProveedor/GetList?sucursal_Id=${getDataSession('sucursal')}`} />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} >
                <div className='col-md-12 row'>
                    <div className='col-md-4'>
                        <h4>Proveedor</h4>
                        <label>Cliente:</label><label>{proveedor?.nombre}</label>
                        <label>Telefono:</label><p>{proveedor?.telefono1}</p>
                        <label>Correo:</label><p>{proveedor?.correo}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4></h4>
                        <label>Rfc:</label><p>{proveedor?.rfc_Curp}</p>
                        <label>Poblacion:</label><p>{proveedor?.poblacion}</p>
                        <label>Colonia:</label><p>{proveedor?.colonia}</p>
                    </div>
                    <div className='col-md-4'>
                         <h4>Adeudo</h4>
                        <label>Deuda:</label><p>{currency(proveedor?.saldo_Proveedor?.deuda)}</p>
                        <label>Acreditado:</label><p>{currency(proveedor?.saldo_Proveedor?.acreditado)}</p>
                        <label>Deuda</label><p>{currency(proveedor?.saldo_Proveedor?.deuda)}</p>
                    </div>
                </div>
                <div className='col-md-12'>
                    <h4>Adeudos</h4>
                    {
                       saldoProveedor?.map(x=>{
                          return  ( <div className='row' style={{border: "1px solid black"}}>
                            <div className='col-md-3'>
                            <label>Folio Adeudo:</label><p>{x?.folio}</p>
                            </div>
                            <div className='col-md-3'>
                            <label>Fecha Vencimiento:</label><p>{x?.fecha_Vencimiento}</p>
                            </div>
                            <div className='col-md-2'>
                            <label>Importe Acreditado:</label><p>{currency(x?.importe_Acreditado)}</p>
                            </div>
                            <div className='col-md-2'>
                            <label>Saldo Cargo:</label><p>{currency(x?.saldo_Cargo)}</p>
                            </div>
                            <div className='col-md-2'>
                            <label>Forma pago:</label><p>{x?.concepto}</p>
                            </div>                                 
                            </div>);
                        })
                    }
                </div>
           </Modal>
        </>
    );
}

export { List };