import React, {useState} from 'react';
//import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken,fetchConTokenFile,fetchSinToken } from 'helpers/fetch';
import  Pagination  from 'components/pagination/Pagination'
import { getDataSession, getFirstDay, getLastDay } from 'views/Utils/Common';
import * as FileSaver from "file-saver";
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import Swal from 'sweetalert2';

import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';

const SeriesEmpresa = ({ match}) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const { id } = match.params;
    const { path } = match;

    const [isOpenModal, openModal, closeModal] = useModal(false);
    async function agregarSerieModal() {
        folio_sucursal.id= 0;
        folio_sucursal.sucursal_Id= getDataSession("sucursal");
        folio_sucursal.tipo_Documento= 'C';
        folio_sucursal.serie= '';
        folio_sucursal.consecutivo= 1;
        openModal();
    }

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Series registradas' ,
          subtitulo: 'Listado de series utilizadas para los documentos.'
        });
      };
    React.useEffect(() => {
        if(id > 0)
        {
            console.log("entro");
            ObtenDatoGuardado(id);
        }
        actualizarTitulo();
    },[]);

    let [folio_sucursal, setFolio_sucursal] = useState({
        id: 0,
        sucursal_Id: getDataSession("sucursal"),
        tipo_Documento: 'C',
        serie: '',
        consecutivo: 1
    });


    const ObtenDatoGuardado = (id) => {
        fetchConToken('FoliosSucursales?id='+id).then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setFolio_sucursal(response);
            openModal();
        });
    };

    const handleInputChange = ({ target }) => {
        setFolio_sucursal({
            ...folio_sucursal,
            [target.name]: target.value
        });
    };

    const GuardarSerie = () => {

        if(folio_sucursal.serie == '')
        {
            Swal.fire('Info', 'Escriba una serie', 'info');
            return;
        }
        if(folio_sucursal.consecutivo == 0)
        {
            Swal.fire('Info', 'Escriba un consecutivo valido', 'info');
            return;
        }

        if(folio_sucursal.id == 0)
            agregarSerie();
        if(folio_sucursal.id > 0)
            ActualizarSerie();

    }
    

    const agregarSerie = () => {
        fetchConToken('FoliosSucursales/FolioSucursal', folio_sucursal, 'POST').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            closeModal();
            Swal.fire('Exito', 'Serie Agregada', 'success');
            window.location.reload();
        });
    };
    const ActualizarSerie = () => {
        fetchConToken('FoliosSucursales/FolioSucursal', folio_sucursal, 'PUT').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            closeModal();
            Swal.fire('Exito', 'Serie actualizada', 'success');
            window.location.href = "/SeriesEmpresa";
        });
    };


    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "URLeditar",UrlEditar : '/SeriesEmpresa/edit/'},
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'documento', headerName: 'Tipo Documento', flex: 1},
        { field: 'serie', headerName: 'Serie', flex: 1 },
        { field: 'consecutivo', headerName: 'Consecutivo', flex: 1}
        
    ];

    
    return (
        <>

          <div className="container-fluid" >
                                    
                <div className='col-md-12'>
                &nbsp;
                <div className='col-md-12'>
                                <button
                                    type='button'
                                    className='btn btn-outline-primary'
                                    onClick={() => agregarSerieModal()}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-plus-square'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                        <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                    </svg>
                                    &nbsp; Nueva Serie
                                </button>
                            </div>
             
            </div>
            <div className='col-md-12' >
            <ListTable
                    columns = {columns}
                    path = "/SeriesEmpresa"
                    url={`FoliosSucursales/FoliosSucursalesGetList?sucursal_id=${getDataSession("sucursal")}`}>
            </ListTable>
                  

            </div>
        </div>
        <Modal isOpen={isOpenModal} closeModal={closeModal}>
                <br></br>
                <h4>Agrege una serie</h4>
                <div className='col-md-12 row'>
                    <div className='col-md-12 row'>
                        <div className='col-md-4'>
                            <label>Tipo documento</label>
                            <select name="serie_Documento" className='form-control' disabled={folio_sucursal.id > 0} onChange={handleInputChange} value={folio_sucursal.serie_Documento}> 
                                <option value="" selected>Seleccione una serie</option>
                                <option value="serie_cotizacion">Cotización</option>
                                <option value="serie_pedido">Pedido</option>
                                <option value="serie_remision">Remision</option>
                                <option value="serie_factura">Factura</option>
                                <option value="serie_ordencompra">Orden Compra</option>
                                <option value="serie_recepcioncompra">Recepcion Compra</option>
                                <option value="serie_compra">Compra</option>
                                <option value="serie_orden_reparacion">Orden Reparación</option>
                                <option value="serie_devolucion">Devolución</option>
                            </select>
                        </div>
                        <div className='col-md-4'>
                            <label>Serie</label>
                            <input
                                    type='text'
                                    name='serie'
                                    disabled={folio_sucursal.id > 0}
                                    value={folio_sucursal.serie}
                                    className='form-control'    
                                    onChange={handleInputChange}                    
                                />
                        </div>
                        <div className='col-md-4'>
                            <label>Consecutivo</label>
                            <input
                                    type='number'
                                    className='form-control' 
                                    name="consecutivo"
                                    value={folio_sucursal.consecutivo}          
                                    onChange={handleInputChange}             
                                />
                        </div>
                    </div>
                </div>
                <div className='col-md-12 text-right'>
                    <br></br>
                    <buton className='btn btn-outline-success' type='button'  onClick={() => GuardarSerie()}>
                        Guardar
                    </buton>
                </div>
                
            </Modal>
        </>
    );    


}

export { SeriesEmpresa };