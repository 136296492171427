import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchSinToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import { descargarFactura } from 'actions/archivos';
import SLUGS from 'resources/slugs';
import { currency, rfcValido } from 'resources/utilities';
import Swal from 'sweetalert2';
//////imagenes////
//////////////////
import fondo from 'images/home/header_factura.jpg';
import logo from 'images/login/logo.png';
import header from 'images/home/header_factura.jpg';

import icon_factuxin from 'images/login/icon_factuxin.png';

import amazon from 'images/login/recuperacion_contrasena/amazon.png'
import shopify from 'images/login/recuperacion_contrasena/shopify.png'
import mercado_libre from 'images/login/recuperacion_contrasena/mercado_libre.png'
import woocomerce from 'images/login/recuperacion_contrasena/woocomerce.png'
 /////////////////


const Facturar = ({ history, match, saludo, subtitulo }) => {

    const baseUrl = process.env.REACT_APP_SERVER_URL;

    const { path } = match;
    const { id,empresa }   = match.params;

    const [facturado, setFacturado] = useState(false);


    let [cfdi, setCfdi] = useState([]);

    let [cliente, setCliente] = useState([]);
    let [direcciones, setDirecciones] = useState([]);
    let [venta, setVenta] = useState([]);
    let [articulos, setArticulos] = useState([]);
    let [ventaUpdate, setVentaUpdate] = useState('');

    let [error, setError] = useState(['']);
    let [erroresCliente, setErroresCliente] = useState({});
    let [erroresV, setErroresV] = useState({});
    let [erroresArticulos, setErroresArticulos] = useState({});
    let [errores, setErrores] = useState({});
    let [configuracion, setConfiguracion] = useState(['']);

    const [satUsoCfdi, setSatUsoCfdi] = useState([]);
    const [satRegimenFiscal, setSatRegimenFiscal] = useState([]);

    console.log(empresa);
    console.log( match.params);

    async function getSatUsoCfdi() {
        const response = await fetchSinToken(`CatalogosSat/ListUsoCfdi?pagina=1&totalXpagina=300`);
        setSatUsoCfdi(response);
    }
    async function getSatRegimelFiscal(uso_Cfdi_clave) {
        const response = await fetchSinToken(`CatalogosSat/ListRegimenFiscal?pagina=1&totalXpagina=300&uso_cfdi_clave=${uso_Cfdi_clave}`);
        console.log("entro get regimen")
        setSatRegimenFiscal(response);
    }

    function getCfdi() {
        fetchSinToken(`Cfdi/GetVenta?id_venta=${id}`).then(
      (response) => {
          if (response.hasOwnProperty('status') && response.status === 400) {
              return;
          } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
              return;
          }
          
          Swal.fire('Exito', 'Esta venta ya fue facturada', 'success');

          setError('Esta venta ya fue facturada');
          setCfdi(response);
          setFacturado(true);
          // history.push('..');
      }
    );
  }

    async function getVenta(recarga=false) {
        const ventaAux = await fetchSinToken(
            `Ventas/VentaEncriptada?id_venta=${id}`
        );
        if(!recarga)
        {
        ventaAux.forma_De_Pago = '--';
        ventaAux.metodo_Pago = '--';
        }

        setCliente(ventaAux.direccion_Cliente.cliente);
        setDirecciones(ventaAux.direccion_Cliente);
        setVenta(ventaAux);
        
        getSatRegimelFiscal(ventaAux.direccion_Cliente.cliente.uso_Cfdi);

        articulos.length = 0;
        ventaAux.venta_Detalles?.map((item) => {
            articulos.push(item?.articulo);
        });
    }


    async function facturar() {
        setFacturado(true);
        
        let respCliente = await saveCliente().then(resp=>{return resp;})
        if(!respCliente)
        {
            setFacturado(false);
            return;
        }
        let respDireccion = await saveDireccion().then(resp=>{return resp;})
        if (!respDireccion) 
        {
            setFacturado(false);
           return;//setError('No se pudo actualizar la direccion');
        }
        let respVenta = await saveVenta().then(resp=>{return resp;})
        if (!respVenta) 
        {
            setFacturado(false);
            return;//setError('No se pudieron actualizar los articulos');
        }
        
        getVenta(true);
        fetchSinToken(`Facturacion/FacturaEncriptada?id_venta=${id}`).then(
            (response) => {
                
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error'); 
                        setFacturado(false);
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    setFacturado(false);
                    return false;
                }

                Swal.fire('Exito', 'Facturado correctamente', 'success');
                setFacturado(true);
                setCfdi(response);
                history.push(`/facturacion/${empresa}/descargar?cfdi=${response.id}`);
            }
        );
        //.catch(alertService.error("75"));
    }

    async function saveCliente(){
        let respuesta = await  fetchSinToken(`Clientes/ActualizaCliente`, cliente, 'PUT').then(
            (response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error'); 
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                setCliente(response)
                return true;
            }
        );
        return respuesta;
        //.catch(alertService.error("91"));direcciones
    };

    async function saveDireccion(){
        let respuesta = await fetchSinToken(`DireccionesClientes/ActualizaDireccion`, direcciones, 'PUT').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error'); 
                    return false;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setDirecciones(response)
            return true;
        });
        return respuesta;
        //.catch(alertService.error("107"));
    }
    

    async function saveVenta(){
        if(venta.forma_De_Pago === '--')
         {
            Swal.fire('Error', "Seleccione una forma de pago valida", 'error');
            return false;
         }
         if(venta.metodo_Pago === '--')
         {
            Swal.fire('Error', "Seleccione un metodo de pago valido", 'error');
            return false;
         }
        venta.venta_Detalles = null;
        let respuesta = await  fetchSinToken(`Ventas/ActualizaVenta`, venta, 'PUT').then(
            (response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error'); 
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                return true;
            } 
        );
        return respuesta;
        //.catch(alertService.error("131"));
    };

    
    React.useEffect(() => {
        getVenta();
        getEmpresa();
        getSatUsoCfdi();
    }, []);

    
    const handleInputChangeCliente = ({ target }) => {
        setCliente({
            ...cliente,
            [target.name]: target.value
        });

        if(target.name === 'uso_Cfdi')
        {
            getSatRegimelFiscal(target.value);
        }
    };

    const handleInputChangeDireccion = ({ target }) => {
        setDirecciones({
            ...direcciones,
            [target.name]: target.value
        });
    };
    const handleInputChangeVenta = ({ target }) => {
        setVenta({
            ...venta,
            [target.name]: target.value
        });
    };

    const handleInputChangeArticulos = (event, key, campo) => {
        var name = event.target.name;
        var value = event.target.value;

        let articulo = articulos.filter((art) => art.id == key);
        articulo[0][campo] = event.target.value;

        setArticulos(articulos.map((el) => (el.id === key ? articulo[0] : el)));
    };

    function getEmpresa(){
        fetchSinToken(`Empresas/GetUrlPersonalizada?url_personalizada=${empresa}`, null, 'GET').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error'); 
                    return false;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            fetchSinToken(`ConfiguracionesEmpresas/GetListSinToken?empresa_Id=${response.id}&pagina=1&totalXpagina=100&categoria=facturacion`, null, 'GET').then((resp) => {
                setConfiguracion(resp);
            });

            return true;
        });
        return true;
        //.catch(alertService.error("107"));
    }

    return (
        <>
       <div className='col-md-12 row' style={{ backgroundColor: 'rgba(35, 46, 209, 1)',height: '11vh'}}>
        <div className='col-md-4'>
        <div style={{float: 'left'}}>
            <img src={icon_factuxin}  style={{width: 'auto',display: 'block',marginLeft: '5vh',maxHeight: '11vh'}}/>
        </div>
            <h4 style={{color: 'rgba(255, 255, 255, 1)', color: '#ffff', fontSize: window.innerWidth <= 767 ? '4vw' : '1rem'}}> ¡Factura tu compra!</h4>
            <label style={{color: 'rgba(255, 255, 255, 1)', color: '#ffff', float: 'left',position: 'absolute', fontSize: window.innerWidth <= 767 ? '2.2vw' : '0.8rem'}}>Corrobora tus datos y genera tu facturación electrónica</label>

        </div>
        <div className='col-md-4'>
                 <Link to={SLUGS.home}>
                            <img
                                src={ baseUrl + configuracion?.filter(config => config.nombre === "logo_empresa")[0]?.valor}
                                style={{
                                        height: '70px',
                                        display: 'block',
                                        width: 'auto',
                                        float: 'right'
                                        }}
                            />
                </Link>
        </div>
        <div className='col-md-4'>
            <label style={{ color: 'rgb(255, 255, 255)',float: 'left', fontSize: '.8rem'}}>¡{configuracion?.filter(config => config.nombre === "nombre_empresa")[0]?.valor} Te agradece por tu adquisición!</label>
            <label style={{ float: 'left',color: 'rgb(255, 255, 255)',fontSize: '.8rem',marginTop: '-10px'}}>{configuracion?.filter(config => config.nombre === "mensaje_facturacion_empresa")[0]?.valor}</label>
                      
        </div>
   
                    </div>
                    
                    <form>
                        <div className='card' style={{
                                       boxShadow: 'rgb(30 56 136 / 20%) 8px 8px 48px',
                                       borderRadius: '8px'
                                }}>
                            <div>                               
                                <div className='col-md-12 text-center' style={{paddingBottom: '0px',padding: '0px 5% 0px 5%'}}>
                                    {error && (
                                        <>
                                            <small style={{ color: 'red' }}>{error}</small>
                                            <br />
                                        </>
                                    )}

                                    <div className='col-md-12 row'>
                                    <div className='col-md-12'>
                        {Object.entries(erroresCliente).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                    </div>
                    <div className='card  text-start' style={{ boxShadow: '0px 1px 5px black'}}>
                    <div className='col-md-12 row'>
                        <div className='card-title'>
                            <h3 style={{color: '#232ED1'}}>Datos fiscales del clientes</h3>
                            <label style={{color: '#01295F'}}>Verifique sus datos ingresados anteriormente y los campos de detalle de su factura</label>
                        </div>
                    <div className='col-md-4' style={{ display: 'grid' }}>
                         
                                <label htmlFor='id' className='form-label' style={{display: 'none'}}>
                                    Cliente Id
                                </label>
                                <input
                                    type='hidden'
                                    className='form-control'
                                    name='id'
                                    value={cliente.id}
                                    onChange={handleInputChangeCliente}
                                    readOnly={true}
                                />

                                <label htmlFor='no_cliente' className='form-label'  style={{display: 'none'}}>
                                    No Cliente*
                                </label>
                                <input
                                    type='hidden'
                                    className='form-control'
                                    name='no_cliente'
                                    value={cliente.no_Cliente}
                                    onChange={handleInputChangeCliente}
                                />
                               

                                <label htmlFor='nombre' className='form-label'>
                                    Nombre*
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='nombre'
                                    value={cliente.nombre}
                                    onChange={handleInputChangeCliente}
                                    required
                                />

                                <label htmlFor='cp' className='form-label'>
                                    Codigo Postal*
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='cp'
                                    value={direcciones.cp}
                                    onChange={handleInputChangeDireccion}
                                />

                               
                      
                      
                    </div>
                    <div className='col-md-4' style={{ display: 'grid' }}>
                       
                       
                                <label htmlFor='rfc' className='form-label'>
                                    RFC*
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='rfc'
                                    value={cliente.rfc}
                                    onChange={handleInputChangeCliente}
                                    required
                                />

                                   <label htmlFor='uso_Cfdi'>Uso CFDI*:</label>
                                    <select
                                        className='form-control'
                                        name='uso_Cfdi'
                                        value={cliente.uso_Cfdi}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                    >
                                        <option value="--" selected>Seleccione un uso de cfdi</option>
                                        {satUsoCfdi.map((usoCfdi) => {
                                        return (
                                            <option key={usoCfdi.id} value={usoCfdi.clave}>
                                            {usoCfdi.clave + ' - ' + usoCfdi.nombre}
                                            </option>
                                        );
                                        })}
                                    </select>
                        
                       
                    </div>
                    <div className='col-md-4' style={{ display: 'grid' }}>
                        
                 
                            <label htmlFor='correo' className='form-label'>
                                    Correo*
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='correo'
                                    value={cliente.correo}
                                    onChange={handleInputChangeCliente}
                                />
                                <label htmlFor='regimen_Fiscal_Receptor'>
                                        Regimen Fiscal*:
                                    </label>
                                    <select
                                        className='form-control'
                                        name='regimen_Fiscal_Receptor'
                                        value={cliente.regimen_Fiscal_Receptor}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                    >
                                        <option value="">Seleccione un regimen fiscal</option>
                                         {satRegimenFiscal.map((regimenfiscal) => {
                                        return (
                                            <option key={regimenfiscal.id} value={regimenfiscal.clave}>
                                            {regimenfiscal.clave + ' - ' + regimenfiscal.nombre}
                                            </option>
                                        );
                                        })}
                                    </select>                       
                    </div>
                    </div>
                    </div>
                    <div className='col-md-12'>
                        <div className='card-title'>
                            <div className='col-md-12'>
                                <p style={{ color: 'red' }}></p>
                            </div>
                        </div>
                    </div>
                    <div className='card  text-start' style={{ boxShadow: '0px 1px 5px black'}}>
                    <div className='col-md-12 row'>
                        <div className='card-title'>
                            <h3 style={{color: '#232ED1'}}>Datos adicionales</h3>
                            <label style={{color: '#01295F'}}>Puede configurar datos adicionales de facturación para complementar su documentación </label>
                        </div>
                    <div className='col-md-4' >
                                <label htmlFor='calle' className='form-label'>
                                    Calle
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='calle'
                                    value={direcciones.calle}
                                    onChange={handleInputChangeDireccion}
                                />

                                <label htmlFor='no_Exterior' className='form-label'>
                                    No Exterior
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='no_Exterior'
                                    value={direcciones.no_Exterior}
                                    onChange={handleInputChangeDireccion}
                                />

                                <label htmlFor='no_Interior' className='form-label'>
                                    No Interior
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='no_Interior'
                                    value={direcciones.no_Interior}
                                    onChange={handleInputChangeDireccion}
                                />

                               
                    </div>

                    <div className='col-md-4' >

                          <label htmlFor='colonia' className='form-label'>
                                    Colonia
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='colonia'
                                    value={direcciones.colonia}
                                    onChange={handleInputChangeDireccion}
                                />
                                <label htmlFor='poblacion' className='form-label'>
                                    Poblacion
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='poblacion'
                                    value={direcciones.poblacion}
                                    onChange={handleInputChangeDireccion}
                                />

                                <label htmlFor='ciudad' className='form-label'>
                                    Ciudad
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='ciudad'
                                    value={direcciones.ciudad}
                                    onChange={handleInputChangeDireccion}
                                />

                               
                    </div>

                    <div className='col-md-4' >
                                
                    <label htmlFor='estado' className='form-label'>
                                    Estado
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='estado'
                                    value={direcciones.estado}
                                    onChange={handleInputChangeDireccion}
                                />

                                <label htmlFor='pais' className='form-label'>
                                    Pais
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='pais'
                                    value={direcciones.pais}
                                    onChange={handleInputChangeDireccion}
                                />
                               
                    </div>
                    </div>
                    </div>

                    <div className='col-md-12'>
                        <div className='card-title'>
                            <div className='col-md-12'>
                                {Object.entries(erroresV).map(([key, value]) => {
                                    return (
                                        <>
                                            <p style={{ color: 'red' }}>{value}</p>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className='card  text-start' style={{ boxShadow: '0px 1px 5px black'}}>
                    <div className='col-md-12 row'>
                        <div className='card-title'>
                            <h3 style={{color: '#232ED1'}}>Datos venta</h3>
                        </div>
                    <div className='col-md-6' >
                                <label htmlFor='forma_De_Pago' className='form-label'>
                                    Forma de Pago
                                </label>
                                <select
                                    className='form-control'
                                    name='forma_De_Pago'
                                    value={venta.forma_De_Pago}
                                    onChange={handleInputChangeVenta}
                                    required
                                >
                                    <option value='--'>Seleccione una forma de pago</option>
                                    <option value='03'>03-Transferencia electrónica de fondos</option>
                                    <option value='04'>04-Tarjeta de crédito</option>
                                    <option value='06'>06-Dinero electrónico</option>
                                    <option value='28'>28-Tarjeta de débito</option>
                                    <option value='99'>99-Por definir</option>
                                </select>

                    </div>

                    <div className='col-md-6' >

                                <label htmlFor='metodo_Pago' className='form-label'>
                                    Metodo de Pago
                                </label>
                                <select
                                    className='form-control'
                                    name='metodo_Pago'
                                    value={venta.metodo_Pago}
                                    onChange={handleInputChangeVenta}
                                    required
                                >
                                    <option value='--'>Seleccione un metodo de pago</option>
                                    <option value='PUE'>(PUE) Pago en una sola exhibición</option>
                                    <option value='PPD'>
                                        (PPD) Pago en Parcialidades o Diferido
                                    </option>
                                </select>
                                </div>

                    </div>
                    </div>

                    <div className='col-md-12'>
                        <div className='card-title'>
                            <div className='col-md-12'>
                                <p style={{ color: 'red' }}></p>
                            </div>
                        </div>
                    </div>
                    <div className='card  text-start' style={{ boxShadow: '0px 1px 5px black'}}>
                    <div className='col-md-12 row'>
                        <div className='card-title'>
                            <h3 style={{color: '#232ED1'}}>Detalles de la compra</h3>
                            <label style={{color: '#01295F'}}>Verifique las cantidades de producto o detalles del servicio y genera su facturación</label>
                        </div>
                                    {Object.entries(erroresArticulos).map(([key, value]) => {
                                        return (
                                            <>
                                                <p style={{ color: 'red' }}>{value}</p>
                                            </>
                                        );
                                    })}
                                    <div style={{ overflowX: 'auto' }}>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Articulo</th>
                                                    <th>ClaveProdServ</th>
                                                    <th>ClaveUnidad</th>
                                                    <th>Cantidad</th>
                                                    <th className='text-right'>Precio Unitario</th>
                                                    <th className='text-right'>Precio Total</th>
                                                   
                                                  
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {venta.venta_Detalles?.map((item) => {
                                                    console.log(item.id)
                                                    return (
                                                        <tr key={item.id}>
                                                            <td className='text-center' key={item.id}>
                                                                {item.articulo.id}
                                                            </td>
                                                            <td>{(item.nombre_Articulo === null || item.nombre_Articulo.length === 0) ? item.articulo?.nombre : item.nombre_Articulo}</td>
                                                            <td className='text-center'>
                                                            {
                                                                        item.articulo
                                                                            .clave_Prod_Serv
                                                                    }
                                                            </td>
                                                            <td className='text-center'>
                                                            {
                                                                        item.articulo.clave_Unidad
                                                                    }
                                                            </td>
                                                            <td className='text-right'>
                                                                {item.cantidad}
                                                            </td>
                                                            <td className='text-right'>
                                                                {currency(item.precio_Unitario)}
                                                            </td>
                                                            <td className='text-right'>
                                                                {currency(item.precio_Total)}
                                                            </td>
                                                           
                                                        </tr>
                                                    );
                                                })}
                                                <tr style={{backgroundColor: '#202bc12b'}}>
                                                           <td className='text-right' colSpan={6}>
                                                               Subtotal
                                                            </td>
                                                            <td className='text-right'>
                                                                {currency(venta.importe_Total)}
                                                            </td>
                                                </tr>
                                                <tr style={{backgroundColor: '#202bc12b'}}>
                                                         <td className='text-right' colSpan={6}>
                                                               IVA
                                                            </td>
                                                            <td className='text-right'>
                                                                {currency( venta.importe_Total_Impuestos - venta.importe_Total)}
                                                            </td>
                                                </tr>
                                                <tr style={{backgroundColor: '#202bc12b'}}>
                                                         <td className='text-right' colSpan={6}>
                                                               Total
                                                            </td>
                                                            <td className='text-right'>
                                                                {currency(venta.importe_Total_Impuestos)}
                                                            </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-md-12 text-right'>
                                        <button
                                                        type='button'
                                            disabled={facturado}
                                            className='btn btn-outline-success'
                                            onClick={(e) => facturar()}
                                            style={{background: '#232ED1', width: '250px',color:'#ffffff !important'}}
                                        >
                                            &nbsp; Emitir facturación
                                        </button>
                                    </div>
                    
                              
                        </div>
                    </div>

                                    </div> 
                                    <div className=' text-start'>                                    
                                </div>
                            </div>
                        </div>
                        </div>
                    </form>
                
              

                    <div style={{background: 'rgba(245, 245, 245, 1)'}}>
                                            <div className=' col-12 col-sm-12 col-md-12 row text-center' >
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                    <a href="https://atti.com.mx" target="_blank" rel="noopener noreferrer">AttiSolutions</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                                                </div>
                                                <div className='col-4 col-sm-4 col-md-4'>
                                                <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                                                </div>
                                            </div>
                                        </div>


        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

Facturar.propTypes = {
    saludo: PropTypes.string
};

Facturar.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { Facturar };


























