import React, { useState, useEffect } from 'react';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';

const MenuMoviminetosContador = ({ data, HandleChange }) => {
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1} className='container-fluid list_overflow'>
            {data && data.map((item) => { return item && (<MoviminetosContador key={item.Titulo} data={item} HandleChange={HandleChange} />); })}
        </Stack>
    );
}

const MoviminetosContador = ({ data, HandleChange }) => {
    const [selectedValue, setSelectedValue] = React.useState(false);
    function handleInputChange(event) {
        setSelectedValue(!selectedValue);
        if (HandleChange) HandleChange(event);
    }

    useEffect(() => setSelectedValue(data.SelectedValue), [data.SelectedValue]);

    return (
        <div className='card'>
            <div className='card-header container-center'>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <label className='form-label'>
                        <Radio
                            checked={selectedValue}
                            onChange={handleInputChange}
                            value={data.Titulo}
                            name={data.Titulo}
                            inputProps={{ 'aria-label': 'A' }}
                        />
                        {data.Titulo}
                    </label>
                </Stack>
            </div>
            <div className='card-body'>
                <Stack direction="row" justifyContent="center" spacing={1}>
                    <div style={{ width: '5px' }} />
                    <Avatar>{data.Value || '0'}</Avatar>
                    <label className='form-label'>Movimientos</label>
                    <div style={{ width: '5px' }} />
                </Stack>
            </div>
        </div>
    );
}

export { MenuMoviminetosContador };