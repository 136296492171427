import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import AsyncSelect from 'react-select/async';
import { useTranslation } from "react-i18next";

const GeneralForm = ({ data, handleInputChange, onChangeCheckActivo, onChangeCheck, ocultarUrl }) => {
    const { t } = useTranslation("common");
    const isAddMode = !data.publicado;
    const [initialObject, setInitialObject] = useState(true);
    const [initialObjectClaveUnidad, setInitialObjectClaveUnidad] = useState(true);
    const loadData = async (query) => await fetchConToken(`CatalogosSat/ListClaveProd?pagina=${1}&totalXpagina=${50}&busqueda=${query}`);
    const loadDataClaveUnidad = async (query) => await fetchConToken(`CatalogosSat/ListClaveUnidad?pagina=${1}&totalXpagina=${50}&busqueda=${query}`);

    return (
        <>
            <div className='row'>
                <div className='col-md-6' style={{ display: 'grid' }}>
                    <div className='card mb-3 '>
                        <label className='form-label title2'>{t('app.Articulos.datosGenerales')}</label>
                        <div className='card-body text-start'>
                            <label htmlFor='clave' className='form-label'>{t('app.Articulos.form.claveArt')}</label>
                            <input
                                type='text'
                                className={isAddMode ? 'form-control' : 'form-control form-control-disabled'}
                                name='clave'
                                maxLength={100}
                                value={data.clave || ''}
                                disabled={!isAddMode}
                                onChange={handleInputChange}
                            />
                            <label htmlFor='nombre' className='form-label'>{t('app.Articulos.form.nombreArt')}*</label>
                            <input
                                type='text'
                                className='form-control'
                                name='nombre'
                                required={true}
                                maxLength={100}
                                value={data.nombre || ''}
                                onChange={handleInputChange}
                            />
                            <label htmlFor='sku' className='form-label'>SKU</label>
                            <input
                                type='text'
                                className='form-control'
                                name='sku'
                                maxLength={100}
                                value={data.sku || ''}
                                onChange={handleInputChange}
                            />
                            <label htmlFor='existencias' className='form-label'>{t('app.Articulos.form.existencias')}*</label>
                            <input
                                type='number'
                                className='form-control'
                                name='existencias'
                                required={true}
                                maxLength={100}
                                value={data.existencias || ''}
                                onChange={handleInputChange}
                            />
                            <div className='col-md-12 row'>
                                <div className='col-sm-5'>
                                    <div className='espacio15px' />
                                    <div className='form-check form-switch margin_left_switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='estatus'
                                            role='switch'
                                            onChange={onChangeCheckActivo}
                                            checked={data.estatus === 'A'}
                                        />
                                        <label htmlFor='estatus' className='margin_left_switch margin_top_switch_label'>{t('app.Articulos.form.estatus')}</label> &nbsp;&nbsp;
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='espacio15px' />
                                    <div className='form-check form-switch margin_left_switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='es_Paquete'
                                            role='switch'
                                            onChange={onChangeCheck}
                                            checked={data.es_Paquete === 'S'}
                                        />
                                        <label htmlFor='es_Paquete' className='margin_left_switch margin_top_switch_label'>{t('app.Articulos.form.esPaquete')}</label> &nbsp;&nbsp;
                                    </div>
                                </div>
                            </div>
                            <label htmlFor='url' className='form-label'>URL</label>
                            <input
                                type='text'
                                className={isAddMode ? 'form-control' : 'form-control form-control-disabled'}
                                name='url'
                                maxLength={100}
                                value={data.url || ''}
                                onChange={handleInputChange}
                                disabled={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-md-6' style={{ display: 'grid' }}>
                    <div className='card mb-3 '>
                        <label className='form-label title2'>{t('app.Articulos.datosSat')}</label>
                        <div className='card-body text-start'>
                            <label htmlFor='no_Identificacion' className='form-label'>{t('app.Articulos.form.codigoIdentificacion')}*</label>
                            <input
                                type='text'
                                className='form-control'
                                name='no_Identificacion'
                                required={true}
                                maxLength={100}
                                placeholder="Escriba código de identificación"
                                value={data.no_Identificacion || ''}
                                onChange={handleInputChange}
                            />
                            <label htmlFor='clave_Prod_Serv' className='form-label'>{t('app.Articulos.form.servicioSAT')}</label>
                            <AsyncSelect
                                isClearable
                                className='react-select-placeholder'
                                getOptionLabel={(option) => option.clave + '-' + option.nombre}
                                getOptionValue={(option) => option.clave}
                                defaultOptions
                                value={initialObject}
                                loadOptions={loadData}
                                onChange={(selectedItem) => {
                                    const val = selectedItem === null ? '' : selectedItem?.clave;
                                    console.log(val);
                                    data.clave_Prod_Serv = val;
                                    setInitialObject(selectedItem);
                                }}
                            />
                            <label htmlFor='clave_Unidad' className='form-label'>{t('app.Articulos.form.claveUnidad')}*</label>
                            <AsyncSelect
                                className='react-select-placeholder'
                                isClearable
                                getOptionLabel={(option) => option.clave + '-' + option.nombre}
                                getOptionValue={(option) => option.clave}
                                defaultOptions //={initialObjectClaveUnidad}
                                value={initialObjectClaveUnidad}
                                loadOptions={loadDataClaveUnidad}
                                onChange={(selectedItem) => {
                                    const val = selectedItem === null ? '' : selectedItem?.clave;
                                    data.clave_Unidad = val; //setFieldValue(fieldName, val)
                                    setInitialObjectClaveUnidad(selectedItem);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='container-fluid'></div>
            </div>
        </>
    );
}

export { GeneralForm };