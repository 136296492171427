import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import * as FileSaver from 'file-saver';
import { descargarFactura } from 'actions/archivos';

import Modal from 'components/modal/Modal';

import Table from 'components/table/Table';
import { alertService } from '_services';
import Swal from 'sweetalert2';
import { useGlobalState } from 'resources/GlobalStateContext';

import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "editar"},
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'rfc', headerName: 'RFC', flex: 1},
        { field: 'nombre', headerName: 'Nombre', flex: 1 },
        { field: 'regimen_Fiscal', headerName: 'Regimen Fiscal', flex: 1},
        { field: 'nombre_Fiscal', headerName: 'Nombre_Fiscal' , flex: 1},
        { field: 'tipo_Persona', headerName: 'Tipo Persona', flex: 1 }
        
    ];
  

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Empresas registradas',
          subtitulo: 'Listado de empresas registradas.'
        });
      };
      React.useEffect(() => {actualizarTitulo();},[]);
    return (
        <>
            <div className='container-fluid'>
                           
                <br></br>
                <div className='col-md-12'>
                <ListTable
                    columns = {columns}
                    path = {path}
                    url={`empresas/EmpresasGetList?`}
                  />
                  {/*
                    <Table
                        columns={[
                            '#',
                            'RFC',
                            'Nombre',
                            'Regimen Fiscal',
                            'Nombre_Fiscal',
                            'Tipo Persona'
                        ]}
                        rows={[
                            ['text','id'],
                            ['text', 'rfc'],
                            ['text', 'nombre'],
                            ['text', 'regimen_Fiscal'],
                            ['text', 'nombre_Fiscal'],
                            ['text', 'tipo_Persona'],
                        ]}
                        path={path}
                        url={`/empresas/List`}
                    ></Table>*/}
                </div>
            </div>
        </>
    );
};

export { List };
