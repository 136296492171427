import React from 'react';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import logo from 'images/panel/Factuxin.png';

const useStyles = createUseStyles((theme) => ({
    title: {
        ...theme.typography.cardTitle,
        color: theme.color.grayishBlue,
        opacity: 0.7,
        marginLeft: 12
    }
}));

function LogoComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <Row key={"logoheader"}> 
            {/*<span className={classes.title}>eMonedero</span>*/}
            <img key={"logoheaderImg"} src={logo} className="sidebar-logo"/>
        </Row>
    );
}

export default LogoComponent;
