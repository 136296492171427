import React, { useState, useEffect } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import { descargarFactura } from 'actions/archivos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';
import { useForm } from 'react-hook-form';
import { currency } from 'resources/utilities';
import { useGlobalState } from 'resources/GlobalStateContext';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import Swal from 'sweetalert2';

const AddEdit = ({ history, match, saludo, subtitulo }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [facturado, setFacturado] = useState(false);
    const { handleSubmit } = useForm();
    const { id } = match.params;

    const { compras } = match.params;
    let TipoDocto =  useState('C');
    let Docto =  useState('C');

    let [proveedor, setProveedor] = useState();

    switch(compras)
    {
        case 'ordenesCompras':
            TipoDocto = 'O';
            Docto = 'Orden compra';
            break;
        case 'recepcionCompra':
            TipoDocto = 'R';
            Docto = 'Recepcion de compra';
            break;
        default:
            TipoDocto = 'C';
            Docto = 'Compra';
            break;
    }

    const isAddMode = !id;

    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);


    let [data, setData] = useState(
        {
        id: 0,
        tipo_Docto: TipoDocto,
        subtipo: TipoDocto,
        sucursal_Id: getDataSession('sucursal'),
        proveedor_Id: 0,
        condicion_Pago_Id: 0,
        folio_Prov: "",
        almacen_Id: 0,
        dscto_Pctje: 0,
        dscto_Importe: 0,
        total_Impuestos: 0,
        fecha: null,
        descripcion: "",
        importe_Neto: 0
    });
    let [compraDetalles, setCompraDetalles] = useState([]);
    let [almacenes, setAlmacenes] = useState([]);
    let [condicionesPago, setCondicionesPago] = useState([]);

    useEffect(() => {
        GetAlmacenes();

        if(TipoDocto == 'C')
        GetCondicionesPago();

        if(!isAddMode)
        {
            getCompra();
        }
        actualizarTitulo();
    },[]);

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: isAddMode ? 'Registrar '+Docto : 'Editar '+Docto,
          subtitulo:''
        });
      };

    async function getCompra() {
        const compraAux = await fetchConToken(
            `VxCompras?id=${id}&sucursal_Id=${getDataSession('sucursal')}`
        );

        setData(compraAux);
        setCompraDetalles(compraAux?.vx_Compra_Detalles);

        const prov = await fetchConToken(
            `VxProveedor?id=${compraAux.proveedor_Id}`
        );
        setProveedor(prov);
    }

    const loadProveedor = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `VxProveedor/GetList?paginas=0&totalXpagina=100&sucursal_Id=${getDataSession('sucursal')}&busqueda=${query}`
        );
        return resp?.data;
    };

    const loadArticulos = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Articulos/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession(
                'sucursal'
            )}`
        );
        return resp?.data;
    };

    function GetAlmacenes(){
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        fetchConToken(`Almacenes/GetList?paginas=0&totalXpagina=50&sucursal_Id=${getDataSession('sucursal')}`).then(
            response=>{
                setAlmacenes(response.data);
            }
        );
    }; 

    function GetCondicionesPago(){
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        fetchConToken(`VxCondicionPagos/GetList?paginas=0&totalXpagina=50&sucursal_Id=${getDataSession('sucursal')}`).then(
            response=>{
                setCondicionesPago(response.data);

                setData({
                    ...data,
                    ['condicion_Pago_Id']: response[0]?.id
                });
                
            }
        );
    };

    async function getProveedor(proveedor) {
        setProveedor(proveedor);
        setData({
            ...data,
            ['proveedor_Id']: proveedor.id
        });
    }
    async function getAlmacen(almacen) {
        setData({
            ...data,
            ['almacen_Id']: almacen.id
        });
    }
    async function getArticulo(id) {
        console.log(id);
        if (!Number.isInteger(id)) return;
        var artAux = compraDetalles.filter((d) => d.articulo_Id == id);
        if (artAux.length > 0) {
            artAux[0].cantidad = artAux[0].cantidad + 1;

            setCompraDetalles(
                compraDetalles.map((det) => (det.articulo_Id === id ? artAux[0] : det))
            );
            //ImpuestosArticulo(ventaDetalles.filter(x=> x.articulo_Id === id ),false);
            return;
        }

        const articulosAux = await fetchConToken(
            `Articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}`
        );

        const detalleCompra = {
            clave_Articulo: articulosAux.clave,
            articulo_Id: articulosAux.id,
            unidades: 1,
            unidades_Rec_Dev: 0,
            unidades_A_Rec: 0,
            unidad_Medida: "",
            contenido_Umed: 0,
            precio_Unitario: 0,
            dscto_Art: 0,
            dscto_Extra: 0,
            precio_Total_Neto: 0,
            notas: "",
            posicion: 0,
            variante_Id: null,
            articulo: articulosAux
        };

        setCompraDetalles([...compraDetalles,detalleCompra]);
        console.log(detalleCompra);
    }

    const handleInputChangeDetallesCompra = (event, key, campo) => {

        let detalleV = compraDetalles.filter((det) => det.articulo_Id === key);

        console.log("entro");
        switch(campo)
        {
            case "unidades":
                detalleV[0][campo] = parseFloat(event.target.value);
                detalleV[0]["precio_Total_Neto"] = detalleV[0]["precio_Unitario"] * detalleV[0]["unidades"];
                break;
            case "precio_Unitario":
                detalleV[0]["precio_Unitario"] = parseFloat(event.target.value);
                detalleV[0]["precio_Total_Neto"] = detalleV[0]["precio_Unitario"] * detalleV[0]["unidades"];
                break;
            default:
                detalleV[0][campo] = event.target.value;
                break;
        }
        console.log(detalleV[0]);
        setCompraDetalles(compraDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));

    };

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ) : target.value;
        const name = target.name;

        setData({
            ...data,
            [name]: value
        });
    }

    async function eliminar(id) {
        if(isAddMode)
        setCompraDetalles(compraDetalles.filter((item) => item.articulo_Id !== id));
        else
        {
            var det = compraDetalles.filter((d) => d.articulo_Id == id);
            det.map(detV=>{
                if(detV?.id > 0)
                {
                    detV.cantidad = 0;
                    setCompraDetalles(compraDetalles.map((d) => (d.articulo_Id === id ? detV : d)));
                }
                else
                {
                    setCompraDetalles(compraDetalles.filter((item) => item.articulo_Id !== id));
                }
           })
        }
    }


    function ConvertirACompra()
    {}
    function Cancelar()
    {}

    
    function onSubmit() {
        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        return isAddMode ? create(data) : update(id, data);
    }

    
    function create(data) {
        data.vx_Compra_Detalles = compraDetalles;
        return fetchConToken('VxCompras', data, 'POST')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return ;
                }
            Swal.fire('Exito', Docto+' agregado', 'success');
            history.push('.');
        })
        .catch(alertService.error);
    }

    function update(id,data) {
        data.vx_Compra_Detalles = compraDetalles;
        data.id = id;
        return fetchConToken('VxCompras', data, 'PUT')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return ;
                }
            Swal.fire('Exito', Docto+' agregado', 'success');
            history.push('..');
        })
        .catch(alertService.error);
    }
    
    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} className='facturar' >
                    
                    <div className='row'>
                        <div className='col-md-12 text-right'>
                            { TipoDocto == 'R' ?
                            <>
                                <button
                                className='btn btn-outline-success'
                                onClick={ (e) => {
                                    return ConvertirACompra();
                                } }
                                type='button'
                            >
                                &nbsp; Convertir a compras
                            </button>
                            &nbsp; 
                            </>
                        : ""
                        }

                            <button
                                className='btn btn-outline-success'
                                type='submit'
                            >
                                <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='20'
                                                height='20'
                                                fill='currentColor'
                                                className='bi bi-check-lg'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                            </svg>
                                &nbsp; Guardar 
                            </button>
                            &nbsp;
                            <button
                                className='btn btn-outline-success'
                                onClick={ (e) => Cancelar() }
                                type='button'
                            >
                                &nbsp; Cancelar {Docto}
                            </button>
                            &nbsp;
                            <Link to={ isAddMode ? '.' : '..'} className='btn btn-outline-warning'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='15'
                                    height='15'
                                    fill='currentColor'
                                    className='bi bi-reply'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                </svg>
                                &nbsp; Regresar
                            </Link>
                        </div>
                        <div className=''>
                            <div className='col-md-12'>
                            <h5>Información del proveedor</h5>
                                                        
                            <div className='col-md-12' style={{ display: 'grid' }}>
                                <div className='card mb-2 '>
                                    <div className='card-body text-start'>
                                        <label htmlFor='id'>Proveedor:</label>
                                        <AsyncSelect
                                        className='react-select-placeholder'
                                            isClearable
                                            getOptionLabel={(option) =>
                                                option.rfc_curp + '-' + option.nombre
                                            }
                                            getOptionValue={(option) => option.id}
                                            loadOptions={loadProveedor}
                                            onChange={(selectedItem) => {
                                                getProveedor(selectedItem);
                                            }}
                                        />
                                        <div className='row col-md-12'>
                                        <div className='col-md-6'>
                                        <label htmlFor='nombre'>Proveedor*</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='nombre'
                                            value={proveedor?.nombre || ''}
                                            disabled={true}
                                        />

                                        <label htmlFor='rfc'>RFC*</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='rfc'
                                            value={proveedor?.rfc_Curp || ''}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='cp'>Codigo Postal*</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='cp'
                                            value={proveedor?.codigo_Postal || ''}
                                            disabled={true}
                                        />

                                        <label htmlFor='cp'>Direccion*</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='cp'
                                            value={proveedor?.calle || ''}
                                            disabled={true}
                                        />
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <h5>Información Compra</h5>
                            <div className='col-md-12' style={{ display: 'grid' }}>
                                <div className='card mb-2 '>
                                    <div className='card-body text-start'>
                                        <div className='row col-md-12'>
                                        <div className='col-md-6'>
                                            <label htmlFor='id'>Almacen:</label>
                                            <select className='form-control' name='almacen_Id' value={data?.almacen_Id} onChange={handleInputChange}>
                                                { almacenes?.map((item) => {
                                                    return (<option key={'suc'+item.id} value={item.id}>{item.nombre}</option>);
                                                }) } 
                                            </select>
                                            {TipoDocto != 'O' ?
                                            <>
                                            <label htmlFor='id'>Condicion de Pago:</label>
                                            <select className='form-control' name='almacen_Id' value={data?.condicion_Pago_Id} onChange={handleInputChange}>
                                                { condicionesPago?.map((item) => {
                                                    return (<option key={'suc'+item.id} value={item.id}>{item.nombre}</option>);
                                                }) } 
                                            </select></> : ''}
                                            {TipoDocto != 'O' ?  <>
                                        <label htmlFor='rfc'>Folio Prov.</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='folio_Prov'
                                            onChange={handleInputChange}
                                            value={data?.folio_Prov || ''}
                                        /></> : '' }
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='cp'>Descripcion</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='descripcion'
                                            onChange={handleInputChange}
                                            value={data?.descripcion || ''}
                                        />
                                    {TipoDocto != 'O' ?
                                            <>
                                        <label htmlFor='cp'>Total Impuestos</label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            name='total_Impuestos'
                                            value={data.total_Impuestos}
                                            onChange={handleInputChange}
                                        /></> : '' }
                                    </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 col-sm-12 col-md-12 text-left'>
                                    <label htmlFor='id'>Buscar Articulo</label>
                                    <AsyncSelect
                                    className='react-select-placeholder'
                                        isClearable
                                        getOptionLabel={(option) =>
                                            option.clave +
                                            '-' +
                                            option.nombre +
                                            '- $' +
                                            option.costo_Unidad
                                        }
                                        value=''
                                        getOptionValue={(option) => option.id}
                                        loadOptions={loadArticulos}
                                        onChange={(selectedItem) => {
                                            const val = selectedItem === null ? '' : selectedItem?.id;
                                            getArticulo(val);
                                        }}
                                    />
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Clave</th>
                                                            <th>Articulo</th>
                                                            <th>Cantidad</th>
                                                            {TipoDocto != 'O' ?  <>
                                                            <th>Precio Unitario <br></br> (sin impuestos)</th>
                                                            <th>Precio Total</th> </> : ''}
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {compraDetalles?.map((item) => {
                                                            return (
                                                                <tr key={item.id}>
                                                                    <td className='text-center'>
                                                                        {item.articulo.id}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {item.articulo.clave}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {item.articulo.nombre}
                                                                    </td>
                                                                    {TipoDocto != 'O' ?  <>
                                                                    <td className='text-right'>
                                                                        <input
                                                                            type='number'
                                                                            className='form-control'
                                                                            name='unidades'
                                                                            value={item.unidades}
                                                                            onChange={(event) =>
                                                                                handleInputChangeDetallesCompra(
                                                                                    event,
                                                                                    item.articulo.id,
                                                                                    'unidades'
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td className='text-right'>
                                                                    <input
                                                                            type='number'
                                                                            className='form-control'
                                                                            name='item.precio_Unitario'
                                                                            value={item.precio_Unitario}
                                                                            onChange={(event) =>
                                                                                handleInputChangeDetallesCompra(
                                                                                    event,
                                                                                    item.articulo.id,
                                                                                    'precio_Unitario'
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                    </> : ''}
                                                                    <td className='text-right'>
                                                                        {currency(item.precio_Total_Neto)}
                                                                    </td>
                                                                    <td style={{ width: "50px"}} hidden={facturado}>
                                                                        <button
                                                                            type='button'
                                                                            className='btn  mr-1'
                                                                            onClick={() =>
                                                                                eliminar(
                                                                                    item.articulo.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <svg
                                                                                xmlns='http://www.w3.org/2000/svg'
                                                                                width='25'
                                                                                height='25'
                                                                                fill='currentColor'
                                                                                className='bi bi-x-circle-fill'
                                                                                viewBox='0 0 16 16'
                                                                            >
                                                                                <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                            </svg>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                         {TipoDocto != 'O' ? 
                                                        <tr>
                                                            <td colSpan={3} className='text-right'>
                                                                Subtotal:
                                                                {currency(
                                                                    compraDetalles.reduce(
                                                                        (a, v) => (a = a + v.precio_Total_Neto),
                                                                        0
                                                                    )
                                                                )}
                                                            </td>
                                                            <td colSpan={2} className='text-right'>
                                                                Impuesto Total:
                                                                {currency(
                                                                    data.total_Impuestos
                                                                )}
                                                            </td>

                                                            <td colSpan={2} className='text-right'>
                                                                Total:{' '}
                                                                {currency(
                                                                    compraDetalles.reduce(
                                                                        (a, v) =>
                                                                            (a = a + v.precio_Total_Neto ),
                                                                        0
                                                                    )+parseFloat(data.total_Impuestos)
                                                                )}
                                                            </td>
                                                        </tr> : ''}
                                                    </tbody>
                                                </table>
                                            </div>
                        </div>
                        </div>
                    </div>
                </form>
            </div>
           
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};
export { AddEdit };
