/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useState, useEffect, useRef } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession, setDataSession } from 'views/Utils/Common';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import { useForm } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CategoriaList } from './CategoriaList';
import Divider from '@mui/material/Divider';
import { FichaTecnica } from './FichaTecnica';
import { ListImg } from './ListImg';
import { Costos, Impuestos } from './Costos';
import { ListVariante } from './Variante';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import Settings from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import TaskIcon from '@mui/icons-material/Task';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import InventoryIcon from '@mui/icons-material/Inventory';
import ListTable from 'components/table/ListTable';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SLUGS from 'resources/slugs';
import { useGlobalState } from 'resources/GlobalStateContext';
import RenderButtons from 'components/table/RenderButtons';
import Button from '@mui/material/Button';
import { ClavesArticulos } from './ClavesArticulos';

const AddEdit = ({ history, match, hideSaved }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const button_submit = useRef(null);
    const { t } = useTranslation('common');
    const { handleSubmit } = useForm();
    const busqueda = useFormInput('');
    const totalxPagina = 10;
    const { id } = match.params;
    const isAddMode = !id;
    const [initialObject, setInitialObject] = useState(true);
    const [initialObjectClaveUnidad, setInitialObjectClaveUnidad] = useState(true);
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [valueTab, setValueTab] = useState(0);
    const [categoriaFinId, setCategoriaFinID] = useState('0');
    let [, setErrores] = useState({});
    let [, setError] = useState(['']);
    let [tienda, setTienda] = useState('');
    let [datos, setDatos] = useState([]);
    let [categorias, setCategorias] = useState([]);
    let [atributos, setAtributos] = useState([]);
    let [articuloSeleccionado, setArticuloSeleccionado] = useState([]);
    let [componentesPaquete, setComponentesPaquete] = useState([]);
    let [atributosArticulo, setAtributosArticulo] = useState([]);
    let [catalogoSat, setCatalogoSat] = useState(['']);
    let [sucursal, setSucursal] = useState([]);
    let [impuestos, setImpuestos] = useState([]);
    let [imagenes, setImagenes] = useState([]);
    let [articulo, setArticulo] = useState({});
    let [almacenes, setAlmacenes] = useState([]);
    let [artVarianteId, setArtVarianteId] = useState(0);
    let [variantes, setVariantes] = useState([]);
    let [listaImpuestosArt, setListaImpuestosArt] = useState([]);
    const [modalValor, setModalValor] = useState(false);
    let [preciosArt, setPreciosArt] = useState([]);
    let [impuestoArticulo, setImpuestoArticulo] = useState({
        id: 0,
        articulo_Id: id,
        tipo_Impuestos_Id: 0,
        tipo_impuesto: {}
    });
    let [existencia, setExistencia] = useState({
        id: 0,
        articulo_Id: parseInt(id),
        variante_Id: null,
        exis_Actual: 0,
        exis_Comprometida: 0,
        exis_Disponible: 0,
        almacen_Id: 0,
        sucursal_Id: getDataSession('sucursal'),
        movimiento: ''
    });
    let [listaClaves, setListaClaves] = useState([]);
    let [listaPrecios, setListaPrecios] = useState([]);
    let [clavesArt, setClavesArt] = useState([]);
    let [clavesArtVariant, setClavesArtVariant] = useState([]);
    let [claveArticulo, setClaveArticulo] = useState({
        id: 0,
        articulo_Id: id,
        clave_Id: 0,
        clave: '',
        rolClave: {}
    });
    let [nuevoPrecioArticulo, setNuevoPrecioArticulo] = useState({
        id: 0,
        articulo_Id: id,
        lista_Precio_Id: 0,
        precio: 0
    });
    let [reload, setReload] = useState(false);

    let [disableTabComponentes, setDisableTabComponentes] = useState(true);
    let [disableTab, setDisableTab] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickButtonSubmit = () => {
        console.log('entro handle', button_submit);
        if (validarArticulo()) button_submit.current.click();
    };

    function precioArticulo(id) {
        fetchConToken(`PrecioArticulo/GetList?articulo_id=${id}`).then((imp) => setPreciosArt(imp.data));
    }

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: isAddMode ? t('app.Articulos.agregarArt') : t('app.Articulos.editarArt'),
            subtitulo: ''
        });
    };

    useEffect(() => {
        if (!isAddMode) getArticulo(true);
        else {
            const fecha = new Date();
            setArticulo({
                ...articulo,
                clave: `${fecha.getFullYear()}${fecha.getMonth() + 1}${fecha.getDate()}${fecha.getHours()}${fecha.getMinutes()}${fecha.getSeconds()}`
            });
            setDisableTab(false);
        }

        getSucursal(getDataSession('sucursal'));
        fetchConToken(`ConfiguracionesSucursales?sucursal_id=${getDataSession('sucursal')}&nombre=TIENDA`).then((response) => { setTienda(response.valor); });
        if (!isAddMode) getArticulo();
        else if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', t('app.SwalFire.infoConexion'), 'info');
            return;
        }

        getAlmacenes();
        listaImpuestos();
        listasClaves();
        actualizarTitulo();
        if (!isAddMode) precioArticulo(id);

        listasPrecios();
        getComponentes();
    }, [getDataSession('sucursal')]);

    async function eliminar() {
        Swal.fire({
            title: t('app.Articulos.SwalFire.eliminar?'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(`Articulos?articulo_id=${id}`, null, 'DELETE').then((response) => {
                    if (response.hasOwnProperty('status')) {
                        Object.entries(response?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }

                    Swal.fire('Exito', t('app.Articulos.SwalFire.eliminado'), 'success');
                    history.push('..');
                });
            }
        });
    }

    function createArt(data) {
        console.log('entro crear');
        if (!isAddMode)
            if (categoriaFinId === '0') {
                Swal.fire('Error', 'Error al seleccionar la categoria', 'error');
                return;
            }

        setErrores({});
        setError('');
        articulo.sucursal_Id = getDataSession('sucursal');
        articulo.empresa_Id = getDataSession('empresa');
        setArticulo({
            ...articulo,
            publicado: false
        });
        if (tienda) {
            Swal.fire({
                title: `Desea publicar o solo guardar el articulo`,
                showCancelButton: true,
                confirmButtonText: 'Guardar y Publicar',
                cancelButtonText: 'Guardar'
            }).then((result) => postArt(result.isConfirmed));
        } else postArt(false);
    }

    function postArt(isConfirmed) {
        articulo.articulo_Variantes = variantes;
        fetchConToken(
            isConfirmed
                ? `Articulos/Publicar?sucursal_id=${getDataSession('sucursal')}`
                : 'Articulos',
            articulo,
            'POST'
        ).then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            existencia.articulo_Id = parseInt(response.id);
            setExistencia(existencia);
            subirImpuestos(response.id);
            subirClaves(response.id);
            subirComponentesPaquete(response.id);
            GuardarExistencia();
            Swal.fire('Exito', t('app.Articulos.SwalFire.agregado'), 'success');
            history.push('.');
        });
    }

    function updateArt(id, data) {
        console.log('entro update');
        articulo.articulo_Variantes = variantes;
        console.log(articulo);
        //return;
        setErrores({});
        setError('');
        fetchConToken(`ConfiguracionesSucursales?sucursal_id=${getDataSession('sucursal')}&nombre=TIENDA`).then((response) => {
            if (response && response.valor) {
                Swal.fire({
                    title: `Desea publicar o solo guardar el articulo`,
                    showCancelButton: true,
                    confirmButtonText: 'Guardar y Publicar',
                    cancelButtonText: 'Guardar'
                }).then((result) => {
                    return fetchConToken(
                        result.isConfirmed
                            ? `Articulos/Publicar?sucursal_id=${getDataSession('sucursal')}`
                            : 'Articulos',
                        articulo,
                        result.isConfirmed ? 'POST' : 'PUT'
                    ).then((response) => {
                        if (response.hasOwnProperty('status') && response.status === 400) {
                            var erroresA = {};
                            Object.entries(response.errors).map(([key, value]) => {
                                erroresA[key] = value[0];
                                Swal.fire('Error', value[0], 'error');
                            });
                            return false;
                        } else if (
                            response.hasOwnProperty('codigo') &&
                            response.codigo !== 200
                        ) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return false;
                        }

                        subirComponentesPaquete(response.id);
                        subirImpuestos(response.id);
                        Swal.fire('Exito', t('app.Articulos.SwalFire.modificado'), 'success');
                        history.push('..');
                    });
                });
            } else return fetchConToken('Articulos', articulo, 'PUT').then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresA[key] = value[0];
                        Swal.fire('Error', value[0], 'error');
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                subirComponentesPaquete(response.id);
                subirImpuestos(response.id);
                Swal.fire('Exito', t('app.Articulos.SwalFire.modificado'), 'success');
                history.push('..');
            });
        }).catch((e) => {
            Swal.fire('Error', e, 'error');
            return false;
        });
    }

    function impuestosByArticulo(id) {
        fetchConToken(`ImpuestosArticulos/GetList?articulo_Id=${id}`).then((imp) => setImpuestos(imp));
    }

    function getSucursal(id) {
        fetchConToken(`sucursales?id=${getDataSession('sucursal')}`).then((suc) => setSucursal(suc));
    }

    function listaImpuestos() {
        fetchConToken(`TiposImpuestos/GetList?paginas=1&totalXpagina=100`).then((imp) => setListaImpuestosArt(imp));
    }

    function clavesArticulo(id) {
        fetchConToken(`ArticulosClaves/GetList?articulo_id=${id}`).then((imp) => setClavesArt(imp));
    }

    function clavesArtVariante(id, art) {
        if (art.articulo_Variantes && art.articulo_Variantes.length > 0)
            art.articulo_Variantes.forEach((a) => clavesArticuloVariante(id, a.id));
    }

    function clavesArticuloVariante(id, varianteId) {
        fetchConToken(`ArticulosClaves/GetList?articulo_id=${id}&variante_id=${varianteId}`).then(
            (imp) => {
                if (imp && imp.length > 0) {
                    imp.forEach((element) => {
                        if (!clavesArtVariant.find((x) => x.id === element.id))
                            clavesArtVariant.push(element);
                    });
                    setClavesArtVariant(clavesArtVariant);
                }
            }
        );
    }

    function listasClaves() {
        fetchConToken(`RolesClaves/GetList?sucursal_Id=${getDataSession('sucursal')}`).then((response) => setListaClaves(response));
    }

    function listasPrecios() {
        fetchConToken(`ListaPrecios/List?sucursal_Id=${getDataSession('sucursal')}`).then((response) => setListaPrecios(response));
    }

    function getArticulo(cargarCategoria) {
        fetchConToken(`articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}&conDetalle=true`).then((art) => {
            if (art.hasOwnProperty('status')) {
                Object.entries(art?.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    history.push(SLUGS.articulos);
                    return;
                });
                return false;
            } else if (art.hasOwnProperty('codigo')) {
                Swal.fire('Error', art.mensajes[0], 'error');
                history.push(SLUGS.articulos);
                return;
            }
            if (art?.sucursal_Id > 0 && getDataSession('sucursal').toString() != art.sucursal_Id.toString()) setDataSession('sucursal', art.sucursal_Id);

            if (
                cargarCategoria &&
                art.clave_Categoria &&
                art.clave_Categoria != '' &&
                art.clave_Categoria != '0'
            ) handleChangeCategoria(art.clave_Categoria);

            setVariantes(art.articulo_Variantes || []);
            setClavesArtVariant([]);
            setArticulo(art);
            setImagenes(art.imagenes);
            setAtributosArticulo(art.atributos);
            clavesArticulo(id);
            impuestosByArticulo(id);
            getInitialClaveUnidad(art);
            clavesArtVariante(id, art);
            setDisableTabComponentes(art.es_Paquete == 'N');
            setCategorias(art.categorias);
            //console.log(art.es_Paquete == 'N');
            fetchConToken(`CatalogosSat/ListClaveProd?pagina=${1}&totalXpagina=${50}&busqueda=${art?.clave_Prod_Serv}`).then((resp) => {
                var aux = resp.find((e) => e.clave === art?.clave_Prod_Serv);
                setInitialObject(aux);
            });
            setDisableTab(false);
        });
    }

    const onChangeCheck = ({ target }) => {
        const xCheck = target.checked ? 'S' : 'N';
        setArticulo({
            ...articulo,
            [target.name]: xCheck
        });
        console.log(xCheck);
        if (target.name === 'es_Paquete') setDisableTabComponentes(xCheck == 'N');
    };

    const onChangeCheckActivo = ({ target }) => {
        const xCheck = target.checked ? 'A' : 'I';
        setArticulo({
            ...articulo,
            [target.name]: xCheck
        });
    };

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setArticulo({
            ...articulo,
            [name]: value
        });
    }

    const onChangeCostos = (costo) => {
        setArticulo({
            ...articulo,
            costo_Unidad: costo.precio_Base,
            envio_Gratis: costo.envio_Gratis,
            costo_Envio: costo.costo_Envio,
            costo_Comision: costo.comision_Publicar,
            utilidad: costo.gananciaVenta
        });
    };

    const registrarImpuesto = () => {
        if (String(impuestoArticulo.tipo_Impuestos_Id) === '0') {
            Swal.fire('', t('app.Articulos.SwalFire.seleccioneImpuestos'), 'info');
            //alertService.error('Seleccione un impuesto', { keepAfterRouteChange: true });
            return;
        }

        console.log(impuestoArticulo.tipo_Impuestos_Id);
        var impuestoRepetido = impuestos.filter(
            (i) => i.tipo_Impuestos_Id === impuestoArticulo.tipo_Impuestos_Id
        );
        if (impuestoRepetido.length > 0) {
            Swal.fire('', t('app.Articulos.SwalFire.impuestoAgregadoAnt'), 'info');
            return;
        }

        console.log(impuestoArticulo);
        if (isAddMode) {
            setImpuestos([...impuestos, impuestoArticulo]);
            return;
        }

        fetchConToken(`ImpuestosArticulos`, impuestoArticulo, 'POST').then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            Swal.fire(
                t('app.SwalFire.exitoso'),
                t('app.Articulos.SwalFire.impuestoAgregado'),
                'success'
            );
            //alertService.success('Impuesto agregado', { keepAfterRouteChange: true });
            setImpuestos([...impuestos, response]);
        });
    };

    const subirImpuestos = (articulo_id) => {
        impuestos
            .filter((imp) => !imp.hasOwnProperty('id') || imp.id < 1)
            .map((item) => {
                item.articulo_Id = articulo_id;
                fetchConToken(`ImpuestosArticulos`, item, 'POST').then(function (response) {
                    if (response.hasOwnProperty('status')) {
                        var erroresA = {};
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return false;
                    }

                    setImpuestos([...impuestos, response]);
                });
            });
    };

    const subirComponentesPaquete = (articulo_id) => {
        if (articulo.es_Paquete === 'N') return;

        if (componentesPaquete === null || componentesPaquete.length === 0) {
            Swal.fire('Info', 'El paquete no tiene componentes asignados', 'Info');
            return;
        }

        fetchConToken(
            `Articulos/ComponentesPaquete?articulo_id=` + articulo_id,
            componentesPaquete,
            'POST'
        ).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
        });
    };

    const subirClaves = (articulo_id) => {
        clavesArt
            .filter((imp) => !imp.hasOwnProperty('id') || imp.id < 1)
            .map((item) => {
                item.articulo_Id = articulo_id;
                fetchConToken(`ArticulosClaves`, item, 'POST');
            });
    };

    const eliminarImpuesto = (id_imp, tipo_Impuestos_Id) => {
        Swal.fire({
            title: t('app.Articulos.SwalFire.eliminarImpuesto'),
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                if (id_imp !== undefined) {
                    fetchConToken(`ImpuestosArticulos?id=${id_imp}`, null, 'DELETE').then(function (
                        response
                    ) {
                        if (response.hasOwnProperty('status')) {
                            var erroresA = {};
                            Object.entries(response.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                                return;
                            });
                            return false;
                        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return false;
                        }
                        Swal.fire(t('app.Articulos.SwalFire.impuestoEliminado'), '', 'success');
                        //alertService.success('Impuesto Eliminado', { keepAfterRouteChange: true });
                        const arrayFiltrado = impuestos.filter(
                            (impuesto) => impuesto.id !== id_imp
                        );
                        setImpuestos(arrayFiltrado);
                    });
                } else {
                    setImpuestos(
                        impuestos.filter((imp) => imp.tipo_Impuestos_Id !== tipo_Impuestos_Id)
                    );
                }
            }
        });
    };

    function handleChange(e) {
        var impuesto = listaImpuestosArt.filter((imp) => String(imp.id) === e.target.value);
        setImpuestoArticulo({
            tipo_Impuestos_Id: e.target.value,
            articulo_Id: id,
            tipo_impuesto: impuesto[0]
        });
    }

    function handleChangeClaves(e) {
        var claveArtAux = listaClaves.filter((c) => String(c.id) === e.target.value);
        if (e.target.name === 'clave')
            setClaveArticulo({
                clave: e.target.value,
                clave_Id: claveArticulo.clave_Id,
                articulo_Id: id,
                rolClave: claveArticulo.rolClave
            });
        else
            setClaveArticulo({
                clave: claveArticulo.clave,
                clave_Id: e.target.value,
                articulo_Id: id,
                rolClave: claveArtAux[0]
            });
    }

    function handleChangeClavesVariante(e) {
        var claveArtAux = listaClaves.filter((c) => String(c.id) === e.target.value);
        if (e.target.name === 'clave')
            setClaveArticulo({
                clave: e.target.value,
                clave_Id: claveArticulo.clave_Id,
                articulo_Id: id,
                variante_Id: artVarianteId,
                rolClave: claveArticulo.rolClave
            });
        else
            setClaveArticulo({
                clave: claveArticulo.clave,
                clave_Id: e.target.value,
                articulo_Id: id,
                rolClave: claveArtAux[0],
                variante_Id: artVarianteId
            });
    }

    function handleChangePrecios(e) {
        setNuevoPrecioArticulo({
            ...nuevoPrecioArticulo,
            [e.target.name]: e.target.value
        });
    }

    function handleChangeCategoria(e) {
        if (getDataSession('tienda') !== 'MERCADOLIBRE') {
            if (e === '0' && articulo.clave_Categoria && articulo.clave_Categoria !== '' && articulo.clave_Categoria !== '0') {
                setCategoriaFinID(articulo.clave_Categoria);
                return;
            }

            setCategoriaFinID(e);
            setArticulo({
                ...articulo,
                clave_Categoria: e
            });
            return;
        }

        if (
            e === '0' &&
            articulo.clave_Categoria &&
            articulo.clave_Categoria !== '' &&
            articulo.clave_Categoria !== '0'
        ) {
            if (articulo.clave_Categoria !== categoriaFinId) loadDataAtributos(articulo.clave_Categoria, 1);

            setCategoriaFinID(articulo.clave_Categoria);
            return;
        }

        setCategoriaFinID(e);
        setArticulo({
            ...articulo,
            clave_Categoria: e
        });
        if (e !== '0') loadDataAtributos(e, 1);
    }

    function handleChangeAtributo(claveAtributo, valor) {
        if (claveAtributo === 'WARRANTY_TYPE') {
            setArticulo({
                ...articulo,
                garantia: valor
            });
            return;
        }

        var res = atributosArticulo?.find((x) => x.clave_Atributo === claveAtributo);
        if (res === null || res === undefined) {
            if (atributosArticulo === null || atributosArticulo === undefined)
                setAtributosArticulo([]);

            res = atributos.find((x) => x.clave_Atributo === claveAtributo);
            res.id = 0;
            res.valor = valor;
            atributosArticulo.push(res);
        } else res.valor = valor;

        setArticulo({
            ...articulo,
            atributos: atributosArticulo
        });
    }

    function handlePredictCat(e) {
        setCategoriaFinID(e);
        setArticulo({
            ...articulo,
            clave_Categoria: e
        });
        handleChangeTab(undefined, 0);
        handleChangeTab(undefined, 2);
    }

    function handleChangeAddImg(data) {
        if (data) {
            imagenes.push({ urlImagen: data });
            handleChangeTab(undefined, 0);
            handleChangeTab(undefined, 1);
        }
    }

    function handleChangeRemove(data) {
        if (data) setImagenes(imagenes.filter((item) => item.urlImagen !== data.urlImagen));
    }

    /////Catalogos////
    function getDatos(page, catalogo = catalogoSat) {
        switch (catalogo) {
            case 'clave_Prod_Serv':
                fetchConToken(
                    `CatalogosSat/ListClaveProd?pagina=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`
                ).then((claves) => setDatos(claves));
                break;
            case 'clave_Unidad':
                fetchConToken(
                    `CatalogosSat/ListClaveUnidad?pagina=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`
                ).then((claves) => setDatos(claves));
                break;
        }

        setCatalogoSat(catalogo);
    }
    /////////////////

    const getInitialClaveUnidad = async (art) => {
        if (art && art.clave_Unidad != null & art.clave_Unidad.length > 0)
            await fetchConToken(`CatalogosSat/ClaveUnidad?claveUnidad=${art?.clave_Unidad}`).then(
                (resp) => setInitialObjectClaveUnidad(resp)
            );

        if (art && art.clave_Prod_Serv != null && art.clave_Prod_Serv.length > 0)
            await fetchConToken(`CatalogosSat/ClaveProd?ClaveProd=${art?.clave_Prod_Serv}`).then(
                (resp2) => {
                    var aux = resp2.find((e) => e.clave === art?.clave_Prod_Serv);
                    setInitialObject(aux);
                }
            );
    };

    const loadData = async (query) =>
        await fetchConToken(
            `CatalogosSat/ListClaveProd?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );

    const loadDataClaveUnidad = async (query) =>
        await fetchConToken(
            `CatalogosSat/ListClaveUnidad?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );

    const downloadDataCategoria = async (query, index) =>
        await fetchConToken(`Categorias/DescargarAtributo?claveCategoria=${query}`)
            .then((resp) => {
                if (index === 1) loadDataAtributos(query, 2);
            })
            .catch((e) => {
                Swal.fire(
                    'Error',
                    `No se pudieron descargar los Atributos de la categoria ${query}`,
                    'error'
                );
            });

    const loadDataAtributos = async (query, index) =>
        await fetchConToken(
            `Categorias/AtributosXCategoria?pagina=${1}&totalXpagina=${100}&claveCategoria=${query}`
        ).then((resp) => {
            if (resp && resp.length > 0)
                resp.filter((x) => x.clave_Atributo === 'ITEM_CONDITION').forEach((x) => {
                    if (x.clave_Atributo == 'ITEM_CONDITION') x.required = true;
                });

            setAtributos(resp);
            if (resp.length === 0 && index === 1) downloadDataCategoria(query, index);
            else if (resp.length === 0 && index === 2)
                Swal.fire('Error', `No se encontraron Atributos de la categoria ${query}`, 'error');
        });

    const ActualizarArticulo = () => {
        Swal.fire({
            title: t('app.Articulos.SwalFire.actualizarArt?'),
            text: t('app.Articulos.SwalFire.infoActArt'),
            showCancelButton: true,
            confirmButtonText: t('app.botones.actualizar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(
                    `Articulos/DescargarArticulos?empresa_id=${getDataSession('empresa')}&sucursal_id=${getDataSession('sucursal')}&clave_articulo=${articulo.clave}`,
                    null,
                    'POST'
                ).then(function (response) {
                    setClavesArtVariant([]);
                    getArticulo();
                    if (response.hasOwnProperty('status') && response.status === 400)
                        Object.entries(response.errors).map(([key, value]) =>
                            Swal.fire('Error', value[0], 'error')
                        );
                    else if (response.hasOwnProperty('codigo') && response.codigo !== 200)
                        Swal.fire('Error', response.mensajes[0], 'error');
                    else Swal.fire('Exito', t('app.Articulos.SwalFire.artActualizado'), 'success');
                });
            }
        });
    };

    function onSubmit(data) {
        console.log('entro submit');
        articulo.imagenes = imagenes;
        articulo.categorias = categorias;
        setArticulo({
            ...articulo,
            imagenes: imagenes,
            categorias: categorias
        });

        if (hideSaved) return;

        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', t('app.SwalFire.infoConexion'), 'info');
            return;
        }

        return isAddMode ? createArt(articulo) : updateArt(id, articulo);
    }

    const handleChangeTab = (event, newValue) => {
        setValueTab(newValue);
    };

    const getIsAddMode = () => {
        return isAddMode || !articulo.publicado;
    };

    const isDisableClaveArt = () => {
        return !getIsAddMode() || tienda;
    };

    const agregarClave = () => {
        if (String(claveArticulo.clave_Id) === '0') {
            Swal.fire('', 'Seleccione un rol de clave', 'info');
            //alertService.error('Seleccione un impuesto', { keepAfterRouteChange: true });
            return;
        }

        if (String(claveArticulo.clave).length === 0) {
            Swal.fire('', 'Escriba una clave valida', 'info');
            //alertService.error('Seleccione un impuesto', { keepAfterRouteChange: true });
            return;
        }
        var ClaveRepetido = clavesArt.filter((i) => i.clave_Id === claveArticulo.clave_Id);
        if (ClaveRepetido.length > 0) {
            Swal.fire('', 'Rol de clave agregado anteriormente', 'info');
            return;
        }
        if (isAddMode) {
            setClavesArt([...clavesArt, claveArticulo]);
            var lista = listaClaves.find((c) => c.id == claveArticulo.clave_Id);
            if (lista?.nombre?.toUpperCase() === 'SKU')
                setArticulo({
                    ...articulo,
                    sku: claveArticulo.clave
                });

            closeModal();
            return;
        }

        postArticuloClave();
    };

    const agregarClaveVariante = () => {
        if (String(clavesArtVariant.clave_Id) === '0') {
            Swal.fire('', 'Seleccione un rol de clave', 'info');
            //alertService.error('Seleccione un impuesto', { keepAfterRouteChange: true });
            return;
        }

        if (String(clavesArtVariant.clave).length === 0) {
            Swal.fire('', 'Escriba una clave valida', 'info');
            //alertService.error('Seleccione un impuesto', { keepAfterRouteChange: true });
            return;
        }
        var ClaveRepetido = clavesArtVariant.filter(
            (i) => i.clave_Id === claveArticulo.clave_Id && i.variante_Id === artVarianteId
        );
        if (ClaveRepetido.length > 0) {
            Swal.fire('', 'Rol de clave agregado anteriormente', 'info');
            return;
        }
        if (isAddMode) {
            setClavesArtVariant([...clavesArtVariant, claveArticulo]);
            return;
        }

        postArticuloClave();
    };

    const postArticuloClave = () => {
        fetchConToken(`ArticulosClaves`, claveArticulo, 'POST').then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            Swal.fire(t('app.SwalFire.exitoso'), 'Clave agregada', 'success');
            //alertService.success('Impuesto agregado', { keepAfterRouteChange: true });
            if (modalValor === 'asignarClave') {
                clavesArt = clavesArt.filter((c) => c.clave_Id !== response.clave_Id);
                setClavesArt([...clavesArt, response]);
                var lista = listaClaves.find((c) => c.id === response.clave_Id);
                if (lista?.nombre?.toUpperCase() === 'SKU') {
                    var name = 'sku';
                    setArticulo({
                        ...articulo,
                        [name]: claveArticulo.clave
                    });
                } else if (lista?.nombre?.toUpperCase() === 'CODIGO UNIVERSAL')
                    handleChangeAtributo('GTIN', claveArticulo.clave);
            } else {
                var claves = clavesArtVariant.filter((c) => c.variante_Id === artVarianteId);
                claves = claves.filter((c) => c.clave_Id !== response.clave_Id);
                clavesArtVariant = clavesArtVariant.filter((c) => c.variante_Id !== artVarianteId);
                if (claves) claves.forEach((x) => clavesArtVariant.push(x));
                setClavesArtVariant([...clavesArtVariant, response]);
            }

            closeModal();
        });
    };

    const agregarPrecio = () => {
        if (String(nuevoPrecioArticulo.lista_Precio_Id) === '0') {
            Swal.fire('', 'Seleccione una lista de precio', 'info');
            return;
        }

        if (String(claveArticulo.precio).length === 0) {
            Swal.fire('', 'Escriba un precio valido', 'info');
            return;
        }

        fetchConToken(`PrecioArticulo`, nuevoPrecioArticulo, 'POST').then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            Swal.fire(t('app.SwalFire.exitoso'), 'Precio agregado', 'success');
            precioArticulo(id);
            closeModal();
        });
    };

    const eliminarClave = (art_clave_id, clave_id, varianteId) => {
        Swal.fire({
            title: '¿Desea eliminar la clave?',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                if (art_clave_id !== undefined) {
                    fetchConToken(
                        `ArticulosClaves?clave_articulo_id=${art_clave_id}`,
                        null,
                        'DELETE'
                    ).then(function (response) {
                        if (response.hasOwnProperty('status')) {
                            var erroresA = {};
                            Object.entries(response.errors).map(([key, value]) => {
                                Swal.fire('Error', value[0], 'error');
                                return;
                            });
                            return false;
                        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                            return false;
                        }

                        Swal.fire('Exito', 'Clave eliminada', 'success');
                        if (varianteId && varianteId > 0) {
                            clavesArtVariant = clavesArtVariant.filter(
                                (c) => c.id !== art_clave_id
                            );
                            setClavesArtVariant(clavesArtVariant);
                            clavesArtVariante(id, articulo);
                        } else clavesArticulo(id);
                    });
                } else setClavesArt(clavesArt.filter((c) => c.clave_Id !== clave_id));
            }
        });
    };

    const eliminarPrecio = (art_precio_id, precio_id) => {
        Swal.fire({
            title: '¿Desea eliminar el precio?',
            showCancelButton: true,
            confirmButtonText: t('app.botones.eliminar'),
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                if (art_precio_id !== undefined) {
                    fetchConToken(`PrecioArticulo?id=${art_precio_id}`, null, 'DELETE').then(
                        function (response) {
                            if (response.hasOwnProperty('status')) {
                                var erroresA = {};
                                Object.entries(response.errors).map(([key, value]) => {
                                    Swal.fire('Error', value[0], 'error');
                                    return;
                                });
                                return false;
                            } else if (
                                response.hasOwnProperty('codigo') &&
                                response.codigo !== 200
                            ) {
                                Swal.fire('Error', response.mensajes[0], 'error');
                                return false;
                            }
                            Swal.fire('Exito', 'Clave eliminada', 'success');

                            precioArticulo(id);
                        }
                    );
                } else {
                }
            }
        });
    };

    const handleEditCellChange = (params) => {
        const { id, field, value } = params;
    };

    const GuardarExistencia = () => {
        if (!(existencia.exis_Actual > 0)) {
            Swal.fire('Info', 'Seleccione un existencia valida', 'info');
            return;
        }

        if (TieneVariantes()) {
            existencia.variante_Id = artVarianteId;
            if (!(artVarianteId > 0)) {
                Swal.fire('Info', 'Seleccione una variante', 'info');
                return;
            }
        } else existencia.variante_Id = null;

        fetchConToken(`Existencias`, existencia, existencia.id > 0 ? 'PUT' : 'POST').then(
            (response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresVenta = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                if (!isAddMode) Swal.fire('Exito', 'Al Guardar la Existencia', 'success');

                setClavesArtVariant([]);
                closeModal();
                setReload(true);
                setReload(false);
                getArticulo();
                return true;
            }
        );
    };

    const handleInputChangeAlmacenMovimiento = ({ target }) => {
        setExistencia({
            ...existencia,
            [target.name]: target.value
        });
    };

    const handleInputChangeAlmacen = ({ target }) => {
        setExistencia({
            ...existencia,
            [target.name]: parseInt(target.value)
        });
    };

    const handleInputChangeExistenciasComponentes = ({ target }) => {
        console.log(target);
        const nuevaLista = [...componentesPaquete];
        const indice = nuevaLista.findIndex(
            (comp) => comp.id.toString() === target.name.toString()
        );

        console.log(nuevaLista);
        console.log(target.name);
        console.log(indice);
        if (indice !== -1) {
            nuevaLista[indice].existencias = parseFloat(target.value);
            setComponentesPaquete(nuevaLista);
        }
    };

    function getComponentes() {
        if (id) {
            fetchConToken(`Articulos/ComponentesByPaquete?articulo_id=` + id).then((response) => {
                setComponentesPaquete(response);
            });
        }
    }

    async function getAlmacenes() {
        const almacenesAux = await fetchConToken(
            `Almacenes/GetList?paginas=0&totalXpagina=200&sucursal_Id=${getDataSession('sucursal')}`
        );
        setAlmacenes(almacenesAux.data);
        setExistencia({
            ...existencia,
            ['almacen_Id']: almacenesAux.data[0].id
        });
    }

    function onChangeSelectdIds(selected) {
        fetchConToken(`Existencias?id=` + selected).then((exis) => {
            setExistencia(exis);
        });
    }

    function regresar() {
        history.push(`${SLUGS.articulos}`);
    }

    function TieneVariantes() {
        return variantes && variantes.length > 0;
    }

    function GetColumClassName() {
        if (TieneVariantes()) return !isAddMode ? 'col-md-3' : 'col-md-4';

        return !isAddMode ? 'col-md-5' : 'col-md-4';
    }

    function GetNombreArticulo(art, isVariante) {
        if (isVariante)
            return art
                ? art.sku
                    ? `SKU:${art.sku} - ${art.nombre}`
                    : `${art.clave} - ${art.nombre}`
                : '';

        return art ? art.nombre : '';
    }

    function onChangeVarianteExistencia({ target }) {
        const { value } = target;
        setArtVarianteId(value);
    }

    const eliminarExistenciaAlmacen = (id_suc) => {
        handleClose();
        Swal.fire({
            title: 'Eliminar existencia',
            text: '¿Esta seguro de eliminar la existencia?',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(`existencias?id=${id_suc}`, null, 'DELETE').then(function (response) {
                    if (response.hasOwnProperty('status') && response.status === 400)
                        Object.entries(response.errors).map(([key, value]) =>
                            Swal.fire('Error', value[0], 'error')
                        );
                    else if (response.hasOwnProperty('codigo') && response.codigo !== 200)
                        Swal.fire('Error', response.mensajes[0], 'error');
                    else {
                        Swal.fire('Exito', 'Existencia del almacen eliminada', 'success');
                        setReload(true);
                        setReload(false);
                    }
                });
            }
        });
    };

    const validarArticulo = () => {
        if (!articulo.clave || articulo.clave === '') {
            Swal.fire('', 'Complete el campo de Clave Articulo', 'warning');
            return false;
        }

        if (!articulo.nombre || articulo.nombre === '') {
            Swal.fire('', 'Complete el campo de Nombre', 'warning');
            return false;
        }

        if (!articulo.costo_Unidad || articulo.costo_Unidad == '' || articulo.costo_Unidad == '0') {
            Swal.fire('', 'Complete el campo de Precio', 'warning');
            return false;
        }

        if (
            isAddMode &&
            (!articulo.existencias || articulo.existencias == '' || articulo.existencias == '0')
        ) {
            Swal.fire('', 'Complete el campo de Existencias', 'warning');
            return false;
        }

        if (tienda == 'MERCADOLIBRE') {
            if (!categoriaFinId || categoriaFinId == '0') {
                Swal.fire('', 'Complete el campo de Categoria', 'warning');
                return false;
            }

            if (!atributos || atributos.length === 0) {
                Swal.fire('', 'Atributos no descargados', 'warning');
                return false;
            }

            atributos.forEach((x) => {
                if (x.required) {
                    if (x.clave_Atributo == 'WARRANTY_TYPE') {
                        if (!articulo.garantia || articulo.garantia == '') {
                            Swal.fire('', `Complete el campo de ${x.nombre}`, 'warning');
                            return false;
                        }
                    } else if (x.value_Type !== 'boolean') {
                        var atributo = articulo.atributos?.find(
                            (y) => y.clave_Atributo === x.clave_Atributo
                        );
                        if (
                            atributo === null ||
                            atributo === undefined ||
                            !atributo.valor ||
                            atributo.valor == ''
                        ) {
                            Swal.fire('', `Complete el campo de ${x.nombre}`, 'warning');
                            return false;
                        }
                    }
                }
            });

            if (!articulo.currency || articulo.currency == '') {
                Swal.fire('', 'Complete el campo de Moneda', 'warning');
                return false;
            }

            if (
                !articulo.tipo_Publicacion ||
                articulo.tipo_Publicacion == '' ||
                articulo.tipo_Publicacion == 'default'
            ) {
                Swal.fire('', 'Complete el campo de Tipo de Publicacion', 'warning');
                return false;
            }
        }

        return true;
    };

    async function getArticuloSeleccionado(articuloSelecc) {
        setArticuloSeleccionado(articuloSelecc);
    }

    async function AsignarArticulo() {
        if (!existeArtComponente(articuloSeleccionado.id)) {
            componentesPaquete.push(articuloSeleccionado);
        }
        closeModal();
    }

    function existeArtComponente(id) {
        return componentesPaquete.some((comp) => comp.id === id);
    }

    const loadArticulos = async (query) => {
        if (getDataSession('sucursalPrincipal') === '0') {
            Swal.fire('Info', 'Configure la conexion principal.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Articulos/ListArtYVar?busqueda=${query}&sucursal_Id=${getDataSession('sucursal')}`
        );
        return resp;
    };

    function eliminarComponentePaquete(id) {
        setComponentesPaquete(componentesPaquete.filter((comp) => comp.id !== id));
    }

    const columns = [
        {
            renderCell: RenderButtons,
            headerName: '',
            maxWidth: 20,
            opciones: 'eliminar',
            eliminar: eliminarExistenciaAlmacen
        },
        { field: 'id', headerName: 'Id', width: 70 },
        {
            field: 'articulo.nombre',
            headerName: 'Articulo',
            flex: 2,
            renderCell: (params) => GetNombreArticulo(params.row.articulo, false)
        },
        {
            field: 'articulo_Variante.nombre',
            headerName: 'Variante',
            flex: 2,
            renderCell: (params) => GetNombreArticulo(params.row.articulo_Variante, true)
        },
        {
            field: 'almacen.nombre',
            headerName: 'Almacen',
            flex: 1,
            renderCell: (params) => (params.row.almacen ? params.row.almacen.nombre : '')
        },
        { field: 'exis_Actual', headerName: 'Exist. Actual', flex: 1, editable: true },
        { field: 'exis_Comprometida', headerName: 'Exis. Comprometida', flex: 1, editable: true },
        { field: 'exis_Disponible', headerName: 'Exis. Disponible', flex: 1, editable: true }
    ];

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        {!hideSaved && (
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-6  col-md-6'>
                                    <div className='espacio5px' />
                                </div>
                                <div className='col-sm-2' />
                                <div className='col-sm-4 text-right'>
                                    <button
                                        className='input_file_hidden'
                                        type='submit'
                                        ref={button_submit}
                                    >
                                        {t('app.botones.guardar')}
                                    </button>
                                    <button
                                        type='button'
                                        className='btn btn-outline-primary m-1'
                                        style={{ width: 'auto' }}
                                        onClick={handleClick}
                                    >
                                        <TuneIcon />
                                        &nbsp; Acciones <ArrowDropDownIcon />
                                    </button>
                                    <Menu
                                        id='basic-menu'
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                    >
                                        {!isAddMode && articulo?.url?.length > 0 ? (
                                            <MenuItem
                                                onClick={() => {
                                                    ActualizarArticulo();
                                                    handleClose();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='16'
                                                        height='16'
                                                        fill='currentColor'
                                                        className='bi bi-arrow-clockwise'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path
                                                            fill-rule='evenodd'
                                                            d='M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z'
                                                        />
                                                        <path d='M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z' />
                                                    </svg>
                                                </ListItemIcon>
                                                {t('app.botones.actualizar')}
                                            </MenuItem>
                                        ) : (
                                            ''
                                        )}
                                        <MenuItem
                                            onClick={() => {
                                                handleClickButtonSubmit();
                                                handleClose();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='20'
                                                    height='20'
                                                    fill='currentColor'
                                                    className='bi bi-check-lg'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                                </svg>
                                            </ListItemIcon>
                                            {t('app.botones.guardar')}
                                        </MenuItem>
                                        {!isAddMode ? (
                                            <MenuItem
                                                onClick={() => {
                                                    eliminar();
                                                    handleClose();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='20'
                                                        height='20'
                                                        fill='currentColor'
                                                        className='bi bi-x-lg'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                                                    </svg>
                                                </ListItemIcon>
                                                {t('app.botones.eliminar')}
                                            </MenuItem>
                                        ) : (
                                            ''
                                        )}
                                        <MenuItem
                                            onClick={() => {
                                                regresar();
                                                handleClose();
                                            }}
                                        >
                                            <ListItemIcon>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='20'
                                                    height='20'
                                                    fill='currentColor'
                                                    className='bi bi-reply'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                                </svg>
                                            </ListItemIcon>
                                            {t('app.botones.regresar')}
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        )}
                        <Tabs
                            value={valueTab}
                            onChange={handleChangeTab}
                            variant='scrollable'
                            scrollButtons='auto'
                        >
                            <Tab
                                icon={<Settings />}
                                iconPosition='start'
                                aria-label='phone'
                                label={t('app.Articulos.form.tabs.tabGneral')}
                                disabled={disableTab}
                            />
                            <Tab
                                icon={<ImageIcon />}
                                iconPosition='start'
                                aria-label='phone'
                                label={t('app.Articulos.form.tabs.tabImgDesc')}
                                disabled={disableTab}
                            />
                            <Tab
                                icon={<TaskIcon />}
                                iconPosition='start'
                                aria-label='phone'
                                label={t('app.Articulos.form.tabs.tabCatAtrb')}
                                disabled={disableTab}
                            />
                            <Tab
                                icon={<AttachMoneyIcon />}
                                iconPosition='start'
                                aria-label='phone'
                                label={t('app.Articulos.form.tabs.tabCosto')}
                                disabled={disableTab}
                            />
                            <Tab
                                icon={<InventoryIcon />}
                                iconPosition='start'
                                aria-label='phone'
                                label={t('app.Articulos.form.tabs.tabExistencia')}
                                disabled={disableTab}
                            />
                            <Tab
                                icon={<ViewQuiltIcon />}
                                iconPosition='start'
                                aria-label='phone'
                                label={t('app.Articulos.form.tabs.tabVariante')}
                                disabled={disableTab}
                            />
                            <Tab
                                icon={<ViewQuiltIcon />}
                                iconPosition='start'
                                aria-label='phone'
                                label='Componentes'
                                disabled={disableTabComponentes}
                            />
                        </Tabs>
                        <div className='espacio10px' />
                        {valueTab === 0 && (
                            <div className='row'>
                                <div className='col-md-6' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='card-header'>
                                            <label className='form-label title2'>
                                                {t('app.Articulos.datosGenerales')}
                                            </label>
                                        </div>
                                        <div className='card-body '>
                                            <label htmlFor='clave' className='form-label'>
                                                {t('app.Articulos.form.claveArt')}*
                                            </label>
                                            <input
                                                type='text'
                                                className={
                                                    !isDisableClaveArt()
                                                        ? 'form-control'
                                                        : 'form-control form-control-disabled'
                                                }
                                                name='clave'
                                                maxLength={100}
                                                value={articulo.clave || ''}
                                                disabled={isDisableClaveArt()}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='nombre' className='form-label'>
                                                {t('app.Articulos.form.nombreArt')}*
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='nombre'
                                                required={true}
                                                maxLength={100}
                                                value={articulo.nombre || ''}
                                                onChange={handleInputChange}
                                            />
                                            <div className='col-md-12 row'>
                                                <div className='col-sm-5'>
                                                    <div className='espacio15px' />
                                                    <div className='form-check form-switch margin_left_switch'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            name='estatus'
                                                            role='switch'
                                                            onChange={onChangeCheckActivo}
                                                            checked={articulo.estatus === 'A'}
                                                        />
                                                        <label
                                                            htmlFor='estatus'
                                                            className='margin_left_switch margin_top_switch_label'
                                                        >
                                                            {t('app.Articulos.form.estatus')}
                                                        </label>{' '}
                                                        &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                                <div className='col-sm-5'>
                                                    <div className='espacio15px' />
                                                    <div className='form-check form-switch margin_left_switch'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            name='es_Paquete'
                                                            role='switch'
                                                            onChange={onChangeCheck}
                                                            checked={articulo.es_Paquete === 'S'}
                                                        />
                                                        <label
                                                            htmlFor='es_Paquete'
                                                            className='margin_left_switch margin_top_switch_label'
                                                        >
                                                            {t('app.Articulos.form.esPaquete')}
                                                        </label>{' '}
                                                        &nbsp;&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <label htmlFor='url' className='form-label'>
                                                URL
                                            </label>
                                            <input
                                                type='text'
                                                className={
                                                    isAddMode
                                                        ? 'form-control'
                                                        : 'form-control form-control-disabled'
                                                }
                                                name='url'
                                                maxLength={100}
                                                value={articulo.url || ''}
                                                onChange={handleInputChange}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='card mb-3 '>
                                        <ClavesArticulos
                                            clavesArt={clavesArt}
                                            eliminarClave={eliminarClave}
                                            setModalValor={setModalValor}
                                            openModal={openModal}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='card-header'>
                                            <label className='form-label title2'>
                                                {t('app.Articulos.datosSat')}
                                            </label>
                                        </div>
                                        <div className='card-body text-start'>
                                            <label
                                                htmlFor='no_Identificacion'
                                                className='form-label'
                                            >
                                                {t('app.Articulos.form.codigoIdentificacion')}
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='no_Identificacion'
                                                maxLength={100}
                                                placeholder='Escriba código de identificación'
                                                value={articulo.no_Identificacion || ''}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='clave_Prod_Serv' className='form-label'>
                                                {t('app.Articulos.form.servicioSAT')}
                                            </label>
                                            <AsyncSelect
                                                isClearable
                                                className='react-select-placeholder'
                                                getOptionLabel={(option) =>
                                                    option.clave + '-' + option.nombre
                                                }
                                                getOptionValue={(option) => option.clave}
                                                defaultOptions
                                                value={initialObject}
                                                loadOptions={loadData}
                                                onChange={(selectedItem) => {
                                                    const val =
                                                        selectedItem === null
                                                            ? ''
                                                            : selectedItem?.clave;
                                                    console.log(val);
                                                    articulo.clave_Prod_Serv = val;
                                                    setInitialObject(selectedItem);
                                                }}
                                            />
                                            <label htmlFor='clave_Unidad' className='form-label'>
                                                {t('app.Articulos.form.claveUnidad')}
                                            </label>
                                            <AsyncSelect
                                                className='react-select-placeholder'
                                                isClearable
                                                getOptionLabel={(option) =>
                                                    option.clave + '-' + option.nombre
                                                }
                                                getOptionValue={(option) => option.clave}
                                                defaultOptions //={initialObjectClaveUnidad}
                                                value={initialObjectClaveUnidad}
                                                loadOptions={loadDataClaveUnidad}
                                                onChange={(selectedItem) => {
                                                    const val =
                                                        selectedItem === null
                                                            ? ''
                                                            : selectedItem?.clave;
                                                    console.log(val);
                                                    articulo.clave_Unidad = val; //setFieldValue(fieldName, val)
                                                    setInitialObjectClaveUnidad(selectedItem);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className='card mb-3 '>
                                        <Impuestos
                                            listaImpuestosArt={listaImpuestosArt}
                                            impuestos={impuestos}
                                            handleChange={handleChange}
                                            registrarImpuesto={registrarImpuesto}
                                            eliminarImpuesto={eliminarImpuesto}
                                        />
                                    </div>
                                </div>
                                <div className='container-fluid'></div>
                            </div>
                        )}
                        {valueTab === 1 && (
                            <div className='row'>
                                <label className='form-label title2'>
                                    {t('app.Articulos.datosObligatorios')}
                                </label>
                                <div className='col-md-5' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='card-body text-start'>
                                            <label className='form-label'>
                                                {t('app.Articulos.form.imagenes')}*
                                            </label>
                                            <div className='card mb-3 card-img'>
                                                <div className='espacio5px' />
                                                <div className='list_img'>
                                                    <ListImg
                                                        data={imagenes}
                                                        isAdd={true}
                                                        isEdit={true}
                                                        handleChangeAddImg={handleChangeAddImg}
                                                        handleChangeRemove={handleChangeRemove}
                                                    />
                                                </div>
                                                <div className='espacio5px' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-7' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='card-body text-start'>
                                            <label htmlFor='descripcion' className='form-label'>
                                                {t('app.Articulos.form.descripcionArt')}
                                            </label>
                                            <textarea
                                                className='form-control'
                                                name='descripcion'
                                                rows='23'
                                                value={articulo.descripcion || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {valueTab === 2 && (
                            <div className='row'>
                                <div className='col-md-12' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <div className='espacio15px' />
                                        {getDataSession('tienda') === 'MERCADOLIBRE' ? <div className='card-body text-start'>
                                            <CategoriaList
                                                data={articulo}
                                                HandleChangePrincipal={handleChangeCategoria}
                                                disabled={!getIsAddMode()}
                                                HandlePredictCat={handlePredictCat}
                                            />
                                            {categoriaFinId && categoriaFinId !== '0' /*&& atributos && atributos.length > 0*/ && (
                                                <div>
                                                    <div className='espacio15px' />
                                                    <div className='espacio15px' />
                                                    <Divider variant='middle' color='indigo' />
                                                    <div className='espacio15px' />
                                                    <FichaTecnica
                                                        data={articulo}
                                                        Atributos={atributos}
                                                        HandleChangePrincipal={handleChangeAtributo}
                                                    />
                                                </div>
                                            )}
                                        </div> : <div className='card-body text-start'>
                                            <CategoriaList
                                                data={articulo}
                                                HandleChangePrincipal={handleChangeCategoria}
                                                HandlePredictCat={handlePredictCat}
                                                categorias={categorias}
                                                SetCategorias={setCategorias}
                                            />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        )}
                        {valueTab === 3 && (
                            <Costos
                                data={articulo}
                                sucursal={sucursal}
                                imagenes={imagenes}
                                handleChange={handleChange}
                                handleInputChange={handleInputChange}
                                onChangeCheckActivo={onChangeCheck}
                                onChangeCostos={onChangeCostos}
                                tienda={tienda}
                                preciosArt={preciosArt}
                                eliminarPrecio={eliminarPrecio}
                                setModalValor={setModalValor}
                                openModal={openModal}
                                isAddMode={isAddMode}
                            />
                        )}
                        {valueTab === 4 && (
                            <div className='row'>
                                <div className='col-md-12' style={{ display: 'grid' }}>
                                    {!isAddMode ? (
                                        <div className='mb-3 text-right'>
                                            <button
                                                className='btn btn-outline-success'
                                                onClick={(e) => {
                                                    setModalValor('asignarExistencias');
                                                    openModal();
                                                }}
                                                type='button'
                                            >
                                                &nbsp; Asignar existencia
                                            </button>
                                            <div className='card-body col-md-12 text-right'>
                                                <ListTable
                                                    columns={columns}
                                                    url={`Existencias/GetList?sucursal_Id=${getDataSession(
                                                        'sucursal'
                                                    )}&articulo_Id=${id ?? 0}&omitirPadres=${articulo
                                                        ? articulo.tiene_Variante == 'S'
                                                        : false
                                                        }`}
                                                    onChangeSelectdIds={onChangeSelectdIds}
                                                    reload={reload}
                                                    hideConexion={true}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='mb-3 text-right card'>
                                            <div className='card-body text-start'>
                                                <div className='espacio10px' />
                                                <label htmlFor='existencias' className='form-label'>
                                                    {t('app.Articulos.form.existencias')}*
                                                </label>
                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    name='existencias'
                                                    required={true}
                                                    maxLength={100}
                                                    value={articulo.existencias || ''}
                                                    onChange={handleInputChange}
                                                />
                                                <div className='espacio10px' />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {valueTab === 5 && (
                            <div className='row'>
                                <div className='col-md-12' style={{ display: 'grid' }}>
                                    <div className='card mb-3 '>
                                        <ListVariante
                                            data={articulo}
                                            viewClaves
                                            clavesArt={clavesArtVariant}
                                            eliminarClave={eliminarClave}
                                            setModalValor={setModalValor}
                                            openModal={openModal}
                                            setVarianteId={setArtVarianteId}
                                            variantes={variantes}
                                            setVariantes={setVariantes}
                                            clavesArticuloVariante={clavesArticuloVariante}
                                            editar={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {valueTab === 6 && (
                            <div className='row'>
                                <div className='col-md-12' style={{ display: 'grid' }}>
                                    <div className='card mb-3 col-md-12'>
                                        <div className='card-header row'>
                                            <div className='col-md-6'>
                                                <label className='form-label'>
                                                    Componentes del paquete
                                                </label>
                                            </div>
                                            <div className='col-md-6 text-right'>
                                                <button
                                                    className='btn btn-outline-success'
                                                    onClick={(e) => {
                                                        setModalValor('asignaComponenteArt');
                                                        openModal();
                                                    }}
                                                    type='button'
                                                >
                                                    &nbsp; Agregar Articulo
                                                </button>
                                            </div>
                                        </div>
                                        <div className='card-body'>
                                            <div style={{ overflowX: 'auto' }}>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>Clave</th>
                                                            <th className='text-center'>
                                                                Articulo
                                                            </th>
                                                            <th className='text-center'>
                                                                Cantidad
                                                            </th>
                                                            <th
                                                                className='text-right'
                                                                width='50'
                                                            ></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {componentesPaquete?.map((item) => {
                                                            return (
                                                                <tr key={item.id}>
                                                                    <td className='text-center'>
                                                                        {item.clave}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        {item.nombre}
                                                                    </td>
                                                                    <td className='text-center'>
                                                                        <input
                                                                            className='form-control'
                                                                            type='number'
                                                                            name={item.id}
                                                                            value={item.existencias}
                                                                            onChange={
                                                                                handleInputChangeExistenciasComponentes
                                                                            }
                                                                        />
                                                                    </td>
                                                                    <td
                                                                        className='text-center'
                                                                        width='50'
                                                                    >
                                                                        <button
                                                                            type='button'
                                                                            className='btn'
                                                                            style={{ color: 'red' }}
                                                                            onClick={() =>
                                                                                eliminarComponentePaquete(
                                                                                    item.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <svg
                                                                                xmlns='http://www.w3.org/2000/svg'
                                                                                width='25'
                                                                                height='20'
                                                                                fill='currentColor'
                                                                                className='bi bi-x-circle-fill'
                                                                                viewBox='0 0 16 16'
                                                                            >
                                                                                <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                                            </svg>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })};
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {modalValor === 'asignarExistencias' ? (
                    <>
                        <div className='espacio30px' />
                        <div className='col-md-12 text-right'>
                            <div className='col-md-12 row'>
                                {TieneVariantes() && (
                                    <div className={!isAddMode ? 'col-md-4' : 'col-md-4'}>
                                        <div className='card mb-2 '>
                                            <div className='card-body text-start'>
                                                <label htmlFor='calle'>Variante</label>
                                                <select
                                                    className='form-select'
                                                    name='sinc_precio_empresa_id'
                                                    value={artVarianteId}
                                                    onChange={onChangeVarianteExistencia}
                                                >
                                                    <option key={0} value={0} disabled>
                                                        Seleccione la variante
                                                    </option>
                                                    {variantes?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.clave} - {item.nombre}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className={GetColumClassName()}>
                                    <div className='card-body text-start'>
                                        <label htmlFor='calle'>Almacen</label>
                                        <select
                                            className='form-control'
                                            name='almacen_Id'
                                            value={existencia.almacen_Id}
                                            onChange={handleInputChangeAlmacen}
                                        >
                                            {almacenes.map((almacen) => {
                                                return (
                                                    <option key={almacen.id} value={almacen.id}>
                                                        {almacen.nombre}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className={GetColumClassName()}>
                                    <div className='card-body text-start'>
                                        <label htmlFor='calle'>Existencia</label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            name='exis_Actual'
                                            value={existencia?.exis_Actual || ''}
                                            onChange={handleInputChangeAlmacen}
                                        />
                                        <label htmlFor='calle'>Concepto</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='movimiento'
                                            value={existencia?.movimiento || ''}
                                            onChange={handleInputChangeAlmacenMovimiento}
                                        />
                                    </div>
                                </div>
                                {!isAddMode ? (
                                    <>
                                        <div className='col-md-2'>
                                            <button
                                                className='btn btn-outline-success'
                                                onClick={(e) => GuardarExistencia()}
                                                type='button'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='20'
                                                    height='20'
                                                    fill='currentColor'
                                                    className='bi bi-check-lg'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                                </svg>
                                                &nbsp; Asignar
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {modalValor === 'asignarPrecio' ? (
                    <>
                        <div className='espacio30px' />
                        <div className='col-md-12 row text-right'>
                            <div className='col-sm-10'>
                                <div className='card-body text-start'>
                                    <label htmlFor='impuesto' className='form-label title2'>
                                        Agregar Precio
                                    </label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <select
                                                className='form-select'
                                                onChange={handleChangePrecios}
                                                name='lista_Precio_Id'
                                            >
                                                <option key={0} value='0'>
                                                    Seleccione el precio
                                                </option>
                                                {listaPrecios?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                type='number'
                                                className='form-control'
                                                name='precio'
                                                onChange={handleChangePrecios}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='espacio30px' />
                                <button
                                    type='button'
                                    className='btn btn-outline-primary'
                                    onClick={(e) => agregarPrecio()}
                                >
                                    Agregar Precio
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {modalValor === 'asignarClave' ? (
                    <>
                        <div className='espacio30px' />
                        <div className='col-md-12 row text-right'>
                            <div className='col-sm-10'>
                                <div className='card-body text-start'>
                                    <label htmlFor='impuesto' className='form-label title2'>
                                        Agregar Clave
                                    </label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <select
                                                className='form-select'
                                                onChange={handleChangeClaves}
                                                name='impuesto'
                                            >
                                                <option key={0} value='0'>
                                                    Seleccione la clave
                                                </option>
                                                {listaClaves?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                className='form-control'
                                                name='clave'
                                                onChange={handleChangeClaves}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='espacio30px' />
                                <button
                                    type='button'
                                    className='btn btn-outline-primary'
                                    onClick={(e) => agregarClave()}
                                >
                                    Agregar Clave
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
                {modalValor === 'asignarClaveVariante' ? (
                    <>
                        <div className='espacio30px' />
                        <div className='col-md-12 row text-right'>
                            <div className='col-sm-10'>
                                <div className='card-body text-start'>
                                    <label htmlFor='impuesto' className='form-label title2'>
                                        Agregar Clave a la Variante{' '}
                                        {
                                            articulo?.articulo_Variantes.find(
                                                (x) => x.id == artVarianteId
                                            )?.nombre
                                        }
                                    </label>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <select
                                                className='form-select'
                                                onChange={handleChangeClavesVariante}
                                                name='impuesto'
                                            >
                                                <option key={0} value='0'>
                                                    Seleccione la clave
                                                </option>
                                                {listaClaves?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>
                                                            {item.nombre}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className='col-md-6'>
                                            <input
                                                className='form-control'
                                                name='clave'
                                                onChange={handleChangeClavesVariante}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='espacio30px' />
                                <button
                                    type='button'
                                    className='btn btn-outline-primary'
                                    onClick={(e) => agregarClaveVariante()}
                                >
                                    Agregar Clave
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}

                {modalValor === 'asignaComponenteArt' ? (
                    <>
                        <div className='card text-left' style={{ padding: 10 }}>
                            <div className='container'>
                                <div>
                                    <div className='espacio15px'></div>
                                    <div className='card-title'>
                                        <div className='row'>
                                            <div className='col-md-6 text-left'>
                                                <label>Seleccione el articulo componente</label>
                                            </div>
                                            <div className='col-md-6 text-right'>
                                                <Button
                                                    variant='contained'
                                                    onClick={() => AsignarArticulo()}
                                                >
                                                    Asignar
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='espacio15px'></div>
                                    <div className='card-body ' style={{ height: '50vh' }}>
                                        <AsyncSelect
                                            isClearable
                                            className='react-select-placeholder'
                                            getOptionLabel={(option) => option.nombre}
                                            getOptionValue={(option) => option.id}
                                            loadOptions={loadArticulos}
                                            onChange={(selectedItem) => {
                                                getArticuloSeleccionado(selectedItem);
                                            }}
                                            value={articuloSeleccionado}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    ''
                )}
            </Modal>
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

export { AddEdit };
