import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import Divider from '@mui/material/Divider';
import RefreshIcon from '@mui/icons-material/Refresh';
import TaskIcon from '@mui/icons-material/Task';
import SinImagen from 'images/venta/SIN-IMAGEN.jpg';
import { currency } from 'resources/utilities';

const Costos = ({ data, sucursal, imagenes, handleInputChange, onChangeCheckActivo, onChangeCostos, tienda, preciosArt, eliminarPrecio, setModalValor, openModal, isAddMode }) => {
    return (
        <div className='row'>
            <div className={tienda == 'MERCADOLIBRE' ? 'col-md-7' : 'col-md-12'} style={{ display: 'grid' }}>
                <ConfiguracionCostos data={data} sucursal={sucursal} handleInputChange={handleInputChange} onChangeCheckActivo={onChangeCheckActivo} preciosArt={preciosArt} eliminarPrecio={eliminarPrecio} setModalValor={setModalValor} openModal={openModal} tienda={tienda} isAddMode={isAddMode} />
            </div>
            {tienda == 'MERCADOLIBRE' ? <div className='col-md-5' style={{ display: 'grid' }}><ResumenCostos data={data} imagenes={imagenes} onChangeCostos={onChangeCostos} tienda={tienda} preciosArt={preciosArt} /></div> : ''}
        </div>
    );
}

const Impuestos = ({ listaImpuestosArt, impuestos, handleChange, registrarImpuesto, eliminarImpuesto }) => {
    const { t } = useTranslation("common");
    return (
        <div className='card col-12 col-sm-12 col-md-12'>
            <div className='col-md-12 row text-right'>
                <div className='col-sm-8'>
                    <div className='card-body text-start'>
                        <label htmlFor='impuesto' className='form-label title2'>{t('app.Articulos.form.agregarImp')}</label>
                        <select className='form-select' onChange={handleChange} name='impuesto'>
                            <option key={0} value='0'>{t('app.Articulos.form.seleccioneImp')}</option>
                            {listaImpuestosArt?.map((item) => { return (<option key={item.id} value={item.id}>{item.nombre}</option>); })}
                        </select>
                    </div>
                </div>
                <div className='col-sm-4'>
                    <div className='espacio45px' />
                    <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={(e) => registrarImpuesto()}
                    >
                        {t('app.Articulos.form.agregarImp')}
                    </button>
                </div>
            </div>
            <div className='card-body'>
                <div style={{ overflowX: 'auto' }}>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th className='text-center'>#</th>
                                <th className='text-center'>{t('app.Articulos.form.clave')}</th>
                                <th className='text-center'>{t('app.Articulos.form.nombreArt')}</th>
                                <th className='text-center'>{t('app.Articulos.form.descripcionArt')}</th>
                                <th className='text-center'>{t('app.Articulos.form.porcentaje')}</th>
                                <th className='text-right' width='25'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {impuestos?.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td className='text-center'>{item.id}</td>
                                        <td className='text-center'>{item.tipo_impuesto?.clave}</td>
                                        <td className='text-center'>{item.tipo_impuesto?.nombre}</td>
                                        <td className='text-center'>{item.tipo_impuesto?.descripcion}</td>
                                        <td className='text-center'>{item.tipo_impuesto?.porcentaje}</td>
                                        <td className='text-right' width='25'>
                                            <button
                                                type='button'
                                                className='btn'
                                                style={{ color: 'red' }}
                                                onClick={(e) => eliminarImpuesto(item.id, item.tipo_Impuestos_Id)}
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='25'
                                                    height='25'
                                                    fill='currentColor'
                                                    className='bi bi-x-circle-fill'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

const ConfiguracionCostos = ({ data, sucursal, handleInputChange, onChangeCheckActivo, preciosArt, eliminarPrecio, setModalValor, openModal, tienda, isAddMode }) => {
    const { t } = useTranslation("common");
    const [initialObject, setInitialObject] = useState(data.currency);
    const [initialObjectMetodoEnvio, setInitialObjectMetodoEnvio] = useState(data.metodo_Envio);
    const loadDataMonedas = async () => await fetchConToken(`Categorias/Monedas?clave_categoria=${data.clave_Categoria}&sucursal_id=${getDataSession('sucursal')}`);
    const loadDataMetodosEnvio = async () => await fetchConToken(`Categorias/MetodoEnvio?clave_categoria=${data.clave_Categoria}&sucursal_id=${getDataSession('sucursal')}`);
    const loadDataMetodoEnvio = async (metodo) => await fetchConToken(`Categorias/MetodoEnvio?clave_categoria=${data.clave_Categoria}&sucursal_id=${getDataSession('sucursal')}`).
        then((resp) => { setInitialObjectMetodoEnvio(resp.find((e) => e.clave_Metodo === metodo)); });

    const loadDataMoneda = async (currency, index) => await fetchConToken(`Categorias/Monedas?clave_categoria=${data.clave_Categoria}&sucursal_id=${getDataSession('sucursal')}`).then((resp) => {
        setInitialObject(resp?.find((e) => e.clave === currency));
        if (resp.length === 0 && index === 1) downloadDataMoneda(currency, index);
        else if (resp.length === 0 && index === 2) Swal.fire('Error', `No se encontraron las Monedas de la categoria ${data.clave_Categoria}`, 'error');
    }).catch((e) => { Swal.fire('Error', `No se pudieron descargar las Monedas de la categoria ${data.clave_Categoria}`, 'error'); });

    const downloadDataMoneda = async (metodo, index) => await fetchConToken(`Categorias/DescargarMoneda?claveCategoria=${data.clave_Categoria}&sucursal_id=${getDataSession('sucursal')}`).
        then((resp) => { if (index === 1) loadDataMoneda(metodo, 2); }).catch((e) => { Swal.fire('Error', `No se pudieron descargar las Monedas de la categoria ${data.clave_Categoria}`, 'error'); });

    useEffect(() => {
        data.utilidad = (!data.utilidad && data.utilidad != 0) ? data.utilidad : data.costo_Unidad;
        if (tienda == 'MERCADOLIBRE' && data.clave_Categoria && data.clave_Categoria != '' && data.clave_Categoria != '0') loadDataMoneda(data.currency, 1);
        
        if (data.metodo_Envio && data.metodo_Envio !== '') loadDataMetodoEnvio(data.metodo_Envio);
    }, []);

    return (
        <div className='card'>
            <div className='card-header'>
                <label className='form-label title2'>{t('app.Articulos.form.configuracionCosto')}</label>
            </div>
            <div className='card-body text-start'>
                {tienda != 'MERCADOLIBRE' && isAddMode && <>
                    <div className='text-start'>
                        <label htmlFor='costo_Unidad' className='form-label'>{t('app.Articulos.form.precioArt')} Publicado*</label>
                        <input
                            type='number'
                            className='form-control'
                            name='costo_Unidad'
                            required={true}
                            maxLength={100}
                            value={data.costo_Unidad || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                </>}
                {tienda == 'MERCADOLIBRE' && <>
                    <div className='col-md-13 row'>
                        <div className='col-sm-3'>
                            <label htmlFor='costo_Unidad' className='form-label'>{t('app.Articulos.form.precioArt')} Publicado*</label>
                            <input
                                type='number'
                                className='form-control'
                                name='costo_Unidad'
                                required={true}
                                maxLength={100}
                                value={data.costo_Unidad || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='col-sm-5'>
                            <label htmlFor='utilidad' className='form-label'>
                                {t('app.Articulos.form.precioArt')} Base {sucursal?.desglosar_Impuesto ? '(' + t('app.Articulos.form.conImpuestos') + ')*' : '(' + t('app.Articulos.form.sinImpuestos') + ')'}
                            </label>
                            <input
                                type='number'
                                className='form-control'
                                name='utilidad'
                                required={true}
                                maxLength={100}
                                value={data.utilidad || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='col-sm-4'>
                            <label htmlFor='moneda' className='form-label'>{t('app.Articulos.form.moneda')}</label>
                            <AsyncSelect
                                isClearable
                                className='react-select-placeholder'
                                name='moneda'
                                getOptionLabel={(option) => option.clave}
                                getOptionValue={(option) => option.clave}
                                defaultOptions
                                value={initialObject}
                                loadOptions={loadDataMonedas}
                                onChange={(selectedItem) => {
                                    const val = selectedItem === null ? '' : selectedItem?.clave;
                                    data.currency = val;
                                    setInitialObject(selectedItem);
                                }}
                            />
                        </div>
                    </div>
                    <label htmlFor='tipo_Publicacion' className='form-label'>{t('app.Articulos.form.tipoPublicacion')}*</label>
                    <select className='form-select' name='tipo_Publicacion' value={data.tipo_Publicacion || 'default'} onChange={handleInputChange}>
                        <option key='default' value='default'>{t('app.Articulos.form.tipoPublicacionDefault')}</option>
                        <option key='gold_pro' value='gold_pro'>{t('app.Articulos.form.tipoPublicacionGoldPro')}</option>
                        <option key='gold_special' value='gold_special'>{t('app.Articulos.form.tipoPublicacionGoldSp')}</option>
                        <option key='free' value='free'>{t('app.Articulos.form.tipoPublicacionFree')}</option>
                    </select>
                    <div className='col-md-13 row'>
                        <div className='col-sm-8'>
                            <label htmlFor='metodo_Envio' className='form-label'>{t('app.Articulos.form.metodoEnvio')}</label>
                            <AsyncSelect
                                isClearable
                                className='react-select-placeholder'
                                name='metodo_Envio'
                                getOptionLabel={(option) => option.nombre}
                                getOptionValue={(option) => option.clave_Metodo}
                                defaultOptions
                                value={initialObjectMetodoEnvio}
                                loadOptions={loadDataMetodosEnvio}
                                onChange={(selectedItem) => {
                                    const val = selectedItem === null ? '' : selectedItem?.clave_Metodo;
                                    data.metodo_Envio = val; console.log(val);
                                    setInitialObjectMetodoEnvio(selectedItem);
                                }}
                            />
                        </div>
                        <div className='col-sm-4'>
                            <div className='espacio45px' />
                            <div className='form-check form-switch margin_left_switch'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    name='envio_Gratis'
                                    role='switch'
                                    onChange={onChangeCheckActivo}
                                    checked={data.envio_Gratis === 'S'}
                                />
                                <label htmlFor='envio_Gratis' className='margin_left_switch margin_top_switch_label'>{t('app.Articulos.form.envioGratis')}</label>{' '}
                                &nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                    <div className='col-md-13 row'>
                        <div className='col-sm-6'>
                            <label htmlFor='costo_Envio' className='form-label'>{t('app.Articulos.form.costoEnvio')}</label>
                            <input
                                type='number'
                                className='form-control'
                                name='costo_Envio'
                                maxLength={100}
                                value={data.costo_Envio || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='col-sm-6'>
                            <label htmlFor='costo_Comision' className='form-label'>{t('app.Articulos.form.costoComision')}</label>
                            <input
                                type='number'
                                className='form-control'
                                name='costo_Comision'
                                maxLength={100}
                                value={data.costo_Comision || ''}
                                onChange={handleInputChange}

                            />
                        </div>
                    </div>
                </>}
                {!isAddMode && <div>
                    <div className='row'>
                        <div className='col-6 col-sm-6 col-md-6'>
                        </div>
                        <div className='col-6 col-sm-6 col-md-6 text-right'>
                            {tienda == 'MERCADOLIBRE' && <div className='espacio45px' />}
                            <button
                                className='btn btn-outline-success'
                                onClick={(e) => { setModalValor("asignarPrecio"); openModal(); }}
                                type='button'
                            >
                                &nbsp; Agregar Precio
                            </button>
                        </div>
                        <label>Precios</label>
                        <div style={{ overflowX: 'auto' }}>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>#</th>
                                        <th className='text-center'>Lista Precio</th>
                                        <th className='text-center'>Precio</th>
                                        <th className='text-right' width='25'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {preciosArt?.map((item) => {
                                        return (
                                            <tr key={item.id}>
                                                <td className='text-center'>{item.id}</td>
                                                <td className='text-center'>{item.lista_Precio?.nombre}</td>
                                                <td className='text-center'>{currency(item.precio)}</td>
                                                <td className='text-right' width='25'>
                                                    {item.lista_Precio?.es_Principal ? '' :
                                                        <button
                                                            type='button'
                                                            className='btn'
                                                            style={{ color: 'red' }}
                                                            onClick={(e) => eliminarPrecio(item.id, item.clave_Id)}
                                                        >
                                                            <svg
                                                                xmlns='http://www.w3.org/2000/svg'
                                                                width='25'
                                                                height='25'
                                                                fill='currentColor'
                                                                className='bi bi-x-circle-fill'
                                                                viewBox='0 0 16 16'
                                                            >
                                                                <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                            </svg>
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

const ResumenCostos = ({ data, imagenes, onChangeCostos, tienda, preciosArt }) => {
    const { t } = useTranslation("common");
    const [costos, setCostos] = useState({});
    const GetCosto = (valor) => { return `$ ${valor || '0'} MXN`; }
    function Recalcular() {
        if (!data.publicado) {
            Swal.fire('Error', 'El articulo no esta publicado no se puede realizar la accion', 'error');
            return;
        }

        setCostos({
            sucursal_Id: getDataSession('sucursal'),
            clave_Publicacion: data.clave,
            tipo_Publicacion: data.tipo_Publicacion,
            clave_Categoria: data.clave_Categoria,
            currency: data.currency,
            metodo_Envio: data.metodo_Envio,
            envio_Gratis: data.envio_Gratis,
            costo_Envio: data.costo_Envio,
            comision_Publicar: data.comision_Publicar,
            precio_Base: data.utilidad,
            gananciaVenta: data.utilidad
        });
        fetchConToken('Articulos/CalcularCosto', {
            sucursal_Id: getDataSession('sucursal'),
            clave_Publicacion: data.clave,
            tipo_Publicacion: data.tipo_Publicacion,
            clave_Categoria: data.clave_Categoria,
            currency: data.currency,
            metodo_Envio: data.metodo_Envio,
            envio_Gratis: data.envio_Gratis,
            costo_Envio: data.costo_Envio,
            comision_Publicar: data.comision_Publicar,
            precio_Base: (data.utilidad || data.costo_Unidad)
        }, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    Object.entries(response?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                setCostos(response);
                onChangeCostos(response);
            })
    }
    function Desglosar() {
        if (!data.publicado) {
            Swal.fire('Error', 'El articulo no esta publicado no se puede realizar la accion', 'error');
            return;
        }

        setCostos({
            sucursal_Id: getDataSession('sucursal'),
            clave_Publicacion: data.clave,
            tipo_Publicacion: data.tipo_Publicacion,
            clave_Categoria: data.clave_Categoria,
            currency: data.currency,
            metodo_Envio: data.metodo_Envio,
            envio_Gratis: data.envio_Gratis,
            costo_Envio: data.costo_Envio,
            comision_Publicar: data.comision_Publicar,
            precio_Base: data.costo_Unidad,
            gananciaVenta: data.utilidad
        });
        fetchConToken('Articulos/CalcularGanancia', {
            sucursal_Id: getDataSession('sucursal'),
            clave_Publicacion: data.clave,
            tipo_Publicacion: data.tipo_Publicacion,
            clave_Categoria: data.clave_Categoria,
            currency: data.currency,
            metodo_Envio: data.metodo_Envio,
            envio_Gratis: data.envio_Gratis,
            costo_Envio: data.costo_Envio || '0',
            comision_Publicar: data.costo_Comision,
            precio_Base: data.costo_Unidad
        }, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    Object.entries(response?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                setCostos(response);
                onChangeCostos(response);
            })
    }
    function ActualizarPrecio() {
        if (!data.publicado) {
            Swal.fire('Error', 'El articulo no esta publicado no se puede realizar la accion', 'error');
            return;
        }

        if (data.articulo_Variantes && data.articulo_Variantes.length > 0) {
            data.articulo_Variantes.forEach(e => e.precio = data.costo_Unidad);
        }

        if (preciosArt && preciosArt.length > 0) {
            if (data.preciosPublicacion && data.preciosPublicacion.length > 0) {
                var listaPpal = preciosArt.find(x => x.lista_Precio && x.lista_Precio.es_Principal);
                if (listaPpal) {
                    var precio = data.preciosPublicacion.find(x => x.lista_Precio_Id == listaPpal.id);
                    if (precio) precio.precio = data.costo_Unidad;
                }
            }
        }

        fetchConToken(`Articulos/ActualizarPrecioEcommerce?sucursal_Id=${getDataSession('sucursal')}`, data, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    Object.entries(response?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }

                if (response.hasOwnProperty('clave') && response.clave === data.clave) Swal.fire('Exito', "Precio actualizado con exito", 'success');
            })
    }

    useEffect(() => {
        if (data.clave && data.clave !== '' && data.clave !== '0' && data.publicado) Desglosar();
        else {
            setCostos({
                sucursal_Id: getDataSession('sucursal'),
                clave_Publicacion: data.clave,
                tipo_Publicacion: data.tipo_Publicacion,
                clave_Categoria: data.clave_Categoria,
                currency: data.currency,
                metodo_Envio: data.metodo_Envio,
                envio_Gratis: data.envio_Gratis,
                costo_Envio: data.costo_Envio,
                comision_Publicar: data.comision_Publicar,
                precio_Base: data.costo_Unidad
            });
        }
    }, []);

    return (
        <div className='col-12 col-sm-12 col-md-12'>
            <div className='card'>
                <div className='espacio30px' />
                <div className='col-md-13 row'>
                    <div className='col-sm-1' />
                    <div className='col-sm-10 card-backgground card'>
                        <div className='card-body'>
                            <div className='espacio30px' />
                            <div className='col-md-13 row'>
                                <div className='col-sm-4 card'>
                                    {imagenes?.at(0)?.urlImagen && (<img className='img_art_card' src={imagenes?.at(0)?.urlImagen} alt={imagenes?.at(0)?.clave_Imagen} />)}
                                    {!imagenes?.at(0)?.urlImagen && (<img src={SinImagen} />)}
                                </div>
                                <div className='col-sm-8'>
                                    <div className={data.nombre ? 'text-justify' : 'text-center'}>
                                        <label className='form-label'>{data.nombre || 'Titulo del Articulo'}</label>
                                    </div>
                                    <div className='text-justify'><label className='form-label'>SKU: {data.sku || 'Sin SKU'}</label></div>
                                </div>
                            </div>
                            <div className='espacio15px' />
                            <Divider variant="middle" color='indigo' />
                            <div className='espacio5px' />
                            <div className='form-control-label-11pt'>
                                <RowResumenCosto key={t('app.Articulos.form.precioPublicado')} valorLeft={t('app.Articulos.form.precioPublicado')} valorRight={GetCosto(costos.precio_Base)} />
                                <RowResumenCosto key={t('app.Articulos.form.costoComision')} valorLeft={t('app.Articulos.form.costoComision')} valorRight={GetCosto(costos.comision_Publicar)} />
                                <RowResumenCosto key={t('app.Articulos.form.costoEnvio')} valorLeft={t('app.Articulos.form.costoEnvio')} valorRight={GetCosto(costos.costo_Envio)} />
                                <RowResumenCosto key={t('app.Articulos.form.costoExtraUnidad')} valorLeft={t('app.Articulos.form.costoExtraUnidad')} valorRight={GetCosto(costos.comision_Unidad)} />
                                <div className='espacio5px' />
                                <div className='card-backgground-black card'>
                                    <RowResumenCosto key={t('app.Articulos.form.recibirasXVenta')} valorLeft={t('app.Articulos.form.recibirasXVenta')} valorRight={GetCosto(costos.gananciaVenta)} />
                                    <div className='espacio5px' />
                                </div>
                                <div className='espacio5px' />
                                {tienda == 'MERCADOLIBRE' ?
                                    <div className='text-right'><label className='form-label title2 form-control-label-8pt'>*{t('app.Articulos.form.msgCostoEnvo')}</label></div>
                                    : <div className='espacio5px' />}
                            </div>
                            <Divider variant="middle" color='indigo' />
                            <div className='espacio15px' />
                            {tienda == 'MERCADOLIBRE' ?
                                <div className='text-center'>
                                    <button type='button' className='btn btn-outline-info btn-info-2' onClick={() => Recalcular()}><RefreshIcon />&nbsp;{t('app.botones.recualcular')}</button>
                                    <button type='button' className='btn btn-outline-info btn-info-2' onClick={() => Desglosar()}><TaskIcon />&nbsp;{t('app.botones.desglosar')}</button>
                                    <button type='button' className='btn btn-outline-info btn-info-2' onClick={() => ActualizarPrecio()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                        </svg>&nbsp;Act. Precio</button>
                                </div> :
                                <div className='text-center'>
                                    <button type='button' className='btn btn-outline-info btn-info-2' onClick={() => ActualizarPrecio()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                                        </svg>&nbsp;Act. Precio</button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='col-sm-1' />
                </div>
                <div className='espacio30px' />
            </div>
        </div>
    );
}

const RowResumenCosto = ({ valorLeft, valorRight }) => {
    return (
        <>
            <div className='espacio5px' />
            <div className='col-md-13 row'>
                <div className='col-sm-6'>{valorLeft}</div>
                <div className='col-sm-6 text-right'>{valorRight}</div>
            </div>
        </>
    );
}

export { Costos, ConfiguracionCostos, Impuestos };