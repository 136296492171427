import React, { useState, useEffect } from 'react';
import { fetchConToken, } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { MenuDescargas } from '../MenuDescargas';
import { useGlobalState } from 'resources/GlobalStateContext';
import { alertService } from '_services';
import { useModal } from 'hooks/useModal';
import Modal from 'components/modal/Modal';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es';
import { currency } from 'resources/utilities';
import { descargarArchivo } from 'actions/archivos';

const List = ({ history, match }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [dataMenu] = useState([{ Data: [{ Titulo: 'Descargar Excel', Slug: 'EXCEL' }, { Titulo: 'Descargar PDF', Slug: 'PDF' }] }]);
    const [headerToolbar] = useState({ left: 'prevYear,prev,next,nextYear', center: 'title', right: 'today,dayGridMonth,listWeek,listDay' });
    const [rangeInfoCalendar, setRangeInfoCalendar] = useState(null);
    const [events, setEvents] = useState([]);
    const [saldos, setSaldos] = useState([]);
    const [saldo, setSaldo] = useState(null);
    const [views] = useState({ listDay: { buttonText: 'Dia' }, listWeek: { buttonText: 'Semana' } });
    const [isOpenModal, openModal, closeModal] = useModal(false);

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Saldos Proveedores',
            subtitulo: ''
        });
    };

    const loadEvents = () => {
        if (!rangeInfoCalendar) return;

        fetchConToken(`VxProveedor/SaldosPendienteByProveedor?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${rangeInfoCalendar.startStr}&fecha_fin=${rangeInfoCalendar.endStr}&paginas=0&totalXpagina=10`)
            .then(response => {
                setSaldos([]);
                setEvents([]);
                if (response.hasOwnProperty('status')) {
                    Object.entries(response?.errors).map(([key, value]) => Swal.fire('Error', value[0], 'error'));
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return;
                }

                if (response.data && response.data.length > 0) {
                    let evnts = [];
                    setSaldos(response.data);
                    response.data.forEach(e => evnts.push({ id: e.documento_Id, title: getTitleEvent(e), start: e.fecha_Documento, display: 'block', allDay: true }));
                    setEvents(evnts);
                }
            });
    }

    const onClickButtonMenu = (slug) => {
        if (slug === 'PDF') {
            descargarArchivo(`VxProveedor/DescargarPDFSaldosPendientes?sucursal_Id=${getDataSession('sucursal')}`, 'Facturas Proveedores.pdf');
            return;
        }
        
        if (slug === 'EXCEL') {
            descargarArchivo(`VxProveedor/DescargarExcelSaldosPendientes?sucursal_Id=${getDataSession('sucursal')}`, 'Facturas Proveedores.xlsx');
            return;
        }
    }

    const onClickEvent = (event) => {
        if (event.view.type !== 'dayGridMonth') return;

        var saldo = saldos?.find(x => x.documento_Id == event.event.id);
        if (!saldo) return;

        setSaldo(saldo);
        openModal();
    }

    const handleDates = (rangeInfo) => {
        if (rangeInfo.startStr != rangeInfoCalendar?.startStr) setRangeInfoCalendar(rangeInfo);
    }

    function renderEventContent(eventInfo) {
        var saldo = saldos?.find(x => x.documento_Id == eventInfo.event.id);
        if (eventInfo.view.type !== 'dayGridMonth' && saldo) return renderContent(saldo);

        return (<b>&nbsp; {eventInfo.event.title}</b>);
    }

    function renderContent(saldo, centrado) {
        if (!saldo) return;

        var titulo = saldo.tipo_Documento ? `Folio ${saldo.tipo_Documento}: ${saldo.folio}` : `Folio ${saldo.folio}`;
        return (
            <div className='card'>
                <div className={centrado ? 'espacio15px' : 'espacio5px'} />
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className={centrado ? 'col-sm-4' : 'col-sm-1'} />
                    <div className={centrado ? 'col-sm-7 row' : 'col-sm-10 row'}>
                        <div className={centrado ? 'espacio15px' : 'espacio5px'} />
                        <h5><b>&nbsp;{titulo}</b></h5>
                        <div className={centrado ? 'espacio10px' : 'espacio5px'} />
                        <h5><b>&nbsp;Proveedor: {saldo.proveedor}</b></h5>
                        <div className={centrado ? 'espacio10px' : 'espacio5px'} />
                        <h5><b>&nbsp;Credito Total: {currency(saldo.importe_Acreditado)}</b></h5>
                        <div className={centrado ? 'espacio10px' : 'espacio5px'} />
                        <h5><b>&nbsp;Saldo Pagado: {currency(saldo.saldo_Pagado)}</b></h5>
                        <div className={centrado ? 'espacio10px' : 'espacio5px'} />
                        <h5><b>&nbsp;Saldo Pendiente: {currency(saldo.saldo_Pendiente)}</b></h5>
                        <div className='espacio5px' />
                    </div>
                    <div className='col-sm-1' />
                </div>
                <div className={centrado ? 'espacio15px' : 'espacio5px'} />
            </div>);
    }

    function getTitleEvent(saldo) {
        return saldo.tipo_Documento ? `Folio ${saldo.tipo_Documento}: ${saldo.folio}, Adeudo: ${saldo.saldo_Pendiente}` : `Folio ${saldo.folio}, Adeudo: ${saldo.saldo_Pendiente}`;
    }

    useEffect(() => actualizarTitulo(), []);

    useEffect(() => loadEvents(), [rangeInfoCalendar]);

    return (
        <>
            <MenuDescargas data={dataMenu} onClickButton={onClickButtonMenu} />
            <div className='espacio15px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-1' />
                    <div className='col-sm-10'>
                        <FullCalendar
                            headerToolbar={headerToolbar}
                            plugins={[dayGridPlugin, listPlugin]}
                            events={events}
                            eventContent={renderEventContent}
                            datesSet={handleDates}
                            views={views}
                            eventClick={onClickEvent}
                            locale={esLocale}
                            initialView='dayGridMonth'
                            height={650}
                            editable={false}
                            eventLimit={true}
                            selectable={true}
                            showNonCurrentDates={false}
                        />
                    </div>
                    <div className='col-sm-1' />
                </div>
            </div>
            <div className='espacio5px' />
            <Modal isOpen={isOpenModal} closeModal={closeModal} min>
                {renderContent(saldo, true)}
            </Modal>
        </>
    );
}

export { List };