import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { fetchConToken,fetchSinToken} from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { useGlobalState } from 'resources/GlobalStateContext';

const CfgTienda = ({ history, match, saludo, subtitulo}) => {

  const { path } = match;
  const { globalTitulo, setGlobalTitulo } = useGlobalState();

    let [data, setData] = useState([''])
    const {code} = data;

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    /*
    React.useEffect(()=>{
      async function getData(){
        const response = await fetchConToken("empresas?id="+getDataSession('empresa'));
        //const body = await response.json();
        setData(response);
      }
      getData();
    }, []);
    // eslint-disable-next-line
    

   

  const onChangeCheck = ({target}) => {
    const xCheck = target.checked ? 'S' : 'N';
    setData({
      ...data,
      [target.name]: xCheck
    });
  }
*/


console.log(getDataSession('empresa'));
console.log(getDataSession('sucursal'));

  const handleInputChange = ({ target }) => {
    setData({
        ...data,
        [target.name]: target.value
    });
  }
  const save = () =>{

    setErrores({});
    setError('');

    fetchConToken(`ConfiguracionesSucursales/ObtenerToken?Code=${code}&sucursal_id=${getDataSession('sucursal')}`)
        .then((response) => {

          if (response.hasOwnProperty('status') && response.status === 400) {
            var erroresC = {};
            Object.entries(response.errors).map(([key, value]) => {
                erroresC[key] = value[0];
                setErrores(erroresC);
            });
            setError('Revisar errores');
            return false;
        } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
            setError(response.mensajes[0]);
            return false;
        }
            alertService.success('La cambios se han guardado correctamente.', { keepAfterRouteChange: true, autoClose: true });
        })
        .catch(alertService.error);
  }
  const actualizarTitulo = () => {
    setGlobalTitulo({
      ...globalTitulo, 
      titulo: 'Tienda',
      subtitulo: ''
    });
  };
  React.useEffect(() => {actualizarTitulo();},[]);

    return (
        <>
 <div className="container-fluid" >
         <div className="mb-3" >
            <div className="row" style={{textAlign: "left"}}>
                <div className="col-sm-6  col-md-6">
                <h3>Tienda</h3>
                </div>
                <div className="col-sm-6  col-md-6" style={{textAlign: "right"}}> 
                 <button className="btn btn-outline-success" type="button" onClick={save}>
                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-lg" viewBox="0 0 16 16">
                         <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
                        </svg>
                        &nbsp;
                        Guardar 
                  </button>
                </div>
            </div>              
          </div>

          <div className="row">
            <div className="col-md-12" style={{display: "grid"}}>
                <p style={{ color: 'red' }}>{error}</p>
                {Object.entries(errores).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                })}
            <div className="card mb-3 ">
                <div className="card-body text-start">
                   <div className="card-title">
                    <h5>Obtener codigo de mercado libre</h5>
                   </div>

                    <a href='https://auth.mercadolibre.com.mx/authorization?response_type=code&client_id=2029702860848059&redirect_uri=https://atti.com.mx/codemeli.php' target="_blank">Mercado libre code </a>
                </div>
                </div>
                <div className="card mb-3 ">
                <div className="card-body text-start">
                   <div className="card-title">
                    <h5>Configuracion de tu tienda</h5>
                   </div>

                    <label htmlFor="code" className="form-label">Codigo de acceso de mercado libre</label>
                    <input type="text" className="form-control" name="code" value={code} onChange={ handleInputChange }/>

                  

                </div>
                <div>
            </div>
          </div>
          </div>
 
          </div> 
        </div>
        </>
    );    


}

CfgTienda.propTypes = {
    saludo: PropTypes.string
}

CfgTienda.defaultProps = {
    subtitulo: "Soy subtitulo"
}

export { CfgTienda };