import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Link, useHistory } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import {
    IconAgents,
    IconArticles,
    IconContacts,
    IconIdeas,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets
} from 'assets/icons';
import { convertSlugToUrl } from 'resources/utilities';
//import LogoComponent from './LogoComponent';
import SubMenuComponent from './SubMenuComponent';
import SubMenuItemComponent from './SubMenuItemComponent';
import { getDataSession, setDataSession, removeUserSession } from 'views/Utils/Common';

import { useSidebar } from 'hooks/useSidebar';

import Crear_factura from 'images/panel/menu/menu_light/crear_factura.svg';
import Fact_emitidas from 'images/panel/menu/menu_light/facturas_emitidas.svg';
import Fact_recibidas from 'images/panel/menu/menu_light/facturas_recibidas.svg';
import Desc_masiva from 'images/panel/menu/menu_light/descarga_masiva.svg';
import Estadisticas from 'images/panel/menu/menu/estadisticas.svg';
import IngresosDiarios from 'images/panel/menu/menu/ic_ingresos_diarios.svg';
import IngresosMensuales from 'images/panel/menu/menu/ic_ingresos_mensuales.svg';
import EgresosMensuales from 'images/panel/menu/menu/ic_egresos_mensuales.svg';
import IngresosEgresos from 'images/panel/menu/menu/ic_ingresos_egresos.svg';
import TopClientes from 'images/panel/menu/menu/ic_top_clientes.svg';
import TopProveedores from 'images/panel/menu/menu/ic_top_proveedores.svg';


import Articulos from 'images/panel/menu/menu_light/articulos.svg';
import Bolsa_timbres from 'images/panel/menu/menu_light/bolsa_timbres.svg';
import Clientes from 'images/panel/menu/menu_light/clientes.svg';
import Impuestos from 'images/panel/menu/menu_light/impuestos.svg';
import Movimientos from 'images/panel/menu/menu_light/movimientos.svg';
import Pagos from 'images/panel/menu/menu_light/pagos.svg';
import Paquetes from 'images/panel/menu/menu_light/paquetes.svg';
import Usuarios from 'images/panel/menu/menu_light/usuarios.svg';



import facturacionImagen from 'images/panel/menu/facturacionImagen.svg';
import icon from 'images/panel/menu/Iconbtn.svg';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

//const baseUrl = process.env.REACT_APP_API_URL;
let lista = [];

const baseUrl = process.env.REACT_APP_TIPO_SISTEMA;


if (baseUrl == 'VXRUTA') {
    lista = [
        {
            menu: 'Catalogos',
            id: SLUGS.vxgruporutas,
            title: 'Grupo Rutas',
            imagen: Usuarios,
            onClick: SLUGS.vxgruporutas,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.vxrutas,
            title: 'Rutas',
            imagen: Usuarios,
            onClick: SLUGS.vxrutas,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Movimientos',
            id: SLUGS.vxpedidos,
            title: 'Pedidos',
            imagen: Usuarios,
            onClick: SLUGS.vxpedidos,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Movimientos',
            id: SLUGS.vxventas,
            title: 'Ventas',
            imagen: Usuarios,
            onClick: SLUGS.vxventas,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Movimientos',
            id: SLUGS.vxsaldosproveedores,
            title: 'Saldos proveedores',
            imagen: Usuarios,
            onClick: SLUGS.vxsaldosproveedores,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Movimientos',
            id: SLUGS.vxfacturas,
            title: 'Control de Facturas',
            imagen: Usuarios,
            onClick: SLUGS.vxfacturas,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Movimientos',
            id: SLUGS.vxplanvisita,
            title: 'Planes de Visita',
            imagen: Usuarios,
            onClick: SLUGS.vxplanvisita,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Movimientos',
            id: SLUGS.vxotrosdocumentos,
            title: 'Otros Documentos',
            imagen: Usuarios,
            onClick: SLUGS.vxotrosdocumentos,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
         //Ventas
         {
            menu: 'Ventas',
            id: SLUGS.cotizaciones,
            title: 'Cotizaciones',
            imagen: Usuarios,
            onClick: SLUGS.cotizaciones,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Ventas',
            id: SLUGS.pedidos,
            title: 'Pedidos',
            imagen: Usuarios,
            onClick: SLUGS.pedidos,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Ventas',
            id: SLUGS.remisiones,
            title: 'Remisiones',
            imagen: Usuarios,
            onClick: SLUGS.remisiones,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Ventas',
            id: SLUGS.facturas,
            title: 'Facturas',
            imagen: Usuarios,
            onClick: SLUGS.facturas,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
                 //Compras
                 {
                    menu: 'Compras',
                    id: SLUGS.ordencompra,
                    title: 'Orden de compra',
                    imagen: Usuarios,
                    onClick: SLUGS.ordencompra,
                    ADMINISTRADOR: 'ADMINISTRADOR',
                    EMPRESA: 'EMPRESA',
                    SUCURSAL: 'SUCURSAL'
                },
                {
                    menu: 'Compras',
                    id: SLUGS.recepcioncompra,
                    title: 'Recepción de mercancía',
                    imagen: Usuarios,
                    onClick: SLUGS.recepcioncompra,
                    ADMINISTRADOR: 'ADMINISTRADOR',
                    EMPRESA: 'EMPRESA',
                    SUCURSAL: 'SUCURSAL'
                },
                {
                    menu: 'Compras',
                    id: SLUGS.comprasList,
                    title: 'Compras',
                    imagen: Usuarios,
                    onClick: SLUGS.comprasList,
                    ADMINISTRADOR: 'ADMINISTRADOR',
                    EMPRESA: 'EMPRESA',
                    SUCURSAL: 'SUCURSAL'
                },
        //Cuentas por cobrar
        {
            menu: 'Cuentas_X_cobrar',
            id: SLUGS.vxCobros,
            title: 'Cargos',
            imagen: Usuarios,
            onClick: SLUGS.vxCobros,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Cuentas_X_cobrar',
            id: SLUGS.vxCreditos,
            title: 'Creditos',
            imagen: Usuarios,
            onClick: SLUGS.vxCreditos,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Cuentas_X_cobrar',
            id: SLUGS.vxotrosdocumentos,
            title: 'Devoluciones',
            imagen: Usuarios,
            onClick: SLUGS.vxotrosdocumentos,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        //Cuentas por pagar
        {
            menu: 'Cuentas_X_pagar',
            id: SLUGS.vxCargos,
            title: 'Cargos',
            imagen: Usuarios,
            onClick: SLUGS.vxCargos,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Cuentas_X_pagar',
            id: SLUGS.vxcreditosProveedores,
            title: 'Creditos',
            imagen: Usuarios,
            onClick: SLUGS.vxcreditosProveedores,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Cuentas_X_pagar',
            id: SLUGS.vxsaldosproveedores,
            title: 'Prox. Facturas',
            imagen: Usuarios,
            onClick: SLUGS.vxsaldosproveedores,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Cuentas_X_pagar',
            id: SLUGS.vxotrosdocumentos,
            title: 'Devoluciones',
            imagen: Usuarios,
            onClick: SLUGS.vxotrosdocumentos,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'ConfigAppMaxventas',
            id: SLUGS.ConfigAppMaxventas,
            title: 'Config.App Maxventas',
            imagen: Usuarios,
            onClick: SLUGS.ConfigAppMaxventas,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        }

        
    ];
}
else {

    lista = [ /*
        {
            menu: 'Articulos',
            id: SLUGS.articulos,
            title: 'Listado',
            imagen: Articulos,
            onClick: SLUGS.articulos,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        }, 
        {
            menu: 'Articulos',
            id: SLUGS.diferencias,
            title: 'Comparativa',
            imagen: Articulos,
            onClick: SLUGS.diferencias,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        }, 
        
         {
            menu: 'Articulos',
            id: SLUGS.publicadormasivo,
            title: 'Publicador masivo',
            imagen: Articulos,
            onClick: SLUGS.publicadormasivo,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        }, 
         {
            menu: 'Articulos',
            id: SLUGS.asignacionautomatica,
            title: 'Asignación automática',
            imagen: Articulos,
            onClick: SLUGS.asignacionautomatica,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        }, 
        {
            menu: 'Ordenes',
            id: SLUGS.cotizaciones,
            title: 'Cotizaciones',
            imagen: Movimientos,
            onClick: SLUGS.cotizaciones,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Ordenes',
            id: SLUGS.ordenes,
            title: 'Pedidos',
            imagen: Movimientos,
            onClick: SLUGS.ordenes,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Ordenes',
            id: SLUGS.remisiones,
            title: 'Remisiones',
            imagen: Movimientos,
            onClick: SLUGS.remisiones,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
         */
        {
            menu: 'Mensajes',
            id: SLUGS.mensajes,
            title: 'Preguntas',
            imagen: Crear_factura,
            onClick: SLUGS.mensajes,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Mensajes',
            id: SLUGS.lognotificaciones,
            title: 'Notificaciones sistema',
            imagen: Desc_masiva,
            onClick: SLUGS.lognotificaciones,
            EMPRESA: 'EMPRESA',
        },
        {
            menu: 'Mensajes',
            id: SLUGS.respuestasMensajes,
            title: 'Respuestas automáticas',
            imagen: Desc_masiva,
            onClick: SLUGS.respuestasMensajes,
            EMPRESA: 'EMPRESA',
        },
        {
            menu: 'Mensajes',
            id: SLUGS.tiposMensajes,
            title: 'Tipos mensajes',
            imagen: Desc_masiva,
            onClick: SLUGS.tiposMensajes,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
        },
        {
            menu: 'Compras',
            id: SLUGS.ordencompra,
            title: 'Ordenes compras',
            imagen: Movimientos,
            onClick: SLUGS.ordencompra,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Compras',
            id: SLUGS.recepcioncompra,
            title: 'Recepcion compras',
            imagen: Movimientos,
            onClick: SLUGS.recepcioncompra,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Compras',
            id: SLUGS.comprasList,
            title: 'Compras',
            imagen: Movimientos,
            onClick: SLUGS.comprasList,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.impuestos,
            title: 'Impuestos',
            imagen: Impuestos,
            onClick: SLUGS.impuestos,
            ADMINISTRADOR: 'ADMINISTRADOR'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.categorias,
            title: 'Categorias',
            imagen: Impuestos,
            onClick: SLUGS.categorias,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.almacenes,
            title: 'Almacenes',
            imagen: Articulos,
            onClick: SLUGS.almacenes,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.listaprecios,
            title: 'Lista Precios',
            imagen: Articulos,
            onClick: SLUGS.listaprecios,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.clientes,
            title: 'Clientes',
            imagen: Clientes,
            onClick: SLUGS.clientes,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.proveedores,
            title: 'Proveedores',
            imagen: Clientes,
            onClick: SLUGS.proveedores,
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        },

        {
            menu: 'Catalogos',
            id: SLUGS.usuarios,
            title: 'Usuarios',
            imagen: Usuarios,
            onClick: SLUGS.usuarios,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.condicionpago,
            title: 'Condicion Pago',
            imagen: Usuarios,
            onClick: SLUGS.condicionpago,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.empresas,
            title: 'Empresas',
            imagen: Usuarios,
            onClick: SLUGS.empresas,
            ADMINISTRADOR: 'ADMINISTRADOR',
        },
        {
            menu: 'Catalogos',
            id: SLUGS.rolesclaves,
            title: 'Roles Claves',
            imagen: Usuarios,
            onClick: SLUGS.rolesclaves,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA'
        },
        {
            menu: 'Catalogos',
            id: SLUGS.vxgruporutas,
            title: 'Grupo Rutas',
            imagen: Usuarios,
            onClick: SLUGS.vxgruporutas,
            ADMINISTRADOR: 'ADMINISTRADOR',
            EMPRESA: 'EMPRESA',
            SUCURSAL: 'SUCURSAL'
        }];
}


function SidebarOptionsComponent({ menu }) {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1300;










    async function logout() {
        removeUserSession();
        push('..'); //SLUGS.login);
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    function renderSwitch() {
        var rol = getDataSession('rol');
        return lista.map((item) => {
            return (item.menu === menu && item.hasOwnProperty(rol)) ? (
                <SubMenuItemComponent
                    key={item.id + "sub"}
                    id={item.id}
                    title={item.title}
                    icon={IconTickets}
                    onClick={() => onClick(item.id)}
                />
            ) : (
                ''
            );
        });
    }

    function facturar() {
        setDataSession('menu', 'Facturacion');
    }

    return (
        <>

            {renderSwitch()}

        </>
    );
}

export default SidebarOptionsComponent;
