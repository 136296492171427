import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import {useLocation} from 'react-router';
import { useGlobalState } from 'resources/GlobalStateContext';
const MercadoLibre = ({ history, match, saludo, subtitulo }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let {search} = useLocation();
    let query = new URLSearchParams(search);
    let code = query.get('code');
    const  id  = getDataSession('sucursalMercadoLibre');

    console.log(id)
    let [error, setError] = useState(['']);
    let [success, setSuccess] = useState(['']);

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Empresa',
          subtitulo: ''
        });
      };
    useEffect(() => {
        saveCode(); 
        actualizarTitulo();
    },[]);


    const saveCode = () =>{
        fetchConToken(`ConfiguracionesSucursales/ObtenerToken?Code=${code}&sucursal_id=${id}`)
            .then((response) => {
                setError('');
                if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    setError(response.mensajes[0]+"\r\n Intentelo mas tarde");
                    return;
                }
                setSuccess('La cambios se han guardado correctamente. \r\n Puede cerrar la ventana');
            })
            .catch(alertService.error);
    }
    
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className='card mb-3 '>
                            <div className='card-body text-start'>
                                <div className='card-title text-center'>
                                    <h5>Confirmando su autorizacion de</h5>
                                    <h2>Mercado Libre</h2>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 text-center'>
                                     {code}
                                    </div>
                                   
                                </div>
                            </div>
                            <div className='text-center'>
                            <h3 style={{ color: 'red' }}>{error}</h3>
                            <h3 style={{ color: 'blue' }}>{success}</h3>
                            </div>
                        </div>                      
                    </div>
                </div>
            </div>
        </>
    );
};


export { MercadoLibre };
