import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { currency } from 'resources/utilities';
import Pagination from 'components/pagination/Pagination';
import { AddEditDireccion } from './AddEditDireccion';
import ListTable from 'components/table/ListTable';

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import AsyncSelect from 'react-select/async';
import RenderButtons from 'components/table/RenderButtons';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ImageIcon from '@mui/icons-material/Image';
import Settings from '@mui/icons-material/Settings';

import Swal from 'sweetalert2';

const AddEdit = ({ history, match }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const { id } = match.params;
    const path = match.url;
    const isAddMode = !id;
    const { register, handleSubmit, reset } = useForm();

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    let [cp, setCp] = useState('');
    let [dir_id, setDir_id] = useState(0);
    let [artid, setArtid] = useState(0);
    let [artSelect, setArtSelect] = useState(0);
    let [listaPrecios, setListaPrecios] = useState([]);
    let [condicionPago, setCondicionPago] = useState([]);
    const [initialAgente, setInitialAgente] = useState(true);
    const [valueTab, setValueTab] = useState(0);
    let [disableTab, setDisableTab] = useState(true);
    let [actualizarTabla, setActualizarTabla] = useState(false);
    let [cliente, setCliente] = useState({
        sucursal_Id: getDataSession('sucursal'),
        empresa_Id: getDataSession('empresa'),
        nombre: '',
        rfc: '',
        estatus: 'A',
        no_Cliente: '',
        uso_Cfdi: 'G01',
        regimen_Fiscal_Receptor: '601',
        lista_Precio_Id: null
    });

    /*
    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }*/
    
        const handleChangeTab = (event, newValue) => {
            setValueTab(newValue);
        };

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo,
          titulo: isAddMode ? 'Agregar Cliente' : 'Editar Cliente',
          subtitulo: '' 
        });
      };

    function onSubmit() {
        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        if(!(cliente.limite_Credito>0))
            cliente.limite_Credito = 0;
        if (cliente.usuario_Id === null || cliente.usuario_Id === "") 
            cliente.usuario_Id = 0;
        return isAddMode ? create(cliente) : update(id, cliente);
    }

    function obtenerUsuario() {
        fetchConToken('clientes?id=' + id + '&sucursal_id=' + getDataSession('sucursal')).then(
            (cte) => {
                if (cte.hasOwnProperty('status')) {
                    Object.entries(cte?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (cte.hasOwnProperty('codigo')) {
                    Swal.fire('Error', cte.mensajes[0], 'error');
                    return;
                }
                cte.estatus = cte.estatus === '' ? 'A' : cte.estatus;
                cte.uso_cfdi = cte.uso_cfdi === '' ? 'G01' : cte.uso_cfdi;
                cte.regimen_fiscal_receptor =
                    cte.regimen_fiscal_receptor === '601' ? '' : cte.regimen_fiscal_receptor;
                setCliente(cte);
                setCp(cte.clientes_Direcciones[0].cp);
                setDir_id(cte.clientes_Direcciones[0].id);

                if(cte.usuario_Id)
                    CargarAgenteId(cte.usuario_Id);
            }
        );
    }

    function listasPrecios() {
        fetchConToken(`ListaPrecios/List?sucursal_Id=${getDataSession('sucursal')}`).then(
            (response) => setListaPrecios(response)
        );
    }

    function condicionesPagos() {
        fetchConToken(`VxCondicionPagos/List?sucursal_Id=${getDataSession('sucursal')}`).then(
            (response) => setCondicionPago(response)
        );
    }

    



    async function eliminar() {
        Swal.fire({
            title: '¿Desea eliminar este cliente?',
		icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(`clientes?cliente_id=${id}`, null, 'DELETE')
                .then((response) => {
                    if (response.hasOwnProperty('status')) {
                        Object.entries(response?.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                            return;
                        });
                        return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }
                    Swal.fire('Exito', 'Cliente eliminado', 'success');
                    history.push('..');
                })
            }})
    }

    function create(data) {
        console.log('create');
        console.log(data);
        data.no_Cliente = 'local';
        setErrores({});
        setError('');

        return fetchConToken('clientes?sucursal_Id=' + getDataSession('sucursal'), data, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        //setErrores(erroresA);
                        return false;
                    });
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Cliente agregado', 'success');

                var direccion = {cp: cp,cliente_Id: response.id,no_Direccion: "local"};
                fetchConToken('DireccionesClientes', direccion, 'POST')
                .then((response) => {
      
                  if (response.hasOwnProperty('status') ) {
                      var erroresA = {};
                      Object.entries(response.errors).map(([key, value]) => {
                          Swal.fire('Error', value[0], 'error');
                          return;
                      });
                      return false;
                  } else if (response.hasOwnProperty('codigo') ) {
                      Swal.fire('Error', response.mensajes[0], 'error');
                      return false;
                  }      
                })
                //.catch(alertService.error);

                history.push('.');
                //alertService.success('Cliente agregado', { keepAfterRouteChange: true });
                setCliente(response);
            })
            .catch(alertService.error);
    }


    function update(id, data) {
        setErrores({});
        setError('');

        return fetchConToken('clientes', data, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        //erroresA[key] = value[0];
                        //setErrores(erroresA);
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error'); //setError(response.mensajes[0]);
                    return false;
                }

                var direccion = {cp: cp,cliente_Id: response.id,no_Direccion: "local",id: dir_id};
                fetchConToken('DireccionesClientes', direccion, 'PUT')
                .then((response) => {
      
                  if (response.hasOwnProperty('status') && response.status === 400) {
                      var erroresA = {};
                      Object.entries(response.errors).map(([key, value]) => {
                          Swal.fire('Error', value[0], 'error');
                      });
                      return false;
                  } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                      Swal.fire('Error', response.mensajes[0], 'error');
                      return false;
                  }
                })
                
                //alertService.success('Cliente modificado', { keepAfterRouteChange: true });
                Swal.fire('Exito', 'Cliente modificado', 'success');
                history.push('..');
            })
            .catch(alertService.error);
    }

    function handleInputChangeCliente(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 'A' : 'I') : target.value;
        const name = target.name;

        console.log(target);
        console.log(value);
        console.log(name);
        setCliente({
            ...cliente,
            [name]: value
        });
    }

    function handleInputChangeDireccion(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 'A' : 'I') : target.value;
        const name = target.name;
        setCp(value);
    }
    
    /*const EditarDireccion = (id_imp) => {
    fetchConToken(`clientes?id=${id_imp}`, null, 'DELETE').then(function (response) {
        if (response.status === 400) {
            alertService.error('Revisar muestra de errores');
            return;
        }
        if (response.status === 400) {
            alertService.error(response.mensajes[0]);
            return;
        }
        alertService.success('Impuesto Eliminado', { keepAfterRouteChange: true });
        const arrayFiltrado = impuestos.filter((impuesto) => impuesto.id !== id_imp);
        setImpuestos(arrayFiltrado);
    });
  };
  */
 
  const loadDataUsuarios = async (query) =>
    await fetchConToken(
        `Usuarios/GetList?empresa_id=${getDataSession('empresa')}&paginas=0&totalXpagina=50&busqueda=${query}&tipoUsuario=CLIENTE&estatus=ACTIVOS`
    ).then((resp) => {
            return resp.data;
        })
        .catch((e) => {
            Swal.fire(
                'Error',
                `Ocurrio un error al obtener usuarios ${query}`,
                'error'
            );
        });

        function CargarAgenteId(id){
            fetchConToken(
                `Usuarios?id=${id}&empresa_id=${getDataSession('empresa')}`
             ).then((resp) => {
                setInitialAgente(resp);
                })
         }

    useEffect(() => {
        if (!isAddMode) {
            obtenerUsuario();
        } else {
            if (getDataSession('sucursal') === '0') {
                Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
                return;
            }
        }
        listasPrecios();
        condicionesPagos();
        actualizarTitulo();
    }, []);

    function eliminar(arti_id){
        return fetchConToken('clientes/Favoritos?articulo_id='+arti_id+"&cliente_id="+id, null, 'DELETE')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    //setErrores(erroresA);
                    return false;
                });
            } else if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setActualizarTabla(true);
            Swal.fire('Exito', 'Articulo Eliminado', 'success');
            setActualizarTabla(false);
        })
        .catch(alertService.error);
    }

    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path+"/Favoritos",opciones: "eliminar",eliminar: eliminar},
        {
            field: 'id', headerName: 'Id', maxWidth: 20, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'clave', headerName: 'Clave', width: 100, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'sku', headerName: 'SKU', width: 100, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'nombre', headerName: 'Nombre', width: 500, renderCell: (params) => (
                <a href={`${path + '/edit/' + params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'url', headerName: 'URL', width: 100, renderCell: (params) => (
                <a href={`${params.value}`}>{params.value}</a>)
        },
        { field: 'tiene_Variante', headerName: 'Variantes' },
        { field: 'estatus', headerName: 'Estatus' }
    ];

    const loadArticulos = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Articulos/GetList?paginas=0&totalXpagina=50&busqueda=${query}&cliente_id=${id}&sucursal_Id=${getDataSession(
                'sucursal'
            )}`
        );
        return resp?.data;
    };


    function agregarArticulo()
    {

        var cliente_articulo = {
            cliente_Id: id,
            articulo_Id: artid
        };

        return fetchConToken('clientes/Favoritos', cliente_articulo, 'POST')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    //setErrores(erroresA);
                    return false;
                });
            } else if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setActualizarTabla(true);
            setArtSelect(null);
            Swal.fire('Exito', 'Articulo agregado', 'success');
            setActualizarTabla(false);
        })
        .catch(alertService.error);
    }



    return (
        <>
            <div className='container-fluid '>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                    <div className='row' style={{ textAlign: 'left' }}>
                    
             
                        <div className='col-md-12' style={{ textAlign: 'left' }}>
                                    {!isAddMode ? (
                                        <button
                                            className='btn btn-outline-danger'
                                            type='button'
                                            style={{ float: 'left' }}
                                            onClick={() => eliminar()}
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='20'
                                                height='20'
                                                fill='currentColor'
                                                className='bi bi-x-lg'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                                            </svg>
                                            &nbsp; &nbsp; Eliminar
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                    &nbsp;
                                    <button className='btn btn-outline-success' type='submit'>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; Guardar
                                    </button>
                                    &nbsp;
                                    <Link
                                        to={isAddMode ? '.' : '..'}
                                        className='btn btn-outline-secondary'
                                    >
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-reply'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                        </svg>
                                        &nbsp; Regresar &nbsp;
                                    </Link>
                                
                            </div>
                        </div>
                    </div>

                    <div className=''>
                    <Tabs
                            value={valueTab}
                            onChange={handleChangeTab}
                            variant='scrollable'
                            scrollButtons='auto'
                        >
                            <Tab
                                icon={<Settings />}
                                iconPosition='start'
                                aria-label='phone'
                                label="General"
                            />
                            {!isAddMode ?
                            <Tab
                                icon={<ImageIcon />}
                                iconPosition='start'
                                aria-label='phone'
                                label="Articulos"
                            /> : ''}
                        </Tabs>

                        {valueTab === 0 && (
                            <div className='col-md-12' style={{ display: 'grid', float: 'left' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start row'>
                                    <div className='col-md-12'>
                                        <label htmlFor='nombre'>Nombre / Razón Social *:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='nombre'
                                            value={cliente.nombre || ''}
                                            onChange={handleInputChangeCliente}
                                            required
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='rfc'>RFC*:</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='rfc'
                                            value={cliente.rfc || ''}
                                            onChange={handleInputChangeCliente}
                                            required
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='uso_Cfdi'>Uso CFDI*</label>
                                        <select
                                            className='form-control'
                                            name='uso_Cfdi'
                                            value={cliente.uso_Cfdi}
                                            onChange={handleInputChangeCliente}
                                        >
                                            <option value='G01'>
                                                G01-Adquisición de mercancías
                                            </option>
                                            <option value='G02'>
                                                G02-Devoluciones, descuentos o bonificaciones
                                            </option>
                                            <option value='G03'>G03-Gastos en general</option>
                                            <option value='I01'>I01-Construcciones</option>
                                            <option value='I02'>
                                                I02-Mobiliario y equipo de oficina por inversiones
                                            </option>
                                            <option value='I03'>I03-Equipo de transporte</option>
                                            <option value='I04'>
                                                I04-Equipo de cómputo y accesorios
                                            </option>
                                            <option value='I05'>
                                                I05-Dados, troqueles, moldes, matrices y herramental
                                            </option>
                                            <option value='I06'>
                                                I06-Comunicaciones telefónicas
                                            </option>
                                            <option value='I07'>
                                                I07-Comunicaciones satelitales
                                            </option>
                                            <option value='I08'>
                                                I08-Otra maquinaria y equipo
                                            </option>
                                            <option value='D01'>
                                                D01-Honorarios médicos, dentales y gastos
                                                hospitalarios
                                            </option>
                                            <option value='D02'>
                                                D02-Gastos médicos por incapacidad o discapacidad
                                            </option>
                                            <option value='D03'>D03-Gastos funerales</option>
                                            <option value='D04'>D04-Donativos</option>
                                            <option value='D05'>
                                                D05-Intereses reales efectivamente pagados por
                                                créditos hipotecarios (casa habitación)
                                            </option>
                                            <option value='D06'>
                                                D06-Aportaciones voluntarias al SAR
                                            </option>
                                            <option value='D07'>
                                                D07-Primas por seguros de gastos médicos
                                            </option>
                                            <option value='D08'>
                                                D08-Gastos de transportación escolar obligatoria
                                            </option>
                                            <option value='D09'>
                                                D09-Depósitos en cuentas para el ahorro, primas que
                                                tengan como base planes de pensiones
                                            </option>
                                            <option value='D10'>
                                                D10-Pagos por servicios educativos (colegiaturas)
                                            </option>
                                            <option value='P01'>P01-Por definir</option>
                                        </select>
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='regimen_Fiscal_Receptor'>
                                            Regimen Fiscal*
                                        </label>
                                        <select
                                            className='form-control'
                                            name='regimen_Fiscal_Receptor'
                                            value={cliente.regimen_Fiscal_Receptor}
                                            onChange={handleInputChangeCliente}
                                        >
                                            <option value='601'>
                                                601-General de Ley Personas Morales
                                            </option>
                                            <option value='603'>
                                                603-Personas Morales con Fines no Lucrativos
                                            </option>
                                            <option value='605'>
                                                605-Sueldos y Salarios e Ingresos Asimilados a
                                                Salarios
                                            </option>
                                            <option value='606'>606-Arrendamiento</option>
                                            <option value='607'>
                                                607-Régimen de Enajenación o Adquisición de Bienes
                                            </option>
                                            <option value='608'>608-Demás ingresos</option>
                                            <option value='609'>609-Consolidación</option>
                                            <option value='610'>
                                                610-Residentes en el Extranjero sin Establecimiento
                                                Permanente en México
                                            </option>
                                            <option value='611'>
                                                611-Ingresos por Dividendos (socios y accionistas)
                                            </option>
                                            <option value='612'>
                                                612-Personas Físicas con Actividades Empresariales y
                                                Profesionales
                                            </option>
                                            <option value='614'>614-Ingresos por intereses</option>
                                            <option value='615'>
                                                615-Régimen de los ingresos por obtención de premios
                                            </option>
                                            <option value='616'>
                                                616-Sin obligaciones fiscales
                                            </option>
                                            <option value='620'>
                                                620-Sociedades Cooperativas de Producción que optan
                                                por diferir sus ingresos
                                            </option>
                                            <option value='621'>621-Incorporación Fiscal</option>
                                            <option value='622'>
                                                622-Actividades Agrícolas, Ganaderas, Silvícolas y
                                                Pesqueras
                                            </option>
                                            <option value='623'>
                                                623-Opcional para Grupos de Sociedades
                                            </option>
                                            <option value='624'>624-Coordinados</option>
                                            <option value='625'>
                                                625-Régimen de las Actividades Empresariales con
                                                ingresos a través de Plataformas Tecnológicas
                                            </option>
                                            <option value='626'>
                                                626-Régimen Simplificado de Confianza
                                            </option>
                                            <option value='628'>628-Hidrocarburos</option>
                                            <option value='629'>
                                                629-De los Regímenes Fiscales Preferentes y de las
                                                Empresas Multinacionales
                                            </option>
                                            <option value='630'>
                                                630-Enajenación de acciones en bolsa de valores
                                            </option>
                                        </select>
                                    </div>

                                    <div className='col-md-6'>
                                        <label htmlFor='correo'>Codigo postal*</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='cp'
                                            value={cp || ''}
                                            onChange={handleInputChangeDireccion}
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <label htmlFor='correo'>Correo*</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='correo'
                                            value={cliente.correo || ''}
                                            onChange={handleInputChangeCliente}
                                            required
                                        />
                                    </div>
                                    <div className='col-md-6'>
                                        <br></br>
                                    <div className='form-check form-switch'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            name='estatus'
                                            role='switch'
                                            onChange={handleInputChangeCliente}
                                            checked={cliente.estatus === 'A'}
                                        />
                                        <label htmlFor='estatus'>Estatus</label> &nbsp;&nbsp;
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='col-md-12'>
                                        <label htmlFor='correo'>Lista de Precio</label>
                                        <select
                                            className='form-control'
                                            name='lista_Precio_Id'
                                            value={cliente.lista_Precio_Id}
                                            onChange={handleInputChangeCliente}
                                        >
                                             <option key={0} value='0'>
                                                     Sin lista de precio
                                                </option>
                                                {listaPrecios?.map((item) => {

                                                    if(item.id === cliente.lista_Precio_Id)
                                                        return (
                                                            <option key={item.id} value={item.id} selected>
                                                                {item.nombre}
                                                            </option>
                                                        );
                                                    else
                                                        return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.nombre}
                                                            </option>
                                                        );
                                                })}
                                        </select>
                                        </div>
                                        <div className='col-md-12'>
                                        <label htmlFor='correo'>Usuario</label>
                                            <AsyncSelect
                                                    isClearable
                                                    className='react-select-placeholder'
                                                    getOptionLabel={(option) =>
                                                        option.nombre + "(" + option.correo+")"
                                                    }
                                                    getOptionValue={(option) => option.id}
                                                    defaultOptions
                                                    value={initialAgente}
                                                    loadOptions={loadDataUsuarios}
                                                    onChange={(selectedItem) => {
                                                        const val =
                                                            selectedItem === null
                                                                ? ''
                                                                : selectedItem?.id;
                                                                cliente.usuario_Id = val;
                                                        setInitialAgente(selectedItem);
                                                    }}
                                                />
                                        </div>
                                    </div>
                                    
                                    <div className='col-md-6'>
                                        <div className='col-md-12'>
                                        <label htmlFor='correo'>Condicion de Pago</label>
                                        <select
                                            className='form-control'
                                            name='condicion_Pago_Id'
                                            value={cliente.condicion_Pago_Id}
                                            onChange={handleInputChangeCliente}
                                        >
                                             <option key={0} value='0'>
                                                     Sin Condicion Pago
                                                </option>
                                                {condicionPago?.map((item) => {

                                                    if(item.id === cliente.condicion_Pago_Id)
                                                        return (
                                                            <option key={item.id} value={item.id} selected>
                                                                {item.nombre}
                                                            </option>
                                                        );
                                                    else
                                                        return (
                                                            <option key={item.id} value={item.id}>
                                                                {item.nombre}
                                                            </option>
                                                        );
                                                })}
                                        </select>
                                        </div>
                                        <div className='col-md-12'>
                                        <label htmlFor='correo'>Limite de Credito</label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            name='limite_Credito'
                                            value={cliente.limite_Credito || ''}
                                            onChange={handleInputChangeCliente}
                                        />
                                        </div>
                                        <b>Saldo Disponible:</b><label>{cliente.saldo_Cliente?.credito_Disponible}</label>
                                    </div>

                                    <div className=' col-12 col-sm-12 col-md-12 text-right'>
                                        <br></br>
                                        {cliente.id > 0 ? (
                                            <b>
                                                <Link
                                                    style={{ color: '#00bcd4' }}
                                                    to={(location) => ({
                                                        ...location,
                                                        pathname: `/clientes/direccion/add/${cliente.id}`
                                                    })}
                                                >
                                                    Agregar dirección de cliente
                                                </Link>
                                            </b>
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                            {cliente.id > 0 ?
                        <div className='accordion' id='accordionExample'>
                            <div className='accordion-item'>
                                <h2 className='accordion-header' id='headingOne'>
                                    <button
                                        className='accordion-button collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseOne'
                                        aria-expanded='true'
                                        aria-controls='collapseOne'
                                        style={{ color: '#0c63e4', backgroundColor: '#e7f1ff' }}
                                    >
                                        <h5>Direcciones del cliente</h5>
                                    </button>
                                </h2>
                                <div
                                    id='collapseOne'
                                    className='accordion-collapse collapse'
                                    aria-labelledby='headingOne'
                                    data-bs-parent='#accordionExample'
                                >
                                    <div className='accordion-body row'>
                                        <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Codigo Postal</th>
                                                        <th>Calle</th>
                                                        <th>No Exterior</th>
                                                        <th>No Interior</th>
                                                        <th>Colonia</th>
                                                        <th>Municipio</th>
                                                        <th>Poblacion</th>
                                                        <th>Ciudad</th>
                                                        <th>Estado</th>
                                                        <th>Pais</th>
                                                        <th>Colonia</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cliente.clientes_Direcciones?.map((item) => {
                                                        return (
                                                            <tr key={item.id}>
                                                                <td>{item.cp}</td>
                                                                <td>{item.calle}</td>
                                                                <td>{item.no_Exterior}</td>
                                                                <td>{item.no_Interior}</td>
                                                                <td>{item.colonia}</td>
                                                                <td>{item.municipio}</td>
                                                                <td>{item.poblacion}</td>
                                                                <td>{item.ciudad}</td>
                                                                <td>{item.estado}</td>
                                                                <td>{item.colonia}</td>
                                                                <td>{item.pais}</td>
                                                                <td>
                                                                    <Link
                                                                        to={(location) => ({
                                                                            ...location,
                                                                            pathname: `/clientes/direccion/${item.id}`
                                                                        })}
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='25'
                                                                            fill='currentColor'
                                                                            className='bi bi-pencil-square'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
                                                                            <path
                                                                                fillRule='evenodd'
                                                                                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z'
                                                                            />
                                                                        </svg>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                            </div>
                            
                        )}

                        {valueTab === 1 && (
                            <div className='col-md-12' style={{ display: 'grid', float: 'left' }}>
                                 <div className='col-md-12 row'>
                                    <div className='col-md-1'><label>Articulo:</label></div>
                                    <div  className='col-md-10'>
                                    <AsyncSelect
                                        className='react-select-placeholder'
                                            isClearable
                                            getOptionLabel={(option) =>
                                                option.clave +
                                                '-' +
                                                option.nombre +
                                                '- $' +
                                                (option.cliente_Precio != null ?  option.cliente_Precio.precio : option.costo_Unidad)
                                                
                                            }
                                            value={artSelect}
                                            getOptionValue={(option) => option.id}
                                            loadOptions={loadArticulos}
                                            onChange={(selectedItem) => {
                                                const val = selectedItem === null ? '' : selectedItem?.id;
                                                setArtid(val);
                                                setArtSelect(selectedItem);
                                            }}
                                        />
                                    </div>
                                    <div  className='col-md-1'>
                                    <button className='btn btn-success' type='button' onClick={()=>agregarArticulo()}>Agregar</button>
                                    </div>
                                    
                                   
                                   
                                    <ListTable
                                        columns={columns}
                                        path={path}
                                        url={`clientes/Favoritos/GetListFavoritos?cliente_id=${id}&conDetalle=false`}
                                        isTreeData={true}
                                        reload={actualizarTabla}
                                        hideConexion={true}
                                    />
                                </div>

                            </div>
                        )} 


                      
                    </div>
                </form>
            </div>
        </>
    );
};
/*
const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};*/

export { AddEdit };
