import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';

import Swal from 'sweetalert2';

const AddEdit = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { register, handleSubmit, reset } = useForm();
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [error, setError] = useState(['']);
    let [erroresCliente, setErroresCliente] = useState({});

    let [sucursales, setSucursales] = useState([]);
    let [data, setData] = useState(['']);

    let [sucursales_ids_local, setSucursales_ids_local] = useState([]);

    let {
        empresa_Id = getDataSession('empresa'),
        nombre = '',
        pwd = '',
        correo = '',
        estatus = 'A',
        telefono = '',
        sucursal_Id = 0,
        num_Whatsapp = '',
        sucursales_ids = []
    } = data;

    function onSubmit() {
        const regex =/^[0-9]{10}$/i;
        if(!regex.test(data?.telefono))
        {
             Swal.fire('Error', "Escriba un numero de telefono valido", 'error');
             return false;
        }
        if(!regex.test(data?.num_Whatsapp))
        {
             Swal.fire('Error', "Escriba un numero de Whatsapp valido", 'error'); 
             return false;
        }
        return isAddMode ? create(data) : update(id, data);
    }

    function create() {
        data.sucursales_ids = [];

        if(sucursales_ids_local.length === 0)
        {
            Swal.fire('Info', 'Seleccione al menos una conexión', 'info');
            return;
        }

        sucursales_ids_local.map((item) => {
            data.sucursales_ids.push(item);
        });

        data.rol = {
            id: 3,
            nombre: 'Sucursal'
        };
        data.empresa_Id = getDataSession('empresa');
        setErroresCliente('');
        setError('');
        return fetchConToken('Usuarios', data, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        //erroresC[key] = value[0];
                        //setErroresCliente(erroresC);
                        Swal.fire('Error', value[0], 'error');
                    });
                    setError('Revisar errores');
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    //setError(response.mensajes[0]);
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Usuario agregado', 'success');
                history.push('.');
                //alertService.success('Usuario agregado', { keepAfterRouteChange: true });
            })
            .catch(alertService.error);
    }

    function update() {
        data.sucursales_ids = [];
        
        if(sucursales_ids_local.length === 0)
        {
            Swal.fire('Info', 'Seleccione al menos una conexión', 'info');
            return;
        }
        
        sucursales_ids_local.map((item) => {
            data.sucursales_ids.push(item);
        });

        data.rol = {
            id: 3,
            nombre: 'Sucursal'
        };

        return fetchConToken('Usuarios', data, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        //erroresC[key] = value[0];
                        //setErroresCliente(erroresC);
                        Swal.fire('Error', value[0], 'error');
                    });
                    setError('Revisar errores');
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    //setError(response.mensajes[0]);
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'Usuario modificado', 'success');
                history.push('..');
            })
            .catch(alertService.error);
    }

    async function getSucursales(sucursales_aux) {
        await fetchConToken(
            `Sucursales/GetList?empresa_id=${getDataSession('empresa')}&pagina=1&totalXpagina=100`
        ).then((suc) => {
            suc.map((item) => {
                item.matriz = sucursales_aux?.includes(item.id);
            });

            setSucursales(suc);
        });
    }

    useEffect(() => {
        if (!isAddMode) {
            fetchConToken(`Usuarios?id=${id}&empresa_id=${getDataSession('empresa')}`).then(
                (response) => {
                    setData(response);

                    setSucursales_ids_local(response.sucursales_ids);
                    getSucursales(response.sucursales_ids);
                }
            );
        }

        if (isAddMode) getSucursales();

        actualizarTitulo();
    }, []);

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: isAddMode ? 'Agregar usuario' : 'Editar usuario',
          subtitulo: ''
        });
      };

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ? 'A' : 'I') : target.value;
        const name = target.name;

        setData({
            ...data,
            [name]: value
        });
    }

    function handleInputChangeSucursales(event) {
        const target = event.target;

        const value = target.type === 'checkbox' ? (target.checked ? 'A' : 'I') : target.value;
        const name = target.name;

        const suc_aux = sucursales.map((item) => {
            if (item.id == target.value) item.matriz = target.checked;
            return item;
        });

        setSucursales(suc_aux);

        if (sucursales_ids_local.includes(parseInt(target.value))) {
            var sucursales_ids_aux = sucursales_ids_local.filter(function (item) {
                return item !== parseInt(target.value);
            });
            setSucursales_ids_local(sucursales_ids_aux);
            console.log('remove');
        } else {
            sucursales_ids_local.push(parseInt(target.value));
            console.log('push');
        }
        console.log(sucursales_ids_local);
    }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                    <div className='mb-3'>
                        <div className='row' style={{ textAlign: 'left' }}>
                           
                            <div className='col-md-12 text-right'>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={isAddMode ? '.' : '..'}
                                    className='btn btn-outline-secondary'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <p style={{ color: 'red' }}>{error}</p>
                        {Object.entries(erroresCliente).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                    </div>
                    <div className='row'>
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='nombre' className='form-label'>
                                        Nombre
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        required={true}
                                        value={nombre}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='telefono' className='form-label'>
                                        Telefono
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='telefono'
                                        required={true}
                                        value={telefono}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor="num_Whatsapp" className="form-label">Numero de Whatsapp</label>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        name="num_Whatsapp" 
                                        placeholder="" 
                                        required={true}
                                        maxLength={25}
                                        value={num_Whatsapp}
                                        onChange={handleInputChange}
                                        />


                                    <label htmlFor='correo' className='form-label'>
                                        Correo
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='correo'
                                        required={true}
                                        value={correo}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='pwd' className='form-label'>
                                        Contraseña
                                    </label>
                                    <input
                                        type='password'
                                        className='form-control'
                                        name='pwd'
                                        required={true}
                                        maxLength={100}
                                        value={pwd}
                                        onChange={handleInputChange}
                                    />

                                    <div className='form-check form-switch'>
                                        <label htmlFor='estatus'>
                                            Estatus
                                            <input
                                                type='checkbox'
                                                className='form-check-input'
                                                name='estatus'
                                                role='switch'
                                                onChange={handleInputChange}
                                                checked={estatus === 'A'}
                                            />
                                        </label>{' '}
                                        &nbsp;&nbsp;
                                    </div>

                                    <label htmlFor='rfc' className='form-label'>
                                        Conexión asignada
                                    </label>
                                    <table>
                                        <tbody>
                                            {sucursales.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <label>
                                                                <input
                                                                    type='checkbox'
                                                                    className='form-check-input'
                                                                    name={item.nombre}
                                                                    onChange={
                                                                        handleInputChangeSucursales
                                                                    }
                                                                    value={item.id}
                                                                    checked={item.matriz}
                                                                />
                                                                &nbsp;&nbsp;{item.nombre}
                                                            </label>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export { AddEdit };
