import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { Link } from 'react-router-dom';
import {  rfcValido } from 'resources/utilities';
import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';

const CfgEmpresa = ({ history, match, saludo, subtitulo }) => {
    const { path } = match;
    const baseUrl = process.env.REACT_APP_SERVER_URL;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    //let [data, setData] = useState(['']);
    //let [nombreCer, setNombreCer] = useState(['']);
    //let [nombreKey, setNombreKey] = useState(['']);
    //let [empresa, setEmpresa] = useState(getDataSession('empresa'));

    //let [sucursales, setSucursales] = useState(['']);

    let [configuraciones, setConfiguraciones] = useState(['']);
    let [error, setError] = useState(['']);
    let [errores, setErrores] = useState({});

    let [urlFacturacion, setUrlFacturacion] = useState(window.location.host);

    let [configuracionEmpresa, setConfiguracionEmpresa] = useState({
        empresa_Id: 1,
        nombre: 'texto_cotizacion',
        valor: '',
        tipo:'texto',
        es_Visible: false,
        descripcion: 'Texto en el footer de cotizacion',
        categoria: 'cotizacion'
    });

    const handleInputChangeConfiEmpresaText = ({ target }) => {
        setConfiguracionEmpresa({
            ...configuracionEmpresa,
            [target.name]: target.value
        });
        console.log(configuracionEmpresa)
    };
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Empresa',
          subtitulo: ''
        });
      };
    React.useEffect(() => {
        async function getData() {
            const response = await fetchConToken('Empresas?id=1');

            if(response.regimen_Fiscal === "")
                response.regimen_Fiscal = '601';
            if(response.tipo_Persona === "")
                response.tipo_Persona = 'Fisica';
            if(response.tamanio_Empresa === 0)
                response.tamanio_Empresa = 1;
            if(response.facturacion_Dias === 0)
                response.facturacion_Dias = 1;
            if(response.giro_Ocupacion === "")
                response.giro_Ocupacion = 'Comerciante';

            setData(response);

        }
       
        fetchConToken(`ConfiguracionesEmpresas?empresa_id=1&nombre=texto_cotizacion`)
        .then((response) => {
            if (response.hasOwnProperty('id') && response.id > 0) {
                setConfiguracionEmpresa(response);
            }
        });

        getData();
        getConfig();
        actualizarTitulo();
        //getSucursales();
    }, []);
    // eslint-disable-next-line


    const [data, setData] = useState({
        id: 0,
        rfc: '',
        regimen_Fiscal: '601',
        tipo_Persona: 'Fisica',
        tamanio_Empresa: 1,
        nombre: '',
        nombre_Fiscal: '',
        nombre_Comercial: '',
        giro_Ocupacion: '',
        clave: '',
        url_Personalizada: '',
        estatus: 'A',
        facturacion_Mes: true,
        facturacion_Dias: 1,
        mensaje_Post_Venta: ''
    });

    /*const handleInputChangeFile = ({ target }) => {
        const formData = new FormData();

        formData.append('empresa_id', getDataSession('empresa'));
        formData.append('sucursal_id', getDataSession('sucursal'));
        formData.append('tipo', 'certificados');

        formData.append('FileToUpload', target.files[0], target.files[0].name);

        fetchConToken('Archivos', formData, 'POST', true)
            .then((response) => {
                alertService.success('Se ha guardado el archivo.', {
                    keepAfterRouteChange: true,
                    autoClose: true
                });
                setData({
                    ...data,
                    [target.name]: response.url
                });
                if (target.name ==='ruta_Cert') {
                    setNombreCer(split(response.url));
                }
                if (target.name === 'ruta_Key') {
                    setNombreKey(split(response.url));
                }
            })
            .catch(alertService.error);
    };
*/

    const handleInputChangeFileConfig = ({ target }) => {
        const formData = new FormData();

        formData.append('empresa_id', 1);
        formData.append('sucursal_id', getDataSession('sucursal'));
        formData.append('tipo', 'imagenes');

        formData.append('FileToUpload', target.files[0], target.files[0].name);

        fetchConToken('Archivos', formData, 'POST', true)
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                let configAux = configuraciones.filter((config) => config.nombre === target.name);
                configAux[0].valor = response.url;
                setConfiguraciones(
                    configuraciones.map((configuracion) =>
                        configuracion.nombre === target.name ? configAux[0] : configuracion
                    )
                );
            })
            //.catch(alertService.error);
    };

    function UpdateTextDocumentos() {
        console.log(configuracionEmpresa)
        return fetchConToken('ConfiguracionesEmpresas', configuracionEmpresa, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }             
            })
    } 

    const handleInputChange = ({ target }) => {

        console.log(target.name);
        

        const value = target.type === 'checkbox' ? target.checked : target.value;
        console.log(value);
        setData({
            ...data,
            [target.name]: value
        });
    };

    const handleInputChangeConfig = ({ target }) => {
        let configAux = configuraciones.filter((config) => config.nombre === target.name);
        configAux[0].valor = target.value;
        setConfiguraciones(
            configuraciones.map((configuracion) =>
                configuracion.nombre === target.name ? configAux[0] : configuracion
            )
        );
    };

    const saveInfoempresa = () => {
        setErrores({});
        setError('');
        UpdateTextDocumentos();
        data.estatus = 'A';
        console.log(data)

        var rfcCorrecto = rfcValido(data.rfc, false);
        if (!rfcCorrecto) {
            Swal.fire('Error', 'RFC no valido', 'error');
            return;
        }

        if(data.rfc === undefined ||  data.rfc?.length === 0)
        {
            Swal.fire('Atención','Escriba su RFC.','info')
            return;
        }

    var url_facturacion = urlFacturacion + '/facturacion/' + data.url_Personalizada;
    fetchConToken('empresas', data, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        //erroresA[key] = value[0] ;
                        //setErrores(erroresA);
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    //setError('Revisar errores:');
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    //setError(response.mensajes[0]);
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                Swal.fire('Exito', 'La cambios se han guardado correctamente.', 'success');
                agregaConfiguracionEmpresa();
            })
            //.catch(alertService.error);
    };

    const getConfig = () => {
        fetchConToken('ConfiguracionesEmpresas/GetList?pagina=1&totalXpagina=100', null, 'GET')
            .then((response) => {
                setConfiguraciones(response);
            })
            //.catch(alertService.error);
    };

    const agregaConfiguracionEmpresa = () => {

        const filtroElemento = configuraciones.filter(item => item.valor?.length > 0);
        console.log("entro agrega")
        console.log(configuraciones)
        console.log(filtroElemento)

        fetchConToken('ConfiguracionesEmpresas/AgregaLista', filtroElemento, 'POST')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
            })
            //.catch(alertService.error);
    };

    var split = function (str) {
        console.log(str.split('\\').pop().split('/').pop());
        return str.split('\\').pop().split('/').pop();
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='mb-3'>
                    <div className='col-md-12' style={{ textAlign: 'left' }}>
                        

                        <div className='col-md-12 text-right'>
                            <button
                                className='btn btn-outline-success'
                                type='button'
                                onClick={saveInfoempresa}
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-check-lg'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                </svg>
                                &nbsp; Guardar
                            </button>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className='card mb-3 '>
                            <div className='card-body text-start'>
                                <div className='card-title'>
                                    <h5>Información de tu empresa</h5>
                                </div>
                                <div className='col-md-12'>
                                    <p style={{ color: 'red' }}>{error}</p>
                                    {Object.entries(errores).map(([key, value]) => {
                                        return (
                                            <>
                                                <p style={{ color: 'red' }}>{value}</p>
                                            </>
                                        );
                                    })}
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='mb-3 '>
                                            <label htmlFor='nombre' className='form-label'>
                                                Nombre de la empresa
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='nombre'
                                                value={data.nombre || ''}
                                                onChange={handleInputChange}
                                            />

                                            <label htmlFor='nombre_Fiscal' className='form-label'>
                                            Nombre Fiscal
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='nombre_Fiscal'
                                                value={data.nombre_Fiscal || ''}
                                                onChange={handleInputChange}
                                            />

                                            <label htmlFor='nombre_Comercial' className='form-label'>
                                            Nombre Comercial
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='nombre_Comercial'
                                                value={data.nombre_Comercial || ''}
                                                onChange={handleInputChange}
                                            />

                                            <label htmlFor='rfc' className='form-label'>
                                                RFC
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='rfc'
                                                required={true}
                                                value={data.rfc || ''}
                                                onChange={handleInputChange}
                                            />

                                            <label htmlFor='tipo_Persona' className='form-label'>
                                                Tipo Persona
                                            </label>
                                            <select
                                                className='form-control'
                                                name='tipo_Persona'
                                                value={data.tipo_Persona}
                                                onChange={handleInputChange}
                                            >
                                                <option key={'Fisica'} value='Fisica'>
                                                    Persona Fisica
                                                </option>
                                                <option key={'Moral'} value='Moral'>
                                                    Persona Moral
                                                </option>
                                            </select>

                                            <label htmlFor='regimen_Fiscal' className='form-label'>
                                                Regimen Fiscal
                                            </label>
                                            <select
                                                className='form-control'
                                                name='regimen_Fiscal'
                                                value={
                                                    data.regimen_Fiscal === '' ? '601' : data.regimen_Fiscal
                                                }
                                                onChange={handleInputChange}
                                            >
                                                <option key={'601'} value='601'>
                                                    601-General de Ley Personas Morales
                                                </option>
                                                <option key={'603'} value='603'>
                                                    603-Personas Morales con Fines no Lucrativos
                                                </option>
                                                <option key={'605'} value='605'>
                                                    605-Sueldos y Salarios e Ingresos Asimilados a
                                                    Salarios
                                                </option>
                                                <option key={'606'} value='606'>
                                                    606-Arrendamiento
                                                </option>
                                                <option key={'607'} value='607'>
                                                    607-Régimen de Enajenación o Adquisición de
                                                    Bienes
                                                </option>
                                                <option key={'608'} value='608'>
                                                    608-Demás ingresos
                                                </option>
                                                <option key={'609'} value='609'>
                                                    609-Consolidación
                                                </option>
                                                <option key={'610'} value='610'>
                                                    610-Residentes en el Extranjero sin
                                                    Establecimiento Permanente en México
                                                </option>
                                                <option key={'611'} value='611'>
                                                    611-Ingresos por Dividendos (socios y
                                                    accionistas)
                                                </option>
                                                <option key={'612'} value='612'>
                                                    612-Personas Físicas con Actividades
                                                    Empresariales y Profesionales
                                                </option>
                                                <option key={'614'} value='614'>
                                                    614-Ingresos por intereses
                                                </option>
                                                <option key={'615'} value='615'>
                                                    615-Régimen de los ingresos por obtención de
                                                    premios
                                                </option>
                                                <option key={'616'} value='616'>
                                                    616-Sin obligaciones fiscales
                                                </option>
                                                <option key={'620'} value='620'>
                                                    620-Sociedades Cooperativas de Producción que
                                                    optan por diferir sus ingresos
                                                </option>
                                                <option key={'621'} value='621'>
                                                    621-Incorporación Fiscal
                                                </option>
                                                <option key={'622'} value='622'>
                                                    622-Actividades Agrícolas, Ganaderas, Silvícolas
                                                    y Pesqueras
                                                </option>
                                                <option key={'623'} value='623'>
                                                    623-Opcional para Grupos de Sociedades
                                                </option>
                                                <option key={'624'} value='624'>
                                                    624-Coordinados
                                                </option>
                                                <option key={'625'} value='625'>
                                                    625-Régimen de las Actividades Empresariales con
                                                    ingresos a través de Plataformas Tecnológicas
                                                </option>
                                                <option key={'626'} value='626'>
                                                    626-Régimen Simplificado de Confianza
                                                </option>
                                                <option key={'628'} value='628'>
                                                    628-Hidrocarburos
                                                </option>
                                                <option key={'629'} value='629'>
                                                    629-De los Regímenes Fiscales Preferentes y de
                                                    las Empresas Multinacionales
                                                </option>
                                                <option key={'630'} value='630'>
                                                    630-Enajenación de acciones en bolsa de valores
                                                </option>
                                            </select>

                                          
                                    </div>
                                    </div>
                                    <div className='col-md-6'>
                                    <div className='mb-3 '>
                                    <label htmlFor='tipo_Persona' className='form-label'>
                                                Cantidad de personal
                                            </label>
                                            <select
                                                className='form-control'
                                                name='tamanio_Empresa'
                                                value={
                                                    data.tamanio_Empresa
                                                }
                                                onChange={handleInputChange}
                                            >
                                                <option key={'1-10'} value='1'>
                                                   1-10
                                                </option>
                                                <option key={'11-20'} value='2'>
                                                    11-20
                                                </option>
                                                <option key={'21-50'} value='3'>
                                                    21-50
                                                </option>
                                                <option key={'51-100'} value='4'>
                                                    51-100
                                                </option>
                                                <option key={'100-500'} value='5'>
                                                    100-500
                                                </option>
                                                <option key={'mas500'} value='6'>
                                                    mas de 500
                                                </option>
                                            </select>
                                            <label htmlFor='giro_Ocupacion' className='form-label'>
                                            Giro Ocupación
                                            </label>

                                            <select
                                                className='form-control'
                                                name='giro_Ocupacion'
                                                value={
                                                    data.giro_Ocupacion
                                                }
                                                onChange={handleInputChange}
                                            >
                                                <option key={'Comerciante'} value='Comerciante'>Comerciante</option>
                                                <option key={'Profesionista'} value='Profesionista'>Profesionista</option>
                                                <option key={'Empresario'} value='Empresario'>Empresario</option>
                                                <option key={'Obrero'} value='Obrero'>Obrero</option>
                                                <option key={'Amadecasa'} value='Ama de casa'>Ama de casa</option>
                                                <option key={'General'} value='General'>General</option>
                                                <option key={'Noespecificado'} value='No especificado'>No especificado</option>
                                            </select>


                                            <label
                                                htmlFor='url_personalizada'
                                                className='form-label'
                                            >
                                                Personaliza tu link de facturación ({urlFacturacion}
                                                /facturacion/{data.url_Personalizada}) <br></br>
                                                (Max 20 caracteres)
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='url_Personalizada'
                                                value={data.url_Personalizada || ''}
                                                onChange={handleInputChange}
                                                maxLength='20'
                                            />

                                            <label className='form-label'>
                                                Mensaje que se enviara después de la venta en su
                                                Marketplace (Máx 130 caracteres)
                                            </label>
                                            <textarea
                                                className='form-control'
                                                name='mensaje_Post_Venta'
                                                value={data.mensaje_Post_Venta || ''}
                                                onChange={handleInputChange}
                                                maxLength='130'
                                            />

                                    <div>
                                        <input
                                            type='checkbox'
                                            name='facturacion_Mes'
                                            role='switch'
                                            onChange={handleInputChange}
                                            checked={data.facturacion_Mes}
                                        />
                                        <label htmlFor='envio_Gratis'>Facturar venta en el mismo mes</label>{' '}
                                        &nbsp;&nbsp;
                                    </div>
                                    {
                                        data.facturacion_Mes ? 
                                        <></> : <>
                                           <label className='form-label row' style={{ alignItems: 'center'}}>
                                           &nbsp;&nbsp; Facturar dentro de los
                                           &nbsp;
                                            <input
                                                type='number'
                                                className='form-control'
                                                name='facturacion_Dias'
                                                value={data.facturacion_Dias || ''}
                                                onChange={handleInputChange}
                                                maxLength='31'
                                                style={{width: '4rem'}}
                                            />&nbsp;
                                            dias después de generar la venta
                                            </label>
                                        </> 
                                    }

<br></br>
                                    <label htmlFor='valor' className='form-label'>
                                        Texto para la nota del documento
                                    </label>

                                    <textarea 
                                        rows={5}
                                        type='text'
                                        className='form-control'
                                        name='valor'
                                        value={configuracionEmpresa.valor || ''}
                                        onChange={handleInputChangeConfiEmpresaText}
                                    />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        {configuraciones?.map((item) => {
                                            return (
                                                <>
                                                    {item.tipo === 'texto' ? (
                                                        <>
                                                            <label
                                                                htmlFor={item.nombre}
                                                                className='form-label'
                                                            >
                                                                {item.descripcion}
                                                            </label>
                                                            <input
                                                                key={item.nombre}
                                                                type='text'
                                                                className='form-control'
                                                                name={item.nombre}
                                                                value={item.valor || ''}
                                                                onChange={handleInputChangeConfig}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <label
                                                                htmlFor={item.nombre}
                                                                className='form-label'
                                                            >
                                                                {item.descripcion}
                                                            </label>
                                                            <img
                                                                src={item.valor?.length > 0 ?  baseUrl + item.valor : '' }
                                                                style={{ width: '250px' }}
                                                            ></img>
                                                            <input
                                                                type='file'
                                                                className='form-control'
                                                                name={item.nombre}
                                                                onChange={
                                                                    handleInputChangeFileConfig
                                                                }
                                                            />
                                                        </>
                                                    )}
                                                </>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

CfgEmpresa.propTypes = {
    saludo: PropTypes.string
};

CfgEmpresa.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { CfgEmpresa };
