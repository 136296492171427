import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDataSession } from 'views/Utils/Common';
import TituloPagina from 'components/header/TituloPagina';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';
    const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "editar"},
        {
            field: 'id', headerName: 'Id',  maxWidth: 20 , renderCell: (params) => (
                <a href={`${path + '/edit/' +  params.row.id}`}>{params.value}</a>)
          },
          {
            field: 'clave', headerName: 'Clave',  minWidth: 100 , renderCell: (params) => (
                <a href={`${path + '/edit/' +  params.row.id}`}>{params.value}</a>)
          },
          {
            field: 'nombre', headerName: 'Nombre',  minWidth: 300 , renderCell: (params) => (
                <a href={`${path + '/edit/' +  params.row.id}`}>{params.value}</a>)
          },   
          { field: 'estatus', headerName: 'Estatus' , type: 'boolean'}     
          
    ];
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Rutas',
          subtitulo: 'Listado Rutas.'
        });
      };
      React.useEffect(() => {actualizarTitulo();},[]);

    

    return (
        <>
            <div className='container-fluid'>
                <div className='col-md-12'>
                        <Link to={`${path}/add`} className='btn btn-outline-primary'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>
                        &nbsp; &nbsp;
                </div>
 
                <div className='col-md-12' >
                <ListTable
                    columns = {columns}
                    path = {path}
                    url={`VxRutas/GetList?sucursal_Id=${getDataSession('sucursal')}`}
                  />
                </div>

            </div>
        </>
    );
};



export { List };
