import shopify from 'images/panel/conexiones/shopify.png';
import tiendanube from 'images/panel/conexiones/tiendanube.png';
import wix from 'images/panel/conexiones/wix.png';
import woocommerce from 'images/panel/conexiones/woocommerce.png';
import amazon from 'images/panel/conexiones/amazon.png';
import mercadolibre from 'images/panel/conexiones/mercadolibre.png';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';

const Cargando = () => {
    return (
        <div className='col-12 col-sm-12 col-md-12'>
            <div className='espacio30px' />
            <div className='col-md-13 row'>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                    <div className='card-body'>
                        <div className='espacio30px' />
                        <div className='col-md-10 row'>
                            <div className='col-sm-7' />
                            <div className='col-sm-4'>
                                <CircularProgress />
                                <div className='text-justify'><label className='form-label'>Cargando...</label></div>
                            </div>
                            <div className='col-sm-3' />
                        </div>
                    </div>
                </div>
                <div className='col-sm-1' />
            </div>
            <div className='espacio30px' />
        </div>);
}

const SelectorConexion = ({ sucursalesTienda, onClickButton, multiple }) => {
    const GetImgTienda = (tienda) => {
        switch (tienda?.toUpperCase()) {
            case 'MERCADOLIBRE': return mercadolibre;
            case 'TIENDANUBE': return tiendanube;
            case 'SHOPIFY': return shopify;
            case 'WOOCOMMERCE': return woocommerce;
            case 'AMAZON': return amazon;
            case 'WIX': return wix;
            default: return tienda ?? '';
        }
    }

    const handleToggle = (value) => () => {
        if (!multiple) sucursalesTienda.forEach(element => element.checked = false);

        sucursalesTienda.find(x => x.id === value).checked = !sucursalesTienda.find(x => x.id === value).checked;
    }

    return (
        <>
            <div className='espacio15px' />
            <h3 className='text-center'>Seleccione su conexion</h3>
            <div className='espacio30px' />
            <div className='col-md-12 row'>
                <div className='col-sm-1' />
                <div className='col-md-10 row'>
                    {sucursalesTienda?.map((item) => {
                        return (
                            <ListItem key={item.id} disablePadding>
                                <ListItemButton>
                                    <Checkbox
                                        onChange={handleToggle(item.id)}
                                        checked={sucursalesTienda.find(x => x.id === item.id).checked}
                                        inputProps={{ 'aria-labelledby': item.id }}
                                    />
                                    <ListItemAvatar>
                                        <Avatar
                                            variant="rounded"
                                            alt={`Avatar n°${item.nombre}`}
                                            src={GetImgTienda(item.tienda)}
                                            sx={{ width: 115, height: 100 }}
                                        />
                                    </ListItemAvatar>
                                    <div style={{ width: '10pt' }} />
                                    <ListItemText id={item.id} primary={item.nombre} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                    {(sucursalesTienda == null || sucursalesTienda?.length == 0) && <Cargando />}
                </div>
                <div className='col-sm-1' />
            </div>
            <div className='espacio30px' />
            <div className='col-md-10 row'>
                <div className='col-sm-6' />
                <div className='col-md-3 row'>
                    <button className='btn btn-outline-success' onClick={onClickButton} disabled={!(sucursalesTienda?.length > 0)}>
                        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='currentColor' className='bi bi-check-lg' viewBox='0 0 16 16'>
                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                        </svg>&nbsp; Aceptar
                    </button>
                </div>
            </div>
        </>
    );
}

export { SelectorConexion };