import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import CircularProgress from '@mui/material/CircularProgress';
import { currency } from 'resources/utilities';
import { ClavesArticulos } from './ClavesArticulos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Swal from 'sweetalert2';
import { fetchConToken } from 'helpers/fetch';
import { ListImg } from './ListImg';

import sinIimagen from 'images/venta/SIN-IMAGEN.jpg';

const ListVariante = ({ data, viewClaves, clavesArt, eliminarClave, setModalValor, openModal, setVarianteId,variantes, setVariantes ,clavesArticuloVariante, editar={editar}}) => {
    console.log("ListVariante");
    return <AgregarVarianteButton data={data} viewClaves={viewClaves} clavesArt={clavesArt} eliminarClave={eliminarClave} setModalValor={setModalValor} openModalPrincipal={openModal} setVarianteId={setVarianteId} variantes={variantes} setVariantes={setVariantes} clavesArticuloVariante={clavesArticuloVariante} editar={editar}/>;
}

const AgregarVarianteButton  = ({ data, viewClaves, clavesArt, eliminarClave, setModalValor, openModalPrincipal, setVarianteId,variantes, setVariantes ,clavesArticuloVariante, editar={editar}}) => {
    
    const { t } = useTranslation('common');
    const [isOpenModal, openModal, closeModal] = useModal(false);
    let [atributos, setAtributos] = useState([]);
    let [atributo, setAtributo] = useState({
        clave_Atributo: '',
        nombre: '',
        valor: ''
    });
    let [variant, setVariant] = useState({
        nombre: data.nombre || "Variante",
        actStockAut: "N",
        altura: 0,
        ancho: 0,
        articulo_Id: data.id,
        articulo_Padre_Id: 0,
        atributos: [],
        atributos_Combinacion: [],
        clave: '',
        clave_Prod_Serv: "",
        clave_Unidad: "",
        clavesArticulo: null,
        codigo_Barras: null,
        estatus: "A",
        existenciaPublicaciones: null,
        existencias: 0,
        imagenes: [],
        longitud: 0,
        nivelesArticulos: [],
        no_Identificacion: "",
        padre: null,
        peso: 0,
        precio: 0,
        sku: data.sku,
        sucursal_Id: 0,
        ultimo_Costo:  null,
        variante_Padre_Id:  null,
        id: 0
    }); 
    let [i, setI] = useState(-1);

    function AddVariante(){ openModal(); }

    function handleInputChange(event) {
        setAtributo({
            ...atributo,
            [event.target.name]: event.target.value
        });
    }

    const agregarAtributo = () => { 

        if(atributo.clave_Atributo.length === 0 || atributo.valor.length === 0)
        {
            Swal.fire('info', "Debe agregar un atributo y valor", 'info');
            return;
        }
        setAtributos([...atributos, atributo]); 
    }

    const agregarVariante = () => 
    {
        if(atributos.length == 0)
        {
            Swal.fire('info', "Debe tener minimo un atributo, para agregar una variante", 'info');
            return;
        }
            variant.id = i;
            setI((i-1));
            variant.atributos_Combinacion = atributos;
            setVariantes([...variantes,variant]);  
            
            if(data.id > 0)
            {

                ActualizarAgregarVariantes(variant);
            }
            setAtributos([]);
            setAtributo({
                clave_Atributo: '',
                nombre: '',
                valor: ''
            });
            setVariant({
                nombre: data.nombre || "Variante",
                actStockAut: "N",
                altura: 0,
                ancho: 0,
                articulo_Id: data.id,
                articulo_Padre_Id: 0,
                atributos: [],
                atributos_Combinacion: [],
                clave: '',
                clave_Prod_Serv: "",
                clave_Unidad: "",
                clavesArticulo: null,
                codigo_Barras: null,
                estatus: "A",
                existenciaPublicaciones: null,
                existencias: 0,
                imagenes: [],
                longitud: 0,
                nivelesArticulos: [],
                no_Identificacion: "",
                padre: null,
                peso: 0,
                precio: 0,
                sku: data.sku,
                sucursal_Id: 0,
                ultimo_Costo:  null,
                variante_Padre_Id:  null,
                id: 0
            });
    }


    function ActualizarAgregarVariantes(artVariante){
        fetchConToken(
            `Articulos/Variante?articulo_id=`+data.id,
            artVariante,
            'PUT'
        ).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            Swal.fire('Exito', 'Actualizacion de variante terminado', 'success');
            clavesArticuloVariante(data.id,response.id);

            if(artVariante.id > 0)
            {
                const variantesActualizar = variantes.map((item) => {
                    if (item.id === response.id) {
                    return response;
                    } else {
                    return item;
                    }
                });
                setVariantes(variantesActualizar);
            }
            else
            {
                setVariantes([...variantes,response]);
            }
            setAtributos([]);
        });
    }


    async function eliminar(varianteid) {
        if(data.id > 0)
        {

        Swal.fire({
            text: "De donde desea eliminar la variante?",
            showCancelButton: true,
            confirmButtonText: 'Microzync',
            showDenyButton: true,
            denyButtonText: 'Ecommerce y Microzync',
            cancelButtonText: "Cancelar"
        }).then((result) => {
            var eliminar = false;
            var eliminarMk = false;
            if (result.isConfirmed) {
                 eliminar = true;
                 eliminarMk = false;
            }
            else if (result.isDenied) {
                 eliminar = true;
                 eliminarMk = true;
            }
            if(eliminar){
                fetchConToken(`Articulos?articulo_id=${data.id}${eliminarMk ? "&eliminarmk=true" : ''}&varianteId=${varianteid}`, null, 'DELETE').then(function (response) {
                    if (response.hasOwnProperty('status') && response.status === 400) {
                        Object.entries(response.errors).map(([key, value]) => {
                            Swal.fire('Error', value[0], 'error');
                        });
                        return;
                    } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                        Swal.fire('Error', response.mensajes[0], 'error');
                        return;
                    }
                    Swal.fire(t('app.SwalFire.eliminado'), t('app.Articulos.SwalFire.eliminado'), 'success');
                    var variantesAux = variantes.filter(x=>x.id != varianteid);
                    setVariantes(variantesAux);
    
                });
            }
        })    
    }
    else
    {
        var variantesAux = variantes.filter(x=>x.id != varianteid);
        setVariantes(variantesAux);
    }
  
    }

    return (
        <>
        <div className='col-12 col-sm-12 col-md-12'>
            <div className='col-md-13 row'>
                <div className='col-sm-10'>
                    <div className='card-body'>
                        {editar ?
                        <div className='text-right'><button type='button' className='btn btn-outline-success' onClick={()=>AddVariante()} >Agregar variante</button></div>
                        : ''}
                    </div>
                </div>
            </div>
        </div>
        <Modal isOpen={isOpenModal} closeModal={closeModal}>
            <div className='text-left'>
                {editar ? 
                <button type='button' className='btn btn-outline-success' onClick={()=>agregarVariante()}>Agregar Variante</button>
                : '' }
            </div>
            <div className='col-md-12 row'>
                <div className='col-md-5'>
                  <label htmlFor='atributo'>Atributo</label>
                    <input type='text' className={'form-control'}
                                                name='clave_Atributo'
                                                onChange={handleInputChange}
                                                placeholder='Color'
                                                value={atributo.clave_Atributo}
                                            />
                </div>
                <div className='col-md-5'>
                <label htmlFor='valor'>Valor</label>
                    <input type='text' className={'form-control'}
                                                name='valor'
                                                onChange={handleInputChange}
                                                placeholder='rojo'
                                                value={atributo.valor}
                                            />
                </div>
                <div className='col-md-2'>
                    <button type='button' className='btn btn-outline-success' onClick={()=>agregarAtributo()}>Agregar Atributo</button>
                </div>
            </div>
            <table className='table'>
                <thead> 
                    <tr>
                        <th>Atributo</th>
                        <th>Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {atributos?.map(item=>{
                    return <tr>
                             <td>{item.clave_Atributo}</td>
                             <td>{item.valor}</td>
                          </tr>
                    })}
                </tbody>
            </table>
        </Modal>
         {  (variantes && variantes.length > 0) ?
             (<div>{variantes?.map((item) => {
             return <RowVariante key={item.id} item={item} viewClaves={viewClaves} clavesArt={clavesArt} eliminarClave={eliminarClave} setModalValor={setModalValor} openModal={openModalPrincipal} setVarianteId={setVarianteId} ActualizarAgregarVariantes={ActualizarAgregarVariantes} eliminar={eliminar} editar={editar} />
        })}</div>) :
         ( <SinVariante data={data} /> )}
        </>
    );
}

const SinVariante = ({ data }) => {
    return (
        <>
        <div className='col-12 col-sm-12 col-md-12'>
            <div className='espacio30px' />
            <div className='col-md-13 row'>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                    <div className='card-body'>
                        <div className='espacio30px' />
                        <div className='col-md-13 row'>
                            <div className='col-sm-5' />
                            <div className='col-sm-4'>
                                <div className='text-justify'><label className='form-label'>Sin Variantes</label></div>
                            </div>
                            <div className='col-sm-3' />
                        </div>
                    </div>
                </div>
                <div className='col-sm-1' />
            </div>
            <div className='espacio30px' />
        </div>
        </>
    );
}

const Cargando = () => {
    return (
        <div className='col-12 col-sm-12 col-md-12'>
            <div className='espacio30px' />
            <div className='col-md-13 row'>
                <div className='col-sm-1' />
                <div className='col-sm-10'>
                    <div className='card-body'>
                        <div className='espacio30px' />
                        <div className='col-md-13 row'>
                            <div className='col-sm-5' />
                            <div className='col-sm-4'>
                                <CircularProgress />
                                <div className='text-justify'><label className='form-label'>Cargando...</label></div>
                            </div>
                            <div className='col-sm-3' />
                        </div>
                    </div>
                </div>
                <div className='col-sm-1' />
            </div>
            <div className='espacio30px' />
        </div>);
}

const RowVariante = ({ item, viewClaves, clavesArt, eliminarClave, setModalValor, openModal, setVarianteId,ActualizarAgregarVariantes ,eliminar, editar={editar}}) => {
    const { t } = useTranslation("common");
    let [imagenes, setImagenes] = useState(item.imagenes);
    let [existencia, setExistencia] = useState(item.existencias);
    let [lsexistencia, setlsExistencia] = useState([{existencia: item.existencias}]);

    function handleChangeAddImg(data) {
        if (data) {
            item.imagenes.push({ urlImagen: data });
            setImagenes([...imagenes,{ urlImagen: data }]);
        }
        if(data.id > 0)
        ActualizarAgregarVariantes(item);
    }

    function handleChangeRemove() {
        item.imagenes = [];
        setImagenes([]);
        ActualizarAgregarVariantes(item);
    }
    const changeExistencia = ({ target }) => {
        setExistencia(target.value);
        item.existencias = target.value;
        setlsExistencia([]);
        setlsExistencia([{existencia: target.value}]);
        item.existenciaPublicaciones=[{existencia: target.value}];
    }

    return (
        <div className='col-12 col-sm-12 col-md-12'>
            <div className='espacio15px' />
            <div className='col-md-13 row'>
                <div className='col-sm-1' />
                <div className='col-sm-10 card card-backgground'>
                    <div className='card-body text-right'>
                        {editar ? 
                    <button type='button' onClick={()=>eliminar(item.id)}  className='btn btn-outline-danger btn-sm'>X</button> : ''}
                        <div className='espacio5px' />
                        <div className='col-md-13 row'>
                            
                            <div className='col-sm-2 container-center'>
                                <div className='card'>
                                {imagenes?.length > 0 ? <><img className='img_art_card' src={imagenes?.at(0)?.urlImagen} alt={imagenes?.at(0)?.clave_Imagen} />
                                {editar ?
                                <button type='button' onClick={()=>handleChangeRemove()}  className='btn btn-outline-danger btn-sm'>X</button> : ''}
                                
                                </> : 
                                (editar ?
                                <ListImg
                                                        data={imagenes}
                                                        isAdd={true}
                                                        isEdit={true}
                                                        handleChangeAddImg={handleChangeAddImg}
                                                        handleChangeRemove={handleChangeRemove}
                                                    /> : <img className='img_art_card' src={sinIimagen}  />)
                                }
                                </div>
                            </div>
                            <div className={viewClaves ? 'col-sm-5 container-center' : 'col-sm-8 container-center'}>
                                <div className='text-justify'><label className='form-label'>Titulo del Articulo: {item?.nombre || ''}</label></div>
                                {!viewClaves && <div className='text-justify'><label className='form-label'>SKU: {item?.sku || ''}</label></div>}
                                {viewClaves && <>
                                    <div className='text-justify'>
                                      {item?.atributos_Combinacion?.map(x=>{
                                           return <small><p>{x.clave_Atributo}: {x.valor}</p></small>
                                      })}
                                       
                                    </div>
                                    {item.id > 0 ?
                                        <div className='text-justify'><label className='form-label'>Existencias: {item?.existencias || '0'}</label></div> :
                                        <div className='text-justify'><label className='form-label'>Existencias: </label><input value={existencia} type='number' onChange={changeExistencia} className='form-control' /></div> 
                                    }
                                    <div className='text-justify'><label className='form-label'>Precio: ${item?.precio || '0'}</label></div>
                                </>}
                            </div>
                            {!viewClaves && <div className='col-sm-2 container-center'> 
                                <div className='text-justify'><label className='form-label'>Existencias: {item?.existencias || '0'}</label></div>
                                <div className='text-justify'><label className='form-label'>Precio: ${item?.precio || '0'}</label></div>
                            </div>}
                            {viewClaves && <div className='col-sm-5 container-center'>
                                <ClavesArticulos clavesArt={clavesArt} eliminarClave={eliminarClave} setModalValor={setModalValor} openModal={openModal} varianteId={item.id} setVarianteId={setVarianteId} />
                            </div>}
                        </div>
                        <div className='espacio5px' />
                        {item.padre ? <div className='row'>
                            <div className='col-md-3'><label>Cve. Orig.:</label><br /><small>{item.padre.cveOrig}</small> </div>
                            <div className='col-md-5'><label>Nom. Orig.:</label><br /><small>{item.padre.nomOrig}</small> </div>
                            <div className='col-md-2'><label>Exi. Orig.:</label><br /><small>{item.padre.exiOrig}</small> </div>
                            <div className='col-md-2'><label>Pre. Orig.:</label><br /><small>{currency(item.padre.preOrig)}</small> </div>
                        </div> : ''}
                       
                    </div>
                </div>
                <div className='col-sm-1' />
               
            </div>
            <div className='espacio15px' />
        </div>);
}

export { ListVariante, Cargando };