import React, { useState } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { setDataSession } from 'views/Utils/Common';
import TituloPagina from 'components/header/TituloPagina';
import SLUGS from 'resources/slugs';
import { currency } from 'resources/utilities';
import Swal from 'sweetalert2';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
/** Swiper***/
import 'swiper/components/effect-fade/effect-fade.min.css';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/effect-coverflow/effect-coverflow.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import { useGlobalState } from 'resources/GlobalStateContext';
// import required modules
import SwiperCore, { EffectCoverflow, Pagination, Navigation } from 'swiper/core';
import { EffectFade } from 'swiper';
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

const PlanesYPrecios = ({ match }) => {

    let [paquetes, setPaquetes] = useState([]);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    async function getPaquetes() {
        const response = await fetchConToken(`PaquetesTimbres/GetList?paginas=1&totalXpagina=50`);
        setPaquetes(response);
    }

    async function agregarPaquete(id) {
        setErrores({});
        setError('');
        await fetchConToken(`Carritos?paquete_id=${id}&cantidad=1`, null, 'POST').then(
            (response) => {

                if (response.hasOwnProperty('status')) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Info', value[0], 'info');
                        return false;
                    });
                    return false;
                    } else if (response.hasOwnProperty('codigo')) {
                        Swal.fire('Info', response.mensajes[0], 'info');
                        return false;
                    }

                    setDataSession('carrito', response.carrito_Detalle.length);
                   window.location.href = SLUGS.carrito;
            }
        )      
        
    }
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Bienvenido a tienda',
          subtitulo: 'Recuerda que puedes ir sumando folios'
        });
      };
    React.useEffect(() => {
        getPaquetes();
        actualizarTitulo()
    }, []);

    return (
        <>
            <div className='container-fluid'>
               
                <p style={{ color: 'red' }}>{error}</p>
                        {Object.entries(errores).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                <div className='col-md-12' style={{ maxWidth: '950px' }}>
                    <Swiper
                        spaceBetween={5}
                        navigation={true}
                        //centeredSlides={true}
                        loop={true}
                        slidesPerView={3}
                        //pagination={true}
                        modules={[EffectFade,Pagination,Navigation]} 
                        effect="fade"
                        autoplay={{
                            "delay": 0,
                            "disableOnInteraction": false
                          }}
                          speed={1000}
                    >
                        {paquetes?.map((item) => {
                            return (
                                <SwiperSlide className='tarjeta-paquete'>
                                    <div>
                                        <div style={{paddingLeft: 12}}>
                                            <p className='paquete'>Paquete</p>
                                            <p className='nombre-paquete'>{item.nombre}</p>
                                            <p className='categoria'>{item.categorias}</p>
                                            <div className='cantidad-facturas'>
                                                <span className='numero-facturas'>
                                                    {item.cantidad_Timbres}
                                                </span>
                                                <span className='facturas'>/Facturas</span>
                                            </div>
                                        </div>
                                        <div className='listado-caracteristicas'>
                                            <div className='caracteristica precio-inactive'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='16'
                                                    height='16'
                                                    fill='currentColor'
                                                    className='bi bi-check-circle-fill active-li'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                                                </svg>
                                                <span
                                                    className='texto-caracteristica'
                                                    style={{ color: '#232ED1' }}
                                                >
                                                    {currency(item.costo)} MXN
                                                </span>
                                            </div>
                                            <div className='caracteristica'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='16'
                                                    height='16'
                                                    fill='currentColor'
                                                    className='bi bi-check-circle-fill inactive-li'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                                                </svg>
                                                <span className='texto-caracteristica'>
                                                    Link personalizado
                                                </span>
                                            </div>
                                            <div className='caracteristica'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='16'
                                                    height='16'
                                                    fill='currentColor'
                                                    className='bi bi-check-circle-fill inactive-li'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                                                </svg>
                                                <span className='texto-caracteristica'>
                                                    Productos ilimitados
                                                </span>
                                            </div>
                                            <div className='caracteristica'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='16'
                                                    height='16'
                                                    fill='currentColor'
                                                    className='bi bi-check-circle-fill inactive-li'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                                                </svg>
                                                <span className='texto-caracteristica'>
                                                    Asistencia técnica
                                                </span>
                                            </div>
                                            <div className='caracteristica'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='16'
                                                    height='16'
                                                    fill='currentColor'
                                                    className='bi bi-check-circle-fill inactive-li'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                                                </svg>
                                                <span className='texto-caracteristica'>
                                                    Conexión con mercado libre, amazon, WooCommerce,
                                                    Prestashop
                                                </span>
                                            </div>
                                            <div className='caracteristica'>
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='16'
                                                    height='16'
                                                    fill='currentColor'
                                                    className='bi bi-check-circle-fill inactive-li'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
                                                </svg>
                                                <span className='texto-caracteristica'>
                                                    IVA incluido
                                                </span>
                                            </div>
                                            <button
                                                className='boton-comprar-paquete'
                                                onClick={() => agregarPaquete(item.id)}
                                            >
                                                <svg
                                                    width='22'
                                                    height='22'
                                                    viewBox='0 0 22 22'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        d='M3.99984 5.41396L0.756836 2.17196L2.17184 0.756958L5.41384 3.99996H20.6558C20.8117 3.99995 20.9654 4.03637 21.1047 4.10633C21.244 4.17628 21.365 4.27782 21.4581 4.40285C21.5511 4.52789 21.6137 4.67294 21.6407 4.82644C21.6678 4.97995 21.6586 5.13765 21.6138 5.28696L19.2138 13.287C19.1521 13.493 19.0255 13.6737 18.853 13.8022C18.6804 13.9306 18.471 14 18.2558 14H5.99984V16H16.9998V18H4.99984C4.73462 18 4.48027 17.8946 4.29273 17.7071C4.10519 17.5195 3.99984 17.2652 3.99984 17V5.41396ZM5.99984 5.99996V12H17.5118L19.3118 5.99996H5.99984ZM5.49984 22C5.10201 22 4.72048 21.8419 4.43918 21.5606C4.15787 21.2793 3.99984 20.8978 3.99984 20.5C3.99984 20.1021 4.15787 19.7206 4.43918 19.4393C4.72048 19.158 5.10201 19 5.49984 19C5.89766 19 6.27919 19.158 6.5605 19.4393C6.8418 19.7206 6.99984 20.1021 6.99984 20.5C6.99984 20.8978 6.8418 21.2793 6.5605 21.5606C6.27919 21.8419 5.89766 22 5.49984 22ZM17.4998 22C17.102 22 16.7205 21.8419 16.4392 21.5606C16.1579 21.2793 15.9998 20.8978 15.9998 20.5C15.9998 20.1021 16.1579 19.7206 16.4392 19.4393C16.7205 19.158 17.102 19 17.4998 19C17.8977 19 18.2792 19.158 18.5605 19.4393C18.8418 19.7206 18.9998 20.1021 18.9998 20.5C18.9998 20.8978 18.8418 21.2793 18.5605 21.5606C18.2792 21.8419 17.8977 22 17.4998 22Z'
                                                        fill='#232ED1'
                                                    />
                                                </svg>
                                                Conseguir ahora
                                            </button>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export { PlanesYPrecios };
