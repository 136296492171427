import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { descargarFactura } from 'actions/archivos';
import TituloPagina from 'components/header/TituloPagina';
import Table from 'components/table/Table'
import Swal from 'sweetalert2'
import moment from 'moment';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';

const $ = require('jquery')

const ListRecibidos = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const baseUrl = process.env.REACT_APP_API_URL;
    let [url, setUrl] = useState(`Facturacion/Facturas/CfdisGetList?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E`);
    let [ejercicio, setEjercicio] = useState(moment().format('Y'));
    let [mes, setMes] = useState('');
    let [estatusSat, setEstatusSat] = useState('T');
    let [estatusPago, setEstatusPago] = useState('T');
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [montoTotal, setMontoTotal] = useState('');

    const SelectAnios = (props) => {
        const year = props.year
        const beforeYear = year - 10
        let años = []
        for(let x = year; x >= beforeYear; x--)
        {
            años.push(x);           
        }

        return (
            <select className='selectCustomTwo m-2 w-100' onChange={props.onChange}>
                {
                años.map((item,i)=>(

                        props.selected == item ?
                        <option key={i} selected value={item}>{item}</option>
                        :
                        <option key={i} value={item}>{item}</option>
                ))
                }
             </select> 
        );
    }
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Facturas Recibidas' ,
          subtitulo: 'Aquí se guarda el historial de facturas que has emitido' 
        });
      };
    React.useEffect(() => {
        montoTotalFunc();
        actualizarTitulo();
    }, []);



    const filtrarPorFecha = () => {
        montoTotalFunc();
        
        setUrl(`Facturacion/Facturas/CfdisGetList?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=E&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}`);
    }

    const onChangeEjercicio = (e) =>{
        ejercicio = e.target.value
        setEjercicio(ejercicio)

        filtrarPorFecha()
    }

    const onChangeMes = (e) =>{
        mes = e.target.value
        setMes(mes)
        filtrarPorFecha()
    }
    const filtrarEstatusSat = (e) => {
        estatusSat = e.target.value;
        setEstatusSat(e.target.value);
        filtrarPorFecha();
        
    }

    const filtrarEstatusPago = (e) => {
        estatusPago = e.target.value;
        setEstatusPago(e.target.value);
        filtrarPorFecha();
        
    }

    const montoTotalFunc = () => {

        fetchConToken(`Facturacion/Facturas/MontoTotalFacturasFiltro?sucursal_Id=${getDataSession('sucursal')}&tipo_factura=R&mes=${mes}&ejercicio=${ejercicio}&estatusSat=${estatusSat}&estatusPago=${estatusPago}`).then(function (response) {
            if (response.hasOwnProperty('status')) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setMontoTotal(response.montoTotal);
        });

    }
    

    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "pdf,xml,MarcarPagado",viewPdf : 'Facturacion/DescargarPDF',  xml: 'Facturacion/DescargarXML', marcarpagado: marcarpagado, marcarpagadoUrl: "Facturacion/MarcarPagado"},
        { field: 'id', headerName: 'Id', width: 70, hide: true },
        { field: 'fecha_Timbrado', headerName: 'Fecha', flex: 1},
        { field: 'folio', headerName: 'Folio', flex: 1 },
        { field: 'rfc_Emisor', headerName: 'RFC Emisor', flex: 1},
        { field: 'nombre_Emisor', headerName: 'Nombre Emisor' , flex: 1},
        { field: 'uso_Cfdi', headerName: 'Uso CFDI', flex: 1 },
        { field: 'total', headerName: 'Total', flex: 1 },
        { field: 'moneda', headerName: 'Moneda', flex: 1 },
        { field: 'estatus_Pago', headerName: 'Estatus Pago', flex: 1 },
        { field: 'fecha_Pago', headerName: 'Fecha Pago', flex: 1 },
        
    ];

    function marcarpagado(url){

        Swal.fire({
             icon: 'warning',
             html:'Esta seguro de registrar como pagada la factura<br/><input id="datetimepicker" type="date" class="form-control" autofocus>',
             showCancelButton: true,
             confirmButtonText: "Registrar Pago",
             cancelButtonText: 'Cancelar'
         }).then((result) => {
             if (result.isConfirmed) {
                  if($('#datetimepicker').val() !== undefined && $('#datetimepicker').val().length > 0 ){                    
                       fetchConToken(
                           url+"&fecha="+$('#datetimepicker').val(),
                            null,
                            'POST'
                       ).then(function (response) {
                            if (response.hasOwnProperty('status') && response.status === 400) {
                            Object.entries(response.errors).map(([key, value]) => {
                                 Swal.fire('Error', value[0], 'error');
                            });
               
                            return;
                            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                            Swal.fire('Error', response.mensajes[0], 'error');
                    
                            return;
                            }
                   
                            Swal.fire('Exitoso', 'Orden Actualizada', 'success');
                       });
                  }
                  else
                  {
                       Swal.fire('Info', 'Seleccione una fecha de pago', 'info');
                  }
   }
   })
   }


    return (
        <>
            <div className='container-fluid'>
                
                <div className='col-md-12 row '>
                    <div className='col-md-3'>
                    <div className='d-flex'>
                        <label className='me-1'>Ejercicio</label>
                        <SelectAnios year={moment().format('Y')} onChange={onChangeEjercicio} selected={ejercicio} />
                    </div>
                    <div className='d-flex'>
                        <label className='me-1'>Periodo</label>
                        <select className='selectCustomTwo m-2 w-100' onChange={onChangeMes}>
                            <option value=''>Todos</option>
                            <option value='01'>Enero</option>
                            <option value='02'>Febrero</option>
                            <option value='03'>Marzo</option>
                            <option value='04'>Abril</option>
                            <option value='05'>Mayo</option>
                            <option value='06'>Junio</option>
                            <option value='07'>Julio</option>
                            <option value='08'>Agosto</option>
                            <option value='09'>Septiembre</option>
                            <option value='10'>Octubre</option>
                            <option value='11'>Noviembre</option>
                            <option value='12'>Diciembre</option>
                        </select>
                    </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus SAT:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusSat}>
                                <option value='T'>Todos</option>
                                <option value='V'>Vigente</option>
                                <option value='C'>Cancelado</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='d-flex'>
                            <label className='me-1'>Estatus Pago:</label>
                            <select className='selectCustomEstatusSat m-2 w-100' onChange={filtrarEstatusPago}>
                                <option value='T'>Todos</option>
                                <option value='P'>Pendiente Pago</option>
                                <option value='F'>Pagado</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-sm-12 col-md-12'>
                <div className='col-md-12' >

                <ListTable
                        columns = {columns}
                        path = {path}
                        url={url}
                    />

                    {/*<Table 
                        columns = {['Fecha','Folio','RFC Emisor','Nombre Emisor','Uso CFDI','Total','Moneda','Estatus Pago','Fecha Pago','Acciones']}
                        rows = {[
                                ['fecha','fecha_Timbrado'],
                                ['text','folio'],
                                ['date','rfc_Emisor'],
                                ['date','nombre_Emisor'],
                                ['text','uso_Cfdi'],
                                ['moneda','total'],
                                ['text','moneda'],
                                ['text','estatus_Pago'],
                                ['fecha','fecha_Pago'],
                                ['opciones','id']
                        ]}
                        opciones={"pdf,xml,MarcarPagado"}
                        ruta = "Facturacion"
                        path = {path}
                        url={url}>
                    </Table>*/}
                    </div> 
                    <div className='col-md-12 text-center' style={{paddingBotton: "50px;"}}>
                    <label>Total: {montoTotal}</label>
                </div>
                </div>
            </div>
        </>
    );
};

export { ListRecibidos };
