//overview: '/overview',
const SLUGS=  {
    // private
    dashboard: '/dashboard',

    articulos: '/articulos',
    diferencias: '/articulos/diferencias',
    publicadormasivo: '/publicador-masivo',
    asignacionautomatica: '/asignacion-automatica',
    
    // auth
    login: '/login',
    signup: '/signup',
    register: '/register',
    activation: '/activation/:token',

    forgotPassword: '/forgotpassword',
    resetpassword: '/resetpassword/:token',
    //home
    clientes: '/clientes',

    //procesos
    //procesos: '',
    mensajes: '/mensajes',
    tiposMensajes: '/tiposmensajes',
    respuestasMensajes: '/RespuestasMensajes',

    ventas: '/ventas',
    sucursal: '/conexion',
    impuestos: '/impuestos',
    facturas: '/facturas',
    facturasRecibidas: '/FacturasRecibidas',
    usuarios: '/usuarios',
    empresa: '/empresa',
    empresas: '/empresas',
    sucursales: '/conexion',
    sellosfiscales: '/sellosfiscales',
    //tienda: '/tienda',
    camposempresa: '/camposempresa',
    paquetes: '/paquetes',
    carrito: '/carrito',
    planesyprecios: '/PlanesYPrecios',
    facturar: '/facturar/:id',
    facturar: '/facturar',
    movimientos: '/movimientos',
    bolsatimbres: '/bolsatimbres',
    FacturacionGlobal: '/FacturacionGlobal',
    descargasat: '/descargasat',
    ayuda: '/ayuda',
    soporte: '/Soporte',
    miperfil: '/MiPerfil',

    pagomercadopago: 'PagoMercadoPago',

    mercadolibre: '/MercadoLibre',

    categorias: '/categorias',
    almacenes: '/almacenes',

    facturacion: '/facturacion/:empresa',
    lognotificaciones: '/lognotificaciones',

    rolesclaves: '/rolesclaves',

    //documentos
    documentos: '/documentos',
    cotizaciones: '/documentos/cotizaciones',
    pedidos: '/documentos/pedidos',
    remisiones: '/documentos/remisiones',

    listaprecios: '/listaPrecios',

    compras: '/compras',
    comprasList: '/compras/compras',
    ordencompra: '/compras/ordenesCompras',
    recepcioncompra: '/compras/recepcionCompra',

    vxgruporutas: '/gruporutas',
    vxrutas: '/rutas',
    vxCobros: '/vxcobros',
    vxCreditos: '/vxcreditos',
    vxcreditosProveedores: '/vxcreditosProveedores',
    vxCompras: '/compras',
    vxCargos: '/vxcargos',

    proveedores: '/proveedores',
    condicionpago: '/CondicionPago',
    
    vxmovimientos: '/vxmovimientos',
    vxpedidos: '/vxpedidos',
    vxventas: '/vxventas',
    vxfacturas: '/vxfacturas',
    vxsaldosproveedores: '/vxsaldosproveedores',
    vxotrosdocumentos: '/vxotrosdocumentos',
    vxplanvisita: '/vxplanvisita',


    ConfigAppMaxventas: '/ConfigAppMaxventas',


    //enddocumentos
    pagos: '/pagos',
    // Estadisticas
    est_ingresos_diarios: '/estadisticas/ingresos-diarios',
    est_ingresos_mensuales: '/estadisticas/ingresos-mensuales',
    est_egresos_mensuales: '/estadisticas/egresos-mensuales',
    est_ingresos_vs_egresos_mensuales: '/estadisticas/ingresos-vs-egresos-mensuales',
    est_top_clientes: '/estadisticas/top-clientes',
    est_top_proveedores: '/estadisticas/top-proveedores',
    file: '/file',

    //documentacion legal
    avisoprivacidad: '/AvisoPrivacidad',
    terminoscondiciones: '/TerminosCondiciones',
    seriesEmpresa: '/SeriesEmpresa/edit/:id',
    home: '/'
    
    
};

export default SLUGS;
