import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession, getFirstDay, getLastDay } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { alertService } from '_services';
import TituloPagina from 'components/header/TituloPagina';
import { useGlobalState } from 'resources/GlobalStateContext';
import * as FileSaver from 'file-saver';

import Table from 'components/table/Table'

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    var curr = new Date();
    curr.setDate(curr.getDate() + 3);
    const [data, setData] = useState([]);
    const [busqueda, setbusqueda] = useState('');
    const [fecha_Inicial, setFechaInicial] = useState(curr.toISOString().substring(0, 10));
    const [totalxPagina, setTotalxPagina] = useState(10);

    let [pagina, setPagina] = useState('');

    React.useEffect(() => {
        getData(1);
        actualizarTitulo();
    }, []);
    
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Movimientos',
          subtitulo: 'Lista de las compras realizadas.'
        });
      };

    async function getData(page) {
        setPagina(page);
        const response = await fetchConToken(
            `Movimiento/GetList?paginas=${page}&totalXpagina=${totalxPagina}`
        );
        setData(response);
    }

    const handleChangeDate = ({ target }) => {
        setFechaInicial(target.value);
    };

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };
  
    const handleChangeTotalXpagina = ({ target }) => {
        const pagination = target.value;
        setTotalxPagina(pagination);
    };

    return (
        <>
            <div className='container-fluid'>
              
                <div className='row row mb-3'></div>
                {
                     getDataSession('rol') == 'ADMINISTRADOR' ? 
                     <Link to={`${path}/add`} className='btn btn-outline-primary'>
                     <svg
                         xmlns='http://www.w3.org/2000/svg'
                         width='20'
                         height='20'
                         fill='currentColor'
                         className='bi bi-plus-square'
                         viewBox='0 0 16 16'
                     >
                         <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                         <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                     </svg>
                     &nbsp; Asignar Paquete
     </Link>
     : ''
                }
               
               
                <div className='col-md-12' >
                    <Table 
                    columns = {[
                        'Paquete',
                        'Razon',
                        'Folio',
                        'Fecha Movimiento',
                        'Fecha Finalizacion de Paquete',
                        'Folios Restantes',
                        'Tipo Pago',
                        'Referencia',
                        getDataSession('rol') == 'ADMINISTRADOR' ? 'Empresa' : 'vacio'
                    ]}
                    rows = {[
                            ['object', 'paquete_Timbre', 'nombre'],
                            ['text','razon'],
                            ['text','folio'],
                            ['date','fecha_Mvto'],
                            ['date','fecha_Finalizacion'],
                            ['text','restantes'],
                            ['text','tipo_Pago'],
                            ['text','referencia'],
                            getDataSession('rol') == 'ADMINISTRADOR' ? ['object', 'empresa', 'nombre'] : ['vacio', 'empresa']
                    ]}
                    path = {path}
                    url={`/Movimiento/List?sucursal_Id=${getDataSession('sucursal')}`}>
                    </Table>
                    {/*    <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Paquete</th>
                                    <th>Folio</th>
                                    <th className='text-center'>Fecha Movimiento</th>
                                    <th className='text-center'>Fecha Finalizacion de Paquete</th>
                                    <th className='text-right'>Folios Restantes</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(data?.length > 0) ?  data.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.razon}</td>
                                            <td>OP{item.folio}</td>
                                            <td className='text-center'>{item.fecha_Mvto?.substring(0, 10)}</td>
                                            <td className='text-center'>{item.fecha_Finalizacion?.substring(0, 10)}</td>
                                            <td className='text-right'> {item.restantes}</td>
                                        </tr>
                                    );
                                }): <tr>
                                <td className='text-center' colSpan={11}>Sin Movimientos</td>
                                </tr>}
                            </tbody>
                            </table>*/}
                 
                </div>
            </div>
        </>
    );
};

export { List };
