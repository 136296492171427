import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { AddEdit } from './AddEdit';

import { List } from "./List";

function RolesClaves({ match }) {
    const { path } = match;

    return (
        <Switch>
            <Route exact path={path} component={List} />
            <Route path={`${path}/add`} component={AddEdit} />
            <Route path={`${path}/edit/:id`} component={AddEdit} />            
            <Redirect from="*" to={{
                                pathname: List
                            }}/>
        </Switch>
    );
}


export { RolesClaves };
