import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { CfgEmpresa } from "./Empresa";
import { AddEditSucursal } from "./AddEditSucursal";

function Configempresa({ match, saludo, subtitulo }) {
    const { path } = match;
 

    return (
        <Switch>
            <Route exact path={path} component={CfgEmpresa} />
            <Route path={`${path}/conexion/add/:empresa`} component={AddEditSucursal} />
            <Route path={`${path}/conexion/:id`} component={AddEditSucursal} />
            <Redirect from="*" to={{
                                pathname: CfgEmpresa
                            }}/>
        </Switch>
    );
}


export { Configempresa };

//export default Sucurs;