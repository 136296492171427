import React from 'react';
import { Redirect, Route, Switch } from "react-router";
import { Config } from "./Config";

function ConfigAppMaxventas({ match }) {
    const { path } = match;
    
    return (
        <Switch>
            <Route exact path={path} component={Config} /> 
            <Redirect from="*" to={{pathname: Config}}/>
        </Switch>
    ); 
}


export { ConfigAppMaxventas };