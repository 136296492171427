import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession, setDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import SLUGS from '../../resources/slugs';
import { useForm } from 'react-hook-form';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Swal from 'sweetalert2';
import shopify from 'images/panel/conexiones/shopify.png';
import tiendanube from 'images/panel/conexiones/tiendanube.png';
import wix from 'images/panel/conexiones/wix.png';
import woocommerce from 'images/panel/conexiones/woocommerce.png';
import amazon from 'images/panel/conexiones/amazon.png';
import mercadolibre from 'images/panel/conexiones/mercadolibre.png';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';
import { useGlobalState } from 'resources/GlobalStateContext';
import { useTranslation } from "react-i18next";


const $ = require('jquery');

const Sucursales = ({ history, match, saludo, subtitulo }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const { t } = useTranslation("common");
    let [actualizarTabla, setActualizarTabla] = useState(false);
    const _AUTH_MERCADOLIBRE = process.env.REACT_APP_AUTH_MERCADOLIBRE; 

    const { path } = match;
    const { handleSubmit, reset } = useForm();
    //const id = getDataSession('empresa');
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState('');

    let [sucursales, setSucursales] = useState(['']);

    let [tienda, setTienda] = useState({});
    let [error, setError] = useState(['']);
    let [errores, setErrores] = useState({});

    let {
        empresa_Id = getDataSession('empresa'),
        nombre = '',
        url_tienda = '',
        api_key = '',
        secret_key = ''
    } = tienda;

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo,
          titulo: 'Mis canales de venta',
          subtitulo: ''
        });
      };

    React.useEffect(() => {
        actualizarTitulo();
        cargarSucursalPrincipal();
        
        $('.dataTables_length').append("&nbsp; &nbsp; &nbsp; &nbsp; <label>Estatus:"+
        "<select class='select-filter-status' >"+
        "<option value='TODOS'>Todos</option>"+
        "<option value='EXITOSA'>Exitosa</option>"+
        "<option value='INCORRECTA'>Incorrecta</option>"+
        "<option value='PENDIENTE'>Pendiente de aprovación</option>"+
        "</select></label>");

        $("body").on("change", ".select-filter-status", function () {
         var table = $('#table-js').DataTable();
         table.columns(1).search( this.value).draw();
       });

    }, []);

    const cargarSucursalPrincipal=()=>{
        fetchConToken(`Sucursales/Principal`).then(function (response) {
            setDataSession('sucursalPrincipal',response.id);
        });
    }

    async function agregarSucursal() {
        console.log(modalValor);
        setModalValor('');
        openModal();
    }

    const handleInputChange = ({ target }) => {
        setTienda({
            ...tienda,
            [target.name]: target.value
        });
    };

    const handleInputChangeSelect = ({ target }) => {
        setModalValor(target.value);
    };

    const codigoMercadoLibre = async () => {
        if(nombre.length == 0)
        {
            Swal.fire('Info', "Escriba un nombre antes de autorizar la aplicación", 'info');
            return;
        }
        closeModal();
        var sucursal = {
            empresa_Id: getDataSession('empresa'), nombre: nombre, estatus: 'A', conexion: '0'
        };
        var table = $('#table-js').DataTable();
        await fetchConToken('Sucursales?tienda=MERCADOLIBRE', sucursal, 'POST').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    closeModal();
                    table.ajax.reload();
                    return;
                });
                table.ajax.reload();
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                table.ajax.reload();
                closeModal();
                return false;
            }
            setDataSession('sucursal', response.id);

            closeModal();

            Swal.fire('Exito', 'Conexión Agregada', 'success');

            setDataSession('sucursalMercadoLibre', response.id);

            window.open(
                _AUTH_MERCADOLIBRE,
                '_blank'
            );

            window.location.reload();
        });

        

    };

    function guardarConfiguracionWooCommerce() {
        guardarConfiguracionECommerce("woocommerce")
    }

    function guardarConfiguracionShopyfi() {
        guardarConfiguracionECommerce("Shopify")
    }

    function guardarConfiguracionECommerce(tienda) {
        setError('');
        setErrores('');
        closeModal();

        var sucursal = {
            empresa_Id: getDataSession('empresa'),
            nombre: nombre,
            estatus: 'A',
            conexion: '0'
        };

        fetchConToken('Sucursales', sucursal, 'POST').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                setError('Revisar errores');
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            setDataSession('sucursal', response.id);
            var table = $('#table-js').DataTable();
            fetchConToken(
                `ConfiguracionesSucursales/${tienda}?url_tienda=${url_tienda}&key=${api_key}&secret=${secret_key}&sucursal_id=${response.id}`,
                null,
                'POST'
            ).then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresC = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                    });
                    table.ajax.reload();
                    closeModal();
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    closeModal();
                    table.ajax.reload();
                    return false;
                }
                closeModal();
                Swal.fire('Exito', 'Conexion Agregada', 'success');
                table.ajax.reload();
                window.location.reload()
            });
        });
    }

    

    const deshabilitar = (id_suc) => {
        Swal.fire({
            title: "DesHabilitar Sucursal",
            text: "¿Esta seguro de deshabilitar la sucursal?",
            showCancelButton: true,
            confirmButtonText: "Deshabilitar",
            cancelButtonText: t('app.botones.cancelar')
        }).then((result) => {
            if (result.isConfirmed) {
                fetchConToken(
                    `sucursales/deshabilitar?id=${id_suc}`,
                    null,
                    'POST'
                ).then(function (response) {
                    if (response.hasOwnProperty('status') && response.status === 400) Object.entries(response.errors).map(([key, value]) => Swal.fire('Error', value[0], 'error'));
                    else if (response.hasOwnProperty('codigo') && response.codigo !== 200) Swal.fire('Error', response.mensajes[0], 'error');
                    else {
                        Swal.fire('Exito', "Si desea habilitar o eliminar la sucursal consulte con soporte", 'success');
                        setActualizarTabla(true);
                        setActualizarTabla(false);
                    }

                });
            }
        }) 

    };

    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "URLeditar,deshabilitar",UrlEditar : SLUGS.empresa + '/conexion/',deshabilitar:deshabilitar},
        {
            field: 'id', headerName: 'Id',  maxWidth: 20 , renderCell: (params) => (
                <a href={`${path.replace('conexion','empresa/conexion/') +  params.row.id}`}>{params.value}</a>)
        },
        {
            field: 'nombre', headerName: 'Nombre',  minWidth: 150 , renderCell: (params) => (
                <a href={`${path.replace('conexion','empresa/conexion/')+  params.row.id}`}>{params.value}</a>)
        },
        { field: 'cp', headerName: 'Codigo Postal', flex: 1 },
        { field: 'colonia', headerName: 'Colonia', flex: 1},
        { field: 'estatus', headerName: 'Estatus', flex: 1 },
        { field: 'conexion', headerName: 'Conexión activa' , flex: 1, renderCell: ({ value }) =>  value === "1" },
        { field: 'matriz', headerName: 'Es Matriz', flex: 1,type: 'boolean' }
        
    ];

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12' style={{ display: 'grid' }}>
                        <div className='col-md-12 '>
                            <div className='col-md-12'>
                                <button
                                    type='button'
                                    className='btn btn-outline-primary'
                                    onClick={() => agregarSucursal()}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-plus-square'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                        <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                                    </svg>
                                    &nbsp; Nuevo canal
                                </button>
                            </div>

                            <div className='col-md-12'>
                                <p style={{ color: 'red' }}>{error}</p>
                                {Object.entries(errores).map(([key, value]) => {
                                    return (
                                        <>
                                            <p style={{ color: 'red' }}>{value}</p>
                                        </>
                                    );
                                })}
                            </div>
                            <div className='col-md-12'>

                            <ListTable
                                columns = {columns}
                                path = 'Sucursales'
                                url={`Sucursales/GetListSucursales?`}
                                hideSearch={true} 
                                reload={actualizarTabla} 
                            />

                               {/* <Table
                                    columns={[
                                        'Nombre',
                                        'Codigo Postal',
                                        'Colonia',
                                        'Conexión',
                                        'Es Matriz',
                                        'Acciones'
                                    ]}
                                    rows={[
                                        ['text', 'nombre'],
                                        ['text', 'cp'],
                                        ['text', 'colonia'],
                                        ['conexion', 'conexion'],
                                        ['bool', 'matriz'],
                                        ['opciones','id']
                                    ]}
                                    opciones={"urlExtra,eliminar"}
                                    path={'Sucursales'}
                                    pathExtra={SLUGS.empresa + '/conexion/'}
                                    url={`/Sucursales/List?empresa_id=${getDataSession('empresa')}`}
                                ></Table>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                <div className='espacio15px' />
                <h4 className='text-center'>Seleccione su e-Commerce</h4>
                <div className='col-md-12 row'>
                    <div className='col-sm-1' />
                    <div className={modalValor === '' ? 'col-md-11 row' : 'col-md-7 row'}>
                        <button type='button' className='btn col-md-4 img_E-Commerce' onClick={()=>{setModalValor('MercadoLibre')}}>
                            <img src={mercadolibre}/>
                        </button>
                        <button type='button' className='btn col-md-4 img_E-Commerce' onClick={()=>{setModalValor('WooCommerce')}}>
                            <img src={woocommerce}/>
                        </button>
                        <button type='button' className='btn col-md-4 img_E-Commerce' onClick={()=>{setModalValor('Shopify')}}>
                            <img src={shopify}/>
                        </button>
                        <button type='button' className='btn col-md-4 img_E-Commerce' onClick={()=>{setModalValor('NoDisponible')}}>
                            <img src={amazon}/>
                        </button>
                        <button type='button' className='btn col-md-4 img_E-Commerce' onClick={()=>{setModalValor('NoDisponible')}}>
                            <img src={tiendanube}/>
                        </button>
                        <button type='button' className='btn col-md-4 img_E-Commerce' onClick={()=>{setModalValor('NoDisponible')}}>
                            <img src={wix}/>
                        </button>

                    </div>
                    <div className='col-md-4'>
                    {modalValor === 'NoDisponible' ? (<><div className='espacio30px' /><div className='espacio45px' /><h3>No dispobible por el momento</h3></>) : ('')}
                    {modalValor === 'MercadoLibre' ? (
                    <> 
                        <div className='col-md-12'>
                            <h3>Mercado Libre</h3>
                            <label htmlFor='nombre'>Nombre Conexión</label>
                            <input
                                type='text'
                                className='form-control'
                                name='nombre'
                                value={nombre}
                                onChange={handleInputChange}
                                required={true}
                            />
                            <br></br>
                            <label htmlFor='nombre'>Autorice su conexion con Mercado Libre: </label>
                            <a href='#' onClick={() => codigoMercadoLibre()}>
                                {' '}
                                Autorizar Mercado Libre{' '}
                            </a>
                        </div>
                    </>
                ) : ('' )}
                {modalValor === 'WooCommerce' ? (
                    <>
                        <form onSubmit={handleSubmit(guardarConfiguracionWooCommerce)}>
                            <div className='col-md-12 row'>
                            <div className='col-md-12 text-right'>
                                    <button className='btn btn-outline-success' type='submit'>
                                        Guardar
                                    </button>
                                </div>
                                <div className='col-md-12 text-left'>
                                    <h3>WooCommerce</h3>
                                </div>
                               
                                <label htmlFor='nombre'>Nombre Conexión</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='nombre'
                                    value={nombre}
                                    onChange={handleInputChange}
                                    required={true}
                                />

                                <label htmlFor='nombre'>URL Tienda</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='url_tienda'
                                    value={url_tienda}
                                    onChange={handleInputChange}
                                    required={true}
                                />

                                <label htmlFor='api_key'>API Key</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='api_key'
                                    value={api_key}
                                    onChange={handleInputChange}
                                    required={true}
                                />

                                <label htmlFor='secret_key'>Secret Key</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='secret_key'
                                    value={secret_key}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                <br></br>
                            </div>
                        </form>
                    </>
                ) : ('')}
                {modalValor === 'Shopify' ?   <>
                        <form onSubmit={handleSubmit(guardarConfiguracionShopyfi)}>
                            <div className='col-md-12 row'>
                            <div className='col-md-12 text-right'>
                                    <button className='btn btn-outline-success' type='submit'>
                                        Guardar
                                    </button>
                                </div>
                                <div className='col-md-12 text-left'>
                                    <h3>Shopify</h3>
                                </div>
                               
                                <label htmlFor='nombre'>Nombre Conexión</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='nombre'
                                    value={nombre}
                                    onChange={handleInputChange}
                                    required={true}
                                />

                                <label htmlFor='nombre'>URL Tienda</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='url_tienda'
                                    value={url_tienda}
                                    onChange={handleInputChange}
                                    required={true}
                                />

                                <label htmlFor='api_key'>API Key</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='api_key'
                                    value={api_key}
                                    onChange={handleInputChange}
                                    required={true}
                                />

                                <label htmlFor='secret_key'>Secret Key</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='secret_key'
                                    value={secret_key}
                                    onChange={handleInputChange}
                                    required={true}
                                    reload={actualizarTabla}
                                />
                                <br></br>
                            </div>
                        </form>
                    </> : ''}
                    </div>

                </div>
                <div className='espacio15px' />
                {/*<select className='form-control' onChange={handleInputChangeSelect}>
                    <option value=''>Seleccione su e-Commerce</option>
                    <option value='MercadoLibre'>Mercado Libre</option>
                    <option value='WooCommerce'>WooCommerce</option>
                    <option value='Shopify'>Shopify</option>
                </select>
                <br></br>*/}
            </Modal>
        </>
    );
};

Sucursales.propTypes = {
    saludo: PropTypes.string
};

Sucursales.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { Sucursales };
