import React, { useState, useEffect } from 'react';
import { HubConnection,HubConnectionBuilder,HttpTransportType } from '@microsoft/signalr';
import { getDataSession,setDataSession, getFirstDay, getLastDay } from 'views/Utils/Common';
import Swal from 'sweetalert2';
import { alertService } from '_services';

const Notificacion = (props) => {
  const $ = require('jquery')
    const baseUrl = process.env.REACT_APP_API_URL;
    const [connection, setConnection] = useState(null);
    var [mensajeNotificacion, setMensajeNotificacion] = useState('');
    var [toast, setToast] = useState(false);

React.useEffect(() => {
    const connect = new HubConnectionBuilder()
    .withUrl(baseUrl+"/notificacion",{
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
    .withAutomaticReconnect()
    .build();
    setConnection(connect);
}, []);

React.useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(async () => {
          await connection.invoke("Add", getDataSession('empresa') +"-"+ getDataSession('sucursal'));

          connection.on("ReceiveMessage", function(message){
            setMensajeNotificacion(message);
            Swal.fire("",message,"info");
          });

          connection.on("ReceiveMessageSucursal", function(message){
              setMensajeNotificacion(message);
              Swal.fire("",message,"info");

              var table = $('#table-js').DataTable();
              table.ajax.reload();

          });

          

          connection.on("ReceiveNotificacion", function(message){
            setMensajeNotificacion(message);
            alertService.success(message, { keepAfterRouteChange: false });
            setDataSession("articulos_descargados",message)
          });

        })
        .catch((error) => console.log(error));
    }

  }, [connection]);

  return <div className=''>
        </div>
}

export default Notificacion;
