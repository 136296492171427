import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
 
const List = ({ match, saludo, subtitulo }) => {
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const { path } = match;

    const columns = [
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'metodo', headerName: 'Metodo', flex: 1},
        { field: 'mensaje', headerName: 'Mensaje', flex: 1,editable: true },
        { field: 'Codigo', headerName: 'codigo', flex: 1},
        { field: 'fecha_Envio', headerName: 'Fecha' , flex: 1}
    ];

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo,
          titulo: 'Notificaciones',
          subtitulo: 'Listado de notificaciones de los mensajes de su e-Commerce.'
        });
      };

      React.useEffect(() => {
        actualizarTitulo();
      },[]);

    return (
        <>
            <div className='container-fluid'>


                <div className='col-md-12'>

                <ListTable
                    
                    columns={columns}
                    path = {path}
                    url={`LogNotificacion/GetList?sucursal_Id=${getDataSession('sucursal')}`}
                  />
                    {/*<Table
                        columns={['Metodo', 'Mensaje', 'Codigo', 'Fecha']}
                        rows={[
                            ['text', 'metodo'],
                            ['text', 'mensaje'],
                            ['text', 'codigo'],
                            ['date', 'fecha_Envio']
                        ]}
                        path={path}
                        url={`/LogNotificacion/List?sucursal_Id=${getDataSession('sucursal')}`}
                    ></Table>*/}
                    {/*
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Metodo</th>
                                    <th>Mensaje</th>
                                    <th>Codigo</th>
                                    <th>Fecha</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(data?.length > 0) ?  data?.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.metodo}</td>
                                            <td>{item.mensaje}</td>
                                            <td>{item.codigo}</td>
                                            <td>{item.fecha_Envio}</td>
                                        </tr>
                                    );
                                }) : <tr>
                                    <td className='text-center' colSpan={11}>Sin Notificaciones</td>
                                    </tr>}
                            </tbody>
                            </table>*/}
                </div>
            </div>
        </>
    );
};

export { List };
