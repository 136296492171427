import React, { useState } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { alertService } from '_services';
import { Link } from 'react-router-dom';
import { descargarFactura } from 'actions/archivos';
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';
import { useForm } from 'react-hook-form';
import { currency, rfcValido } from 'resources/utilities';
import { useGlobalState } from 'resources/GlobalStateContext';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import Swal from 'sweetalert2';

const Facturar = ({ history, match, saludo, subtitulo }) => {
    const { id } = match.params;
    const [facturado, setFacturado] = useState(false);
    const { handleSubmit } = useForm();
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [initialObject, setInitialObject] = useState(true);
    const [initialObjectClaveUnidad, setInitialObjectClaveUnidad] = useState(true);


    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    let [cfdi, setCfdi] = useState([]);

    let [cliente, setCliente] = useState([]);
    let [clientes, setClientes] = useState([]);
    let [direcciones, setDirecciones] = useState([]);
    let [direccionesList, setDireccionesList] = useState([]);
    let [venta, setVenta] = useState({
        id: 0,
        empresa_Id: getDataSession('empresa'),
        sucursal_Id: getDataSession('sucursal'),
        direccion_Id: 0,
        no_Orden: '',
        folio: '',
        moneda: 'MXN',
        descuento_Importe: 0,
        estatus: 'T',
        importe_Total_Impuestos: 0,
        metodo_Pago: 'PUE',
        clave_Envio: '',
        forma_De_Pago: '01'
    });
    let [ventaDetalles, setVentaDetalles] = useState([]);
    let [articulos, setArticulos] = useState([]);

    let [articulo, setArticulo] = useState([]);

    let [serie, setSerie] = useState('');

    let [art_id, setArtId] = useState('');

    let [sucursales, setSucursales] = useState([]);
    let [series, setSeries] = useState([]);

    var direccion_id = 0;

    const totalxPagina = 10;

    const busqueda = useFormInput('');
    let [error, setError] = useState(['']);
    let [errorRfc, setErrorRfc] = useState(['']);
    let [erroresCliente, setErroresCliente] = useState({});
    let [erroresDirecciones, setErroresDirecciones] = useState({});

    let [erroresV, setErroresV] = useState({});
    let [erroresArticulos, setErroresArticulos] = useState({});

    const [satUsoCfdi, setSatUsoCfdi] = useState([]);
    const [satRegimenFiscal, setSatRegimenFiscal] = useState([]);
    const [satFormaPago, setSatFormaPago] = useState([]);
    const [satPais, setSatPais] = useState(['']);

    React.useEffect(() => {
        getSucursales();
        getSatUsoCfdi();
        //getSatRegimelFiscal();
        getSatFormaPago();
        getSatPais();
        getSeries(getDataSession('sucursal'));

        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        actualizarTitulo();
    }, []);

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Facturacion',
          subtitulo: ''
        });
      };
    /////////////////////
    const loadData = async (query) => {
        var resp = await fetchConToken(
            `CatalogosSat/ListClaveProd?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );
        return resp;
    };
    const loadDataClaveUnidad = async (query) => {
        var resp = await fetchConToken(
            `CatalogosSat/ListClaveUnidad?pagina=${1}&totalXpagina=${50}&busqueda=${query}`
        );
        return resp;
    };


    function getByArticulo(art_id) {
        fetchConToken(`articulos?id=${art_id}&sucursal_Id=${getDataSession('sucursal')}`).then(
            (art) => {
                if (art.hasOwnProperty('status')) {
                    Object.entries(art?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (art.hasOwnProperty('codigo')) {
                    Swal.fire('Error', art.mensajes[0], 'error');
                    return;
                }

                setArticulo(art);

                fetchConToken(
                    `CatalogosSat/ListClaveProd?pagina=${1}&totalXpagina=${50}&busqueda=${
                        art?.clave_Prod_Serv
                    }`
                ).then((resp) => {
                    var aux = resp.find((e) => e.clave === art?.clave_Prod_Serv);
                    setInitialObject(aux);
                });

                getInitialClaveUnidad(art);
            }
        );
    }

    const getInitialClaveUnidad = async (art) => {

        await fetchConToken(
            `CatalogosSat/ClaveUnidad?claveUnidad=${art?.clave_Unidad}`
        ).then((resp) => {
            setInitialObjectClaveUnidad(resp);
            console.log(resp);
        });


        await fetchConToken(
            `CatalogosSat/ClaveProd?ClaveProd=${art?.clave_Prod_Serv}`
        ).then((resp) => {
            setInitialObject(resp);
            
            /*var aux = resp.find((e) => e.clave === art?.clave_Prod_Serv);
            setInitialObject(aux);*/
        });

        
    };

    function GuardarArticulo(id, data) {
        return fetchConToken('Articulos', articulo, 'PUT')
            .then((response) => {
                if (response.hasOwnProperty('status') && response.status === 400) {
                    var erroresA = {};
                    Object.entries(response.errors).map(([key, value]) => {
                        erroresA[key] = value[0];
                        Swal.fire('Error', value[0], 'error');
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
                closeModal();
                Swal.fire('Exito', 'Articulo modificado', 'success');

                ventaDetalles?.map((item) => {
                    if(item.articulo.id == response.id)
                    {
                        item.articulo.clave = response.clave;
                        item.articulo.nombre = response.nombre;
                        item.articulo.no_Identificacion = response.no_Identificacion;
                        item.articulo.clave_Prod_Serv = response.clave_Prod_Serv;
                        item.articulo.clave_Unidad = response.clave_Unidad;
                        
                        
                    }
                });

                setVentaDetalles([...ventaDetalles]);
               
            })
    }

    //////////////////////////
    async function getSatUsoCfdi() {
        const response = await fetchConToken(`CatalogosSat/ListUsoCfdi?pagina=1&totalXpagina=300`);
        setSatUsoCfdi(response);
    }

    async function getSatRegimelFiscal(uso_Cfdi_clave) {
        const response = await fetchConToken(`CatalogosSat/ListRegimenFiscal?pagina=1&totalXpagina=300&uso_cfdi_clave=${uso_Cfdi_clave}`);
        console.log("entro get regimen")
        setSatRegimenFiscal(response);
    }

    async function getSatFormaPago() {
        const response = await fetchConToken(`CatalogosSat/ListFormaPago?pagina=1&totalXpagina=300`);
        setSatFormaPago(response);
    }

    async function getSatPais() {
        const response = await fetchConToken(`CatalogosSat/ListPais?pagina=1&totalXpagina=300`);
        setSatPais(response);
    }

    async function getSucursales() {
        const response = await fetchConToken(
            `Sucursales/GetList?empresa_id=${getDataSession('empresa')}&pagina=1&totalXpagina=50`
        );
        setSucursales(response);
    }

    async function getSeries(sucursal_id) {
        const response = await fetchConToken(
            `Sucursales/GetFoliosSucursales?sucursal_Id=${sucursal_id}&tipo_Documento=F`
        );
        setSeries(response);
    }

    async function getDireccion(direccion_id) {
        const direccionAux = await fetchConToken(`DireccionesClientes?id=${direccion_id}`);
        setDirecciones(direccionAux);
        getCliente(direccionAux.cliente_Id);
    }

    /*async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(
            `Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`
        );
        clienteAux.regimen_Fiscal_Receptor =
            clienteAux.regimen_Fiscal_Receptor.replace(' ') === ''
                ? '601'
                : clienteAux.regimen_Fiscal_Receptor;
        clienteAux.uso_Cfdi = clienteAux.uso_Cfdi.replace(' ') === '' ? 'G01' : clienteAux.uso_Cfdi;
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
        console.log(clienteAux);
        console.log(clienteAux.clientes_Direcciones[0]);

        console.log(clienteAux)
    }*/

    const facturar = () => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        if (cliente.rfc > 0) {
            setError('Escriba correctamente el RFC');
            return '';
        }

        if (!cliente.hasOwnProperty('no_Cliente')) cliente.no_Cliente = 'local';
        cliente.sucursal_Id = getDataSession('sucursal');

        if (!direcciones.hasOwnProperty('no_Direccion')) direcciones.no_Direccion = 'local';

        setCliente(cliente);

        setDirecciones(direcciones);

        cliente.clientes_Direcciones = null;
        venta.direccion_Cliente = direcciones;
        venta.direccion_Cliente.cliente= cliente;
        venta.venta_Detalles = ventaDetalles;

        console.log(venta)

        facturarVenta(venta)

    };

    const facturarVenta = (id) => {
        fetchConToken(`Facturacion/FacturaVenta?serie_Id=${serie}`,venta,'POST').then((response) => {
            setError('');
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return false;
                });
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }
            Swal.fire('Exitoso', 'Facturado', 'success');
            setFacturado(true);
            setCfdi(response);
        });
    };
/*
    const saveCliente = async () => {
        if (!cliente.hasOwnProperty('uso_Cfdi')) cliente.uso_Cfdi = 'G01';
        if (!cliente.hasOwnProperty('regimen_Fiscal_Receptor'))
            cliente.regimen_Fiscal_Receptor = '601';

        if (!cliente.hasOwnProperty('no_Cliente')) cliente.no_Cliente = 'local';

        cliente.sucursal_Id = getDataSession('sucursal');
        fetchConToken(
            `Clientes?sucursal_Id=${getDataSession('sucursal')}`,
            cliente,
            cliente.hasOwnProperty('id') && cliente.id !== null ? 'PUT' : 'POST'
        ).then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return 0;
                });
                return 0;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return 0;
            }
            setCliente([]);
            setCliente(response);
            return response.id;
        });
        return 0;
    };*/

   /* const saveDireccion = (id) => {
        console.log(id);
        direcciones.cliente_Id = id;
        console.log(direcciones);

        if (!direcciones.hasOwnProperty('no_Direccion')) direcciones.no_Direccion = 'local';

        fetchConToken(
            `DireccionesClientes`,
            direcciones,
            direcciones.hasOwnProperty('id') && direcciones.id !== null ? 'PUT' : 'POST'
        ).then((response) => {
            setError(['']);
            setErroresV({});
            setErroresDirecciones('');
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresC = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return 0;
                });
                return 0;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return 0;
            }
            setDirecciones([]);
            setDirecciones(response);
            return response.id;
        });
        return 0;
    };*/

    ////////Guardar Venta
    ///////////////////////////////////////////
    /*const guardarVenta = (id) => {
        console.log('entro guardar venta');
        venta.sucursal_Id = getDataSession('sucursal');
        venta.direccion_Id = id;
        if (venta.direccion_Id === 0) {
            console.log('Seleccione una direccion');
            Swal.fire('Error', 'Seleccione una direccion', 'error');
            return false;
        }
        if (ventaDetalles.length === 0) {
            console.log('Seleccione por los menos un articulo');
            Swal.fire('Error', 'Seleccione por los menos un articulo', 'error');
            return false;
        }

        if (venta.forma_De_Pago.length === 0 || !venta.hasOwnProperty('forma_De_Pago'))
            venta.forma_De_Pago = '01';

        if (venta.metodo_Pago.length === 0 || !venta.hasOwnProperty('metodo_Pago'))
            venta.metodo_Pago = 'PUE';

        if (venta.length === 0 || !venta.hasOwnProperty('moneda')) 
            venta.moneda = 'MXN';

        console.log(venta);
        venta.venta_Detalles = ventaDetalles;
        venta.estatus = 'P';

        fetchConToken(
            `Ventas?sucursal_Id=${getDataSession('sucursal')}`,
            venta,
            venta.hasOwnProperty('id') && venta.id > 0 ? 'PUT' : 'POST'
        ).then((response) => {
            setErroresV({});
            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresVenta = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                setError('Revisar errores');
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            //alertService.success('Venta guardada', { keepAfterRouteChange: true });

            setError('');
            setVenta(response);

            setVentaDetalles([]);
            setVentaDetalles(response.venta_Detalles);
            console.log(response.venta_Detalles);
            facturarVenta(response.id);

            return true;
        });
        return true;
        //.catch(alertService.error("131"));
    };*/
    ///////////////////////////////////////////
/*
    const saveArticulos = () => {
        ventaDetalles.map((art) => {
            art.articulo.descripcion =
                art.articulo.descripcion.replace(' ') === ' ' ? '' : art.articulo.descripcion;
            fetchConToken(
                `Articulos?sucursal_Id=${getDataSession('sucursal')}`,
                art.articulo,
                'PUT'
            ).then((response) => {
                if (response.hasOwnProperty('status')) {
                    Object.entries(response.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0] + ' Clave: ' + art.articulo.clave, 'error');
                        return false;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return false;
                }
            });
        });
        return true;
    };*/

    const handleInputChangeCliente = ({ target }) => {
        setCliente({
            ...cliente,
            [target.name]: target.value
        });

        if (target.name === 'rfc') {
            var rfcCorrecto = rfcValido(target.value, true);
            if (!rfcCorrecto) {
                setErrorRfc('RFC no valido');
            } else {
                setErrorRfc('');
            }
        }

        if(target.name === 'uso_Cfdi')
        {
            getSatRegimelFiscal(target.value);
        }
    };

    const handleInputChangeDireccion = ({ target }) => {
        setDirecciones({
            ...direcciones,
            [target.name]: target.value
        });
    };
    const handleInputChangeVenta = ({ target }) => {
        setVenta({
            ...venta,
            [target.name]: target.value
        });
    };

    const handleInputChangeSerie = ({ target }) => {
        setSerie(target.value);
    };

    const handleInputChangeArticulos = (event, key, campo) => {
        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);
        detalleV[0].articulo[campo] = event.target.value;

        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));
    };

    async function eliminar(id) {
        setVentaDetalles([]);
        setVentaDetalles(ventaDetalles.filter((item) => item.articulo_Id !== id));
    }

    ////Clientes
    /////////////////////////////////////////////
    async function getClientes(page) {
        setModalValor('cliente');
        const response = await fetchConToken(
            `clientes/GetList?sucursal_id=${getDataSession('sucursal')}&empresa_id=${getDataSession(
                'empresa'
            )}&paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${busqueda.value}`
        );
        setClientes(response.data);
        //getDirecciones();
    }

    async function getCliente(cliente_id) {
        const clienteAux = await fetchConToken(
            `Clientes?id=${cliente_id}&sucursal_Id=${getDataSession('sucursal')}`
        );
        if(cliente.uso_Cfdi !== null)
        console.log(clienteAux);
        console.log(clienteAux.uso_Cfdi);
         getSatRegimelFiscal(clienteAux.uso_Cfdi);
        
        clienteAux.estatus = clienteAux.estatus.replace(' ') === '' ? 'A' : clienteAux.estatus;
        clienteAux.rfc = clienteAux.estatus.replace(' ') === '' ? '' : clienteAux.rfc;
        setCliente(clienteAux);
        setDirecciones(clienteAux.clientes_Direcciones[0]);
        venta.direccion_Id = clienteAux.clientes_Direcciones[0].id;
        console.log(clienteAux.clientes_Direcciones[0].id);

        console.log(clienteAux)
    }
    ////////////////////////////////////////////

    /////////Direcciones
    ///////////////////////////////////////////
    async function getDirecciones(page) {
        console.log(cliente);
        if (cliente.length === 0) {
            Swal.fire('Error', 'Seleccione un cliente', 'error');
            //setError('Seleccione un cliente');
            return;
        }
        setModalValor('direccion');
        const direccionAux = await fetchConToken(
            `DireccionesClientes/GetList?paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${cliente.id}`
        );

        console.log(direccionAux);
        setDireccionesList(direccionAux);
        getCliente(direccionAux.cliente_Id);
        openModal();
    }

    async function getDireccion(direccion_id) {
        console.log(direccion_id);
        venta.direccion_Id = direccion_id;
        const direccionAux = await fetchConToken(`DireccionesClientes?id=${direccion_id}`);
        setDirecciones(direccionAux);
        console.log(direccionAux);
        getCliente(direccionAux.cliente_Id);
        console.log(direccionAux.cliente_Id);
    }
    ///////////////////////////////////////////

    ///////////Articulos
    ///////////////////////////////////////////
    async function getArticulos(page) {
        //busqueda.value='';
        setModalValor('articulo');
        console.log(totalxPagina);
        const articulosAux = await fetchConToken(
            `Articulos/GetList?paginas=${page}&totalXpagina=${totalxPagina}&busqueda=${
                busqueda.value
            }&sucursal_Id=${getDataSession('sucursal')}`
        );
        setArticulos(articulosAux?.data);
    }

    async function ImpuestosArticulo(ventaDet, nuevaLinea)
    {
        if(nuevaLinea)
        {
            await fetchConToken(`ventas/CalculaDetalle?sucursal_Id=` + getDataSession('sucursal'),ventaDet,'POST' ).then((detVentaResp) =>{
                setVentaDetalles([...ventaDetalles,detVentaResp]);
            });
        }
        else
        {
            await fetchConToken(`ventas/CalculaDetalle?sucursal_Id=` + getDataSession('sucursal'),ventaDet[0],'POST' ).then((detVentaResp) =>{
                setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === detVentaResp.articulo_Id ? detVentaResp : det)));
            });
        }
    }

    async function getArticulo(id) {

        if (!Number.isInteger(id)) return;

        var artAux = ventaDetalles.filter((d) => d.articulo_Id == id);
        if (artAux.length > 0) {
            artAux[0].cantidad = artAux[0].cantidad + 1;

/*
            artAux[0]['iva'] = artAux[0]['impuesto'] * artAux[0]['cantidad'];
            artAux[0]['subtotal'] = artAux[0]['precio_Unitario'] * artAux[0]['cantidad'];
            artAux[0]['precio_Total'] = artAux[0]['iva'] + artAux[0]['subtotal'];

*/
            setVentaDetalles(
                ventaDetalles.map((det) => (det.articulo_Id === id ? artAux[0] : det))
            );

            ImpuestosArticulo(ventaDetalles.filter(x=> x.articulo_Id === id ),false);

            return;
        }

        const articulosAux = await fetchConToken(
            `Articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}`
        );

        const detalleVenta = {
            id: 0,
            venta_Id: 0,
            articulo_Id: articulosAux.id,
            no_Detalle: 'local',
            posicion: 0,
            cantidad: 1,
            clave_Unidad: articulosAux.clave_Unidad,
            precio_Unitario: articulosAux.precio_Sin_Impuesto ,
            descuento_Total: 0,
            precio_Total: articulosAux.costo_Unidad,
            impuesto_Total: 0,
            estatus: 'A',
            articulo: articulosAux,
            nombre_Articulo: articulosAux.nombre,
            impuesto: 0,
            precioSinImpuesto: articulosAux.costo_Unidad
        };

     
        ImpuestosArticulo(detalleVenta,true);

        /*
        const articulosAux = await fetchConToken(
            `Articulos?id=${id}&sucursal_Id=${getDataSession('sucursal')}`
        );
        var porcentajeImp = 0;


        var imp = await fetchConToken(`ImpuestosArticulos/GetList?articulo_Id=` + id);
        if (imp !== undefined) {
            var impAux = imp[0]?.tipo_impuesto;

            porcentajeImp = impAux?.porcentaje;
            porcentajeImp = porcentajeImp / 100;
        }
        if (porcentajeImp > 0) {
            porcentajeImp = porcentajeImp;
        } else porcentajeImp = 0;

        if(imp[0]?.tipo_impuesto?.desglose)
        {
            articulosAux.costo_Unidad = (articulosAux.costo_Unidad / (1  + porcentajeImp));
        }
        //articulosAux.costo_Unidad = await ImpuestosArticulo(id, articulosAux.costo_Unidad);

        const detalleVenta = {
            id: 0,
            venta_Id: 0,
            articulo_Id: articulosAux.id,
            no_Detalle: 'local',
            posicion: 0,
            cantidad: 1,
            clave_Unidad: articulosAux.clave_Unidad,
            precio_Unitario: articulosAux.costo_Unidad ,
            descuento_Total: 0,
            precio_Total: articulosAux.costo_Unidad,
            impuesto_Total: (articulosAux.costo_Unidad ) * porcentajeImp,
            estatus: 'A',
            articulo: articulosAux,

            impuesto: (articulosAux.costo_Unidad) * porcentajeImp,
            precioSinImpuesto: articulosAux.costo_Unidad,

            subtotal: articulosAux.costo_Unidad,
            precio_Total: articulosAux.costo_Unidad + ((articulosAux.costo_Unidad) * porcentajeImp), 
            iva: (articulosAux.costo_Unidad) * porcentajeImp
        };

        const newTodos = [...ventaDetalles];
        newTodos.push(detalleVenta);

        setVentaDetalles(newTodos);*/

    }

    ///////////////////////////////////////////


    const EditarArticulo = (art_id) => {
        setModalValor('articuloModificar');
        console.log(art_id);
        getByArticulo(art_id)
        setArtId(art_id);
        openModal();
    };

    /////////// Detalles venta
    //////////////////////////////////////////
    const handleInputChangeDetallesVenta = (event, key, campo) => {
        let detalleV = ventaDetalles.filter((det) => det.articulo_Id === key);

        switch(campo)
        {
            case "cantidad":
                detalleV[0][campo] = parseFloat(event.target.value);
                break;
            case "precio_Unitario":
                detalleV[0]["precio_Unitario"] = parseFloat(event.target.value);
                break;
            default:
                detalleV[0][campo] = event.target.value;
                break;
        }
        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));

          if(campo !== "nombre_Articulo")
          {
            ImpuestosArticulo(ventaDetalles.filter(x=> x.articulo_Id === key ),false);
          }

          
        /*switch(campo)
        {
            case "cantidad":
                detalleV[0][campo] = parseFloat(event.target.value);
                break;
            case "precio_Unitario":
                detalleV[0]["precio_Unitario"] = parseFloat(event.target.value);
                break;
            default:
                detalleV[0][campo] = event.target.value;
                break;
        }

        setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === key ? detalleV[0] : det)));*/
        
    };
    //////////
    //////////////////select2
    const loadClientes = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `clientes/GetList?sucursal_id=${getDataSession('sucursal')}&empresa_id=${getDataSession(
                'empresa'
            )}&paginas=0&totalXpagina=50&busqueda=${query}`
        );
        return resp.data;
    };

    const loadArticulos = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `Articulos/GetList?paginas=0&totalXpagina=50&busqueda=${query}&sucursal_Id=${getDataSession(
                'sucursal'
            )}`
        );
        return resp?.data;
    };

    const loadDirecciones = async (query) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        var resp = await fetchConToken(
            `DireccionesClientes/GetList?paginas=1&totalXpagina=50&busqueda=${cliente.id}`
        );
        return resp;
    };

    function handleInputChangeArticulo({target}) {
        setArticulo({
            ...articulo,
            [target.name]: target.value
        });
    }

    async function descripcionDetalle(id) {
        
        var detAux = ventaDetalles.filter((d) => d.articulo_Id == id);

           Swal.fire({
            title: 'Agregar descripción al detalle',
            input: 'textarea',
            inputValue: detAux[0]?.descripcion,
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Guardar',
            showLoaderOnConfirm: true,

          }).then((result) => {
            if (result.isConfirmed) {
                
                detAux[0].descripcion = result.value;
                setVentaDetalles(ventaDetalles.map((det) => (det.articulo_Id === id ? detAux[0] : det)));
            }
          })
    }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(facturar)} className='facturar' /*onReset=""*/>
                    <div className='col-md-12'>
                        <div className='col-md-12 text-right'>
                            <button
                                hidden={facturado}
                                className='btn btn-outline-success'
                                //onClick={ (e) => facturar() }
                                type='submit'
                            >
                                &nbsp; Facturar
                            </button>
                            &nbsp;
                            <button
                                type='button'
                                hidden={!facturado}
                                className='btn btn-outline-danger'
                                onClick={() =>
                                    descargarFactura(
                                        cfdi.id,
                                        'Facturacion/DescargarPDF',
                                        cfdi.folio + '.pdf'
                                    )
                                }
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-file-pdf'
                                    viewBox='0 0 16 16'
                                >
                                    <path d='M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
                                    <path d='M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z' />
                                </svg>
                                &nbsp; Descargar PDF
                            </button>
                            &nbsp; &nbsp; &nbsp;
                            <button
                                type='button'
                                hidden={!facturado}
                                className='btn btn-outline-warning'
                                onClick={() =>
                                    descargarFactura(
                                        cfdi.id,
                                        'Facturacion/DescargarXML',
                                        cfdi.folio + '.xml'
                                    )
                                }
                            >
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='20'
                                    height='20'
                                    fill='currentColor'
                                    className='bi bi-filetype-xml'
                                    viewBox='0 0 16 16'
                                >
                                    <path
                                        fillRule='evenodd'
                                        d='M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z'
                                    />
                                </svg>
                                &nbsp; Descargar XML
                            </button>
                        </div>
                    </div>
                    <p style={{ color: 'red' }}>{error}</p>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card-title'>
                                <h5>Información del cliente</h5>
                            </div>
                            {Object.entries(erroresCliente).map(([key, value]) => {
                                return (
                                    <>
                                        <p style={{ color: 'red' }}>{value}</p>
                                    </>
                                );
                            })}
                        </div>
                        <div className='col-md-4' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='id'>Cliente:</label>
                                    <AsyncSelect
                                    className='react-select-placeholder'
                                        isClearable
                                        getOptionLabel={(option) => option.rfc + '-' + option.nombre}
                                        getOptionValue={(option) => option.id}
                                        //defaultValue={initialObject.clave}
                                        loadOptions={loadClientes}
                                        onChange={(selectedItem) => {
                                            const val = selectedItem === null ? '' : selectedItem?.id;
                                            getCliente(val);
                                        }}
                                    />

                                    <input
                                        type='hidden'
                                        className='form-control'
                                        name='no_cliente'
                                        value={cliente.no_Cliente}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                        required
                                    />

                                    <label htmlFor='nombre'>Razón Social*:</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        value={cliente.nombre}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                        required
                                    />

                                    <label htmlFor='cp'>Codigo Postal*:</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='cp'
                                        value={direcciones?.cp || ''}
                                        onChange={handleInputChangeDireccion}
                                        disabled={facturado}
                                        required
                                    />

                                </div>
                            </div>
                        </div>
                        <div className='col-md-4' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='rfc'>RFC*:</label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='rfc'
                                        value={cliente.rfc}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                        required
                                    />
                                    <p style={{ color: 'red' }}>{errorRfc}</p>
                                    <label htmlFor='uso_Cfdi'>Uso CFDI*:</label>
                                    <select
                                        className='form-control'
                                        name='uso_Cfdi'
                                        value={cliente.uso_Cfdi}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                    >
                                        <option value="--" selected>Seleccione un uso de cfdi</option>
                                        {satUsoCfdi.map((usoCfdi) => {
                                        return (
                                            <option key={usoCfdi.id} value={usoCfdi.clave}>
                                            {usoCfdi.clave + ' - ' + usoCfdi.nombre}
                                            </option>
                                        );
                                        })}
                                    </select>

                                    <label htmlFor='regimen_Fiscal_Receptor'>
                                        Regimen Fiscal*:
                                    </label>
                                    <select
                                        className='form-control'
                                        name='regimen_Fiscal_Receptor'
                                        value={cliente.regimen_Fiscal_Receptor}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                    >
                                        <option value="">Seleccione un regimen fiscal</option>
                                         {satRegimenFiscal.map((regimenfiscal) => {
                                        return (
                                            <option key={regimenfiscal.id} value={regimenfiscal.clave}>
                                            {regimenfiscal.clave + ' - ' + regimenfiscal.nombre}
                                            </option>
                                        );
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-4' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <div className='col-md-12'></div>
                                    <label htmlFor='forma_De_Pago'>Forma de Pago:</label>
                                    <select
                                        className='form-control'
                                        name='forma_De_Pago'
                                        value={venta.forma_De_Pago}
                                        onChange={handleInputChangeVenta}
                                        disabled={facturado}
                                        required
                                    >
                                        {satFormaPago.map((formapago) => {
                                        return (
                                            <option key={formapago.id} value={formapago.clave}>
                                            {formapago.clave + ' - ' + formapago.nombre}
                                            </option>
                                        );
                                        })}

                                    </select>

                                    <label htmlFor='metodo_Pago'>Metodo de Pago:</label>
                                    <select
                                        className='form-control'
                                        name='metodo_Pago'
                                        value={venta.metodo_Pago}
                                        onChange={handleInputChangeVenta}
                                        disabled={facturado}
                                        required
                                    >
                                        <option value='PUE'>
                                            (PUE) Pago en una sola exhibición
                                        </option>
                                        <option value='PPD'>
                                            (PPD) Pago en Parcialidades o Diferido
                                        </option>
                                    </select>

                                    <label htmlFor='correo'>Correo*:</label>
                                    <input
                                        type='email'
                                        className='form-control'
                                        name='correo'
                                        value={cliente.correo}
                                        onChange={handleInputChangeCliente}
                                        disabled={facturado}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        {Object.entries(erroresDirecciones).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                        <div
                            className='col-md-12 text-left'
                            style={{ 
                                marginTop: '-10px', 
                                marginBottom: '5px',
                                display: cliente.id > 0 ? 'block' : 'none'
                             }}
                        >
                            <label htmlFor='id'>Buscar Direccion</label>
                            <AsyncSelect
                            className='react-select-placeholder'
                                isClearable
                                getOptionLabel={(option) =>
                                    option.cp +
                                    '-' +
                                    option.calle +
                                    '-' +
                                    option.no_Interior +
                                    '-' +
                                    option.colonia
                                }
                                getOptionValue={(option) => option.id}
                                //defaultValue={initialObject.clave}
                                loadOptions={loadDirecciones}
                                onChange={(selectedItem) => {
                                    const val = selectedItem === null ? '' : selectedItem?.id;
                                    getDireccion(val);
                                }}
                            />
                        </div>

                        <div className='accordion' id='accordionExample' style={{ 'z-index': '0' }}>
                            <div className='accordion-item'>
                                <h2 className='accordion-header' id='headingOne'>
                                    <button
                                        className='accordion-button collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#collapseOne'
                                        aria-expanded='true'
                                        aria-controls='collapseOne'
                                        style={{ color: '#0c63e4', backgroundColor: '#e7f1ff' }}
                                    >
                                        <h5>Domicilio cliente</h5>
                                    </button>
                                </h2>
                                <div
                                    id='collapseOne'
                                    className='accordion-collapse collapse'
                                    aria-labelledby='headingOne'
                                    data-bs-parent='#accordionExample'
                                >
                                    <div className='accordion-body row'>
                                        <div className='col-md-4' style={{ display: 'grid' }}>
                                            <div className='card'>
                                                <div className='card-body text-start'>
                                                    <label htmlFor='calle'>Calle:</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='calle'
                                                        value={direcciones?.calle || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='no_Exterior'>
                                                        No Exterior:
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='no_Exterior'
                                                        value={direcciones?.no_Exterior || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='no_Interior'>
                                                        No Interior:
                                                    </label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='no_Interior'
                                                        value={direcciones?.no_Interior || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-4' style={{ display: 'grid' }}>
                                            <div className='card'>
                                                <div className='card-body text-start'>
                                                    <label htmlFor='colonia'>Colonia:</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='colonia'
                                                        value={direcciones?.colonia || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='poblacion'>Poblacion:</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='poblacion'
                                                        value={direcciones?.poblacion || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='ciudad'>Ciudad:</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='ciudad'
                                                        value={direcciones?.ciudad || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4' style={{ display: 'grid' }}>
                                            <div className='card'>
                                                <div className='card-body text-start'>

                                                    <label htmlFor='estado'>Municipio:</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='municipio'
                                                        value={direcciones?.municipio || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                    <label htmlFor='estado'>Estado:</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        name='estado'
                                                        value={direcciones?.estado || ''}
                                                        onChange={handleInputChangeDireccion}
                                                        disabled={facturado}
                                                    />

                                                <label htmlFor='pais'>Pais:</label>
                                                <select
                                                    className='form-control'
                                                    name='pais'
                                                    value={direcciones?.pais || 'México'}
                                                    onChange={handleInputChangeDireccion}
                                                    disabled={facturado}
                                                    required
                                                >
                                                    {satPais.map((pais) => {
                                                    return (
                                                        <option key={pais.id} value={pais.nombre}>
                                                        {pais.clave + ' - ' + pais.nombre}
                                                        </option>
                                                    );
                                                    })}

                                                </select>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-12'>
                            <div className='card-title'>
                                <div className='col-md-12'>
                                    {Object.entries(erroresV).map(([key, value]) => {
                                        return (
                                            <>
                                                <p style={{ color: 'red' }}>{value}</p>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div
                            className='col-md-12 row'
                            style={{ paddingTop: '7px', paddingBottom: '7px' }}
                        >
                            <div className='col-4 col-sm-4 col-md-4 '>
                                <label>Serie:</label>
                                <select
                                    className='form-control'
                                    value={serie}
                                    onChange={handleInputChangeSerie}
                                    disabled={facturado}
                                >
                                    {series.map((s) => {
                                        return (
                                            <option key={s.id} value={s.id}>
                                                {s.serie}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 text-left'>
                                <label htmlFor='id'>Buscar Articulo</label>
                                <AsyncSelect
                                className='react-select-placeholder'
                                    isClearable
                                    getOptionLabel={(option) =>
                                        option.clave +
                                        '-' +
                                        option.nombre +
                                        '- $' +
                                        option.costo_Unidad
                                    }
                                    value=''
                                    getOptionValue={(option) => option.id}
                                    //defaultValue={initialObject.clave}
                                    loadOptions={loadArticulos}
                                    onChange={(selectedItem) => {
                                        const val = selectedItem === null ? '' : selectedItem?.id;
                                        getArticulo(val);
                                    }}
                                />
                                {/*<button
                                        hidden={facturado}
                                        type='button'
                                        className='btn btn-outline-danger'
                                        onClick={function (event) {
                                            getArticulos(1);
                                            openModal();
                                        }}
                                    >
                                        Buscar Articulo
                                    </button>*/}
                            </div>
                        </div>
                        <div className='col-md-12' style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='text-start'>
                                    <div className='card col-12 col-sm-12 col-md-12'>
                                        {Object.entries(erroresArticulos).map(([key, value]) => {
                                            return (
                                                <>
                                                    <p style={{ color: 'red' }}>{value}</p>
                                                </>
                                            );
                                        })}
                                        <div style={{ overflowX: 'auto' }}>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        <th>Articulo</th>
                                                        <th>Claves</th>
                                                        <th>Cant.</th>
                                                        <th>Precio Unitario <br></br>(sin impuesto)</th>
                                                        <th>IVA</th>
                                                        <th>Precio Total</th>
                                                        <th hidden={facturado}></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ventaDetalles?.map((item) => {
                                                         console.log("entro item dato"+item.nombre_Articulo);
                                                        return (
                                                           
                                                            <tr key={item.id}>
                                                                <td>
                                                                <textarea
                                                                        disabled={facturado}
                                                                        type='text'
                                                                        className='form-control'
                                                                        name='item.nombre_Articulo'
                                                                        value={(item.nombre_Articulo === null || item.nombre_Articulo.length === 0) ? item.articulo?.nombre : item.nombre_Articulo}
                                                                        onChange={(event) =>
                                                                            handleInputChangeDetallesVenta(
                                                                                event,
                                                                                item.articulo.id,
                                                                                'nombre_Articulo'
                                                                            )
                                                                        }
                                                                        cols="170"
                                                                        rows="3"
                                                                    />
                                                                </td>
                                                               
                                                                <td className='text-center'>

                                                                { (  item.articulo.clave.length === 0  || 
                                                                         item.articulo.clave_Prod_Serv.length === 0 ||
                                                                         item.articulo.no_Identificacion.length === 0 ||
                                                                         item.articulo.clave_Unidad.length === 0  ) ? 
                                                                    <button 
                                                                       type='button' 
                                                                       className='btn btn-outline-danger'
                                                                       onClick={(event) => EditarArticulo(item.articulo.id) }
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-input-cursor" viewBox="0 0 16 16">
                                                                            <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z"/>
                                                                            <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13A.5.5 0 0 1 8 1z"/>
                                                                        </svg>
                                                                    </button>
                                                                         :
                                                                    <button 
                                                                         type='button' 
                                                                         className='btn btn-outline-primary'
                                                                         onClick={(event) => EditarArticulo(item.articulo.id) }
                                                                    >
                                                                           <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-input-cursor" viewBox="0 0 16 16">
                                                                               <path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z"/>
                                                                               <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-1 0v-13A.5.5 0 0 1 8 1z"/>
                                                                           </svg>
                                                                    </button>
                                                                    
                                                                }
                                                                </td>
                                                                <td className='text-right'>
                                                                    <input
                                                                        type='number'
                                                                        className='form-control'
                                                                        name='item.cantidad'
                                                                        value={item.cantidad}
                                                                        onChange={(event) =>
                                                                            handleInputChangeDetallesVenta(
                                                                                event,
                                                                                item.articulo.id,
                                                                                'cantidad'
                                                                            )
                                                                        }
                                                                        style={{width: '80px'}}
                                                                        disabled={facturado}
                                                                    />
                                                                </td>
                                                                <td className='text-right'>
                                                                    <input
                                                                        type='number'
                                                                        className='form-control'
                                                                        name='item.precio_Unitario'
                                                                        value={item.precio_Unitario}
                                                                        onChange={(event) =>
                                                                            handleInputChangeDetallesVenta(
                                                                                event,
                                                                                item.articulo.id,
                                                                                'precio_Unitario'
                                                                            )
                                                                        }
                                                                        disabled={facturado}
                                                                    />
                                                                </td>
                                                                <td className='text-right'>
                                                                    {currency(item.impuesto_Total)}
                                                                </td>
                                                                <td className='text-right'>
                                                                    {currency(item.precio_Total + item.impuesto_Total)}
                                                                </td>

                                                                <td hidden={facturado}>
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        onClick={() =>
                                                                            eliminar(
                                                                                item.articulo.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                            width='25'
                                                                            height='25'
                                                                            fill='currentColor'
                                                                            className='bi bi-x-circle-fill'
                                                                            viewBox='0 0 16 16'
                                                                        >
                                                                            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z' />
                                                                        </svg>
                                                                    </button>
                                                                </td>

                                                                <td>
                                                                    <button
                                                                        type='button'
                                                                        className='btn  mr-1'
                                                                        onClick={() =>
                                                                            descripcionDetalle(
                                                                                item.articulo.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                                                                        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                                                                        <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    <tr>
                                                        <td colSpan={3} className='text-right'>
                                                            Subtotal:
                                                            {currency(
                                                                ventaDetalles.reduce(
                                                                    (a, v) => (a = a + v.precio_Total),
                                                                    0
                                                                )
                                                            )}
                                                        </td>
                                                        <td colSpan={2} className='text-right'>
                                                            IVA:{' '}
                                                            {currency(
                                                                ventaDetalles.reduce(
                                                                    (a, v) => (a = a + v.impuesto_Total),
                                                                    0
                                                                )
                                                            )}
                                                        </td>

                                                        <td colSpan={2} className='text-right'>
                                                            Total:{' '}
                                                            {currency(
                                                                ventaDetalles.reduce(
                                                                    (a, v) =>
                                                                        (a = a + (v.precio_Total + v.impuesto_Total)),
                                                                    0
                                                                )
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal}>
                {modalValor === 'cliente' ? (
                    <>
                        {' '}
                        <br />
                        <div className='row'>
                            <div className='col-md-2 tex-left'>
                                <label>Buscar</label>
                            </div>
                            <div className='col-md-8 '>
                                <input type='text' className='form-control' {...busqueda} />
                            </div>
                            <div className='col-md-2 text-left'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary'
                                    onClick={() => getClientes(1)}
                                    style={{ border: 'none' }}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        fill='currentColor'
                                        className='bi bi-search'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <br></br>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th width='40px'>#No</th>
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th className='text-center'>RFC</th>
                                    <th width='160px'>Estatus</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientes.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.no_cliente}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.rfc}</td>
                                            <td>{item.estatus === 'A' ? 'Activo' : 'Inactivo'}</td>
                                            <td className='text-center'>
                                                <button
                                                    className='btn btn-outline-warning'
                                                    type='button'
                                                    onClick={function (event) {
                                                        getCliente(item.id);
                                                        closeModal();
                                                    }}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='25'
                                                        height='25'
                                                        fill='currentColor'
                                                        className='bi bi-check2'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            consumirPaginacion={getClientes}
                            elementos={clientes.length}
                            totalxPagina={totalxPagina}
                        />
                    </>
                ) : (
                    ''
                )}
                {modalValor === 'direccion' ? (
                    <>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th width='40px'>#No</th>
                                    <th>Codigo Postal</th>
                                    <th>Calle</th>
                                    <th className='text-center'>No. Exterior</th>
                                    <th width='160px'>No. Interior</th>
                                    <th className='text-center'>Colonia</th>
                                    <th className='text-center'>Poblacion</th>
                                    <th className='text-center'>Estado</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {direccionesList.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.cp}</td>
                                            <td>{item.calle}</td>
                                            <td>{item.no_Exterior}</td>
                                            <td>{item.no_Interior}</td>
                                            <td>{item.colonia}</td>
                                            <td>{item.poblacion}</td>
                                            <td>{item.estado}</td>
                                            <td className='text-center'>
                                                <button
                                                    className='btn btn-outline-warning'
                                                    type='button'
                                                    onClick={function (event) {
                                                        getDireccion(item.id);
                                                        closeModal();
                                                    }}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='25'
                                                        height='25'
                                                        fill='currentColor'
                                                        className='bi bi-check2'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            consumirPaginacion={getDirecciones}
                            elementos={direcciones.length}
                            totalxPagina={totalxPagina}
                        />
                    </>
                ) : (
                    ''
                )}

                {modalValor === 'articulo' ? (
                    <>
                        {' '}
                        <br />
                        <div className='row'>
                            <div className='col-md-2 tex-left'>
                                <label>Buscar</label>
                            </div>
                            <div className='col-md-8 '>
                                <input type='text' className='form-control' {...busqueda} />
                            </div>
                            <div className='col-md-2 text-left'>
                                <button
                                    type='button'
                                    className='btn btn-outline-secondary'
                                    onClick={() => getArticulos(1)}
                                    style={{ border: 'none' }}
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='16'
                                        height='16'
                                        fill='currentColor'
                                        className='bi bi-search'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z' />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <br></br>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th width='40px'>#No</th>
                                    <th>Clave</th>
                                    <th>Nombre</th>
                                    <th className='text-center'>No Identificacion</th>
                                    <th width='160px'>Clave Prod.Ser.</th>
                                    <th className='text-center'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {articulos.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.clave}</td>
                                            <td>{item.nombre}</td>
                                            <td>{item.no_Identificacion}</td>
                                            <td>{item.clave_Prod_Serv}</td>
                                            <td className='text-center'>
                                                <button
                                                    className='btn btn-outline-warning'
                                                    type='button'
                                                    onClick={function (event) {
                                                        getArticulo(item.id);
                                                        closeModal();
                                                    }}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='25'
                                                        height='25'
                                                        fill='currentColor'
                                                        className='bi bi-check2'
                                                        viewBox='0 0 16 16'
                                                    >
                                                        <path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z' />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Pagination
                            consumirPaginacion={getClientes}
                            elementos={clientes.length}
                            totalxPagina={totalxPagina}
                        />
                    </>
                ) : (
                    ''
                )}

                {modalValor === 'articuloModificar' ? (
                    <>
                        {' '}
                        <br />
                        <div className='card-body text-start'>
                        <div className='row'>
                            <div className='col-md-10 tex-left'>
                            <h3 className='form-label'>Editar Claves de Articulo</h3>
                            </div>
                            <div className='col-md-2 '>
                                <button
                                    type='button'
                                    className='btn btn-success'
                                    onClick={() => GuardarArticulo()}
                                >
                                  Guardar
                                </button>
                            </div>
                        </div>
                      
                       
                        

<label htmlFor='clave' className='form-label'>
        Clave*
    </label>
    <input
        type='text'
        className='form-control'
        name='clave'
        required={true}
        maxLength={100}
        value={articulo.clave || ''}
        onChange={handleInputChangeArticulo}
    />

    <label htmlFor='nombre' className='form-label'>
        Nombre*
    </label>
    <input
        type='text'
        className='form-control'
        name='nombre'
        required={true}
        maxLength={100}
        value={articulo.nombre || ''}
        onChange={handleInputChangeArticulo}
    />

    <label htmlFor='clave_Prod_Serv' className='form-label'>
        Clave del Producto o Servicio SAT
    </label>
    <AsyncSelect
        isClearable
        className='react-select-placeholder'
        getOptionLabel={(option) =>
            option.clave + '-' + option.nombre
        }
        getOptionValue={(option) => option.clave}
        defaultOptions
        value={initialObject}
        loadOptions={loadData}
        onChange={(selectedItem) => {
            const val =
                selectedItem === null ? '' : selectedItem?.clave;
            console.log(val);
            articulo.clave_Prod_Serv = val;
            setInitialObject(selectedItem);
        }}
    />

    <label htmlFor='no_Identificacion' className='form-label'>
        Código de Identificación*
    </label>
    <input
        type='text'
        className='form-control'
        name='no_Identificacion'
        required={true}
        maxLength={100}
        placeholder="Escriba código de identificación"
        value={articulo.no_Identificacion || ''}
        onChange={handleInputChangeArticulo}
    />

    <label htmlFor='clave_Unidad' className='form-label'>
        Clave de Unidad (SAT)*
    </label>
    <AsyncSelect
    className='react-select-placeholder'
        isClearable
        getOptionLabel={(option) =>
            option.clave + '-' + option.nombre
        }
        getOptionValue={(option) => option.clave}
        defaultOptions 
        value={initialObjectClaveUnidad}
        loadOptions={loadDataClaveUnidad}
        onChange={(selectedItem) => {
            const val =
                selectedItem === null ? '' : selectedItem?.clave;
            console.log(val);
            articulo.clave_Unidad = val;
            setInitialObjectClaveUnidad(selectedItem);
        }}
    />
</div>
                    </>
                ) : (
                    ''
                )}
            </Modal>
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};
export { Facturar };
