import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';

const ClavesArticulos = ({ clavesArt, eliminarClave, setModalValor, openModal, varianteId, setVarianteId }) => {
    const { t } = useTranslation("common");
    const onClick = (e) => {
        if (varianteId && varianteId > 0) {
            setModalValor("asignarClaveVariante");
            setVarianteId(varianteId);
        } 
        else setModalValor("asignarClave");

        openModal();
    }
    
    return (
        <div className='card col-12 col-sm-12 col-md-12'>
            <div className='card-body'>
                <div style={{ overflowX: 'auto' }}>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th className='text-center'>Rol</th>
                                <th className='text-center'>Clave</th>
                                <th className='text-right' width='50'><Button variant="outlined" size="small" onClick={onClick}>Agregar</Button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {((varianteId && varianteId > 0) ? clavesArt.filter((x) => x.variante_Id === varianteId) : clavesArt)?.map((item) => {
                                return (
                                    <tr key={item.id}>
                                        <td className='text-center'>{item.rolClave?.nombre}</td>
                                        <td className='text-center'>{item.clave}</td>
                                        <td className='text-center' width='50'>
                                            <button
                                                type='button'
                                                className='btn'
                                                style={{ color: 'red' }}
                                                onClick={(e) => eliminarClave(item.id, item.clave_Id, varianteId)}
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='25'
                                                    height='20'
                                                    fill='currentColor'
                                                    className='bi bi-x-circle-fill'
                                                    viewBox='0 0 16 16'
                                                >
                                                    <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z'></path>
                                                </svg>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export { ClavesArticulos };