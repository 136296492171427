import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import Theme from 'resources/theme';
import Routes from 'routes';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next'
import i18next from "i18next"

import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'es',                              // language to use:  en,es
    resources: {
        es: {
            common: common_es               // 'common' is our custom namespace
        },
        en: {
            common: common_en
        },
    },
})

ReactDOM.render(
    <ThemeProvider theme={Theme}>
        <Router>
            <I18nextProvider i18n={i18next}>
                <Routes />
            </I18nextProvider>
        </Router>
    </ThemeProvider>,
    document.getElementById('root')
);
 
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
