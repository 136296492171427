import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { setDataSession, setUserSession } from 'views/Utils/Common';
import { fetchSinToken,fetchConToken } from 'helpers/fetch';
import { Link } from 'react-router-dom';
import SLUGS from 'resources/slugs';

import fondo from 'images/login/fondo_login.png';
import logo from 'images/login/logo.png'
import videomz from 'images/login/microzync.m4v'

import woocomerce from 'images/login/Woocomerce.svg'
import amazon from 'images/login/Amazon.svg'
import shopify from 'images/login/Shopify.svg'
import mercado_libre from 'images/login/Mercado_libre.svg'

import Swal from 'sweetalert2';

const Login = ({ history, saludo, subtitulo }) => {
    const baseUrl = process.env.REACT_APP_SERVER_URL;

    const [loading, setLoading] = useState(false);
    const username = useFormInput('');
    const password = useFormInput('');

    const [error, setError] = useState(null);

    //const [empresa, setEmpresa] = useState(0);
    //const [sucursal, setSucursal] = useState(0);
    //const [listEmpresas, setListEmpresas] = useState([]);
    //const [listSucursales, setListSucursales] = useState([]);

    const [opcionModal] = useState('opcionLogin');

    /* 
    const handleChangeEmpresa = (event) => {
        setDataSession('empresa', event.target.value);
    };

    const handleChangeSucursal = (event) => {
        //setSucursal(event.target.value);
        var index = event.nativeEvent.target.selectedIndex;
        setDataSession('sucursalNombre', event.nativeEvent.target[index].text);
    };
    */

    const handleLogin = () => {
        setError(null);
        setLoading(true);

        if(username.value.length == 0)
        {
        Swal.fire("","Capture su usuario",'info');
        setLoading(false);
        return;
        }
        if(password.value.length == 0)
        {
        Swal.fire("","Capture su contraseña",'info');
        setLoading(false);
        return;
        }

        fetchSinToken(
            'Login/Login',
            { usuario: username.value, clave: password.value },
            'POST'
        ).then((response) => {

            if (response.codigo === 500 || response.codigo === 401) {
                Swal.fire("",response.mensajes[0],'info');
                setLoading(false);
                return;
            }

            if (response.hasOwnProperty('token')) {
                setUserSession(response.token, response.userName);
                setDataSession('empresa', response.empresa_Id);
                setDataSession('sucursal', response.sucursal_Id);
                setDataSession('rol', response.rol.toUpperCase()); 
                setDataSession('menu', 'Dashboard');

                
                fetchConToken(`Sucursales/Principal`).then(function (response) {
                    setDataSession('sucursalPrincipal',response.id);
                });
                
                setDataSession('sucursalPrincipal', );
                    
                fetchConToken(
                    `usuarios?id=0&empresa_id=${response.empresa_Id}`
                ).then((responseUsuario) => {
                    setDataSession('nombre', responseUsuario?.nombre);
    
                    if(responseUsuario?.imagen_perfil.length > 0)
                    {
                      setDataSession('imagenPerfil', baseUrl+responseUsuario?.imagen_perfil);
                    }
                });
                
                fetchConToken(`ConfiguracionesSucursales?nombre=TIENDA&sucursal_id=${response.sucursal_Id}`)
                .then(responseT => {
                    setDataSession('tienda',responseT?.valor);
                });

                if(response.rol.toUpperCase() == "ADMINISTRADOR")
                    history.push(SLUGS.bolsatimbres);
                else
                    history.push(SLUGS.dashboard);
            }
            else
            {
                Swal.fire("","No se pudo iniciar sesión",'info');
            }

            
            setLoading(false);
        }).catch((error) => {
            console.log(error)
            var errorMgs = (error.message === "Failed to fetch" ? "Error al conectar con el servidor" : 'Ocurrio un error, contactar con soporte');
            Swal.fire("",errorMgs,'info');
            setLoading(false);
        });
    };

    return (
        <>
            <div
                className='col-12 col-sm-12 col-md-12 row login'
                style={{ minHeight: '100vh', marginLeft: '0', textAlign:'center' }}
            >
                <div
                    className='col-12 col-md-7'
                    style={{
                        
                        backgroundRepeat: 'no-repeat',
                        display: window.innerWidth < 768 ? 'none' : 'block',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'relative',
                        backgroundColor:'#FAFAFA'
                    }}
                >

{/*
backgroundImage: `url("${Math.abs(window.innerWidth - window.innerHeight) < 200
                                ? fondo
                                : fondo

                            }")`,*/}

<video src={videomz} loop muted width="100%" id="background-video" height="380" type="video/mp4" autoplay="true" />
                
                        
                    <div className='text-center' style={{
                        position: 'absolute',
                        top: '75%',
                        left: '50%',
                        height: '30%',
                        width: '80%',
                        margin: '-15% 0 0 -40%'
                    }}
                    >

                        <div style={{ maxWidth: '100%' }}>
                            <h4 className="colorfg text-center"><b>¡Expande tus canales de ventas con Microzync!</b></h4>
                        </div>
                        <br></br>
                        <div style={{ maxWidth: '95%' }}>
                            <p className="colorfg text-center">Conecta tu ecommerce con tu marketplace o tu ERP y sigue vendiendo, microzync se encarga de mantenerlos sincronizados.</p>
                        </div>
                        <br></br>
                        <div className='col-md-12 row text-center'>

                            <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center' style={{ backgroundColor:'gray', borderTopLeftRadius: '10px'}}>
                                <img src={woocomerce} alt='' />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center' style={{color:'gray', backgroundColor:'gray'}}>
                                <img src={amazon} alt=''  />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center' style={{color:'gray', backgroundColor:'gray'}}>
                                <img src={mercado_libre} alt='' />
                            </div>
                            <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 text-center' style={{color:'gray', backgroundColor:'gray', borderTopRightRadius:'10px'}}>
                                <img src={shopify} alt='' />
                            </div>
                        </div>
                        <br></br><br></br>
                        <div className='col-md-12 row text-center'>
                            <div className='col-4 col-sm-3 col-md-3'>
                                <a className="colorfg" href="https://microzync.com" target="_blank" rel="noopener noreferrer">microzync</a>
                            </div>
                            <div className='col-4 col-sm-3 col-md-3'>
                                <a className="colorfg" href="https://vetecno.net" target="_blank" rel="noopener noreferrer">vetecno</a>
                            </div>
                            <div className='col-4 col-sm-3 col-md-3'>
                                 <a className="colorfg" href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos de uso</a>
                            </div>
                            <div className='col-4 col-sm-3 col-md-3'>
                                 <a className="colorfg" href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                            </div>
                    </div>

                    </div>


                </div>
                <div
                    className='col-12 col-md-5 zz'
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed',
                        backgroundImage: window.innerWidth < 768 ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${fondo})` : 'none',
                        backgroundSize: 'cover',


                    }}
                >
                    <form style={{height: '100%'}}>
                        <div className='card' style={{
                            /*boxShadow: 'rgb(30 56 136 / 20%) 8px 8px 48px',*/
                            borderRadius: '8px',
                            height: '100%'
                            /*margin: '1% 15% 5px'*/
                        
                        }}>
                            {opcionModal === 'opcionLogin' ? (
                                <div

                                >
                                    <div className='col-md-12 text-center'>
                                        <Link to={SLUGS.home}>
                                            <img src={window.innerWidth < 768 ? logo : logo} className="img-fyt" alt='' />
                                        </Link>
                                        <h2
                                            style={{
                                                color: '#01295F'
                                            }}
                                        >
                                            <b>¡Bienvenido!</b>
                                        </h2>
                                        <p
                                            style={{
                                                color: '#01295F'
                                            }}
                                        >
                                            Inicie sesión para continuar
                                        </p>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='col-md-11 container'>
                                            <div className=''>
                                                <label className='form-label'>Nombre</label>
                                                <input
                                                    type='text'
                                                    {...username}
                                                    className='form-control'
                                                    autoComplete='new-password'
                                                    placeholder='Correo'
                                                    style={{ background: '#F4F7FF' }}
                                                    required={true}
                                                />
                                               </div>
                                               <div className=''>
                                                <label className='form-label'>Contraseña</label>
                                                <input
                                                    type='password'
                                                    {...password}
                                                    className='form-control'
                                                    autoComplete='new-password'
                                                    placeholder='Clave'
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-11 container text-center' style={{ paddingBottom: '15px' }}>
                                        {error && (
                                            <>
                                                <small style={{ color: 'red' }}>{error}</small>
                                                <br />
                                            </>
                                        )}
                                        <br />
                                        <input
                                            style={{ width: '100%', background: 'linear-gradient(86.42deg, #232ED1 11.64%, #0F5EF6 80.17%)' }}
                                            type='button'
                                            className='btn-carrousel-home-index'
                                            value={loading ? 'Cargando...' : 'Iniciar sesión'}
                                            onClick={handleLogin}
                                            disabled={loading}
                                        />
                                        <br />
                                       
                                        <b>
                                        <Link to= {SLUGS.forgotPassword}
                                                className='text-center'
                                                style={{
                                                    float: 'right',
                                                    color: '#16375b'
                                                }}
                                            >
                                                {' '}
                                                ¿Olvidaste tu contraseña?
                                            </Link>
                                        </b>
                                        <br></br>
                                        <b>
                                            <label>¿Aún no tienes una cuenta?
                                            <Link to= {SLUGS.register} 
                                                >
                                                    {' '}
                                                    Registrate aquí
                                                </Link>

                                            </label>

                                        </b>
                                        
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                        
                        <div className='text-center'>
                            <label className='text-footer'>© 2024 Microzync. Powered by <a href="https://vetecno.net" target="_blank" rel="noopener noreferrer">VETECNO</a></label>
                        </div>

{/*
                        <div style={{background: 'rgba(245, 245, 245, 1)'}}>
                            <div className=' col-12 col-sm-12 col-md-12 row text-center' >
                                
                                <div className='col-6 col-sm-6 col-md-6'>
                                <a href={SLUGS.terminoscondiciones} target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                                </div>
                                <div className='col-6 col-sm-6 col-md-6'>
                                <a href={SLUGS.avisoprivacidad} target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                                </div>
                            </div>
                        </div>
                        */}

                        </div>
                        
                    </form>
                </div>
            </div>
        </>
    );
};

const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};

Login.propTypes = {
    saludo: PropTypes.string
};

Login.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { Login };

/*
<br></br>
<div className='col-md-12'>
    <Link to={SLUGS.facturacion}>Facturar</Link>
</div>*/