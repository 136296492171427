/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import TituloPagina from 'components/header/TituloPagina';
import Swal from 'sweetalert2';
import { useTranslation } from "react-i18next";
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';

import { currency } from 'resources/utilities';
import AsyncSelect from 'react-select/async';

import SLUGS from 'resources/slugs';

const $ = require('jquery')
$.DataTable = require('datatables.net-select')

const DiferenciasArt = ({ history, match, saludo, subtitulo }) => {
    const { path } = match;
    const { t } = useTranslation("common");

    let [sucursal, setSucursal] = useState({});
    let [listaPrecio, setListaPrecio] = useState([]);
    let [almacenes, setAlmacenes] = useState([]);
    let [asignacion, setAsignacion] = useState({
        listaPrecioId: 0,
        almacenId: 0,
    });
    let [existenciaAlmacen, setExistenciaAlmacen] = useState({
        articulo_Id: 0,
        exis_Actual: 0,
        almacen_Id: 0,
        sucursal_Id: 0,
    });
    let [precioArticulo, setPrecioArticulo] = useState({
        articulo_Id: 0,
        lista_Precio_Id: 0,
        precio: 0,
        sucursal_Id: 0,
    });
    

    React.useEffect(() => {

        asignacion.listaPrecioId = obtenerPrincipal();
        asignacion.almacenId = obtenerAlmacenes();  
       
       setAsignacion(asignacion);
    }, []);

    function obtenerPrincipal(){
        fetchConToken(`Sucursales/Principal`).then(function (response) {
            setSucursal(response);
            fetchConToken(`ListaPrecios/GetList?paginas=0&totalXpagina=100&sucursal_Id=${response.id}`).then(function (response2) {
                setListaPrecio(response2.data);

                return asignacion.listaPrecioId =  response2.data.find(item=>item.es_Principal == true).id.toString();
            });
        });
    }
    function obtenerAlmacenes(){
        fetchConToken(`Almacenes/GetListByPrincipal`).then(function (response) {
            
            setAlmacenes(response);
            return asignacion.almacenId = response.find(item=>item.es_Ppal == true).id.toString();

        });
    }

    function publicarExistencia(id){
        fetchConToken(`Articulos/ActualizarExistenciaArticulo?articulo_id_tienda=${id}&almacen_principal_id=${asignacion.almacenId}&sucursal_tienda=${getDataSession('sucursal')}`,null,'POST').then(function (response) {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            Swal.fire(t('app.SwalFire.exitoso'), "Existencia actualizada", 'success');
        });
    }


    function publicarPrecio(id){

        fetchConToken(`Articulos/ActualizarPrecioArticulo?articulo_id_tienda=${id}&listaprecio_principal_id=${asignacion.listaPrecioId || listaPrecio[0].id}&sucursal_tienda=${getDataSession('sucursal')}`,null,'POST').then(function (response) {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }
            Swal.fire(t('app.SwalFire.exitoso'), "Existencia actualizada", 'success');
        });
    }



    const columns = [
        { renderCell: RenderButtons, headerName: '', maxWidth: 20 , path: path, opciones: "publicarExistencia,publicarPrecio", publicarExistencia: publicarExistencia,publicarPrecio: publicarPrecio },
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'sku', headerName: 'SKU', flex: 1 },
        { field: 'nombre', headerName: 'Articulo', flex: 1 },
        { field: 'existencia', headerName: 'Exist. Ecommerce', flex: 1 },
        { field: 'precio', headerName: 'Precio Ecommerce', flex: 1,type: 'number',renderCell: ({ value }) => currency(value)  },
        { field: 'skuPrincipal', headerName: 'SKU', flex: 1 },
        { field: 'nombrePrincipal', headerName: 'Articulo', flex: 1 },
        { field: 'existenciaPrincipal', headerName: 'Exist. Principal', flex: 1 },
        { field: 'precioPrincipal', headerName: 'Precio Principal', flex: 1,type: 'number',renderCell: ({ value }) => currency(value)  }
        
    ];


    const handleInput = ({ target }) => {
        console.log(asignacion);
        setAsignacion({
            ...asignacion,
            [target.name]: target.value
        });
    };

    return (
        <>
            <div className='container-fluid'>
                <hr></hr>
                <TituloPagina
                    titulo={t('app.Articulos.lista.titulo')}
                    descripcion={t('app.Articulos.lista.descripcion')}
                />
                <div className='row'>
                    <hr></hr>
                    <div className='col-md-4'>
                        <p>Conexion principal: </p>
                        <p><b>{sucursal.nombre}</b></p>
                    </div>
                    <div className='col-md-4'>
                        <p>Lista de precio:</p>
                        <select
                                            className='form-control'
                                            name='listaPrecioId'
                                            value={asignacion?.listaPrecioId}
                                            onChange={handleInput}
                                            required
                                        >
                                        {listaPrecio.map((rol) => {
                                            return (
                                                <option key={rol.id} value={rol.id}>
                                                {rol.nombre}
                                                </option>
                                            );
                                            })}
                        </select>
                        </div>
                    <div className='col-md-4'>
                        <p>Almacen:</p>
                        <select
                                            className='form-control'
                                            name='almacenId'
                                            value={asignacion?.almacenId}
                                            onChange={handleInput}
                                            required
                                        >
                                        {almacenes.map((rol) => {
                                            return (
                                                <option key={rol.id} value={rol.id}>
                                                {rol.nombre}
                                                </option>
                                            );
                                            })}
                        </select>
                    </div>
                    
                    
                    
                </div>
                <br></br>
                <div className='col-md-12'>
                    <ListTable
                        columns={columns}
                        path={path}
                        url={`Articulos/GetListComparativa?sucursal_Id=${getDataSession('sucursal')}&almacenId=${asignacion?.almacenId || 0}&listaPrecioId=${asignacion?.listaPrecioId || 0}`}
                        isTreeData={true}
                    />
                </div>
            </div>

        </>
    );
};

export { DiferenciasArt };
