import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';
import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';

const AddEdit = ({ history, match }) => {
    const path = match.url;
    const { id } = match.params;
    const isAddMode = !id;
    const { register, handleSubmit, reset } = useForm();
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    
    let [sucursalPrincipal, setSucursalPrincipal] = useState({});
    let [sucursales, setSucursales] = useState();
    let [almacenes, setAlmacenes] = useState();
    let [almacenesSelect, setAlmacenesSelect] = useState([]);


    let [data, setData] = useState(
        {
            nombre: '',
            almacen_Marketplace: '',
            es_Ppal: false,
            consolidar: false,
            direccion: '',
            sucursal_Id: getDataSession('sucursal'),
            sucursal_Origen_Id: 0,
            almacenes_Origen_Ids: '',
            prioridad: 0
        }
    );

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? (target.checked ) : target.value;
        const name = target.name;

        setData({
            ...data,
            [name]: value
        });
    
        if( target.name == "consolidar")
        {
            setAlmacenesSelect([]);
        }
    }

    function handleAlmacenes(event)
    {
        const target = event.target;
        console.log(almacenesSelect);
        if(target.checked )
        {
            if(!almacenesSelect.includes(target.name))
                setAlmacenesSelect([...almacenesSelect, target.name])
        }
        else
        {
            if(almacenesSelect.includes(target.name))
            {
                const almacenSelects = almacenesSelect.filter((elemento) => elemento !== target.name);
                setAlmacenesSelect(almacenSelects);
            }
        }
        console.log(almacenesSelect?.join(','));
    }

    function obtenerPrincipal(){
        fetchConToken(`Sucursales/Principal`).then(function (response) {
            setSucursalPrincipal(response);
        });
    }

    function getAlmacenes()
    {
        fetchConToken(`Almacenes/GetListByPrincipal`).then(response=>{
            setAlmacenes(response);
        });
    }

    function onSubmit() {
        console.log(data);
        if (isAddMode && getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }
        return isAddMode ? create(data) : update(id, data);
    }

    useEffect(() => {
            if(!isAddMode)
            getAlmacen();
            obtenerPrincipal();
            getAlmacenes();
            actualizarTitulo();
    },[]);

    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: isAddMode ? 'Agregar Almacen' : 'Editar Almacen',
          subtitulo: ''
        });
      };
    const getAlmacen = () =>
    {
        return fetchConToken('Almacenes?id='+id)
        .then((response) => {
            setData(response);
            if(response?.almacenes_Origen_Ids.trim().replace('|','').length > 0)
            {
                setAlmacenesSelect(response?.almacenes_Origen_Ids?.split('||').filter(str => str !== '').map(str => str.replace(/\|/g, '')));
            }
            else
            {
                setAlmacenesSelect([]);
            }
        });

    }

    function create(data) {
        data.almacenes_Origen_Ids = '|'+almacenesSelect.join('||') + '|';
        data.sucursal_Origen_Id = sucursalPrincipal?.id;
        return fetchConToken('Almacenes', data, 'POST')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return ;
                }
            Swal.fire('Exito', 'Almacen agregado', 'success');
            history.push('.');
        })
        .catch(alertService.error);
    }

    function update(id,data) {
        data.id = id;
        data.sucursal_Origen_Id = sucursalPrincipal?.id;
        data.almacenes_Origen_Ids = '|'+almacenesSelect.join('||') + '|';
        return fetchConToken('Almacenes', data, 'PUT')
        .then((response) => {
            if (response.hasOwnProperty('status')) {
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return ;
                }
            Swal.fire('Exito', 'Almacen agregado', 'success');
            history.push('..');
        })
        .catch(alertService.error);
    }

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <div className='mb-3'>
                        <div className='row' style={{ textAlign: 'left' }}>
                           

                            <div className='col-md-12 text-right'>
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                    to={isAddMode ? '.' : '..'}
                                    className='btn btn-outline-secondary'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className={ !data.consolidar ? 'col-md-12' : 'col-md-6' } style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='nombre' className='form-label'>
                                        Nombre
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='nombre'
                                        required={true}
                                        value={data.nombre || ''}
                                        onChange={handleInputChange}
                                    />

                                    <label htmlFor='telefono' className='form-label'>
                                    direccion
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='direccion'
                                        required={true}
                                        value={data.direccion || ''}
                                        onChange={handleInputChange}
                                    />

                                    <div className='form-check form-switch'>
                                        <label htmlFor='es_Ppal'>
                                        Es Principal (Almacen para publicar)
                                            <input
                                                type='checkbox'
                                                className='form-check-input'
                                                name='es_Ppal'
                                                role='switch'
                                                onChange={handleInputChange}
                                                checked={data.es_Ppal || false}
                                            />
                                        </label>
                                        &nbsp;&nbsp;
                                    </div>
                                { getDataSession('sucursalPrincipal') === getDataSession('sucursal') ? '' :
                                    <div className='form-check form-switch'>
                                        <label htmlFor='es_Ppal'>
                                        Consolidar
                                            <input
                                                type='checkbox'
                                                className='form-check-input'
                                                name='consolidar'
                                                role='switch'
                                                onChange={handleInputChange}
                                                checked={data.consolidar || false}
                                            />
                                        </label>
                                        &nbsp;&nbsp;
                                    </div> }

                                </div>
                            </div>
                        </div>
                        {data.consolidar ? 
                        <div className='col-md-6'  style={{ display: 'grid' }}>
                            <div className='card mb-3 '>
                                <div className='card-body text-start'>
                                    <label htmlFor='nombre' className='form-label'>
                                        Sucursal Origen
                                    </label>
                                    <p><b>{sucursalPrincipal.nombre}</b></p>
                                    <label htmlFor='nombre' className='form-label'>
                                        Almacenes Origen
                                    </label>
                                    <div>
                                    { almacenes?.map((item) => {
                                        console.log(almacenesSelect);
                                        return  (
                                            <div>
                                            <label htmlFor='nombre' className='form-label'> 
                                            <input
                                                type='checkbox'
                                                className='form-check-input'
                                                name={item.id.toString()}
                                                checked={almacenesSelect?.includes(item.id.toString())}
                                                onChange={handleAlmacenes}
                                            /> {item.nombre}
                                            </label>
                                            </div>
                                        );
                                        }) 
                                    }
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                        : '' }
                       
                    </div>
                </form>
            </div>
        </>
    );
};

// AddEdit.propTypes = {
//     saludo: PropTypes.string
// }

// AddEdit.defaultProps = {
//     subtitulo: "Soy subtitulo"
// }

export { AddEdit };
