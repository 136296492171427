import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import Modal from 'components/modal/Modal';
import { useModal } from 'hooks/useModal';
import Pagination from 'components/pagination/Pagination';

import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import { getDataSession } from 'views/Utils/Common';
import SLUGS from 'resources/slugs';
import { useGlobalState } from 'resources/GlobalStateContext';
import Swal from 'sweetalert2';

const AddEditDireccion = ({ history, match }) => {

    const { id, cliente } = match.params;

    const { globalTitulo, setGlobalTitulo } = useGlobalState();

    const path = match.url;
    const isAddMode = !id;
    const { register, handleSubmit, reset } = useForm();

    let [errores, setErrores] = useState({});
    let [error, setError] = useState(['']);

    let [direccion, setDireccion] = useState({
        cliente_Id: "",
        no_Direccion: "",
        cp: "",
        calle: "",
        no_Exterior: "",
        no_Interior: "",
        colonia: "",
        poblacion: "",
        municipio: "",
        ciudad: "",
        estado: "",
        pais: ""
  });
  const [satPais, setSatPais] = useState(['']);

  const actualizarTitulo = () => {
    setGlobalTitulo({
      ...globalTitulo,
      titulo: isAddMode ? 'Agregar Dirección' : 'Editar Dirección',
      subtitulo: '' 
    });
  };

  async function getSatPais() {
    const response = await fetchConToken(`CatalogosSat/ListPais?pagina=1&totalXpagina=300`);
    setSatPais(response);
}

    function onSubmit() {
      return isAddMode ? create(direccion) : update(id, direccion);
    }

    function obtenerDireccion() {
        fetchConToken('DireccionesClientes?id=' + id ).then(
            (dir) => {
                setDireccion(dir);
            }
        );
    }


    function create(data) {
        data.cliente_Id = cliente;
        data.no_Direccion = "local";

        setErrores({});
        setError('');

      return fetchConToken('DireccionesClientes', data, 'POST')
          .then((response) => {

            if (response.hasOwnProperty('status') ) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                    return;
                });
                return false;
            } else if (response.hasOwnProperty('codigo') ) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

            Swal.fire('Exito', 'Direccion agregado', 'success');
            history.push( SLUGS.clientes + `/edit/${data.cliente_Id}`);

          })
          .catch(alertService.error);
    }

    function update(id, data) {

        setErrores({});
        setError('');

      return fetchConToken('DireccionesClientes', data, 'PUT')
          .then((response) => {

            if (response.hasOwnProperty('status') && response.status === 400) {
                var erroresA = {};
                Object.entries(response.errors).map(([key, value]) => {
                    Swal.fire('Error', value[0], 'error');
                });
                return false;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return false;
            }

              Swal.fire('Exito', 'Direccion modificado', 'success');
              //alertService.success('Direccion modificado', { keepAfterRouteChange: true });
              history.push( SLUGS.clientes + `/edit/${data.cliente_Id}`);
          })
          .catch(alertService.error);
  }

  async function eliminar() {
    Swal.fire({
        title: '¿Desea eliminar esta direccion?',
    icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            fetchConToken(`DireccionesClientes?direccion_id=${id}`, null, 'DELETE')
            .then((response) => {
                if (response.hasOwnProperty('status')) {
                    Object.entries(response?.errors).map(([key, value]) => {
                        Swal.fire('Error', value[0], 'error');
                        return;
                    });
                    return false;
                } else if (response.hasOwnProperty('codigo')) {
                    Swal.fire('Error', response.mensajes[0], 'error');
                    return;
                }
                Swal.fire('Exito', 'Direccion eliminada', 'success');
                history.push( SLUGS.clientes + `/edit/${direccion.cliente_Id}`);
            })
        }})
}

    function handleInputChangeCliente(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      setDireccion({
          ...direccion,
          [name]: value
      });
  }

    useEffect(() => {
        getSatPais();
        if (!isAddMode)
        obtenerDireccion();
        actualizarTitulo();
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <form onSubmit={handleSubmit(onSubmit)} /*onReset=""*/>
                <div className='mb-3'>
                        <div className='col-md-12' >
                                                     
                                      
                               <div className='col-md-12 text-right'>
                               {!isAddMode ? (
                                        <button
                                            className='btn btn-outline-danger'
                                            type='button'
                                            style={{ float: 'left' }}
                                            onClick={() => eliminar()}
                                        >
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='20'
                                                height='20'
                                                fill='currentColor'
                                                className='bi bi-x-lg'
                                                viewBox='0 0 16 16'
                                            >
                                                <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
                                            </svg>
                                            &nbsp; &nbsp; Eliminar
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                    &nbsp;
                                <button className='btn btn-outline-success' type='submit'>
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-check-lg'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                    </svg>
                                    &nbsp; Guardar
                                </button>
                                &nbsp;
                                <Link
                                   to={`/clientes/edit/${isAddMode ? cliente : direccion.cliente_Id}`}
                                    className='btn btn-outline-warning'
                                >
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='20'
                                        fill='currentColor'
                                        className='bi bi-reply'
                                        viewBox='0 0 16 16'
                                    >
                                        <path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.74 8.74 0 0 0-1.921-.306 7.404 7.404 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254a.503.503 0 0 0-.042-.028.147.147 0 0 1 0-.252.499.499 0 0 0 .042-.028l3.984-2.933zM7.8 10.386c.068 0 .143.003.223.006.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96v-.667z' />
                                    </svg>
                                    &nbsp; Regresar &nbsp;
                                </Link>
                            </div>
                           
                        </div>
                    </div>



                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='card-title'>
                                <h5>Direccion del cliente</h5>
                            </div>
                        </div>
                        <p style={{ color: 'red' }}>{error}</p>
                        {Object.entries(errores).map(([key, value]) => {
                            return (
                                <>
                                    <p style={{ color: 'red' }}>{value}</p>
                                </>
                            );
                        })}
                        
                                <div className='col-md-12'>
                                <div className='card mb-3'>
                                  <div className='card-body text-start row'>
                                    <div className='col-md-6'>
                                            
                                                <label htmlFor='cp'>
                                                    Codigo Postal*
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='cp'
                                                    value={direccion.cp}
                                                    onChange={handleInputChangeCliente}
                                                    required
                                                />

                                                <label htmlFor='calle'>
                                                Calle
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='calle'
                                                    value={direccion.calle}
                                                    onChange={handleInputChangeCliente}
                                                />

                                                <label htmlFor='no_Exterior'>No. Exterior</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='no_Exterior'
                                                    value={direccion.no_Exterior}
                                                    onChange={handleInputChangeCliente}
                                                />

                                                <label htmlFor='no_Interior'>No. Interior</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='no_Interior'
                                                    value={direccion.no_Interior}
                                                    onChange={handleInputChangeCliente}
                                                />
                                                <label htmlFor='colonia' >Colonia</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    name='colonia'
                                                    value={direccion.colonia}
                                                    onChange={handleInputChangeCliente}
                                                />

                                                
                                    </div>
                                    <div className='col-md-6'>

                                        <label htmlFor='municipio'>Municipio</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='municipio'
                                            value={direccion.municipio}
                                            onChange={handleInputChangeCliente}
                                        />

                                        <label htmlFor='poblacion'>Poblacion</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='poblacion'
                                            value={direccion.poblacion}
                                            onChange={handleInputChangeCliente}
                                        />

                                        <label htmlFor='ciudad'>Ciudad</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='ciudad'
                                            value={direccion.ciudad}
                                            onChange={handleInputChangeCliente}
                                        />

                                        <label htmlFor='estado'>Estado</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='estado'
                                            value={direccion.estado}
                                            onChange={handleInputChangeCliente}
                                        />

                                        <label htmlFor='pais'>Pais:</label>
                                                <select
                                                    className='form-control'
                                                    name='pais'
                                                    value={direccion.pais || 'México'}
                                                    onChange={handleInputChangeCliente}
                                                    required
                                                >
                                                    {satPais.map((p) => {
                                                    return (
                                                        <option key={p.id} value={p.nombre}>
                                                        {p.clave + ' - ' + p.nombre}
                                                        </option>
                                                    );
                                                    })}
                                                    </select>
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
/*
const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (e) => {
        setValue(e.target.value);
    };
    return {
        value,
        onChange: handleChange
    };
};*/

export { AddEditDireccion };
