import {useTranslation} from "react-i18next";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import  { useState } from 'react';
import PublicIcon from '@mui/icons-material/Public';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
export const LanguageSelector = () =>
{
    const handleClick = (event) => { setAnchorEl(event.currentTarget); };
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [t, i18n] = useTranslation('common');
    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
                        <a
                           
                            onClick={handleClick}
                        >
                          <PublicIcon/> {i18n.language}
                        </a>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'basic-button', }}
                        >
                            <MenuItem onClick={() => {i18n.changeLanguage('es');handleClose();}}>
                                <ListItemIcon>
                                <ChromeReaderModeIcon/>
                                </ListItemIcon>{t('es')}
                            </MenuItem>
                            <MenuItem onClick={() => {i18n.changeLanguage('en');handleClose();}}>
                                <ListItemIcon>
                                <ChromeReaderModeIcon/>
                                </ListItemIcon>{t('en')}
                            </MenuItem>
                        </Menu>
            {/*<div className="row">
                <div className="col-md-6">
                <button onClick={() => i18n.changeLanguage('es')}>{t('es')}</button>
                </div>
                <div className="col-md-6">
                <button onClick={() => i18n.changeLanguage('en')}>{t('en')}</button>
            </div>
</div>*/}
        </>
}