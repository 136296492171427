import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import TituloPagina from 'components/header/TituloPagina';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';
import { currency } from 'resources/utilities';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const columns = [
        {renderCell: RenderButtons ,headerName: '', flex: 1, path: path,opciones: "editar"},
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'nombre', headerName: 'Paquete', flex: 1},
        { field: 'descripcion', headerName: 'Descripcion', flex: 1 },
        { field: 'costo', headerName: 'Costo', flex: 1},
        { field: 'tipo_Paquete', headerName: 'Tipo Paquete' , flex: 1},
        { field: 'dias_Duracion', headerName: 'Dias Duracion', flex: 1 },
        
    ];
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Paquetes de timbres',
          subtitulo:'Lista de los paquetes de timbres.'
        });
      };
      React.useEffect(() => {actualizarTitulo();},[]);

    return (
        <>
            <div className='container-fluid'>
              
                <div className='col-md-12'>
                    <Link to={`${path}/add`} className='btn btn-outline-success'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            fill='currentColor'
                            className='bi bi-plus-square'
                            viewBox='0 0 16 16'
                        >
                            <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                            <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                        </svg>
                        &nbsp; Nuevo
                    </Link>
                    &nbsp; &nbsp;
                </div>
                &nbsp;
               
                <div className='card col-12 col-sm-12 col-md-12'>
                    <div style={{ overflowX: 'auto' }}>
                    <ListTable
                        columns = {columns}
                        path = {path}
                        url={`PaquetesTimbres/PaquetesGetList?`}
                    />
                        {/*<Table
                            columns={[
                                'Paquete',
                                'Descripcion',
                                'Costo',
                                'Tipo Paquete',
                                'Dias Duracion',
                                'Acciones'
                            ]}
                            rows={[
                                ['text', 'nombre'],
                                ['text', 'descripcion'],
                                ['moneda', 'costo'],
                                ['text', 'tipo_Paquete'],
                                ['text', 'dias_Duracion'],
                                ['opciones','id']
                            ]}
                            opciones={"edit"}
                            path={path}
                            url={`/PaquetesTimbres/List`}
                        ></Table>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

List.propTypes = {
    saludo: PropTypes.string
};

List.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { List };
