import React, { useState, useEffect, useRef } from 'react';
import { fetchConToken } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import { MenuDescargas } from '../MenuDescargas';
import { useGlobalState } from 'resources/GlobalStateContext';
import { currency } from 'resources/utilities';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import ListTable from 'components/table/ListTable';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RenderButtons from 'components/table/RenderButtons';
import Swal from 'sweetalert2';
import Modal from 'components/modal/Modal';
import { alertService } from '_services';
import { useModal } from 'hooks/useModal';
import { useTranslation } from 'react-i18next';
import Add_File from 'images/panel/add-image.png'

const List = ({ history, match }) => {
    const { path } = match;
    const { t } = useTranslation('common');
    const seleccionarArchivo = useRef(null);
    const [fechaPago, setFechaPago] = useState(GetFecha(true));
    const [fechaDeposito, setFechaDeposito] = useState(GetFecha(true));
    const [fechaInicio, setFechaInicio] = useState(GetFecha(true));
    const [fechaFin, setFechaFin] = useState(GetFecha(false));
    const [estatus, setEstatus] = useState('00');
    const [estatusExtra, setEstatusExtra] = useState('00');
    const [tipoFecha, setTipoFecha] = useState('Registro');
    const [actualizarTabla, setActualizarTabla] = useState(false);
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    const [asignacion, setAsignacion] = useState('');
    const [isOpenModal, openModal, closeModal] = useModal(false);
    const [modalValor, setModalValor] = useState(false);
    const [cuentasBanco, setCuentasBanco] = useState([]);
    const [cuentaBanco, setCuentaBanco] = useState('');
    const [factura, setFactura] = useState(null);
    const [nombreArchivo, setNombreArchivo] = useState('Ningun archivo seleccionado');
    const [archivo, setArchivo] = useState(null);
    const [dataStatus, setDataStatus] = useState([]);
    const [dataStatusExtra, setDataStatusExtra] = useState([]);
    const [pagado, setPagado] = useState(false);
    const [dataMenu] = useState([
        {
            Titulo: 'Facturas Pagadas',
            Data: [
                { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
                { Titulo: 'Descargar PDF', Slug: 'PDF' }
            ]
        }, {
            Titulo: 'Facturas Faltantes de Pago',
            Data: [
                { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
                { Titulo: 'Descargar PDF', Slug: 'PDF' }
            ]
        }, {
            Titulo: 'Ficha de Pago',
            Data: [
                { Titulo: 'Descargar Excel', Slug: 'EXCEL' },
                { Titulo: 'Descargar PDF', Slug: 'PDF' }
            ]
        }]);

    const actualizarTitulo = () => {
        setGlobalTitulo({
            ...globalTitulo,
            titulo: 'Facturas',
            subtitulo: 'Listado de Facturas'
        });
    };

    const handleClick = () => seleccionarArchivo.current.click();

    const handleInputChangeFileConfig = ({ target }) => {
        if (target && target.files[0]) {
            setNombreArchivo(target.files[0].name);
            setArchivo(target.files[0]);
        }
    };

    const handleClickSubirExcel = () => {
        if (archivo) {
            const formData = new FormData();
            formData.append('empresa_id', getDataSession('empresa'));
            formData.append('sucursal_id', getDataSession('sucursal'));
            formData.append('tipo', 'excel');
            formData.append('FileToUpload', archivo, nombreArchivo);
            fetchConToken('Archivos', formData, 'POST', true).then((response) => { console.log(response) }).catch(alertService.error);
        }
        else Swal.fire('Error', 'Seleccione el archivo', 'error');
    }

    const asignarDatosExtraFactura = (factura_id) => {
        setFechaPago(GetFecha(true));
        setFactura(null);
        setEstatusExtra('00');
        setAsignacion(factura_id);
        loadFactura(factura_id);
        setModalValor('AsignarDatosExtraFactura');
        openModal();
    }

    const verNotasCredito = (factura_id) => {
        setFactura(null);
        setAsignacion(factura_id);
        loadFactura(factura_id);
        setModalValor('VerNotasCredito');
        openModal();
    }

    const validarFormaPagoDatosExtra = (formaPago) => {
        var temp = dataStatus?.find(x => x.clave === formaPago);
        if (temp) return temp.nombre.toUpperCase().includes("EFECTIVO") || temp.nombre.toUpperCase().includes("TARJETA");

        return false;
    }

    const onClickButtonMenu = (slug) => {

    }

    const onClickButtonSaveDatosExtra = () => {
        factura.estatus_Pago = pagado ? 'F' : 'P';
        factura.fecha_Pago = pagado ? fechaPago : null;
        factura.fecha_Deposito = factura.depositado ? fechaDeposito : null;
        fetchConToken('Facturacion/Facturas/ActualizarDatosExtra', factura, 'PUT').then((response) => {
            if (response.hasOwnProperty('status') && response.status === 400) {
                Object.entries(response.errors).map(([key, value]) => { Swal.fire('Error', value[0], 'error'); });
                return;
            } else if (response.hasOwnProperty('codigo') && response.codigo !== 200) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            Swal.fire('Exito', '', 'success');
            closeModal();
        }).catch((e) => Swal.fire('Error', e, 'error'));
    }

    const handleInputChangeFechaPago = ({ target }) => {
        setFechaPago(target.value);
        setFactura({ ...factura, fecha_Pago: target.value });
    };

    const handleInputChangeFechaDeposito = ({ target }) => {
        setFechaDeposito(target.value);
        setFactura({ ...factura, fecha_Deposito: factura.depositado ? target.value : null });
    };

    const handleInputChangeFechaInicio = ({ target }) => {
        setFechaInicio(target.value);
    };

    const handleInputChangeFechaFin = ({ target }) => {
        setFechaFin(target.value);
    }

    const handleChange = ({ target }) => {
        setEstatus(target.value);
    }

    const handleChangeExtra = ({ target }) => {
        setEstatusExtra(target.value);
        setFactura({ ...factura, forma_Pago: target.value });
    }

    const handleChangeTipoFecha = ({ target }) => {
        setTipoFecha(target.value);
    }

    const handleChangeObservaciones = ({ target }) => {
        setFactura({ ...factura, observaciones: target.value });
    }

    const handelChangeDepositado = ({ target }) => {
        setFactura({ ...factura, depositado: target.checked });
    }

    const handelChangePagado = ({ target }) => {
        setPagado(target.checked);
    }

    const handleCargarExcel = (e) => {
        setNombreArchivo('Ningun archivo seleccionado');
        setArchivo(null);
        setModalValor('CargarExcel');
        openModal();
    }

    const loadCuentasBanco = () => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        fetchConToken(`VxCuentaBanco/GetList?sucursal_Id=${getDataSession('sucursal')}`).then((response) => {
            if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            setCuentasBanco(response)
        });
    };

    const loadFormasPago = () => {
        fetchConToken(`CatalogosSat/ListFormaPago?pagina=1&totalXpagina=50`).then((response) => {
            if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            setDataStatusExtra([...response]);
            response.push({ id: 0, clave: '00', nombre: 'Todos' });
            response.sort((a, b) => a.clave.localeCompare(b.clave));
            response.push({ id: -0, clave: 'CC', nombre: 'Cancelado' });
            setDataStatus(response)
        });
    }

    const loadFactura = (factura_id) => {
        if (getDataSession('sucursal') === '0') {
            Swal.fire('Info', 'Seleccione una conexión para continuar.', 'info');
            return;
        }

        fetchConToken(`Facturacion/Facturas/?id=${factura_id}`).then((response) => {
            if (response.hasOwnProperty('codigo')) {
                Swal.fire('Error', response.mensajes[0], 'error');
                return;
            }

            setFactura(response);
            setEstatusExtra(response.forma_Pago);
            setFechaPago(ConverDate(response?.fecha_Pago));
            setFechaDeposito(ConverDate(response?.fecha_Deposito));
            setPagado(response?.estatus_Pago === 'F');
        });
    }

    function GetFecha(inicio) {
        var curr = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        if (!inicio) curr = new Date(curr.getFullYear(), curr.getMonth(), new Date(curr.getFullYear(), curr.getMonth() + 1, 0).getDate());

        curr.setDate(curr.getDate());
        return curr.toISOString().substring(0, 10);
    }

    function ConverDate(fecha) {
        var date = fecha ? new Date(fecha) : new Date();
        date.setDate(date.getDate());
        return date.toISOString().substring(0, 10);
    }

    const columns = [
        { renderCell: RenderButtons, type: 'actions', headerName: '', maxWidth: 20, path: path, opciones: "asignarDatosExtraFactura,verNotasCredito", asignarDatosExtraFactura: asignarDatosExtraFactura, verNotasCredito: verNotasCredito },
        { field: 'id', headerName: 'Id', maxWidth: 10 },
        { field: 'uuid', headerName: 'UUID', minWidth: 50 },
        { field: 'folio', headerName: 'Folio', minWidth: 50 },
        { field: 'fecha_Timbrado', headerName: 'Fecha Timbrado', minWidth: 100 },
        { field: 'fecha_Registro', headerName: 'Fecha Registro', minWidth: 100 },
        { field: 'nombre_Receptor', headerName: 'Cliente', minWidth: 275 },
        { field: 'rfc_Receptor', headerName: 'RFC', minWidth: 100 },
        { field: 'subtotal', headerName: 'SubTotal', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'total_Impuestos', headerName: 'Impuestos', type: 'number', renderCell: ({ value }) => currency(value) },
        { field: 'total', headerName: 'Total', type: 'number', renderCell: ({ value }) => currency(value) }
    ];

    const columnsNotaCredito = [
        { field: 'id', headerName: 'Id', maxWidth: 10 },
        { field: 'folio', headerName: 'Folio', minWidth: 50 },
        { field: 'fecha_Registro', headerName: 'Fecha Registro', minWidth: 200 },
        { field: 'nombre_Cliente', headerName: 'Cliente', minWidth: 300 },
        { field: 'rfc_Cliente', headerName: 'RFC', minWidth: 120 },
        { field: 'importe', headerName: 'Total', type: 'number', renderCell: ({ value }) => currency(value) }
    ];

    useEffect(() => {
        actualizarTitulo();
        loadCuentasBanco();
        loadFormasPago();
    }, []);

    return (
        <>
            <MenuDescargas data={dataMenu} onClickButton={onClickButtonMenu} />
            <div className='espacio10px' />
            <div className='container-fluid'>
                <div className='col-12 col-sm-12 col-md-12 row'>
                    <div className='col-sm-2 container-center'>
                        <button className='btn btn-outline-primary m-1' style={{ width: 'auto' }} onClick={handleCargarExcel}><UploadFileIcon />&nbsp; Cargar Excel</button>
                    </div>
                    <div className='col-sm-3' style={{ display: 'flex' }}>
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Inicio"
                            type="date"
                            value={fechaInicio}
                            onChange={handleInputChangeFechaInicio}
                            InputLabelProps={{ shrink: true, }}
                        />
                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                            id="filled-number"
                            label="Fecha Fin"
                            type="date"
                            value={fechaFin}
                            onChange={handleInputChangeFechaFin}
                            InputLabelProps={{ shrink: true, }}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="cuenta-banco-label">Cuenta de Banco</InputLabel>
                            <Select label="Cuenta de Banco" value={cuentaBanco} onChange={handleChange}>
                                {cuentasBanco?.map((item) => {
                                    return <MenuItem key={item.id} value={item.id}><ListItemText primary={(item.banco + ': ' + item.cuenta)} /></MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-sm-2'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="estatus-label">Forma Pago</InputLabel>
                            <Select label="Estatus Pago" value={estatus} onChange={handleChange}>
                                {dataStatus?.map((item) => {
                                    return <MenuItem key={item.clave} value={item.clave}><ListItemText primary={(item.nombre)} /></MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-sm-2'>
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="fecha-label">Tipo Fecha</InputLabel>
                            <Select label="Tipo Fecha" value={tipoFecha} onChange={handleChangeTipoFecha}>
                                <MenuItem key='Registro' value='Registro'><ListItemText primary='Fecha Registro' /></MenuItem>
                                <MenuItem key='Factura' value='Factura'><ListItemText primary='Fecha Factura' /></MenuItem>
                                <MenuItem key='Pago' value='Pago'><ListItemText primary='Fecha Pago' /></MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='col-md-12'>
                    <ListTable
                        columns={columns}
                        path={path}
                        reload={actualizarTabla}
                        url={`Facturacion/Facturas/CfdisGetList?sucursal_Id=${getDataSession('sucursal')}&fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&tipoFecha=${tipoFecha}&statusPago=${estatus}`} />
                </div>
            </div>
            <Modal isOpen={isOpenModal} closeModal={closeModal} min={modalValor !== 'VerNotasCredito'}>
                {modalValor === 'AsignarDatosExtraFactura' ? (
                    <div className='card text-left'>
                        <div className='card-header'>
                            <div className='espacio5px' />
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h4>Asignar Datos Extra</h4></Stack>
                            <div className='espacio10px' />
                        </div>
                        <div className='espacio15px' />
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h5>FOLIO: {factura?.folio} - UUID: {factura?.uuid}</h5></Stack>
                        <div className='espacio10px' />
                        <div className='container'>
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-1' />
                                <div className='col-sm-3'>
                                    <Stack direction="row" alignItems="center" spacing={1}><FormControlLabel control={<Checkbox checked={pagado} onChange={handelChangePagado} />} label="Fue Pagado" /></Stack>
                                </div>
                                <div className='col-sm-3' style={{ display: 'flex' }}>
                                    <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                        id="filled-number"
                                        label="Fecha Pago"
                                        type="date"
                                        value={fechaPago}
                                        onChange={handleInputChangeFechaPago}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                </div>
                                <div className='col-sm-4' style={{ display: 'flex' }}>
                                    <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                                        <InputLabel id="estatus-label">Forma Pago</InputLabel>
                                        <Select label="Forma Pago" value={estatusExtra} onChange={handleChangeExtra}>
                                            {dataStatusExtra?.map((item) => {
                                                return <MenuItem key={item.clave} value={item.clave}><ListItemText primary={(item.nombre)} /></MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='col-sm-1' />
                            </div>
                            {(factura?.depositado || validarFormaPagoDatosExtra(estatusExtra)) &&
                                <div className='col-12 col-sm-12 col-md-12 row'>
                                    <div className='col-sm-1' />
                                    <div className='col-sm-5'>
                                        <Stack direction="row" alignItems="center" spacing={1}><FormControlLabel control={<Checkbox checked={factura?.depositado} onChange={handelChangeDepositado} />} label="Fue Depositado" /></Stack>
                                    </div>
                                    <div className='col-sm-5' style={{ display: 'flex' }}>
                                        <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                            id="filled-number"
                                            label="Fecha Deposito"
                                            type="date"
                                            value={fechaDeposito}
                                            onChange={handleInputChangeFechaDeposito}
                                            InputLabelProps={{ shrink: true, }}
                                        />
                                    </div>
                                    <div className='col-sm-1' />
                                </div>}
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-1' />
                                <div className='col-sm-10' style={{ display: 'flex' }}>
                                    <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                        id="filled-Observaciones"
                                        label="Observaciones"
                                        value={factura?.observaciones}
                                        onChange={handleChangeObservaciones}
                                        multiline
                                        rows={10}
                                        InputLabelProps={{ shrink: true, }}
                                    />
                                </div>
                                <div className='col-sm-1' />
                            </div>
                            <div className='espacio10px' />
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-8' />
                                <div className='col-sm-3' style={{ display: 'flex' }}>
                                    <button type='button' className='btn btn-outline-primary' onClick={onClickButtonSaveDatosExtra} style={{ width: '100%' }}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; {t('app.botones.guardar')}
                                    </button>
                                </div>
                                <div className='col-sm-1' />
                            </div>
                            <div className='espacio15px' />
                        </div>
                    </div>
                ) : ('')}
                {modalValor === 'CargarExcel' ? (
                    <div className='card text-left'>
                        <div className='card-header'>
                            <div className='espacio5px' />
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h4>Cargar Excel</h4></Stack>
                            <div className='espacio10px' />
                        </div>
                        <div className='espacio10px' />
                        <div className='container'>
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-6 container-center'>
                                    <div style={{ justifyContent: 'flex-start' }}>
                                        <h5>&nbsp; Excel de facturas</h5>
                                        <div className='espacio5px' />
                                        <input type='file' className='input_file_hidden' name='imagen_nueva' onChange={handleInputChangeFileConfig} ref={seleccionarArchivo}
                                            accept=".csv, .xls, .xlsx, text/csv, application/csv,
                                            text/comma-separated-values, application/csv, application/excel,
                                            application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
                                            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                                        <Tooltip title="Seleccionar archivo" className='col-12 col-sm-12 col-md-12 row cursor-pointer-hover' onClick={handleClick}>
                                            <div className='col-sm-4'><IconButton className='img_art_add'><img src={Add_File} className='img_art_add' alt='Add' /></IconButton></div>
                                            <div className='col-sm-8 container-center'><h6>{nombreArchivo}</h6></div>
                                        </Tooltip>
                                        <div className='espacio30px' />
                                    </div>
                                </div>
                                <div className='col-sm-6' style={{ display: 'flex' }}>
                                    <TextField fullWidth sx={{ m: 1, minWidth: 120 }} size="small"
                                        id="filled-number"
                                        label="Resultado"
                                        multiline
                                        rows={8}
                                    />
                                </div>
                            </div>
                            <div className='espacio15px' />
                            <div className='col-12 col-sm-12 col-md-12 row'>
                                <div className='col-sm-5' />
                                <div className='col-sm-3' style={{ display: 'flex' }}>
                                    <button type='button' className='btn btn-outline-primary' onClick={handleClickSubirExcel} style={{ width: '100%' }}>
                                        <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='20'
                                            height='20'
                                            fill='currentColor'
                                            className='bi bi-check-lg'
                                            viewBox='0 0 16 16'
                                        >
                                            <path d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z' />
                                        </svg>
                                        &nbsp; {t('app.botones.guardar')}
                                    </button>
                                </div>
                                <div className='col-sm-4' />
                            </div>
                            <div className='espacio15px' />
                        </div>
                    </div>
                ) : ('')}
                {modalValor === 'VerNotasCredito' ? (
                    <div className='card text-left'>
                        <div className='card-header'>
                            <div className='espacio5px' />
                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}><h4>Notas de crédito</h4></Stack>
                            <div className='espacio10px' />
                        </div>
                        <div className='espacio10px' />
                        <h5>&nbsp; Lista de notas de crédito</h5>
                        <div className='card-body col-md-12 text-right' style={{ padding: 10, height: "50vh" }}>
                            <ListTable
                                columns={columnsNotaCredito}
                                path={path}
                                reload={actualizarTabla}
                                hideSearch
                                url={`VxNotasCredito/GetList?sucursal_Id=${getDataSession('sucursal')}&venta_Id=${factura?.venta_Id ?? 0}`} />
                        </div>
                        <div className='espacio10px' />
                    </div>
                ) : ('')}
            </Modal>
        </>
    );
}

export { List };