import React, { createContext, useContext, useState } from 'react';

// Creamos un nuevo contexto
const GlobalStateContext = createContext();

// Hook personalizado para usar el contexto
export const useGlobalState = () => useContext(GlobalStateContext);

// Proveedor del estado global
export const GlobalStateProvider = ({ children }) => {
  const [globalVariable, setGlobalVariable] = useState([]);
  const [globalTitulo, setGlobalTitulo] = useState({
    titulo: "Plataforma de administración",
    subtitulo: "Sincronizando datos",
  });

  return (
    <GlobalStateContext.Provider value={{ globalVariable, setGlobalVariable, globalTitulo, setGlobalTitulo }}>
      {children}
    </GlobalStateContext.Provider>
  );
};