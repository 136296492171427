import React from 'react';

const TituloPagina = (props) => {
    return <>
            <div className='col-md-12' style={{lineHeight: "1"}}>
              <h4 style={{marginBottom:'1px'}}><b>{props.titulo}</b></h4>
              <small>{props.descripcion}</small>        
            </div>
    </>;
}
export default TituloPagina;
