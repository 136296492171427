import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Add_Img from 'images/panel/add-image.png'
import ClearIcon from '@mui/icons-material/Clear';
import { getDataSession } from 'views/Utils/Common';
import { fetchConToken } from 'helpers/fetch';
import { alertService } from '_services';
import Stack from '@mui/material/Stack';

const GaleriaImg = ({ data, isAdd, isEdit, handleChangeAddImg, handleChangeRemove }) => {
    const { t } = useTranslation("common");
    return (
        <div className='row'>
            <div className='col-md-12' style={{ display: 'grid' }}>
                <div className='card mb-3 '>
                    <label className='form-label title2'>{t('app.Articulos.datosObligatorios')}</label>
                    <div className='card-body text-start'>
                        <label className='form-label'>{t('app.Articulos.form.imagenes')}*</label>
                        <div className='card mb-3 card-img'>
                            <div className='espacio5px' />
                            <div className='list_img'><ListImg data={data} isAdd={isAdd} isEdit={isEdit} handleChangeAddImg={handleChangeAddImg} handleChangeRemove={handleChangeRemove} /></div>
                            <div className='espacio5px' />
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

const ListImg = ({ data, isAdd, isEdit, handleChangeAddImg, handleChangeRemove }) => {
    const handleCliclk = (item) => { if (handleChangeRemove) handleChangeRemove(item); }
    return (<>
        <div className=''>{data && data.length > 0 && (<img className='imgFull' src={data[0]?.urlImagen} alt={data[0]?.clave_Imagen} />)}</div>
        <div className='list_imge'>
            {isAdd && handleChangeAddImg && (<AddImg handleChangeAddImg={handleChangeAddImg} />)}
            <div className='list_imge'>
                <Stack direction="row" spacing={2}>
                    {data && data.map((item) => { return (<Img key={item.clave_Imagen} item={item} isEdit={isEdit && handleChangeRemove} handleCliclk={handleCliclk} />); })}
                </Stack>
            </div>
        </div>
    </>);
}

const Img = ({ item, isEdit, handleCliclk }) => {
    const handleCliclkInter = () => { handleCliclk(item); }
    return (
        <div className='img_art'>
            {isEdit && (<Tooltip title="Delete"><IconButton sx={{ color: 'black' }} className='iconButton' onClick={handleCliclkInter}><ClearIcon /></IconButton ></Tooltip>)}
            <img src={item?.urlImagen} alt={item?.clave_Imagen} />
        </div>
    );
}

const AddImg = ({ handleChangeAddImg }) => {
    const imagen_nueva = useRef(null);
    const handleClick = () => imagen_nueva.current.click();
    const handleInputChangeFileConfig = ({ target }) => {
        if (target && target.files[0]) {
            const formData = new FormData();
            formData.append('empresa_id', getDataSession('empresa'));
            formData.append('sucursal_id', getDataSession('sucursal'));
            formData.append('tipo', 'imagenes');
            formData.append('FileToUpload', target.files[0], target.files[0].name);
            fetchConToken('Archivos', formData, 'POST', true).then((response) => { handleChangeAddImg(response.url_Api); }).catch(alertService.error);
        }
    };

    return (
        <>
            <input type='file' className='input_file_hidden' name='imagen_nueva' onChange={handleInputChangeFileConfig} ref={imagen_nueva} />
            <Tooltip title="Seleccionar"><IconButton className='img_art_add' onClick={handleClick}><img src={Add_Img} className='img_art_add' alt='Add Img' /></IconButton></Tooltip>
        </>
    );
}

export { GaleriaImg, ListImg, AddImg };