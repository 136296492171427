import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fetchConToken, fetchConTokenFile } from 'helpers/fetch';
import { getDataSession } from 'views/Utils/Common';
import Pagination from 'components/pagination/Pagination';
import { alertService } from '_services';
import * as FileSaver from 'file-saver';
import TituloPagina from 'components/header/TituloPagina';
import Table from 'components/table/Table';
import { useGlobalState } from 'resources/GlobalStateContext';
import ListTable from 'components/table/ListTable';
import RenderButtons from 'components/table/RenderButtons';

const List = ({ match, saludo, subtitulo }) => {
    const { path } = match;
    const { globalTitulo, setGlobalTitulo } = useGlobalState();
    let [data, setData] = useState([]);
    const [totalxPagina, setTotalxPagina] = useState(10);
    const [busqueda, setbusqueda] = useState('');

    let [pagina, setPagina] = useState('');

    async function getData(page) {
        setPagina(page)
        const response = await fetchConToken(
            `TiposImpuestos/GetList?paginas=${page}&totalXpagina=${totalxPagina}`
        );
        setData(response);
    }

    async function eliminar(id){
        const response = await fetchConToken(`TiposImpuestos?id=${id}`, null, 'DELETE');
        alertService.success('Impuesto eliminado', {
            keepAfterRouteChange: true,
            autoClose: true
        });
        getData(pagina);
    }
    const actualizarTitulo = () => {
        setGlobalTitulo({
          ...globalTitulo, 
          titulo: 'Impuestos de articulos' ,
          subtitulo: 'Listado de impuestos para los articulos.'
        });
      };
    React.useEffect(() => {
        getData(1);
        actualizarTitulo();
    }, []);

    const handleChangeBusqueda = ({ target }) => {
        setbusqueda(target.value);
    };

    const columns = [
        {renderCell: RenderButtons ,headerName: '', maxWidth: 20 , path: path,opciones: "editar"},
        { field: 'id', headerName: 'Id', width: 70 },
        { field: 'clave', headerName: 'Clave', flex: 1},
        { field: 'nombre', headerName: 'Nombre', flex: 1 },
        { field: 'descripcion', headerName: 'Descripcion', flex: 1},
        { field: 'porcentaje', headerName: 'Porcentaje' , flex: 1},
        { field: 'tipo_Factor', headerName: 'Tipo Factor', flex: 1 }
        
    ];

    return (
        <> 
            <div className='container-fluid'>
                <div className='row row mb-3'>
                    <div className='col-12 col-sm-12 col-md-12' style={{ textAlign: 'right' }}>
                        <Link to={`${path}/add`} className='btn btn-outline-success'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                fill='currentColor'
                                className='bi bi-plus-square'
                                viewBox='0 0 16 16'
                            >
                                <path d='M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z' />
                                <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z' />
                            </svg>
                            &nbsp; Nuevo
                        </Link>
                        &nbsp; &nbsp;
                    </div>
                </div>

                
                <div className='card col-12 col-sm-12 col-md-12'>
                    <div style={{ overflowX: 'auto' }}>
                    <ListTable
                    columns = {columns}
                    path = {path}
                    url={`tiposimpuestos/TiposGetList?`}
                    />
{/*
                    <Table
                        columns={[
                            '#',
                            'Clave',
                            'Nombre',
                            'Descripcion',
                            'Porcentaje',
                            'Tipo Factor'
                        ]}
                        rows={[
                            ['text','id'],
                            ['text', 'clave'],
                            ['text', 'nombre'],
                            ['text', 'descripcion'],
                            ['text', 'porcentaje'],
                            ['text', 'tipo_Factor'],
                        ]}
                        opciones={"edit"}
                        path={path}
                        url={`/tiposimpuestos/ListTable`}
                    ></Table>*/}
                    </div>
                </div>
            </div>
        </>
    );
};

List.propTypes = {
    saludo: PropTypes.string
};

List.defaultProps = {
    subtitulo: 'Soy subtitulo'
};

export { List };
